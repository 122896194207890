import React, {useState} from 'react';
import {Field} from "redux-form";
import _ from 'lodash'
import Card from "../../../../Commun/Card/Card";
import LargeSelectFieldRedux from "../../../../Commun/Inputs/Select/LargeSelectFieldRedux/LargeSelectFieldRedux";
import TextFieldRedux from "../../../../Commun/Inputs/Text/TextFieldRedux";
import SmallSpinner from "../../../../Commun/Spinners/SmallSpinner";
import newImg from "../../../../../assets/images/publicites/new.svg";

const Publicites = (props) => {
    const { plv, dispositions, handleSubmit, commande, publicites, putLoading, putError } = props
    const [step, setStep] = useState(1)
    let putSuccess = putLoading === false && putError === false

    commande && Object.keys(commande).map(k => {
        if (!commande[k]){
            return delete commande[k]
        }
        return false
    })

    return (
        <div className={'p-lg-4'}>
            <Card>
                <form onSubmit={handleSubmit}>
                    <h3 className={'m-0 pb-3 border-bottom border-dark'}>
                        Commandez <span className={'text-color-secondary'}>vos supports publicitaires</span> et <span className={'text-color-secondary'}>dispositions générales</span> en ligne
                    </h3>

                    {
                        !putSuccess && step === 1 && <div>
                            {
                                dispositions.length > 0 && <div className={'mt-5'}>
                                    <h4>Mes <span className={'text-color-secondary'}>Dispositions Générales</span></h4>
                                    <div className="row">
                                        {
                                            dispositions.map(d => (
                                                <div className="col-lg-3 col-md-6 mt-3 position-relative" key={d.id}>
                                                    {d.new && <img src={newImg} style={{zIndex: 10}} alt="Nouveauté" width={50} className={'position-absolute'}/> }
                                                    <Field
                                                        name={`commande[${d.id}]`}
                                                        component={LargeSelectFieldRedux}
                                                        label={d.title_flyer}
                                                        sublabel={d.size_flyer}
                                                        img={d.flyer_image}
                                                        disabled={!d.available}
                                                        unit={d.unit}
                                                    >
                                                        <option value={''}>Choisissez une quantité</option>
                                                        {
                                                            _.range(d.quantity_max).map(q => (
                                                                <option value={q+1} key={q}>{q+1}</option>
                                                            ))
                                                        }
                                                    </Field>
                                                    {
                                                        !d.available && <div className={'w-100 h-100 position-absolute results-search-content'} style={{opacity: '0.8'}}>
                                                            <div className={'results-card w-100 text-center'}>
                                                                <Card><b>Bientôt disponible</b></Card>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            }

                            {
                                plv.length > 0 && <div className={'mt-5'}>
                                    <h4>Mes publicités sur point de vente (<span className={'text-color-secondary'}>PLV</span>)</h4>
                                    <div className="row">
                                        {
                                            plv.map(p => (
                                                <div className="col-lg-3 col-md-6 mt-3 position-relative" key={p.id}>
                                                    {p.new && <img src={newImg} style={{zIndex: 10}} alt="Nouveauté" width={50} className={'position-absolute'}/> }
                                                    <Field
                                                        name={`commande[${p.id}]`}
                                                        component={LargeSelectFieldRedux}
                                                        label={p.title_flyer}
                                                        sublabel={p.size_flyer}
                                                        img={p.flyer_image}
                                                        disabled={!p.available}
                                                        unit={p.unit}
                                                    >
                                                        <option value={''}>Choisissez une quantité</option>
                                                        {
                                                            _.range(p.quantity_max).map(q => (
                                                                <option value={q+1} key={q}>{q+1}</option>
                                                            ))
                                                        }
                                                    </Field>
                                                    {
                                                        !p.available && <div className={'w-100 h-100 position-absolute results-search-content'} style={{opacity: '0.8'}}>
                                                            <div className={'results-card w-100 text-center'}>
                                                                <Card><b>Bientôt disponible</b></Card>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            }

                            <span
                                className={'d-block btn btn-primary mt-5 float-lg-left max-rounded mx-auto'}
                                onClick={() => setStep(2)}
                            >
                                Je valide ma commande
                            </span>

                        </div>
                    }

                    {
                        !putSuccess && step === 2 && <div className={'mt-5'}>
                            <div className="row">
                                <div className="col-lg-6 col-md-12">
                                    <h4>Mes <span className={'text-color-secondary'}>Informations</span> :</h4>
                                    <Field
                                        name={'name'}
                                        component={TextFieldRedux}
                                        label={'Mon cabinet'}
                                    />
                                    <Field
                                        name={'email'}
                                        component={TextFieldRedux}
                                        label={'Mon Email'}
                                    />
                                    <Field
                                        name={'delivery_address'}
                                        component={TextFieldRedux}
                                        label={'Mon adresse de livraison'}
                                    />
                                    <Field
                                        name={'zip_code'}
                                        component={TextFieldRedux}
                                        label={'Code postal'}
                                    />
                                    <Field
                                        name={'city'}
                                        component={TextFieldRedux}
                                        label={'Ville'}
                                    />
                                </div>
                                <div className="col-lg-6 col-md-12">
                                    <h4>Ma <span className={'text-color-secondary'}>Commande</span> :</h4>

                                    {
                                        Object.keys(commande).map(k => (
                                            <p className={'mt-4'} key={k}><i className={'fas fa-circle text-color-secondary mr-4'} /> {commande[k]} - <b>{_.find(publicites, ['id', Number(k)]).title_flyer}</b></p>
                                        ))
                                    }

                                </div>
                            </div>

                            {
                                putLoading
                                    ? <SmallSpinner />
                                    : <div>
                                        <span
                                            className={'d-inline-block btn btn-secondary mt-5 float-left max-rounded'}
                                            onClick={() => setStep(1)}
                                        >
                                            Retour
                                        </span>
                                        <button
                                            className={`d-inline-block btn btn-primary max-rounded mt-5 ml-3 float-left ${_.keys(commande).length === 0 ? 'disabled amo-tooltip' : ''}`}
                                            disabled={_.keys(commande).length === 0}
                                        >
                                            Je valide ma commande
                                            <small className={'tooltiptext'}>Vous n'avez aucune publicité</small>
                                        </button>
                                    </div>
                            }

                        </div>
                    }

                    {
                        putSuccess && <div className={'mt-5'}>
                            <p>Nous vous remercions pour votre commande.</p>
                            <p>Votre commande sera livrée dans un délai de 4 jours ouvrés.</p>
                        </div>
                    }
                </form>
            </Card>
        </div>
    );
};

export default Publicites;
