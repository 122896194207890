import {API_ERROR, API_SUCCESS, apiRequest} from '../../../actions/core/api/api.actions'
import {setError, setErrorResponse, setFilters, setLoader} from '../../../actions/app/ui/ui.actions'
import {
    CONTRACT, CONTRACT_SITUATION,
    FETCH_CONTRACT, POST_CONFIRM_RESIL_HAMON,
    POST_CONTRACT_SITUATION,
    setContract
} from "../../../actions/app/contracts/contracts.actions";
import _ from 'lodash'

import moment from "moment";
import {setNotification} from "../../../actions/core/notifications/notifications.actions";

export const contractsMiddleware = () => next => action => {
    next(action)

    const { payload } = action
    let title = null
    let message = null

    switch (action.type){

        case FETCH_CONTRACT:
            let url = _.includes(action.payload.data, '?') ? action.payload.data : `${action.payload.data}?`

            if (action.payload.filters){
                for (let [key, value] of Object.entries(action.payload.filters)) {
                    url = `${url}&${key}=${value}`
                }
            }

            next([
                apiRequest({body: null, method: 'GET', url, entity: CONTRACT}),
                setFilters({entity: CONTRACT, filters: {...action.payload.filters}}),
                setLoader({state: true, entity: CONTRACT})
            ])
            break

        case `${CONTRACT} ${API_SUCCESS}`:
            next([
                setContract({data: action.payload.data}),
                setError({state: false, entity: CONTRACT}),
                setLoader({state: false, entity: CONTRACT})
            ])
            break

        case `${CONTRACT} ${API_ERROR}`:
            next([
                setError({state: true, entity: CONTRACT}),
                setErrorResponse({entity: CONTRACT, data: action?.payload?.data?.response?.data}),
                setLoader({state: false, entity: CONTRACT})
            ])
            break

        case POST_CONTRACT_SITUATION:
            if(payload.body.type === 'terminate' || payload.body.type === 'rev'){
                payload.body.date = moment(payload.body.date).format('YYYY-MM-DD')
            }

            next([
                apiRequest({body: (payload.body), method: 'POST', url: payload.data, entity: CONTRACT_SITUATION, otherData: payload.otherData}),
                setLoader({state: true, entity: CONTRACT_SITUATION})
            ])
            break

        case `${CONTRACT_SITUATION} ${API_SUCCESS}`:
            if(payload.meta.otherData.type === 'terminate'){
                message= `
                    <p>Le contrat a bien été résilié et un gestionnaire va reprendre le dossier pour pour régulariser les quittances.</p>
                    <p>Le contrat apparaîtra en statut “Résilié” demain sur votre espace.</p>
                `
                title= 'Le contrat a bien été résilié';
            } else if(payload.meta.otherData.type === 'rev'){
                message= `<p>Le contrat a bien été remis en vigueur</p>`
                title= 'Le contrat est remis en vigueur !';
            } else {
                message= `<p>Le contrat a été validé et la situation du contrat est passée en cours</p>`
                title= 'Le contrat a été validé !';
            }

            next([
                setLoader({state: false, entity: CONTRACT_SITUATION}),
                setError({state: false, entity: CONTRACT_SITUATION}),
                setNotification({entity: CONTRACT_SITUATION, body: message, type: 'success', title: title})
            ])
            break

        case `${CONTRACT_SITUATION} ${API_ERROR}`:
            message= `
                <p>Une erreur est survenue.</p><p>${payload.data.response.data.reason}</p>
            `
            next([
                setNotification({entity: CONTRACT_SITUATION, body: message, type: 'error', title: 'Erreur !', timeout: '0'}),
                setError({state: true, entity: CONTRACT_SITUATION}),
                setLoader({state: false, entity: CONTRACT_SITUATION}),
            ])
            break

        case POST_CONFIRM_RESIL_HAMON:
            console.log(action);
            const confirmResil = action.payload.body.confirm_resil;
            next([
                apiRequest({
                    body: action.payload.body,
                    method: 'POST',
                    url: action.payload.data,
                    entity: POST_CONFIRM_RESIL_HAMON,
                    otherData: {
                        ...action.payload.otherData,
                        confirmResil
                    }
                }),
                setLoader({ state: true, entity: POST_CONFIRM_RESIL_HAMON })
            ]);
            break;

        case `${POST_CONFIRM_RESIL_HAMON} ${API_SUCCESS}`:
            const notificationMessage = action.payload.meta.otherData.confirmResil === 'T'
                ? 'Vous nous avez confirmé avoir recu la notificiation de la resiliation de votre ancine contrat'
                : action.payload.meta.otherData.confirmResil === 'F'
                    ? 'Vérifiez auprès de votre ancienne assurance que votre contrat est bien résilié ? Partagez nous l\'info ici dès que vous l\'obtenez !'
                    : 'Merci pour votre retour, un conseiller vous recontacte dans les meilleurs délais'

            next([
                setError({ state: false, entity: POST_CONFIRM_RESIL_HAMON }),
                setLoader({ state: false, entity: POST_CONFIRM_RESIL_HAMON }),
                setNotification({
                    entity: POST_CONFIRM_RESIL_HAMON,
                    // html: `<div><p>${notificationMessage}</p></div>`,
                    type: 'success',
                    title: 'Loi Hamon',
                    body: notificationMessage,
                    confirmButtonText: 'J\'ai compris',
                    successFunction: action.payload.meta.otherData.successFunction
                })
            ]);
            break;

        case `${POST_CONFIRM_RESIL_HAMON} ${API_ERROR}`:
            next([
                setError({ state: true, entity: POST_CONFIRM_RESIL_HAMON }),
                setLoader({ state: false, entity: POST_CONFIRM_RESIL_HAMON }),
                setNotification({
                    entity: POST_CONFIRM_RESIL_HAMON,
                    html: `<p>${action.payload.data.response.data.reason}</p>`,
                    icon: 'error',
                    title: 'Attention !',
                    body: 'Une erreur est survenue',
                    confirmButtonText: 'J\'ai compris'
                })
            ]);
            break;

        default:
            break
    }
    return null
}
