import {connect} from 'react-redux'
import {compose} from 'redux'
import _ from 'lodash'
import ContractFleet from "./ContractFleet";
import {getContractLink} from "../../../../../../../../redux/selectors/links/links.selectors";
import {CONTRACT, fetchContract} from "../../../../../../../../redux/actions/app/contracts/contracts.actions";
import {getContract} from "../../../../../../../../redux/selectors/contracts/contracts.selectors";
import loader from "../../../../../../../Commun/Spinners/Loader/Loader";
import {getLoadingEntity} from "../../../../../../../../redux/selectors/ui/ui.selectors";

const mapStateToProps = state => {
    return {
        query: getContractLink(state),
        contract: getContract(state),
        loaded: getLoadingEntity(state, CONTRACT) === false
    }
}

const mapDispatchToProps = {
    fetchContract
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { query } = stateProps
    const { fetchContract } = dispatchProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => fetchContract({query: _.replace(query, '{id}', ownProps.match.params.id)})
    }
}

const ContractFleetContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader()
)(ContractFleet)

export default ContractFleetContainer
