import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import {formValueSelector, change} from "redux-form";
import _ from 'lodash'
import Declaration from "./Declaration";
import {
    getAccidentsContract,
    getDateAffaireNouvelleContract,
    getDateFinContract, getFleetContract,
    getGarantiesContract, getIdContract, getIsFleetContract
} from "../../../../../../../../../../redux/selectors/contracts/contracts.selectors";
import {
    getAccidentsContractLink,
} from "../../../../../../../../../../redux/selectors/links/links.selectors";
import {fetchAccidentsContract} from "../../../../../../../../../../redux/actions/app/contracts/accidents.contract.actions";
import loader from "../../../../../../../../../Commun/Spinners/Loader/Loader";

const mapStateToProps = state => {
    const selector = formValueSelector('declaration-sinistre')

    return {
        type_sinistre: selector(state, 'type_sinistre'),
        catsinorigin: selector(state, 'catsinorigin'),
        datesurvorigin: selector(state, 'datesurvorigin'),
        pays_lieu_sinistre: selector(state, 'pays_lieu_sinistre'),
        garanties: !getIsFleetContract(state) ? getGarantiesContract(state) : getFleetContract(state).garanties,
        dateFinContrat: getDateFinContract(state),
        dateAfnNouvelle: getDateAffaireNouvelleContract(state),
        isFleet: getIsFleetContract(state),
        queryGetAccidents: _.replace(getAccidentsContractLink(state), '{id}', getIdContract(state)),
        accidents: getAccidentsContract(state)
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    changeValue: (field, value) => change('declaration-sinistre', field, value),
    fetchAccidentsContract: ({query}) => fetchAccidentsContract({query})
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { queryGetAccidents } = stateProps
    const { fetchAccidentsContract } = dispatchProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => fetchAccidentsContract({query: queryGetAccidents})
    }
}

const DeclarationContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader()
)(Declaration)

export default DeclarationContainer
