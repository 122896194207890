import React, {useState} from 'react';
import { withRouter } from "react-router"
import PropTypes from 'prop-types'
import Card from "../../../../../../../../Commun/Card/Card";
import DeclarationContainer from "./Declaration/DeclarationContainer";
import showStep from "./showStep";
import DeclarationBisContainer from "./DeclarationBis/DeclarationBisContainer";
import ReparationContainer from "./Reparation/ReparationContainer";
import AdversairesContainer from "./Adversaires/AdversairesContainer";
import Blesses from "./Blesses/Blesses";
import TemoinsContainer from "./Temoins/TemoinsContainer";
import DocumentsContainer from "./Documents/DocumentsContainer";
import {Field} from "redux-form";
import SmallSpinner from "../../../../../../../../Commun/Spinners/SmallSpinner";
import ChocsContainer from "./Chocs/ChocsContainer";
import ConstatContainer from "./Constat/ConstatContainer";
import PoliceContainer from "./Police/PoliceContainer";
import DeclarationReparation from "./DeclarationReparation/DeclarationReparation";
import CirconstancesContainer from "./Circonstances/CirconstancesContainer";
import DeclarationTerContainer from "./DeclarationTer/DeclarationTerContainer";
import DecCirconstancieeContainer
    from "@/components/App/Layout/Pages/Contracts/ContractDetail/AccidentsContract/AccidentForm/InitForm/DecCirconstanciee/DecCirconstancieeContainer";

const InitForm = (props) => {
    const {
        handleSubmit, nom,
        history, categorie,
        type_sinistre, vehicule, identify,
        blesse, temoin, loading, success,
        postRejectAccident, constatAssureCheck, constatTiersCheck, constatCheck,
        fetchDocumentsAccident, formValues, changeValue,
        accidentResonse
    } = props

    const [validFirstCheck, setValidFirstCheck] = useState(false)
    const [validSecondCheck, setValidSecondCheck] = useState(false)
    const [validThirdCheck, setValidThirdCheck] = useState(false)
    const [emailIsSet, setEmailIsSet] = useState(false)

    const setEmailToClient = cause => {
        if (!emailIsSet){
            postRejectAccident(cause)
            setEmailIsSet(true)
        }
    }

    return (
        <div className={'p-4'}>
            <Card>
                <div>
                    <h3 className={'m-0 pb-3 border-bottom border-dark'}>
                        <span className={'text-color-secondary'}>Votre déclaration de sinistre</span> en ligne - {nom}
                    </h3>

                    <div className="mt-5">
                        <span className={'btn btn-outline-secondary max-rounded'} onClick={() => history.goBack()}><i className={'fas fa-chevron-left mr-3'}/> Retour</span>
                    </div>

                    {
                        success
                            ? <div className={'mt-5'}>
                                {
                                    accidentResonse ?
                                        <p>{accidentResonse}</p> :
                                        <>
                                            <p>Votre déclaration de sinistre est enregistrée, avec les
                                                réserves habituelles de garanties.</p>
                                            <p>Votre conseiller expert de notre service indemnisation
                                                reviendra vers vous dans un délai maximum de 48 heures.</p>
                                        </>
                                }
                            </div>
                            : <div className="mt-5 col-lg-10 col-md-12 m-auto">
                                <form onSubmit={handleSubmit} onKeyPress={event => {
                                    if (event.which === 13 /* Enter */) {
                                        event.preventDefault();
                                    }
                                }}>

                                    <div className="row mt-5">
                                        <div className="col">
                                            <DeclarationContainer setValidFirstCheck={setValidFirstCheck} setValidSecondCheck={setValidSecondCheck} setEmailToClient={setEmailToClient} fetchDocumentsAccident={fetchDocumentsAccident} />
                                        </div>
                                    </div>

                                    {
                                        validFirstCheck && <div className="row mt-5">
                                            <div className="col">
                                                <DeclarationTerContainer />
                                            </div>
                                        </div>
                                    }

                                    {
                                        validFirstCheck && showStep({step: 'declarationBis', categorie, typeSinistre: type_sinistre}) && <>
                                            <div className="row mt-5">
                                                    <div className="col">
                                                        <DeclarationBisContainer setValidSecondCheck={setValidSecondCheck} setEmailToClient={setEmailToClient} fetchDocumentsAccident={fetchDocumentsAccident}/>
                                                    </div>
                                            </div>
                                        </>
                                    }

                                    {
                                        validSecondCheck && <div>
                                            {
                                                showStep({step: 'constat', typeSinistre: type_sinistre}) && <>
                                                <div className="row mt-5">
                                                    <div className="col">
                                                        <ConstatContainer/>
                                                    </div>
                                                </div>
                                                {(constatCheck === 'F' || constatTiersCheck === 'F') &&
                                                    <div className="row mt-5">
                                                        <div className="col">
                                                            <DecCirconstancieeContainer/>
                                                        </div>
                                                    </div>
                                                }
                                                {(constatAssureCheck === 'F' || constatTiersCheck === 'F') &&
                                                    <div className="row mt-5">
                                                        <div className="col">
                                                            <PoliceContainer fetchDocumentsAccident={fetchDocumentsAccident}/>
                                                        </div>
                                                    </div>
                                                }
                                            </>
                                            }
                                            {
                                                showStep({step: 'adversaires', categorie, identify}) && <div className="row mt-5">
                                                    <div className="col">
                                                        <AdversairesContainer />
                                                    </div>
                                                </div>
                                            }

                                            {
                                                showStep({step: 'blesses', blesse}) && <div className="row mt-5">
                                                    <div className="col">
                                                        <Blesses />
                                                    </div>
                                                </div>
                                            }

                                            {
                                                showStep({step: 'temoin', categorie, temoin}) && <div className="row mt-5">
                                                    <div className="col">
                                                        <TemoinsContainer />
                                                    </div>
                                                </div>
                                            }


                                            {
                                                showStep({step: 'circonstances', typeSinistre: type_sinistre}) && <>
                                                    <div className="row mt-5">
                                                        <div className="col">
                                                            <CirconstancesContainer form={formValues} changeValue={changeValue} setValidThirdCheck={setValidThirdCheck}/>
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                            {
                                                validThirdCheck && <div>
                                                    {
                                                        showStep({step: 'chocs', typeSinistre: type_sinistre}) && <>
                                                            <div className="row mt-5">
                                                                <div className="col">
                                                                    <ChocsContainer/>
                                                                </div>
                                                            </div>
                                                            <div className="row mt-5">
                                                                <div className="col">
                                                                    <DeclarationReparation/>
                                                                </div>
                                                            </div>
                                                        </>
                                                    }
                                                </div>
                                            }
                                            {
                                                showStep({step: 'reparation', categorie, vehicule}) && <div className="row mt-5">
                                                    <div className="col">
                                                        <ReparationContainer />
                                                    </div>
                                                </div>
                                            }

                                            <Field
                                                component={'input'}
                                                type={'text'}
                                                hidden
                                                name={'risque'}
                                            />
                                        </div>
                                    }
                                    {
                                        (type_sinistre !== 'accident' && validSecondCheck) &&
                                        <>
                                            <div className="row mt-5">
                                                <div className="col">
                                                    <DocumentsContainer />
                                                </div>
                                            </div>
                                            {
                                                loading
                                                    ? <SmallSpinner/>
                                                    : <button className={'btn btn-primary mt-5 float-right max-rounded'}>Enregistrer la déclaration</button>
                                            }
                                        </>
                                    }
                                    {
                                        (type_sinistre === 'accident' && validThirdCheck) &&
                                            <>
                                                <div className="row mt-5">
                                                    <div className="col">
                                                        <DocumentsContainer />
                                                    </div>
                                                </div>
                                                {
                                                    loading
                                                        ? <SmallSpinner/>
                                                        : <button className={'btn btn-primary mt-5 float-right max-rounded'}>Enregistrer la déclaration</button>
                                                }
                                            </>
                                    }
                                </form>
                            </div>
                    }

                </div>
            </Card>
        </div>
    );
};

InitForm.defaultProps = {
    nom: '',
    garanties: []
};

InitForm.propTypes = {
    nom: PropTypes.string,
    garanties: PropTypes.array
};

export default withRouter(InitForm);
