import {connect} from 'react-redux'
import {compose} from 'redux'
import _ from 'lodash'
import SendSignatures from "./SendSignatures";
import {contractsSendSignLink} from "../../../../../../../../redux/selectors/links/links.selectors";
import {
    POST_SEND_SIGNATURE,
    postSendSignatures
} from "../../../../../../../../redux/actions/app/signatures/signatures.actions";
import {formValueSelector, reduxForm} from "redux-form";
import {getLoadingEntity} from "../../../../../../../../redux/selectors/ui/ui.selectors";

const mapStateToProps = state => {
    const selector = formValueSelector(`sendSignature`)

    return {
        uri: contractsSendSignLink(state),
        method: selector(state, 'method'),
        loading: getLoadingEntity(state, POST_SEND_SIGNATURE)
    }
}

const mapDispatchToProps = {
    postSendSignatures,
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { uri } = stateProps
    const { postSendSignatures } = dispatchProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        onSubmit: (form) => postSendSignatures({query: _.replace(uri, '{id}', ownProps.location.match.params.id) , form})
    }
}

const SendSignaturesContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    reduxForm({
        form: 'sendSignature'
    }),
)(SendSignatures)

export default SendSignaturesContainer
