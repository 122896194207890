import {API_ERROR, API_SUCCESS, apiRequest} from '../../../actions/core/api/api.actions'
import {setError, setLoader} from '../../../actions/app/ui/ui.actions'
import {POST_UPLOAD, UPLOAD} from "../../../actions/app/upload/upload.actions";
import {setNotification} from "../../../actions/core/notifications/notifications.actions";

export const uploadMiddleware = () => next => action => {
    next(action)

    switch (action.type){
        case POST_UPLOAD:
            let dataForm = new FormData()

            for (let [key, value] of Object.entries(action.payload.body)) {
                for (let [vKey, vValue] of Object.entries(value)) {
                    dataForm.append(`${key}[${vKey}]`, vValue)
                }
            }

            next([
                apiRequest({body: dataForm, method: 'POST', url: action.payload.data, entity: UPLOAD, otherData: action.payload.meta}),
                setLoader({state: true, entity: action.payload.meta})
            ])
            break

        case `${UPLOAD} ${API_SUCCESS}`:
            next([
                setNotification({entity: action.payload.meta.otherData, body: 'Le document a bien été envoyé. Un gestionnaire s\'engage à traiter la pièce sous 10 jours.', title: 'Merci !', type: 'success', timeout: '10000'}),
                setError({state: false, entity: action.payload.meta.otherData}),
                setLoader({state: false, entity: action.payload.meta.otherData})
            ])
            break

        case `${UPLOAD} ${API_ERROR}`:
            next([
                setNotification({entity: action.payload.meta.otherData, body: 'Une erreur est survenue, votre fichier n\'a pas été envoyé', title: 'Attention !', type: 'error'}),
                setError({state: true, entity: action.payload.meta.otherData}),
                setLoader({state: false, entity: action.payload.meta.otherData})
            ])
            break

        default:
            break
    }
    return null
}
