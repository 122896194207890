import React, { useState, useEffect } from 'react';
import moment from 'moment';
import _ from 'lodash';
import { Link } from "react-router-dom";

const DocumentsContract = (props) => {
    const { documents, upload_app_link, contract } = props;

    // État pour stocker l'adhésion sélectionnée
    const [selectedAdhesion, setSelectedAdhesion] = useState('');

    // Extraction des adhésions uniques des documents
    const uniqueAdhesions = _.uniqBy(documents, 'nb_piece');

    // Filtrer les documents par adhésion sélectionnée
    const filteredDocuments = selectedAdhesion ? documents.filter(doc => doc.nb_piece === selectedAdhesion) : [];

    // Déterminer l'adhésion la plus récente lors du chargement initial
    useEffect(() => {
        if (uniqueAdhesions.length > 0) {
            const recentPiece = Math.max(...uniqueAdhesions.map(adhesion => parseInt(adhesion.nb_piece))); // Convertir en nombre pour trouver le maximum
            setSelectedAdhesion(recentPiece.toString()); // Stocker en tant que chaîne de caractères
        }
    }, []);

    return (
        <div className="table-responsive mt-5">
            {/* Liste déroulante pour sélectionner l'adhésion */}
            <div className="form-group">
                <label htmlFor="adhesion">Sélectionner l'adhésion :</label>
                <select
                    id="adhesion"
                    className="form-control"
                    onChange={(e) => setSelectedAdhesion(e.target.value)}
                    value={selectedAdhesion}
                >
                    <option value="">Toutes les adhésions</option>
                    {uniqueAdhesions.map((adhesion, index) => (
                        <option key={index} value={adhesion.nb_piece}>
                            Pièce n°{adhesion.nb_piece} - Motif {adhesion.motif_piece}
                        </option>
                    ))}
                </select>
            </div>

            {/* Affichage des documents pour l'adhésion sélectionnée */}
            <table className="table">
                <tbody>
                {filteredDocuments.map(d => (
                    <tr key={d.code} className={`${!d.recu && 'hover-background-table'}`}>
                        <td className="font-weight-bold text-left align-middle border-top-0 border-bottom">
                        {
                                !d.recu ?
                                    <i className={'fas fa-circle text-danger mr-2'} />
                                    : d.traite ?
                                        <i className={'fas fa-circle text-success mr-2'} />
                                        : <i className={'fas fa-circle text-warning mr-2'} />
                            }
                            {d.libelle}
                        </td>
                        <td className={' border-top-0 border-bottom'}>
                            {d.commentaire || '-'}
                        </td>
                        <td className={'text-center border-top-0 border-bottom align-middle'}>
                            {
                                !d.recu ?
                                    <div>
                                        <p className={'bg-danger d-inline-block p-1 text-white rounded m-0 w-100 max-width-2'}>
                                            Non reçu
                                        </p>
                                    </div>
                                    : d.traite ?
                                        <div>
                                            <p className={'bg-success d-inline-block p-1 text-white rounded m-0 w-100 max-width-2'}>
                                                Validé
                                            </p>
                                            <p className="text-success m-0">
                                                {d.dateTraitement ? `le ${moment(d.dateTraitement).format('L')}` : '-'}
                                            </p>
                                        </div>
                                        : <div>
                                            <p className={'bg-warning d-inline-block p-1 text-white rounded m-0 w-100 max-width-2'}>
                                                En cours de traitement
                                            </p>
                                        </div>
                            }
                        </td>
                        <td className={' border-top-0 border-bottom'}>
                            {/*{d.download_link &&
                                    <a target={'_blank'} rel="noopener noreferrer" href={d.download_link}>
                                        <i className={'fas fa-search text-color-secondary'} />
                                    </a>
                                }*/}
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
            <div className="d-flex mt-5 justify-content-around p-0">
                <div className="col-6 col-lg-5">
                    <a href={upload_app_link} target={'_blank'} rel="noopener noreferrer">
                        <div className={'border text-dark p-4 hover-translateY hover-border-secondary h-100'}>
                            <h4 className={'mb-3'}>Envoyez les documents sur l'espace sécurisé</h4>
                            <div className={'text-center'}>
                                <span className={'btn btn-outline-secondary max-rounded ml-2'}>
                                    <i className="fas fa-cloud-upload-alt" /> Envoyer les documents
                                </span>
                            </div>
                        </div>
                    </a>
                </div>
                <Link
                    to={`/mon-contrat/envoyer-email/pmq/1309563/${contract.souscripteur.id}/${contract.id}`}>
                    <div className={'border text-dark p-4 hover-translateY hover-border-secondary h-100'}>
                        <h4 className={'mb-3'}>Envoyez le mail de pièces manquantes au client </h4>
                        <div className={'text-center'}>
                            <span className={'btn btn-outline-secondary max-rounded ml-2'}>
                                <i className="fas fa-cloud-upload-alt" /> Envoyer l'email
                            </span>
                        </div>
                    </div>
                </Link>
            </div>
        </div>
    );
};

export default DocumentsContract;
