import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import _ from 'lodash'
import loader from '../../../../Commun/Spinners/Loader/Loader'
import Contacts from './Contacts'
import {CONTACTS, fetchContacts} from "../../../../../redux/actions/app/contacts/contacts.actions";
import {getErrorEntity, getLoadingEntity} from "../../../../../redux/selectors/ui/ui.selectors";
import {getRepresentativesLink} from "../../../../../redux/selectors/links/links.selectors";
import {getFirstSalesAssistantsContacts,getFirstSalesRepresentativeContacts} from "../../../../../redux/selectors/contacts/contacts.selectors";
import {
    getBelairIdUser, getUserIsConcessionnaire, getUserIsConcessionnairePremium,
    getUserIsCourtierPremium
} from "../../../../../redux/selectors/user/user.selectors";

const mapStateToProps = state => {
    return {
        commercial: getFirstSalesRepresentativeContacts(state),
        assistants: getFirstSalesAssistantsContacts(state),
        isCourtierPremium: getUserIsCourtierPremium(state),
        isConcessionnaire: getUserIsConcessionnaire(state),
        isConcessionnairePremium: getUserIsConcessionnairePremium(state),
        query: _.replace(getRepresentativesLink(state), '{id}', getBelairIdUser(state)),
        loaded: getLoadingEntity(state, CONTACTS) === false,
        error: getErrorEntity(state, CONTACTS)
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    fetchContacts: ({query}) => fetchContacts({query})
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { query } = stateProps
    const { fetchContacts } = dispatchProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => fetchContacts({query}),
    }
}

const ContactsContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader()
)(Contacts)

export default ContactsContainer
