import React from 'react';
import PropTypes from 'prop-types'
import {Link} from "react-router-dom";
import './topArticles.scss'

const TopArticles = (props) => {
    const { topArticles, nbArticles } = props

    return (
        <div className={'bg-white px-lg-5 mb-4'}>
            <p className={'h4 my-5 pl-3'}><span className={'text-color-secondary font-weight-bold'}>Les actualités</span> APRIL Moto</p>

            <div className={'row m-0'} id={'top-articles'}>
                {
                    topArticles.map(a => (
                        <div className="col-lg-3 col-md-12 mt-3" key={a.id}>
                            <Link to={`/mon-article/${a.id}`}>
                                <div className={'border bg-white text-center cursor-pointer hover-translateY text-dark h-100'}>
                                    <div>
                                        <img src={a.thumbnail} alt={a.title} className={'img-fluid'}/>
                                    </div>
                                    <div className={'p-4 mb-4'}>
                                        <p className={'h5 my-3 pb-3 text-color-secondary font-weight-bold'}>{a.title}</p>
                                        {a.lead}
                                    </div>
                                </div>
                            </Link>
                        </div>
                    ))
                }
            </div>
            {
                nbArticles > topArticles.length && <div className="col text-center mt-5">
                    <Link to={'/mon-actualite'} className={'btn btn-outline-secondary max-rounded px-5'}>
                        Voir toutes les actualités
                    </Link>
                </div>
            }
        </div>
    );
};

TopArticles.defaultProps = {
    topArticles: [],
    nbArticles: 0
};

TopArticles.propTypes = {
    topArticles: PropTypes.array,
    nbArticles: PropTypes.number
};

export default TopArticles;
