import React from 'react';
import PropTypes from 'prop-types'
import Card from "../../../../../Commun/Card/Card";
import {Link} from "react-router-dom";

const ArticleDetail = (props) => {
    const { article } = props

    return (
        <div className={'p-4'}>
            <Card>
                <div>
                    <h3 className={'m-0 pb-3 border-bottom border-dark'}>
                        <span className={'text-color-secondary'}>{article.title}</span>
                        <Link to={'/'} className={'btn btn-outline-secondary max-rounded px-5 float-right'}>
                            Retour à l'accueil
                        </Link>
                    </h3>
                </div>

                <img src={article.image} alt={article.title} className={'img-fluid my-5'}/>

                <p className={'h4 mb-3'}>{article.lead}</p>

                <div dangerouslySetInnerHTML={{__html: article.content_html}} />
            </Card>
        </div>
    );
};

ArticleDetail.defaultProps = {
    article: {}
};

ArticleDetail.propTypes = {
    article: PropTypes.object
};

export default ArticleDetail;
