import {createSelector} from 'reselect'

const whitelabelSelector = state => state.whitelabel

export const getUrlSiteWhitelabel = createSelector(
    whitelabelSelector,
    whitelabel => whitelabel.url_site
)

export const getNameWhitelabel = createSelector(
    whitelabelSelector,
    whitelabel => whitelabel.name
)

export const getLogoLightUrlWhitelabel = createSelector(
    whitelabelSelector,
    whitelabel => whitelabel.logo_light_url
)

export const getColor1Whitelabel = createSelector(
    whitelabelSelector,
    whitelabel => whitelabel.style_color1
)

export const getColor2Whitelabel = createSelector(
    whitelabelSelector,
    whitelabel => whitelabel.style_color2
)
