import React, {useState} from 'react';

const Collapse = (props) => {
    const { title, children, className } = props
    const [collapse, setCollapse] = useState(false)

    return (
        <div className={`cursor-pointer mb-2 ${className}`}>
            <p className={'p-3 background-color-secondary text-light font-weight-bold rounded-top m-0'} onClick={() => setCollapse(!collapse)}>
                {title} <i className={`fas fa-chevron-${collapse ? 'down' : 'right'} float-right`} />
            </p>
            {
                collapse && <div className={'border-color-secondary border'}>
                    {children}
                </div>
            }
        </div>
    );
};

export default Collapse;
