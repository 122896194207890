export const PROFIL = '[Profil]'

export const SET_PROFIL = `${PROFIL} Set`
export const POST_PROFIL = `${PROFIL} Post`

export const setProfil = ({user}) => ({
    type: SET_PROFIL,
    payload: {
        data: user
    }
})

export const postProfil = ({form, query, idApporteur}) => ({
    type: POST_PROFIL,
    payload: {
        data: query,
        body: form
    },
    meta: {
        idApporteur,
        isBu: true
    }
})
