import React, {useEffect, useState} from 'react';
import {Field} from "redux-form";
import moment from "moment";
import _ from 'lodash'
import {Link} from "react-router-dom";
import DatePickerFieldRedux from "../../../../../../Commun/Inputs/Date/DatePickerFieldRedux";
import SmallSpinner from "../../../../../../Commun/Spinners/SmallSpinner";
import RadioDesignButton from "../../../../../../Commun/Inputs/Radio/RadioDesignButton/RadioDesignButton";
import SelectFieldRedux from "../../../../../../Commun/Inputs/Select/SelectFieldRedux";
import TextFieldRedux from "../../../../../../Commun/Inputs/Text/TextFieldRedux";
import UploadInlineContainer from "../../../../../../Commun/Inputs/Upload/UploadInline/UploadInlineContainer";
import {
    getBrandsByTypes,
    getSizesByWheels,
    getTypesByWheels,
    getVehiculesByWheels
} from "../../../../../../../utils/function";
import {normalizeImmatriculation} from "../../../../../../../utils/normalize";

const AddFleet = (props) => {
    const { loading, handleSubmit, id, valid, typeSelected, brandSelected, changeValue, file, success, reset, numeroPolice } = props
    const [ types, setTypes ] = useState([])
    const [ brands, setBrands ] = useState([])
    const [ sizes, setSizes ] = useState([])
    const [ vehicules, setVehicules ] = useState([])
    const [ formSend, setFormSend ] = useState(false)

    useEffect(() => {
        (async function fetchTypesVehicules(){
            let types = await getTypesByWheels()
            _.forEach(types, t => {
                t.value = t.id
                t.label = t.name
            })
            _.remove(types, t => _.includes(['quad'], t.id))
            setTypes(types)
        })()
        setFormSend(typeSelected && success)
    }, [success])

    async function fetchBrandsVehicules(type){
        let brands = await getBrandsByTypes({type})
        setBrands(_.orderBy(brands, ['name']))
    }

    async function fetchSizesVehicules({type, brand}){
        let sizes = await getSizesByWheels({type, brand})
        setSizes(sizes)
        setVehicules([])
    }

    async function fetchVehicules({type, brand, size}){
        let vehicules = await getVehiculesByWheels({type, brand, size})
        setVehicules(_.orderBy(vehicules, ['modele']))
    }


    return (
        <div>
            <h3 className={'m-0 pb-3 border-bottom border-dark'}>
                <span className={'text-color-secondary'}>Ajout d'un véhicule</span> dans votre flotte
            </h3>

            <div className="mt-5">
                <Link className={'btn btn-outline-secondary max-rounded'} to={`/mon-contrat/${id}`}>
                    <i className={'fas fa-chevron-left mr-3'}/> Retour au contrat
                </Link>
            </div>

            {
                formSend && typeSelected ? <div className="mt-5 col-lg-6 col-md-12 mx-auto border text-center p-5">
                        <h3 className={'text-color-primary'}>La demande a bien été enregistrée, les informations seront visibles d'ici 24h.</h3>

                        <div className="mt-4">
                            <h3 className={'text-color-primary'}>Un mail de confirmation va vous être envoyé.</h3>
                        </div>

                        <div className="mt-4">
                            <button className={`btn btn-secondary max-rounded mx-3`} onClick={() => {setFormSend(false);reset('add-fleet')}}>
                                Ajouter un autre véhicule
                            </button>
                        </div>

                    </div>
                    : <form onSubmit={handleSubmit} className={'mt-5 col-lg-6 col-md-12'}>
                        {
                            types.length === 0 ? <><label>Le type de véhicule :</label> <SmallSpinner /></> : <Field
                                name={'type'}
                                component={RadioDesignButton}
                                label={'Le type de véhicule :'}
                                data={types}
                                onChange={(e) => fetchBrandsVehicules(e.target.value)}
                            />
                        }

                        {
                            typeSelected && <>
                                <Field
                                    name={'marque'}
                                    component={SelectFieldRedux}
                                    label={'La marque du véhicule :'}
                                    onChange={(e) => fetchSizesVehicules({type: typeSelected, brand: e.target.value})}
                                >
                                    <option value={null} />
                                    {
                                        brands.map(b => (
                                            <option key={b.id} value={b.name}>{b.name}</option>
                                        ))
                                    }
                                </Field>

                                <Field
                                    name={'cylindree'}
                                    component={SelectFieldRedux}
                                    label={'La cylindrée du véhicule :'}
                                    onChange={(e) => fetchVehicules({type: typeSelected, brand: brandSelected, size: e.target.value})}
                                >
                                    <option value={null} />
                                    {
                                        sizes.map((s, i) => (
                                            <option key={i} value={s}>{s}</option>
                                        ))
                                    }
                                </Field>

                                <Field
                                    name={'vehicule'}
                                    component={SelectFieldRedux}
                                    label={'Le modèle du véhicule :'}
                                    onChange={(e) => {
                                        changeValue('modele', _.find(vehicules, {'sra': e.target.value}).modele)
                                        changeValue('sra', _.find(vehicules, {'sra': e.target.value}).sra)
                                        changeValue('alimentation', _.find(vehicules, {'sra': e.target.value}).alimentation)
                                    }}
                                >
                                    <option value={null} />
                                    {
                                        vehicules.map((v, i) => (
                                            <option key={i} value={v.sra}>{v.modele} {v.cylindree !== 50 && v.version} ({v.annee_debut_version}{v.annee_fin_version && ` - ${v.annee_fin_version}`})</option>
                                        ))
                                    }
                                </Field>
                            </>
                        }

                        <Field
                            name={'date_mec'}
                            component={DatePickerFieldRedux}
                            label={'Date de mise en circulation du véhicule :'}
                            maxDate={moment()}
                        />

                        <Field
                            name={'date_achat'}
                            component={DatePickerFieldRedux}
                            label={'Date d\'achat du véhicule :'}
                        />

                        <Field
                            name={'immatriculation'}
                            component={TextFieldRedux}
                            label={'Immatriculation du véhicule :'}
                            normalize={normalizeImmatriculation}
                        />


                        {
                            numeroPolice !== "61866386/1" && <Field
                                name={'assistance'}
                                component={RadioDesignButton}
                                label={"Inclure l'assistance panne 0km ?"}
                                data={[{value: 'T', label:'Oui'}, {value: 'F', label: 'Non'}]}
                            />
                        }

                        <Field
                            component={UploadInlineContainer}
                            name={'file'}
                            formName='add-fleet'
                            label={'Carte grise :'}
                            code={'file'}
                            file={file}
                            isForm
                        />

                        {
                            loading ? <SmallSpinner /> : <button className={`${!valid && 'disabled'} btn btn-primary max-rounded`}>
                                Je valide l'entrée du véhicule
                            </button>
                        }
                    </form>
            }

        </div>
    );
};

AddFleet.defaultProps = {};

AddFleet.propTypes = {};

export default AddFleet;
