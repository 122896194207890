export const USER = '[User]'
export const USER_REMINDER_PREFERENCES = '[User Reminder Preferences]'

export const FETCH_USER = `${USER} Fetch`
export const SET_USER = `${USER} Set`
export const POST_USER_REMINDER_PREFERENCES_PMQ = `${USER_REMINDER_PREFERENCES} PMQ Post`
export const SET_USER_REMINDER_PREFERENCES_PMQ = `${USER_REMINDER_PREFERENCES} Set`

export const fetchUser = ({query}) => ({
    type: FETCH_USER,
    payload: {
        data: query
    }
})

export const setUser = ({user}) => ({
    type: SET_USER,
    payload: {
        data: user
    }
})

export const postUserReminderPreferences = ({values, query}) => ({
    type: POST_USER_REMINDER_PREFERENCES_PMQ,
    payload: {
        data: query,
        body: values,
        otherData: {
            pmq_reminder_activate: values.pmq_reminder_activate
        }
    }
})

export const setUserPMQReminder = ({value}) => ({
    type: SET_USER_REMINDER_PREFERENCES_PMQ,
    payload: {
        data: value
    }
})
