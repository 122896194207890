import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import {change, formValueSelector} from 'redux-form'
import Adversaires from "./Adversaires";

const mapStateToProps = state => {
    const selector = formValueSelector('declaration-sinistre')

    return {
        adversaires: selector(state, 'adversaires'),
        nbVehicules: selector(state, 'nb_vehicules')
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    changeValue: (field, value) => change('declaration-sinistre', field, value)
}, dispatch)

const AdversairesContainer = connect(mapStateToProps, mapDispatchToProps)(Adversaires)

export default AdversairesContainer
