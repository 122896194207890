import React from 'react';
import _ from "lodash";

const GarantiesContract = (props) => {
    const { garanties, accessoire } = props

    return (
        <div className="table-responsive">
            <table className="table table-hover">
                <thead>
                <tr>
                    <th scope="col">Libellé</th>
                    <th scope="col">Capital</th>
                    <th scope="col">Franchise</th>
                </tr>
                </thead>
                <tbody>
                {
                    garanties.map(g => {
                        if (!_.includes(['FRAIS_GESTION', 'FRAIS_FRAC', 'FRAIS_VARIATION'], g.code_famille)){
                            return <tr key={g.code}>
                                <td>{g.libelle}</td>
                                <td>{g.capital > 0 ? `${g.capital} €` : "-"}</td>
                                <td>{g.franchise}</td>
                            </tr>
                        }
                    })
                }
                <tr>
                    <td>Valeur des accessoires et équipements</td>
                    <td>{accessoire > 0 ? `${accessoire} €` : '-'}</td>
                    <td>&nbsp;</td>
                </tr>
                </tbody>
            </table>
        </div>
    );
};

export default GarantiesContract;