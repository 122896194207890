import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import loader from '../../../../Commun/Spinners/Loader/Loader'
import Souscriptions from './Souscriptions'
import {getQuotesLink} from "../../../../../redux/selectors/links/links.selectors";
import {fetchQuote, QUOTE} from "../../../../../redux/actions/app/quotes/quotes.actions";
import {getUserIsConcessionnaire} from "../../../../../redux/selectors/user/user.selectors";
import {getLoadingEntity} from "../../../../../redux/selectors/ui/ui.selectors";

const mapStateToProps = state => {
    return {
        isConcessionnaire: getUserIsConcessionnaire(state),
        filters: {sort: '-id', status: "won", acteGestion: getUserIsConcessionnaire(state) ? 'AN' : ''},
        query: getQuotesLink(state),
        loaded: getLoadingEntity(state, QUOTE) === false
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    fetchQuote: ({query, filters}) => fetchQuote({query, filters})
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { query, filters } = stateProps
    const { fetchQuote } = dispatchProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => fetchQuote({query, filters}),
        fetchQuote: ({filters}) => fetchQuote({query, filters})
    }
}

const SouscriptionsContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader()
)(Souscriptions)

export default SouscriptionsContainer
