import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import Dashboard from "./Dashboard";
import {getBuChildrenUser} from "../../../../../redux/selectors/user/user.selectors";
import {fetchDashboard} from "../../../../../redux/actions/app/dashboard/dashboard.actions";
import {getDashboardLink, getStatsLink} from "../../../../../redux/selectors/links/links.selectors";

const mapStateToProps = state => {
    return {
        query: getDashboardLink(state),
        apporteurs: getBuChildrenUser(state),
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    fetchDashboard: ({query, filters}) => fetchDashboard({query, filters})
}, dispatch)

const DashboardContainer = compose(
    connect(mapStateToProps, mapDispatchToProps)
)(Dashboard)

export default DashboardContainer
