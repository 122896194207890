import {connect} from 'react-redux'
import FormulaireProducts from './FormulaireProducts'
import {getBuChildrenUser, getSubscribeLinksUser} from "../../../../../redux/selectors/user/user.selectors";

const mapStateToProps = (state) => {

    return {
        buChildren: getBuChildrenUser(state),
        subscribeLinks: getSubscribeLinksUser(state)
    }
}

const FormulaireProductsContainer = connect(mapStateToProps)(FormulaireProducts)

export default FormulaireProductsContainer
