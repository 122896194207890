import {SET_ACCIDENTS} from "../../actions/app/accidents/accidents.actions";

export const accidentsReducer = (accident = {}, action = {}) => {
    const {payload} = action

    if (action.type === SET_ACCIDENTS) {
        return payload.data
    } else {
        return accident
    }
}
