import React, {useEffect} from 'react';
import Card from "../../../../../Commun/Card/Card";
import Cadre from "../../../../../Commun/Cadre/Cadre";
import {formatPhone} from "../../../../../../utils/function";
import moment from 'moment'
import LargeSpinner from "../../../../../Commun/Spinners/LargeSpinner/LargeSpinner";

const DevisDetail = (props) => {
    const { souscripteur, conducteur, vehicule, quote, fetchQuote, query, reprise, isCourtier, isWakam } = props
    const { rue1, rue2, codePostal, ville, email, phone } = souscripteur
    const { nom, prenom, naissance, permis, numPermis,
        nbMoisAssuranceMoto,
        bonusMoto,
        nbMoisAssuranceAuto,
        bonusAuto,
        nbSinistres
    } = conducteur
    const { dateMEC, immatriculation, usage } = vehicule
    const {
        id,
        libelleFormule,
        libelleProduit,
        endValidity,
        desiredDate,
        createdDate,
        fractionnement,
        primeFractionneTTC,
        primeFA,
        tauxApporteur,
        honoraire,
        urlSouscription,
        urlReprise,
        urlPdf
    } = quote

    useEffect(() => {
        if (Number(props.match.params.id) !== id){
            fetchQuote({query})
        }
    })

    if (reprise){
        window.location.href = urlReprise
        return <LargeSpinner />
    }

    if (moment(endValidity, "DD/MM/YYYY") < moment()){
        return (
            <div className={'p-4'}>
                <Card>
                    <p className={'h5 text-center'}>Votre devis a été réalisé <span className="text-danger">il y a plus de 30 jours</span><br/>ou celui-ci est indisponible.</p>
                    <p className={'h6 text-center mb-3'}>Merci de réaliser une nouvelle proposition ou de nous contacter au 02-47-51-07-07.</p>
                </Card>
            </div>
        )
    }

    return (
        <div className={'p-lg-4'}>
            <Card>
                <div>
                    <h3 className={'m-0 pb-3 border-bottom border-dark'}>
                        <b>{prenom}</b> {nom} - N°&nbsp;<b>{id}</b> / formule&nbsp;:&nbsp;<b>{libelleFormule}</b>
                    </h3>

                    <div className={'mt-5'}>
                        <a href={urlSouscription} target={'_blank'} rel="noopener noreferrer" className={'btn btn-primary max-rounded d-lg-inline-block d-block mt-3 mt-lg-0'}>
                            Souscrire le devis
                        </a>
                        <a href={urlReprise} target={'_blank'} rel="noopener noreferrer" className={'btn btn-secondary max-rounded mx-0 mx-lg-3 d-lg-inline-block d-block mt-3 mt-lg-0'}>
                            Modifier le devis
                        </a>
                        <a href={urlPdf} download className={'btn btn-ternary max-rounded d-lg-inline-block d-block mt-3 mt-lg-0'}>
                            Télécharger le devis
                        </a>
                    </div>

                    <div className={'mt-5 row m-0'}>
                        <div className="col-12 col-lg-6 pl-lg-0 mt-5 mt-lg-0">
                            <Cadre icon={'fas fa-file-alt'} title={'Situation du devis'}>
                                <div className="mt-5 mt-lg-0">
                                    <p>Type de véhicule : <b className={'d-block float-lg-right'}>{libelleProduit}</b></p>
                                    <p>Date de fin de validité : <b className={'d-block float-lg-right'}>{endValidity}</b></p>
                                    <p>Date d'effet souhaitée : <b className={'d-block float-lg-right'}>{desiredDate}</b></p>
                                    <p>Date de création du devis : <b className={'d-block float-lg-right'}>{createdDate}</b></p>
                                    <p>Fractionnement : <b className={'d-block float-lg-right'}>{fractionnement}</b></p>
                                    <p>Prime fractionnée TTC : <b className={'d-block float-lg-right'}>{primeFractionneTTC}&nbsp;€</b></p>
                                    <p>Prime annuelle : <b className={'d-block float-lg-right'}>{primeFA}&nbsp;€</b></p>
                                    <p>Formule : <b className={'d-block float-lg-right'}>{libelleFormule}</b></p>
                                </div>
                            </Cadre>
                        </div>
                        <div className="col-12 col-lg-6 pr-lg-0 mt-5 mt-lg-0">
                            <Cadre icon={'fas fa-user'} title={'Souscripteur / Assuré(e)'}>
                                <div className="mt-5 mt-lg-0">
                                    <p>Adresse : <b className={'d-block float-lg-right'}>{rue1} {rue2}</b></p>
                                    <p>Code Postal - Ville : <b className={'d-block float-lg-right'}>{codePostal} - {ville}</b></p>
                                    <p>Téléphone : <b className={'d-block float-lg-right'}>{formatPhone(phone)}</b></p>
                                    <p>Email : <b className={'d-block float-lg-right'}>{email}</b></p>
                                    { !isWakam && <p>Date de naissance : <b className={'d-block float-lg-right'}>{moment(naissance).format('L')}</b></p> }
                                    {
                                        !isWakam && permis.map(p => (
                                            <p key={p.type} className={'max-width-4'}>Date de permis {p.type} : <b className={'d-block float-lg-right'}>{moment(p.date).format('L')}</b></p>
                                        ))
                                    }
                                    { !isWakam && <p>Numéro de permis : <b className={'d-block float-lg-right'}>{numPermis ? numPermis : '-'}</b></p> }
                                </div>
                            </Cadre>
                        </div>
                    </div>

                    {
                        !isWakam && <div className={'mt-5 row m-0'}>
                            <div className="col-12 col-lg-6 pl-lg-0 mt-5 mt-lg-0">
                                <Cadre icon={'fas fa-motorcycle'} title={'Véhicule'}>
                                    <div className="mt-5 mt-lg-0">
                                        <p>Type de véhicule : <b className={'d-block float-lg-right'}>{libelleProduit}</b>
                                        </p>
                                        <p>Date de mise en circulation : <b
                                            className={'d-block float-lg-right'}>{moment(dateMEC).format('L')}</b></p>
                                        <p>Immatriculation : <b
                                            className={'d-block float-lg-right'}>{immatriculation ? immatriculation : '-'}</b>
                                        </p>
                                        <p>Usage : <b className={'d-block float-lg-right'}>{usage}</b></p>
                                    </div>
                                </Cadre>
                            </div>
                            <div className="col-12 col-lg-6 pr-lg-0 mt-5 mt-lg-0">
                                <Cadre icon={'fas fa-shield-alt'} title={'Antécédents'}>
                                    <div className="mt-5 mt-lg-0">
                                        <p>Ancienneté d'assurance MOTO : <b
                                            className={'d-block float-lg-right'}>{nbMoisAssuranceMoto} mois</b></p>
                                        <p>CRM Moto : <b className={'d-block float-lg-right'}>{bonusMoto} <span
                                            className={'text-lowercase'}>à la date du</span> {moment(createdDate).format('L')}
                                        </b></p>
                                        <p>Ancienneté d'assurance AUTO : <b
                                            className={'d-block float-lg-right'}>{nbMoisAssuranceAuto} mois</b></p>
                                        <p>CRM Auto : <b className={'d-block float-lg-right'}>{bonusAuto} <span
                                            className={'text-lowercase'}>à la date du</span> {moment(createdDate).format('L')}
                                        </b></p>
                                        <p>Sinistres : <b className={'d-block float-lg-right'}>{nbSinistres}</b></p>
                                    </div>
                                </Cadre>
                            </div>
                        </div>
                    }

                    {
                        isCourtier && <div className={'mt-5 row m-0'}>
                            <div className="col p-0">
                                <Cadre large icon={'fas fa-euro-sign'} title={'Gestion, Commission et frais de courtage'}>
                                    <div className="mt-5 mt-lg-0 pt-4 pt-lg-0">
                                        <p>Taux de commissionnement : <b
                                            className={'d-block float-lg-right'}>{tauxApporteur}&nbsp;%</b></p>
                                        <p>Frais de dossier : <b className={'d-block float-lg-right'}>{honoraire}&nbsp;€</b>
                                        </p>
                                    </div>
                                </Cadre>
                            </div>
                        </div>
                    }

                </div>
            </Card>
        </div>
    );
};

export default DevisDetail;
