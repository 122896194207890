import React, {Fragment} from 'react'
import moment from "moment";
import {Link} from "react-router-dom";

const Signatures = (props) => {
    const {signatures, idContrat} = props

    return (
        <Fragment>
            {signatures && signatures.length > 0 &&
                <div className={'col-12 mt-4'}>
                    <div className={'alert alert-warning row'}>
                        <div className={'col-8'}>
                            Une procédure de signature électronique est en cours sur ce contrat. { signatures[0].started_at && `Elle expire le ${moment(signatures[0].started_at).add(30, 'days').format('L')}`}
                        </div>
                        <div className={'col-4'}>
                            <div style={{pointerEvents:'none'}} className={''}>
                                <span className={'btn btn-secondary btn-small float-right disabled amo-tooltip'}>Renvoyer la procédure<small className={'tooltiptext'}>Momentanement indisponible</small></span>
                            </div>
                            {/*<Link to={`/mon-contrat/${idContrat}/procedure-signature`}
                                  className={'btn btn-secondary btn-small float-right'}
                            >
                                Renvoyer la procédure
                            </Link>*/}
                        </div>
                    </div>
                </div>
            }
        </Fragment>
    )
}

export default Signatures
