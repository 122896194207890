import React from 'react';
import SideBarContainer from "./Sidebar/SideBarContainer";
import PagesContainer from "./Pages/PagesContainer";
import PoppinInformationsContainer from "./PoppinInformations/PoppinInformationsContainer";
import PoppinCharteContainer from "./PoppinCharte/PoppinCharteContainer";

const Layout = (props) => {
    const {isConcessionnaire, isCourtier, isConcessionnairePremium, isConcessionnaireGP, lastDateCharterSigned} = props
    return (
        <div>
            <SideBarContainer />
            <PagesContainer />
            { !lastDateCharterSigned && ( isConcessionnaire || isConcessionnairePremium || isConcessionnaireGP ) && <PoppinCharteContainer />}
            { (isConcessionnaire || isCourtier) && <PoppinInformationsContainer />}
        </div>
    );
};

export default Layout;
