export const PRODUITS = '[Produits]'

export const FETCH_PRODUITS = `${PRODUITS} Fetch`
export const SET_PRODUITS = `${PRODUITS} Set`

export const fetchProduits = ({query}) => ({
    type: FETCH_PRODUITS,
    payload: {
        data: query
    }
})

export const setProduits = ({data}) => ({
    type: SET_PRODUITS,
    payload: {
        data
    }
})
