import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import loader from '../../../../../../Commun/Spinners/Loader/Loader'
import QuittancesContract from './QuittancesContract'
import {
    fetchQuittancesContract,
    QUITTANCES_CONTRACT
} from "../../../../../../../redux/actions/app/contracts/quittances.contract.actions";
import _ from 'lodash'
import {getQuittancesContractLink} from "../../../../../../../redux/selectors/links/links.selectors";
import {getIdContract, getQuittancesContract} from "../../../../../../../redux/selectors/contracts/contracts.selectors";
import {getErrorEntity, getLoadingEntity} from "../../../../../../../redux/selectors/ui/ui.selectors";

const mapStateToProps = state => {
    return {
        quittances: getQuittancesContract(state),
        idContrat: getIdContract(state),
        query: _.replace(getQuittancesContractLink(state), '{id}', getIdContract(state)),
        loaded: getLoadingEntity(state, QUITTANCES_CONTRACT) === false,
        error: getErrorEntity(state, QUITTANCES_CONTRACT)
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    fetchQuittancesContract: ({query}) => fetchQuittancesContract({query})
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { fetchQuittancesContract } = dispatchProps
    const { query } = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => fetchQuittancesContract({query})
    }
}

const QuittancesContractContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader()
)(QuittancesContract)

export default QuittancesContractContainer
