import React from 'react';
import LargeSpinner from "../../../../Commun/Spinners/LargeSpinner/LargeSpinner";

const Deconnexion = () => {
    return (
        <div>
            <LargeSpinner />
            <h2 className={'text-center mt-2'}>Déconnexion en cours ...</h2>
        </div>
    );
};

export default Deconnexion;
