import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import loader from '../../../../../../Commun/Spinners/Loader/Loader'
import DocumentsContract from './DocumentsContract'
import {
    DOCUMENTS_CONTRACT,
    fetchDocumentsContract
} from "../../../../../../../redux/actions/app/contracts/documents.contract.actions";
import _ from 'lodash'
import {
    getDocumentsContractLink
} from "../../../../../../../redux/selectors/links/links.selectors";
import {
    getDocumentsContract,
    getIdContract,
    getUploadAppLinkContract
} from "../../../../../../../redux/selectors/contracts/contracts.selectors";
import {getErrorEntity, getLoadingEntity} from "../../../../../../../redux/selectors/ui/ui.selectors";

const mapStateToProps = state => {
    return {
        documents: getDocumentsContract(state),
        query: _.replace(getDocumentsContractLink(state), '{id}', getIdContract(state)),
        upload_app_link: getUploadAppLinkContract(state),
        loaded: getLoadingEntity(state, DOCUMENTS_CONTRACT) === false,
        error: getErrorEntity(state, DOCUMENTS_CONTRACT)
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    fetchDocumentsContract: ({query}) => fetchDocumentsContract({query})
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { fetchDocumentsContract } = dispatchProps
    const { query } = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => fetchDocumentsContract({query})
    }
}

const DocumentsContractContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader()
)(DocumentsContract)

export default DocumentsContractContainer
