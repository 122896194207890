import {connect} from 'react-redux'
import Pages from './Pages'
import {
    getPermissionsUser
} from "../../../../redux/selectors/user/user.selectors";

const mapStateToProps = state => {
    return {
        permissions: getPermissionsUser(state),
        hideSidebarAndHeader: window.location.pathname.includes('reprise')
    }
}

const PagesContainer = connect(mapStateToProps)(Pages)

export default PagesContainer
