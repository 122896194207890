import {
    FETCH_PRODUITS,
    PRODUITS,
    setProduits
} from "../../../actions/app/produits/produits.actions";
import {API_ERROR, API_SUCCESS, apiRequest} from "../../../actions/core/api/api.actions";
import {setLoader, setError} from "../../../actions/app/ui/ui.actions";

export const produitsMiddleware = () => next => action => {
    next(action)

    const { payload } = action

    switch (action.type){
        case FETCH_PRODUITS:
            next([
                apiRequest({body: null, method: 'GET', url: payload.data, entity: PRODUITS}),
                setLoader({state: true, entity: PRODUITS})
            ])
            break

        case `${PRODUITS} ${API_SUCCESS}`:

            next([
                setProduits({data: action.payload.data.data}),
                setError({state: false, entity: PRODUITS}),
                setLoader({state: false, entity: PRODUITS}),
            ])
            break

        case `${PRODUITS} ${API_ERROR}`:
            next([
                setError({state: true, entity: PRODUITS}),
                setLoader({state: false, entity: PRODUITS}),
            ])
            break

        default:
            break
    }
    return null
}
