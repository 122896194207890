import React, {useState, useEffect} from 'react';
import {QUOTE} from "../../../../../redux/actions/app/quotes/quotes.actions";
import {CONTRACT} from "../../../../../redux/actions/app/contracts/contracts.actions";
import {Link, NavLink} from "react-router-dom";
import {PagesRoutes} from "../PagesRoutes";
import _ from "lodash";
import Dropdown from "../../Sidebar/Dropdown/Dropdown";
import { withRouter } from "react-router";
import SearchContainer from "../../../../Commun/Inputs/Search/SearchContainer";
import newImg from "../../../../../assets/images/publicites/new.svg";

const MobileMenu = (props) => {
    const { isConcessionnaire, isCourtier, logo, permissions, userRoles, stats, location: {pathname} } = props
    const [entity, setEntity] = useState(QUOTE)
    const [open, setOpen] = useState(false)

    useEffect(() => {
        setOpen(false)
    }, [pathname])

    const checkRoles = route => {
        if (route.roles){
            let hasRole = false;
            _.map(userRoles, ur => {
                if(_.includes(route.roles, ur)){
                    hasRole = true;
                }
            });

            return hasRole
        }

        return true;
    }

    return (
        <div className={'d-block d-lg-none'}>

            {
                !open && <span onClick={() => setOpen(true)} className={'h2 px-4 py-3 m-0 d-block'}><i className="fas fa-bars" /></span>
            }

            {
                open && <div className={'bg-white'}>
                    <div className={'px-4 pt-0 text-center'}>
                        <span onClick={() => setOpen(false)} className={'h2'} style={{position: 'absolute', top: 15, left: 25}}><i className="fas fa-times" /></span>
                        <Link to="/">
                            <img src={logo} alt="Logo" className={'img-fluid mb-2'} />
                        </Link>
                    </div>

                    <div className={`col p-0 d-flex slider ${(isConcessionnaire || isCourtier) && 'border-left'}`}>
                        {
                            (isConcessionnaire || isCourtier) &&
                            <>
                                <div className="col-2 p-0 min-width-1">
                                    <select style={{fontSize: 14}} className="custom-select focus-border-secondary background-primary no-focus cursor-pointer form-control h-100 rounded-0 border-color-secondary border-top-0 border-left-0 border-right-0" onChange={(e) => setEntity(e.target.value)} value={entity}>
                                        <option value={QUOTE}>Devis</option>
                                        <option value={CONTRACT}>Contrats</option>
                                    </select>
                                </div>
                                <div className="col p-0">
                                    <SearchContainer className={'h-100'} entity={entity} placeholder={`${entity === QUOTE ? 'Rechercher un devis' : 'Rechercher un contrat'}`} />
                                </div>
                            </>
                        }
                    </div>

                    <div className={'mt-4 pl-3'}>
                        {
                            PagesRoutes.map(r => (
                                r.sidebar && checkRoles(r)
                                    ? r.children
                                    ? (_.includes(permissions, r.permission) || r.permission === 'all') && <Dropdown key={r.path} childrenRoutes={r.children} title={r.title} icon={r.icon} permissions={permissions} stats={stats} rStats={r.stats}/>
                                    : (_.includes(permissions, r.permission) || r.permission === 'all') && <NavLink key={r.path} to={r.path} className={'navlink d-block text-dark text-decoration-none my-4'}>
                                        {r.icon && <i className={`${r.icon} mr-2 text-color-secondary`} /> } <span className="title-navlink d-inline-block">{r.title}
                                        {r.new && <img src={newImg} style={{zIndex: 10}} alt="Nouveauté" width={35} className={'ml-2'}/> }
                                        </span>
                                    </NavLink>
                                    : null
                            ))
                        }
                    </div>
                </div>
            }

        </div>
    );
};

export default withRouter(MobileMenu);
