export const GARANTIE_MOBILITE = '[Garantie Mobilite]'

export const POST_GARANTIE_MOBILITE = `${GARANTIE_MOBILITE} Post`

export const postGarantieMobilite = ({form, query, formName}) => ({
    type: POST_GARANTIE_MOBILITE,
    payload: {
        data: query,
        body: form,
        meta: formName
    }
})
