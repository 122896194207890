import moment from 'moment'
import _ from 'lodash'
import {API_ERROR, API_SUCCESS, apiRequest} from '../../../actions/core/api/api.actions'
import {setError, setErrorResponse, setLoader, setPostAccidentResponse} from '../../../actions/app/ui/ui.actions'
import {
    ACCIDENTS, DOCUMENTS_ACCIDENT,
    FETCH_ACCIDENTS,
    FETCH_DOCUMENTS_ACCIDENT, POST_ACCIDENT_REPAIRER, POST_ACCIDENTS,
    setAccidents, setDocumentsAccident
} from "../../../actions/app/accidents/accidents.actions";
import {setNotification} from "../../../actions/core/notifications/notifications.actions";


export const accidentsMiddleware = () => next => action => {
    next(action)
    let entity

    switch (action.type){

        case FETCH_ACCIDENTS:
            let url = _.includes(action.payload.data, '?') ? action.payload.data : `${action.payload.data}?`

            if (action.payload.filters){
                for (let [key, value] of Object.entries(action.payload.filters)) {
                    url = `${url}&${key}=${value}`
                }
            }

            next([
                apiRequest({body: null, method: 'GET', url, entity: ACCIDENTS}),
                setLoader({state: true, entity: ACCIDENTS})
            ])
            break

        case `${ACCIDENTS} ${API_SUCCESS}`:
            next([
                setAccidents({data: action.payload.data}),
                setError({state: false, entity: ACCIDENTS}),
                setLoader({state: false, entity: ACCIDENTS})
            ])
            break

        case `${ACCIDENTS} ${API_ERROR}`:
            next([
                setError({state: true, entity: ACCIDENTS}),
                setLoader({state: false, entity: ACCIDENTS})
            ])
            break

        case POST_ACCIDENTS:
            let data = new FormData()
            let newValues = action.payload.body.form
            entity = action.payload.otherData ? `${POST_ACCIDENTS}-${action.payload.otherData}` : POST_ACCIDENTS

            /* Format form before POST */
            /* datesurv veux une date en / datesurvorigin est un objet moment */
            newValues.datesurv = moment(newValues.datesurvorigin).format('L')
            /* catsinorigin est slice parce que le select de la categorie est très précise */
            const arrayCategory = (newValues.catsinorigin).split('.')
            newValues.catsin = arrayCategory[0]
            newValues.catsinsubcategory = arrayCategory[1]

            if(newValues.birthdate_conduct){
                newValues.birthdate_conduct = moment(newValues.birthdate_conduct).format('L')
            }

            /* Gestion si un autre garage a été saisie */
            if (newValues.reparateur){
                if (newValues.reparateur === 'NONE'){
                    newValues.reparateur_autre = `${newValues.reparateurAutreNom} ${newValues.reparateurAutreAdresse}, ${newValues.cpreparateur} ${newValues.reparateurAutreVille}`
                    delete newValues.reparateur
                }
            }

            /* Envoie de la liste des documents pour le mail */
            if (action.payload.body.documents){
                action.payload.body.documents.forEach((doc, index) => {
                    data.append(`documents[${index}]`, doc.description)
                })
            }

            /* Mise à plat des files, adversaires, témoins, victimes */
            Object.keys(newValues).forEach(key => {
                if (key === 'files') {
                    Object.keys(newValues[key]).forEach(doc => {
                        if (newValues[key][doc].name !== undefined) {
                            data.append(`files[${doc}]`, newValues[key][doc])
                        }
                    })
                } else if (key === 'adversaires' || key === 'temoins' || key === 'victimes') {
                    newValues[key].forEach((person, index) => {
                        Object.keys(person).forEach(info => {
                            data.append(`${key}[${index}][${info}]`, person[info])
                        })
                    })
                } else if (key === 'circonstances') {
                    Object.keys(newValues[key]).forEach(circ => {
                        data.append(`${key}[${circ}]`, true)
                    })
                } else {
                    data.append(key, newValues[key])
                }
                return true
            })

            /* Ajout categorie */

            switch (newValues.catsinorigin) {
                case '10.0':
                case '105.1':
                case '9.0':
                case '105.2':
                    data.append('categorie', 'vol')
                    break;
                default:
                    data.append('categorie', 'autre')
            }

            next([
                apiRequest({body: data, method: 'POST', url: action.payload.data, entity: POST_ACCIDENTS, otherData: action.payload.otherData}),
                setLoader({state: true, entity})
            ])
            break

        case `${POST_ACCIDENTS} ${API_SUCCESS}`:
            entity = action.payload.meta.otherData ? `${POST_ACCIDENTS}-${action.payload.meta.otherData}` : POST_ACCIDENTS

            next([
                setError({state: false, entity}),
                setLoader({state: false, entity}),
                setPostAccidentResponse({data: (action.payload.data && action.payload.data.data && action.payload.data.data.message ? action.payload.data.data.message : null)})
            ])
            break

        case `${POST_ACCIDENTS} ${API_ERROR}`:
            entity = action.payload.meta.otherData ? `${POST_ACCIDENTS}-${action.payload.meta.otherData}` : POST_ACCIDENTS
            next([
                setNotification({entity, body: `${action.payload.data.response.data.message} ${action.payload.data.response.data.reason}`, title: 'Une erreur est survenue !', type: 'error'}),
                setError({state: true, entity}),
                setLoader({state: false, entity})
            ])
            break

        case FETCH_DOCUMENTS_ACCIDENT:
            next([
                apiRequest({body: null, method: 'GET', url: action.payload.data, entity: DOCUMENTS_ACCIDENT}),
                setLoader({state: true, entity: DOCUMENTS_ACCIDENT})
            ])
            break

        case `${DOCUMENTS_ACCIDENT} ${API_SUCCESS}`:
            next([
                setDocumentsAccident({data: action.payload.data}),
                setError({state: false, entity: DOCUMENTS_ACCIDENT}),
                setLoader({state: false, entity: DOCUMENTS_ACCIDENT})
            ])
            break

        case `${DOCUMENTS_ACCIDENT} ${API_ERROR}`:
            next([
                setError({state: true, entity: DOCUMENTS_ACCIDENT}),
                setErrorResponse({entity: DOCUMENTS_ACCIDENT, data: action?.payload?.data?.response?.data}),
                setLoader({state: false, entity: DOCUMENTS_ACCIDENT})
            ])
            break

        case POST_ACCIDENT_REPAIRER:
            next([
                apiRequest({body: (action.payload.body), method: 'POST', url: action.payload.data, entity: POST_ACCIDENT_REPAIRER, otherData: action.payload.otherData}),
                setLoader({state: true, entity: POST_ACCIDENT_REPAIRER})
            ])
            break

        case `${POST_ACCIDENT_REPAIRER} ${API_SUCCESS}`:
            next([
                setError({state: false, entity: POST_ACCIDENT_REPAIRER}),
                setLoader({state: false, entity: POST_ACCIDENT_REPAIRER})
            ])
            break

        case `${POST_ACCIDENT_REPAIRER} ${API_ERROR}`:
            next([
                setNotification({POST_ACCIDENT_REPAIRER, body: action.payload.data.response.data.message, title: 'Une erreur est survenue !', type: 'error'}),
                setErrorResponse({entity: POST_ACCIDENT_REPAIRER, data: action?.payload?.data?.response?.data}),
                setLoader({state: false, entity: POST_ACCIDENT_REPAIRER})
            ])
            break

        default:
            break
    }
    return null
}
