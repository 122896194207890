import React from 'react';
import PropTypes from 'prop-types'
import {useHistory} from "react-router-dom";
import Card from "../../../../../../Commun/Card/Card";
import {Field} from "redux-form";
import SelectFieldRedux from "../../../../../../Commun/Inputs/Select/SelectFieldRedux";
import UploadWithPreview from "../../../../../../Commun/Inputs/Upload/UploadWithPreview/UploadWithPreview";
import SmallSpinner from "../../../../../../Commun/Spinners/SmallSpinner";

const GarantieMobilite = (props) => {
    const { handleSubmit, loading, success } = props
    const history = useHistory()

    return (
        <div className={'p-lg-4'}>
            <Card>
                <div>
                    <h3 className={'m-0 pb-3 border-bottom border-dark'}>
                        Votre <span className={'text-color-secondary'}>Garantie mobilité</span>
                    </h3>

                    <div className="mt-5">
                        <span className={'btn btn-outline-secondary'} onClick={() => history.goBack()}><i className={'fas fa-chevron-left mr-3'}/> Retour</span>
                    </div>

                    <div className="mt-5 col-lg-6 col m-auto p-0">
                        {
                            success
                                ? <p>Votre demande a bien été enregistré, vous aller recevoir un email vous confirmant son enregistrement.</p>
                                : <form onSubmit={handleSubmit} className={'mt-3'}>
                                    <Field
                                        name={'circonstances'}
                                        component={SelectFieldRedux}
                                        label={"Pourquoi votre client n'a pas pu utiliser sa trottinette ?"}
                                    >
                                        <option value="">Choisissez un motif</option>
                                        <option value={'Il a eu un problème de batterie'}>
                                            Il a eu un problème de batterie
                                        </option>
                                        <option value={'Il a eu un problème de freins sur sa trottinette'}>
                                            Il a eu un problème de freins sur sa trottinette
                                        </option>
                                        <option value={'Sa trottinette n\'était pas disponible'}>
                                            Sa trottinette n'était pas disponible
                                        </option>
                                        <option value={'Autres motifs'}>Autres motifs</option>
                                    </Field>

                                    <div className={'text-center'}>
                                        <Field
                                            component={UploadWithPreview}
                                            name={'files[X]'}
                                            label={'Choisissez votre Justificatif'}
                                        />
                                    </div>

                                    <div className={'text-center'}>
                                        {
                                            loading
                                                ? <SmallSpinner />
                                                : <button className={'btn btn-primary max-rounded'}>Mon client souhaite bénéficier de la garantie mobilité</button>
                                        }
                                    </div>

                                </form>
                        }
                    </div>
                </div>
            </Card>
        </div>
    );
};

GarantieMobilite.defaultProps = {};

GarantieMobilite.propTypes = {
    handleSubmit: PropTypes.func
};

export default GarantieMobilite;
