import React from 'react';
import Card from "../../../../../../Commun/Card/Card";
import {Redirect, useHistory} from "react-router-dom";
import FormHivernageContainer from "./FormHivernage/FormHivernageContainer";

const Hivernage = (props) => {
    const {id_contrat, is_eligible_hivernage } = props
    const history = useHistory();

    if (!is_eligible_hivernage){
        return <Redirect to={`/mon-contrat/${id_contrat}`} />
    }
    
    return (
        <div className={'p-lg-4'}>
            <Card>
                <h3 className={'m-0 pb-3 border-bottom border-dark'}>
                    <span className="text-color-secondary">Ma période</span> hivernale
                </h3>

                <div className='mt-5'>
                    <span className={'btn btn-outline-secondary'} onClick={() => history.goBack()}>
                        <i className={'fas fa-chevron-left mr-3'} /> Retour
                    </span>
                </div>
                <FormHivernageContainer id_contrat={id_contrat}/>
            </Card>
        </div>
    );
};

export default Hivernage;