export const IMPAYES = '[Impayes]'

export const FETCH_IMPAYES = `${IMPAYES} Fetch`
export const SET_IMPAYES = `${IMPAYES} Set`
export const POST_PAY_MAIL_IMPAYES = `${IMPAYES} Post pay mail`

export const fetchImpayes = ({query, filters}) => ({
    type: FETCH_IMPAYES,
    payload: {
        data: query,
        filters
    }
})

export const setImpayes = ({data}) => ({
    type: SET_IMPAYES,
    payload: {
        data
    }
})

export const postPayMail = ({query, email, idQuittance, idContract}) => ({
    type: POST_PAY_MAIL_IMPAYES,
    payload: {
        data: query,
        body: {
            email,
            receipt: idQuittance,
            contract: idContract
        }
    }
})
