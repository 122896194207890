import React from 'react';
import _ from 'lodash';
import BeneficiaireEffectifItem from "./BeneficiaireEffectifItem/BeneficiaireEffectifItem";

const BeneficiairesEffectif = (props) => {
    const { beneficiairesEffectif } = props;

    return (
        <>
            {!_.isEmpty(beneficiairesEffectif) ? (
                <div className='table-responsive pt-1'>
                    <table className='table table-hover'>
                        <thead>
                            <tr>
                                <th scope='col'>Nom et Prénom</th>
                                <th scope='col'>Nom de naissance</th>
                                <th scope='col'>Date de naissance</th>
                                <th scope='col'>Ville de naissance</th>
                                <th scope='col'>Pays de naissance</th>
                            </tr>
                        </thead>
                        <tbody>
                            {beneficiairesEffectif.map((b, index) => (
                                <BeneficiaireEffectifItem key={index} beneficiaire={b} {...props} />
                            ))}
                        </tbody>
                    </table>
                </div>
            ) : (
                <p className={'no-result'}>Il n'y a pas de bénéficiaire effectif sur le tiers</p>
            )}
        </>
    );
};

export default BeneficiairesEffectif;
