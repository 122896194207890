import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import FeedBack from './FeedBack'
import {reduxForm, formValueSelector} from "redux-form";
import validate from "./validate";
import {getFeedBackLink} from "../../../../../redux/selectors/links/links.selectors";
import {FEEDBACK, postFeedBack} from "../../../../../redux/actions/app/feedback/feedback.actions";
import {getLoadingEntity} from "../../../../../redux/selectors/ui/ui.selectors";

const mapStateToProps = state => {
    const selector = formValueSelector('feedback')
    return {
        typeRetour: selector(state, 'type_retour'),
        query: getFeedBackLink(state),
        loading: getLoadingEntity(state, FEEDBACK)
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    postFeedBack: ({query, form}) => postFeedBack({query, form})
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { query } = stateProps
    const { postFeedBack } = dispatchProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        onSubmit: (form) => postFeedBack({query, form})
    }
}

const FeedBackContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    reduxForm({
        form: 'feedback',
        validate
    })
)(FeedBack)

export default FeedBackContainer
