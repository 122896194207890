export const LOGO_PROFIL = '[Profil Logo]'

export const POST_LOGO_PROFIL = `${LOGO_PROFIL} Post`

export const postLogoProfil = ({form, query}) => ({
    type: POST_LOGO_PROFIL,
    payload: {
        data: query,
        body: form
    }
})
