import React, { useState } from 'react';
import Card from "../../../../../../Commun/Card/Card";
import moment from "moment";
import APILink from "../../../../../../Commun/APILink/APILink";

const EditionAttestationNvei = ({ quittances }) => {
    const [url, setUrl] = useState(null);

    const handleQuittanceChange = (event) => {
        if (event.target.value) {
            setUrl(event.target.value);
        } else {
            setUrl(null);
        }
    };

    return (
        <div className={'p-lg-4'}>
            <Card>
                <div>
                    <h3 className={'m-0 pb-3 border-bottom border-dark'}>
                        Edition de l'attestation <span className="text-color-secondary">d'assurance trottinette</span> de votre client
                    </h3>

                    <div className="mt-5 col-lg-6 col p-0 mx-auto">
                        <div className="form-group">
                            <label htmlFor="quittanceSelect">Sélectionnez une quittance :</label>
                            <select id="quittanceSelect" className="form-control" onChange={handleQuittanceChange}>
                                <option value={""}>Choisissez une période</option>
                                {
                                    quittances.map((q) => (
                                        <option key={q.id} value={q.attestation_assurance_nvei_link}>
                                            {`du ${moment(q.dateDebut).format('L')} au ${moment(q.dateFin).format('L')}`}
                                        </option>
                                    ))
                                }
                            </select>
                        </div>

                        <div className="text-center mt-3">
                            {
                                url &&
                                <APILink href={url} download>
                                    <button className={'btn btn-primary'} type="button">Télécharger l'attestation</button>
                                </APILink>
                            }
                        </div>
                    </div>
                </div>
            </Card>
        </div>
    );
};

export default EditionAttestationNvei;
