import React, {useState} from 'react';
import {Link} from "react-router-dom";

const ProductButton = (props) => {
    const { to, className, png, pngSelect, title, color } = props
    const [blur, setBlur] = useState(false)

    return (
        <Link to={to} target={"_blank"} className={`height-100 w-100 border d-block bg-white p-3 text-dark ${className} hover-border-${color} hover-translateY`} onMouseEnter={() => setBlur(true)} onMouseLeave={() => setBlur(false)}>
            <div className={'d-flex p-1'}>
                <img src={blur ? pngSelect : png} alt={png} width={50} height={50} className={'mr-3 mt-1'} style={{minWidth: 50}}/>

                <div className={'text-left w-100'}>
                    <p className={`${!blur ? 'pt-3' : 'mb-2'}`}>Assurance {title}</p>
                    {blur && <span className={`btn btn-${color} btn-block max-rounded float-right p-0`}>Nouveau devis</span>}
                </div>
            </div>
        </Link>
    );
};

ProductButton.defaultProps = {
    color: 'secondary'
}


export default ProductButton;
