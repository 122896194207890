import React from 'react';
import Card from "../../../../Commun/Card/Card";
import SouscriptionsTableContainer from "./SouscriptionsTable/SouscriptionsTableContainer";

const Souscriptions = (props) => {
    const { filters, isConcessionnaire, fetchQuote } = props

    return (
        <div className={'p-lg-4'}>
            <Card>
                <div>
                    <h3 className={'m-0 pb-3 border-bottom border-dark'}>
                        <span className={'text-color-secondary'}>Vos souscriptions</span> de moins de 30 jours
                    </h3>

                    {
                        !isConcessionnaire && <div className="row mt-5">
                            <div className="col-lg-6 col-md-12">
                                <label>Type d'affaires</label>
                                <select className="form-control" onChange={(e) => fetchQuote({filters: {...filters, acteGestion: e.target.value}})}>
                                    <option value={''}>Tous type d'affaires</option>
                                    <option value={'AN'}>Affaires nouvelles</option>
                                    <option value={'AV'}>Avenants</option>
                                </select>
                            </div>
                        </div>
                    }

                    <SouscriptionsTableContainer />

                </div>
            </Card>
        </div>
    );
};

export default Souscriptions;
