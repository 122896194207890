import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import Search from './Search'
import {fetchSearch, SEARCH, clearSearch} from "../../../../redux/actions/app/search/search.actions";
import {getContractsLink, getQuotesLink} from "../../../../redux/selectors/links/links.selectors";
import {CONTRACT} from "../../../../redux/actions/app/contracts/contracts.actions";
import {QUOTE} from "../../../../redux/actions/app/quotes/quotes.actions";
import {getLoadingEntity, getSearchEntity} from "../../../../redux/selectors/ui/ui.selectors";

const mapStateToProps = (state, ownProps) => {
    let query;
    let urlRedirect;

    switch (ownProps.entity) {
        case CONTRACT:
            query = getContractsLink(state)
            urlRedirect = '/mon-contrat/'
            break;
        case QUOTE:
            query = getQuotesLink(state)
            urlRedirect = '/mon-devis/'
            break;
        default:
            query = getContractsLink(state)
            urlRedirect = '/mon-contrat/'
    }
    return {
        query, urlRedirect,
        result: getSearchEntity(state, ownProps.entity),
        loading: getLoadingEntity(state, SEARCH)
    }
}

const mapDispatchToProps = (dispatch, ownProps) => bindActionCreators({
    postSearch: ({query}) => fetchSearch({query, entity: ownProps.entity}),
    clearSearch: () => clearSearch({entity: ownProps.entity})
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { query } = stateProps
    const { postSearch } = dispatchProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        postSearch: ({string}) => postSearch({query: `${query}?search=${string}`})
    }
}

const SearchContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps)
)(Search)

export default SearchContainer
