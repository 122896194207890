import {connect} from 'react-redux'
import Chocs from './Chocs'
import {getCodeProduitVehicule} from "../../../../../../../../../../redux/selectors/contracts/contracts.selectors";

const mapStateToProps = state => {
    return {
        produit: getCodeProduitVehicule(state),
    }
}

const ChocsContainer = connect(mapStateToProps)(Chocs)

export default ChocsContainer
