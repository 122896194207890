import {createSelector} from "reselect";

export const impayesSelector = state => state.impayes

/***** DATA CONTRACTS *****/
export const getDataImpayes = createSelector(
    impayesSelector,
    impayes => impayes.data
)

/***** META CONTRACTS *****/
export const getMetaImpayes = createSelector(
    impayesSelector,
    impayes => impayes.meta || {}
)

/***** LINKS CONTRACTS *****/
export const getLinksImpayes = createSelector(
    impayesSelector,
    impayes => impayes.links
)
