import {createSelector} from 'reselect'

const documentsSelector = state => state.documents

export const getDocumentsActiveDocuments = createSelector(
    documentsSelector,
    documents => documents.active
)

export const getDocumentsInactiveDocuments = createSelector(
    documentsSelector,
    documents => documents.inactive
)
