import moment from 'moment/moment';

const validate = (values) => {
    const errors = {};

    if (!values['type']) {
        errors['type'] = 'Le champ est obligatoire';
    } else {
        let requiredFields = [];

        if (values['type'] === 'P') {
            requiredFields = ['firstname', 'lastname', 'birthname', 'birthdate', 'birthcountry', 'birthcity', 'relationship'];

            if (values['relationship'] && values['relationship'] === 'A') {
                requiredFields.push('third_payeur_reason');
            }
            if (values.birthdate !== undefined && (!moment(values.birthdate).isValid() || moment(values.birthdate).add(14, 'years') >= moment())) {
                errors.birthdate = 'Le tiers payeur doit avoir au moins 14 ans.';
            }
        } else {
            requiredFields = ['lastname'];
        }

        requiredFields.forEach((field) => {
            if (!values[field]) {
                errors[field] = 'Le champ est obligatoire';
            }
        });
    }
    return errors;
};

export default validate;
