import React, {useState} from 'react';
import PropTypes from 'prop-types'
import _ from 'lodash'
import {Field} from "redux-form";
import Cadre from "../../../../../../../../../Commun/Cadre/Cadre";
import RadioDesignButton from "../../../../../../../../../Commun/Inputs/Radio/RadioDesignButton/RadioDesignButton";
import TextFieldRedux from "../../../../../../../../../Commun/Inputs/Text/TextFieldRedux";
import DatePickerFieldRedux from "@/components/Commun/Inputs/Date/DatePickerFieldRedux";
import SelectFieldRedux from "@/components/Commun/Inputs/Select/SelectFieldRedux";

const DeclarationBis = (props) => {
    const {isWakam, categorie, vehiculeDommage, type_sinistre, vehiculeIsAttached, antiVolMecanique, garanties, blesse,
        indentify, temoin, sraCertified, cylindree, moreDamages, corpoconduct, setValidSecondCheck, setEmailToClient,
        fetchDocumentsAccident, document_police, catsin, equipementsDommage, equipementsDommageAutre} = props
    const [alert, setAlert] = useState(false)
    const categoryNeedIdentify = ['104.1', '104.4', '104.3']

    const checkIfNotDTA = () => {
        return !_.find(garanties, ['code_famille', 'DTA'])
    }

    const checkIfNotVI = () => {
        return !_.find(garanties, ['code_famille', 'VI'])
    }

    const checkIfNotPCC = () => {
        return !_.find(garanties, g => {
            return _.includes(['PCC1', 'PCC2', 'PCC3', 'PCC0'], g.code_famille)
        })
    }

    const checkIfNotIC = () => {
        return !_.find(garanties, g => {
            return _.includes(['IC1', 'IC2'], g.code_famille)
        })
    }

    const checkIfNotASSorASSP = () => {
        return !_.find(garanties, g => {
            return _.includes(['ASSISTANCE', 'ASSISTANCE_PLUS'], g.code_famille)
        })
    }

    const setRefusContinuePreDec = (cause) => {
        setAlert(true)
        setValidSecondCheck(false)
        setEmailToClient(cause)
    }

    const checkStep = () => {
        /* Accident Seul */
        if (categorie === "104.2"){
            if (blesse === "F" && checkIfNotPCC() && checkIfNotIC() && checkIfNotDTA() ){
                if (checkIfNotASSorASSP()){
                    setRefusContinuePreDec("accident_seul")
                } else {
                    setRefusContinuePreDec("accident_seul_ASS")
                }

                return false
            }
        }
        /* Délit de fuite */
        if (categorie === "104.1"){
            if (indentify === "F" && temoin === "F" && checkIfNotPCC() && checkIfNotIC() && checkIfNotDTA()){
                if (checkIfNotASSorASSP()){
                    setRefusContinuePreDec("delit_fuite")
                } else {
                    setRefusContinuePreDec("delit_fuite_ASS")
                }
                return false
            }
        }
        /* Stationnement */
        if (categorie === "104.3"){
            if (indentify === "F" && temoin === "F" && checkIfNotDTA()){
                setRefusContinuePreDec("stationnement")
                return false
            }
        }
        /* Animal */
        if (categorie === "104.4"){
            if (indentify === "F" && checkIfNotPCC() && checkIfNotIC() && checkIfNotDTA()){
                if (checkIfNotASSorASSP()){
                    setRefusContinuePreDec("choc_animal")
                } else {
                    setRefusContinuePreDec("choc_animal_ASS")
                }
                return false
            }
        }
        /* Vol, Tentative de Vol, Vol retrouvé, Vol d'accessoires */
        if (categorie === "10.0" || categorie === "105.1" || categorie === "9.0" || categorie === "105.2"){
            if ((sraCertified === "F" || vehiculeIsAttached === "F") && cylindree === 50){
                setRefusContinuePreDec("vehicule_non_attache_agree_sra")
                return false
            }
        }
        /* Incendie */
        if (categorie === "18.0") {
            if (moreDamages === "F" && checkIfNotVI()) {
                if (checkIfNotASSorASSP()){
                    setRefusContinuePreDec("incendie")
                } else {
                    setRefusContinuePreDec("incendie_ASS")
                }
                return false
            }
        }

        setAlert(false)
        setValidSecondCheck(true)
    }

    return (
        <div className={'form-content'}>
            <Cadre icon={'fas fa-edit'} title={'Dites-nous en plus...'}>

                {
                    type_sinistre === 'accident'
                        ? <div>
                            {
                                _.includes(categoryNeedIdentify, categorie) && <Field
                                    name='indentify'
                                    component={RadioDesignButton}
                                    label={'La partie adverse est elle identifiée ?'}
                                    data={[{value: "T", label:"Oui"}, {value: "F", label:"Non"}]}
                                />
                            }

                            <Field
                                name='corpoconduct'
                                component={RadioDesignButton}
                                label={'Le conducteur est-il blessé ?'}
                                data={[{value: "T", label:"Oui"}, {value: "F", label:"Non"}]}
                            />

                            <Field
                                name='blesse'
                                component={RadioDesignButton}
                                label={'Y-a-t-il d\'autre(s) blessé(s) ?'}
                                data={[{value: "T", label:"Oui"}, {value: "F", label:"Non"}]}
                            />

                            <Field
                                name='temoin'
                                component={RadioDesignButton}
                                label={'Y-a-t-il des témoins ?'}
                                data={[{value: "T", label: "Oui"}, {value: "F", label: "Non"}]}
                            />

                            { isWakam && categorie === '103.2' &&
                                <Field
                                    name='nb_vehicules'
                                    component={SelectFieldRedux}
                                    label={'Combien de véhicules sont impliqués sur ce sinistre ?'}
                                >
                                    <option value={null}>Sélectionnez le nombre</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                    <option value="7">7</option>
                                    <option value="8">8</option>
                                    <option value="9">9</option>
                                    <option value="10">10</option>
                                </Field>
                            }

                            <Field
                                name='vehiculeDommage'
                                component={RadioDesignButton}
                                label={'Le véhicule a-t-il subi des dommages ?'}
                                data={[{value: "T", label:"Oui"}, {value: "F", label:"Non"}]}
                            />
                            {
                                vehiculeDommage === 'T' && <div>
                                    <Field
                                        name='dommagesDeclares'
                                        component={TextFieldRedux}
                                        label='Quels sont les dommages du véhicule ?'
                                    />
                                </div>
                            }
                            <Field
                                name='equipementsDommage'
                                component={RadioDesignButton}
                                label={'Il y a-t-il des dégâts sur ses équipements et accessoires ?'}
                                data={[{value: "T", label:"Oui"}, {value: "F", label:"Non"}]}
                                onChange={(e) => fetchDocumentsAccident({
                                        categorie: _.split(catsin, '.')[0],
                                        souscategorie: _.split(catsin, '.')[1],
                                        equipements: e.target.value === 'T' ?? false,
                                        doc_police: document_police
                                    })
                                }
                            />

                            {equipementsDommage === 'T' && <div className={'row'}>
                                <Field
                                    name='equipementsDommageCasque'
                                    component={RadioDesignButton}
                                    label={'Casque'}
                                    className={'col-4'}
                                    data={[{value: "T", label:"Oui"}, {value: "F", label:"Non"}]}
                                />
                                <Field
                                    name='equipementsDommageGants'
                                    component={RadioDesignButton}
                                    label={'Gants'}
                                    className={'col-4'}
                                    data={[{value: "T", label:"Oui"}, {value: "F", label:"Non"}]}
                                />
                                <Field
                                    name='equipementsDommageBlouson'
                                    component={RadioDesignButton}
                                    label={'Blouson'}
                                    className={'col-4'}
                                    data={[{value: "T", label:"Oui"}, {value: "F", label:"Non"}]}
                                />
                                <Field
                                    name='equipementsDommageBottes'
                                    component={RadioDesignButton}
                                    label={'Bottes'}
                                    className={'col-4'}
                                    data={[{value: "T", label:"Oui"}, {value: "F", label:"Non"}]}
                                />
                                <Field
                                    name='equipementsDommageAirbag'
                                    component={RadioDesignButton}
                                    label={'Gilet Airbag'}
                                    className={'col-4'}
                                    data={[{value: "T", label:"Oui"}, {value: "F", label:"Non"}]}
                                />
                                <Field
                                    name='equipementsDommageAutre'
                                    component={RadioDesignButton}
                                    label={'Autres'}
                                    className={'col-4'}
                                    data={[{value: "T", label:"Oui"}, {value: "F", label:"Non"}]}
                                />
                                {equipementsDommageAutre === 'T' &&
                                    <Field
                                        name='equipementsDommageAutreDesc'
                                        component={TextFieldRedux}
                                        label="Préciser l'équipement endommagé"
                                    />
                                }
                            </div>}
                        </div>
                        : type_sinistre === 'vol'
                        ? <div>
                            {
                                categorie !== "10.0" && <div>
                                    <Field
                                        name='vehiculeDommage'
                                        component={RadioDesignButton}
                                        label={'Le véhicule a-t-il subi des dommages ?'}
                                        data={[{value: "T", label:"Oui"}, {value: "F", label:"Non"}]}
                                    />


                                    {
                                        vehiculeDommage === 'T' && <div>
                                            <Field
                                                name='dommagesDeclares'
                                                component={TextFieldRedux}
                                                label='Quels sont les dommages du véhicule ?'
                                            />

                                            <Field
                                                name='vehicule'
                                                component={RadioDesignButton}
                                                label={'Connaissez-vous le garage qui fera les réparations de votre véhicule ?'}
                                                data={[{value: "T", label:"Oui"}, {value: "F", label:"Non"}]}
                                            />
                                        </div>
                                    }
                                </div>
                            }
                            <Field
                                name='vehiculeIsInGarage'
                                component={RadioDesignButton}
                                label={'Votre véhicule était-il dans un garage ?'}
                                data={[{value: "T", label:"Oui"}, {value: "F", label:"Non"}, {value: "unknown", label:"Ne sais pas"}]}
                            />

                            <Field
                                name='vehiculeIsMark'
                                component={RadioDesignButton}
                                label={'Votre véhicule était-il gravé ?'}
                                data={[{value: "T", label:"Oui"}, {value: "F", label:"Non"}, {value: "unknown", label:"Ne sais pas"}]}
                            />

                            <Field
                                name='vehiculeIsAttached'
                                component={RadioDesignButton}
                                label={'Votre véhicule était-il attaché avec un antivol au moment du vol ?'}
                                data={[{value: "T", label:"Oui"}, {value: "F", label:"Non"}, {value: "unknown", label:"Ne sais pas"}]}
                            />

                            {
                                vehiculeIsAttached === 'T' && <div>
                                    <Field
                                        name='antiVolMecanique'
                                        component={RadioDesignButton}
                                        label={'Quel(s) anti-vol(s) possédez vous ?'}
                                        type={'checkbox'}
                                        data={[{label:"Mécanique", value: true}]}
                                    />
                                    {
                                        antiVolMecanique && <Field
                                            name='sraCertified'
                                            component={RadioDesignButton}
                                            label={'Agréé SRA ?'}
                                            data={[{value: "T", label:"Oui"}, {value: "F", label:"Non"}]}
                                        />
                                    }
                                    <Field
                                        name='antiVolElectronique'
                                        component={RadioDesignButton}
                                        type={'checkbox'}
                                        data={[{label:"Electronique", value: true}]}
                                    />
                                    <Field
                                        name='antiVolAntiDemarrage'
                                        component={RadioDesignButton}
                                        type={'checkbox'}
                                        data={[{label:"Anti démarrage", value: true}]}
                                    />
                                </div>
                            }
                        </div>
                        : <div>
                            <Field
                                name='moreDamages'
                                component={RadioDesignButton}
                                label={"Votre véhicule a-t-il causé des dommages à d'autres biens ou personnes ?"}
                                data={[{value: "T", label:"Oui"}, {value: "F", label:"Non"}]}
                            />
                        </div>
                }

                {
                    alert && <div className="alert alert-danger" role="alert">
                        <p>Les garanties du contrat et les éléments de la déclaration ne permettent pas de donner suite à cette déclaration.</p>
                        <p>Une confirmation est adressée au client.</p>
                    </div>
                }

                <div className={'text-right'}>
                    <span className={'btn btn-primary max-rounded'} onClick={() => checkStep()}>Poursuivre</span>
                </div>
            </Cadre>
        </div>
    );
};

DeclarationBis.propTypes = {
    vehiculeDommage: PropTypes.string,
    type_sinistre: PropTypes.string,
    vehiculeIsAttached: PropTypes.string,
    antiVolMecanique: PropTypes.string,
    garanties: PropTypes.array,
    blesse: PropTypes.string,
    indentify: PropTypes.string,
    temoin: PropTypes.string,
    sraCertified: PropTypes.string,
    cylindree: PropTypes.number,
    moreDamages: PropTypes.string,
    corpoconduct: PropTypes.string,
    setValidSecondCheck: PropTypes.func,
    setEmailToClient: PropTypes.func,
};

export default DeclarationBis;
