import {SET_IMPAYES} from "../../actions/app/impayes/impayes.actions";

export const impayesReducer = (impayes = {}, action = {}) => {
    const {payload} = action

    if (action.type === SET_IMPAYES) {
        return payload.data
    } else {
        return impayes
    }
}
