/**
 * Normalize input Tel XX.XX.XX.XX.XX
 * @param value
 * @returns {string}
 */
export const normalizeTel = value => {
    if (!value) {
        return value
    }

    const separateur = '.'

    const onlyNums = value.replace(/[^\d]/g, '')

    if (onlyNums.length <= 2) {
        return onlyNums
    }
    if (onlyNums.length <= 4) {
        return `${onlyNums.slice(0, 2)}${separateur}${onlyNums.slice(2, 4)}`
    }
    if (onlyNums.length <= 6) {
        return `${onlyNums.slice(0, 2)}${separateur}${onlyNums.slice(2, 4)}${separateur}${onlyNums.slice(4, 6)}`
    }
    if (onlyNums.length <= 8) {
        return `${onlyNums.slice(0, 2)}${separateur}${onlyNums.slice(2, 4)}${separateur}${onlyNums.slice(4, 6)}${separateur}${onlyNums.slice(6, 8)}`
    }
    return `${onlyNums.slice(0, 2)}${separateur}${onlyNums.slice(2, 4)}${separateur}${onlyNums.slice(4, 6)}${separateur}${onlyNums.slice(6, 8)}${separateur}${onlyNums.slice(
        8,
        10
    )}`
}

/**
 * Normalize input IBAN FR11-2222-3333-4444-5555-6666-777
 * @param value
 * @returns {string}
 */
export const normalizeIban = value => {
    if (!value) {
        return value
    }

    const separateur = '-'

    const onlyNums = value.replace(/[!@#$%^&*\-(),.?":{}|<>]/g, '').toUpperCase()

    if (onlyNums.length <= 4) {
        return onlyNums
    }
    if (onlyNums.length <= 8) {
        return `${onlyNums.slice(0, 4)}${separateur}${onlyNums.slice(4, 8)}`
    }

    if (onlyNums.length <= 12) {
        return `${onlyNums.slice(0, 4)}${separateur}${onlyNums.slice(4, 8)}${separateur}${onlyNums.slice(8, 12)}`
    }

    if (onlyNums.length <= 16) {
        return `${onlyNums.slice(0, 4)}${separateur}${onlyNums.slice(4, 8)}${separateur}${onlyNums.slice(8, 12)}${separateur}${onlyNums.slice(12, 16)}`
    }

    if (onlyNums.length <= 20) {
        return `${onlyNums.slice(0, 4)}${separateur}${onlyNums.slice(4, 8)}${separateur}${onlyNums.slice(8, 12)}${separateur}${onlyNums.slice(12, 16)}${separateur}${onlyNums.slice(16, 20)}`
    }

    if (onlyNums.length <= 24) {
        return `${onlyNums.slice(0, 4)}${separateur}${onlyNums.slice(4, 8)}${separateur}${onlyNums.slice(8, 12)}${separateur}${onlyNums.slice(12, 16)}${separateur}${onlyNums.slice(16, 20)}${separateur}${onlyNums.slice(20, 24)}`
    }

    return `${onlyNums.slice(0, 4)}${separateur}${onlyNums.slice(4, 8)}${separateur}${onlyNums.slice(8, 12)}${separateur}${onlyNums.slice(12, 16)}${separateur}${onlyNums.slice(16, 20)}${separateur}${onlyNums.slice(20, 24)}${separateur}${onlyNums.slice(24, 27)}`
}

/**
 * Normalize Boolean
 * @param value
 * @returns {boolean}
 */
export const normalizeBooleanForNoobBelair = value => {

    if (value) {
        return "T";
    } else {
        return "F";
    }

};

/**
 * Normalize input Immatriculation
 * YY-XXX-YY - Nouvelle + YY-XXX-Y - Mobylette
 * XXXX-YY-XX - Ancienne
 * XXXX-AAA-1A - Ancienne Corse
 * @param value
 * @returns {string}
 */
export const normalizeImmatriculation = value => {
    if (!value) {
        return value
    }

    const separateur = ''

    const onlyNums = value.replace(/[!@#$%^&*\-(),.?":{}|<>]/g, '').toUpperCase()

    if (/^[a-zA-Z]{2} ?-?[0-9]{3} ?-?[a-zA-Z]{2}$/.test(value)){
        return `${onlyNums.slice(0, 2)}${separateur}${onlyNums.slice(2, 5)}${separateur}${onlyNums.slice(5, 7)}`
    }

    return value.slice(0, 7)

}
