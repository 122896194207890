import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import ChangeFractionnement from "./ChangeFractionnement";
import {change, formValueSelector, reduxForm} from "redux-form";
import _ from "lodash";
import loader from "../../../../../../Commun/Spinners/Loader/Loader";
import {getContract} from "../../../../../../../redux/selectors/contracts/contracts.selectors";
import {CONTRACT, fetchContract} from "../../../../../../../redux/actions/app/contracts/contracts.actions";
import {getErrorEntity, getLoadingEntity} from "../../../../../../../redux/selectors/ui/ui.selectors";
import {
    getContractLink,
    postContractFractionnementUpdate
} from "../../../../../../../redux/selectors/links/links.selectors";
import {
    postChangeFractionnementContract
} from "../../../../../../../redux/actions/app/contracts/fractionnement.contract.actions";

const mapStateToProps = (state, ownProps) => {
    const formName = `change_fractionnement-${ownProps.match.params.id}`
    const selector = formValueSelector(formName)

    return {
        contract: getContract(state),
        uriContract: getContractLink(state),
        uriPostChangeFractionnement: postContractFractionnementUpdate(state),
        idContract: ownProps.match.params.id,
        loaded: getLoadingEntity(state, CONTRACT) === false,
        loading: getLoadingEntity(state, formName),
        error: getErrorEntity(state, CONTRACT),
        ibanIsCheck: Boolean(selector(state, 'domiciliation') && selector(state, 'bic')),
        form: formName,
    }
}

const mapDispatchToProps = (dispatch, ownProps) => bindActionCreators({
    fetchContract: (query) => fetchContract({query}),
    changeValue: (field, value) => change(`change_fractionnement-${ownProps.match.params.id}`, field, value),
    postChangeFractionnementContract: ({query, form, formName}) => postChangeFractionnementContract({query, form, formName})
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { uriPostChangeFractionnement, uriContract, idContract, form} = stateProps
    const { fetchContract, postChangeFractionnementContract } = dispatchProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => fetchContract(_.replace(uriContract, '{id}', idContract)),
        onSubmit: (formulaire) => postChangeFractionnementContract({query: _.replace(uriPostChangeFractionnement, '{id}', idContract), form: formulaire, formName:form})
    }
}

const ChangeFractionnementContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    reduxForm({}),
    loader()
)(ChangeFractionnement)

export default ChangeFractionnementContainer
