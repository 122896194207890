import React, {useEffect, useRef, useState} from 'react';
import moment from 'moment'
import {Link, useHistory} from "react-router-dom";
import Card from "../../../../../../Commun/Card/Card";
import SelectFieldRedux from "../../../../../../Commun/Inputs/Select/SelectFieldRedux";
import _ from "lodash";
import {Field} from "redux-form";
import TextFieldRedux from "../../../../../../Commun/Inputs/Text/TextFieldRedux";
import {checkIban} from "../../../../../../../utils/function";
import SmallSpinner from "../../../../../../Commun/Spinners/SmallSpinner";
import UploadInlineContainer from "../../../../../../Commun/Inputs/Upload/UploadInline/UploadInlineContainer";
import UploadWithPreview from "../../../../../../Commun/Inputs/Upload/UploadWithPreview/UploadWithPreview";

const ChangeFractionnement = (props) => {
    const { handleSubmit, contract, changeValue, ibanIsCheck, form, loading } = props
    const [createChrono, setCreateChrono] = useState(null)
    const [loadingIban, setLoadingIban] = useState(false)
    const [errorIban, setErrorIban] = useState(null)
    const history = useHistory()
    const inputIban = useRef("");
    const anneeEnCours = new Date().getFullYear();
    const date = moment(`${contract.situation.echeance}/${anneeEnCours}`, 'DD/MM/YYYY');

    useEffect(() => {
        // Si la date de la demande (aujourd'hui) est entre 60j avant echeance et 15j après echeance
        if (moment().diff(date, 'days') >= -60 && moment().diff(date, 'days') <= 15){
            setCreateChrono(false)
            changeValue('createChrono', false)
        } else {
            setCreateChrono(true)
            changeValue('createChrono', true)
        }
    }, [])

    const fetchCheckIban = async (value) => {
        setLoadingIban(true)
        let iban = await checkIban(value)
        setLoadingIban(false)
        changeValue('domiciliation', iban.bank_data.bank)
        changeValue('bic', iban.bank_data.bic)

        /* Vérifie si le format de l'iban est correct */
        const codeValidationsSuccess = ['001', '002', '003', '004', '005', '006', '007'];
        const fieldValidations = ['account', 'country_support', 'iban', 'length', 'structure'];
        let error = null

        _.forEach(fieldValidations, field => {
            if (!_.includes(codeValidationsSuccess, iban.validations[field].code)) {
                error = iban.validations[field].message
                return false;
            }
        });

        /* Vérifie si l'iban accepte les prélèvement */
        if (iban.sepa_data.SDD === "NO" || iban.sepa_data.SCT === "NO") {
            return setErrorIban("L'IBAN renseignée n'autorise pas les prélèvements bancaires")
        }

        return error ? setErrorIban(`Le format de l'IBAN n'est pas valide. <br /> ${error}`) : setErrorIban(null)
    }

    return (
        <div className={'p-lg-4'}>
            <Card>
                <div>
                    <h3 className={'m-0 pb-3 border-bottom border-dark'}>
                        Modifier <span className="text-color-secondary">Le fractionnement de paiement</span> de votre client
                    </h3>

                    <div className="mt-5">
                        <span className={'btn btn-outline-secondary'} onClick={() => history.goBack()}><i
                            className={'fas fa-chevron-left mr-3'}/> Retour</span>
                    </div>

                    <div className={'alert-warning alert mb-0 mt-3 col-6 mx-auto'}>
                        <p className={'m-0'}>Pensez à vérifier votre email : <b>{contract.souscripteur.email}</b></p>
                        <p>Pensez à vérifier votre numéro de téléphone : <b>{contract.souscripteur.phone}</b></p>
                        Si ces informations sont fausses, pensez à les modifier <Link to={`/mon-contrat/${contract.id}/modifier-les-informations-personnelles`}>Modifier les informations personnelles</Link>
                    </div>

                    <div className="mt-5 col-lg-6 col-md-12 mx-auto">
                        <form onSubmit={handleSubmit} className={'mt-5'}>
                            <Field
                                name={'fractionnement'}
                                component={SelectFieldRedux}
                                label={'Nouveau Fractionnement'}
                            >
                                <option value={''}>Sélectionnez un fractionnement</option>
                                <option value={"A"}>Fractionnement annuel en prélèvement</option>
                                <option value={"M"}>Fractionnement mensuel en prélèvement</option>
                            </Field>

                            {
                                !createChrono ? <>
                                        <label>Importer le RIB du client</label>
                                        <Field
                                            name='upload'
                                            component={UploadInlineContainer}
                                            formName={form}
                                            code={`files`}
                                        />

                                        <Field
                                            name='iban'
                                            component={TextFieldRedux}
                                            label={'IBAN'}
                                            ref={inputIban}
                                            normalize={value => value && value.toUpperCase()}
                                        />

                                        {
                                            loadingIban ? <SmallSpinner/> : <span className="mb-3 btn btn-primary max-rounded" onClick={loadingIban ? null : () => fetchCheckIban(inputIban.current.value)}>Vérifier l'IBAN du client</span>
                                        }

                                        <Field
                                            name='domiciliation'
                                            component={TextFieldRedux}
                                            label={'Domiciliation de la banque'}
                                            normalize={value => value && value.toUpperCase()}
                                        />

                                        <Field
                                            name='bic'
                                            component={TextFieldRedux}
                                            label={'Code BIC'}
                                            normalize={value => value && value.toUpperCase()}
                                        />

                                        {
                                            errorIban && <div className={'alert-danger alert mb-0'} dangerouslySetInnerHTML={{__html: errorIban}}/>
                                        }

                                        {
                                            ibanIsCheck && !errorIban && <div className={'alert-success alert mb-0'}>L'IBAN est valide</div>
                                        }
                                    </>
                                    : <>
                                        <label>Importer Le RIB du client</label>
                                        <Field
                                            name='upload'
                                            component={UploadInlineContainer}
                                            formName={form}
                                            code={`files`}
                                        />
                                    </>
                            }
                            {
                                loading ? <SmallSpinner/> : <button className={'btn btn-primary d-block max-rounded mt-3'} type={'submit'}>
                                    Mettre à jour le prélèvement
                                </button>
                            }
                        </form>
                    </div>
                </div>
            </Card>
        </div>
    );
};

export default ChangeFractionnement;