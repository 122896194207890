export const CONTRACT = '[Contract]'
export const CONTRACT_SITUATION = '[Contract Situation]'

export const FETCH_CONTRACT = `${CONTRACT} Fetch`
export const SET_CONTRACT = `${CONTRACT} Set`
export const POST_CONTRACT_SITUATION = `${CONTRACT_SITUATION} Post`
export const POST_CONFIRM_RESIL_HAMON = `${CONTRACT} Post Confirm Resil Hamon`


export const fetchContract = ({query, filters}) => ({
    type: FETCH_CONTRACT,
    payload: {
        data: query,
        filters
    }
})

export const setContract = ({data}) => ({
    type: SET_CONTRACT,
    payload:{
        data
    }
})

export const postChangeSituationContract = ({type, form, query}) => ({
    type: POST_CONTRACT_SITUATION,
    payload: {
        data: query,
        body: {type, ...form},
        otherData: {
            type: type
        }
    }
})

export const postConfirmResilHamon = ({query, form}) => ({
    type: POST_CONFIRM_RESIL_HAMON,
    payload: {
        data: query,
        body: form,
    }
})
