import {connect} from 'react-redux'
import Footer from './Footer'
import {getNameWhitelabel, getUrlSiteWhitelabel} from "../../../../../redux/selectors/whitelabel/whitelabel.selectors";

const mapStateToProps = state => {
    return {
        website: getUrlSiteWhitelabel(state),
        name: getNameWhitelabel(state)
    }
}

const FooterContainer = connect(mapStateToProps)(Footer)

export default FooterContainer
