import 'core-js'
import 'raf/polyfill'
import 'url-polyfill/url-polyfill'
import 'nodelist-foreach-polyfill'
import React from 'react'
import { createRoot } from 'react-dom/client';
import AppProvider from './AppProvider'
import {APP_ENV} from "./constants/constants"
import 'bootstrap/dist/css/bootstrap.min.css'
import './assets/scss/global.scss'
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

window.dataLayer = window.dataLayer || []

window.dataLayer.push({
    'environnement': APP_ENV
})

/*Sentry.init({
    dsn: "https://93848e104efb4b86a9adc0707ce2e7d5@sentry.april-moto.com/11",
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
    environment: APP_ENV
});*/

Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
    replaysSessionSampleRate: 0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 0.1, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.,
    tracesSampleRate: 0.01,
    environment: APP_ENV,
    enabled: true
});

const container = document.getElementById('root');
const root = createRoot(container);

if (APP_ENV) {
    root.render(<AppProvider />);
} else {
    root.render(<h1>.env requis à la racine du projet, un npm start est nécessaire.</h1>);
}
