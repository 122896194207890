import {combineReducers} from 'redux'
import {reducer as form} from 'redux-form'
import {linksReducer} from './links/links.reducer'
import {uiReducer} from './ui/ui.reducer'
import {userReducer} from "./user/user.reducer";
import {whitelabelReducer} from "./whitelabel/whitelabel.reducer";
import {quotesReducer} from "./quotes/quotes.reducer";
import {contractsReducer} from "./contracts/contracts.reducer";
import {statsReducer} from "./stats/stats.reducer";
import {commissionsReducer} from "./commissions/commissions.reducer";
import {accidentsReducer} from "./accidents/accidents.reducer";
import {publicitesReducer} from "./publicites/publicites.reducer";
import {documentsReducer} from "./documents/documents.reducer";
import {produitsReducer} from "./produits/produits.reducer";
import {contactsReducer} from "./contacts/contacts.reducer";
import {profilReducer} from "./profil/profil.reducer";
import {articlesReducer} from "./articles/articles.reducer";
import {impayesReducer} from "./impayes/impayes.reducer";
import {dashboardReducer} from "./dashboard/dashboard.reducer";
import {tiersReducer} from "./tiers/tiers.reducer";
import {signaturesReducer} from "./signatures/signatures.reducer";
import {casIdaReducer} from "./cas_ida/cas_ida.reducer";
import {tiersPayeurReducer} from "./tiers_payeur/tiers_payeur.reducer";

export const AppReducers = combineReducers({
    form,
    links: linksReducer,
    ui: uiReducer,
    user: userReducer,
    whitelabel: whitelabelReducer,
    quotes: quotesReducer,
    contracts: contractsReducer,
    stats: statsReducer,
    commissions: commissionsReducer,
    accidents: accidentsReducer,
    publicites: publicitesReducer,
    contacts: contactsReducer,
    documents: documentsReducer,
    produits: produitsReducer,
    profil: profilReducer,
    articles: articlesReducer,
    impayes: impayesReducer,
    dashboard: dashboardReducer,
    tiers: tiersReducer,
    signatures: signaturesReducer,
    tiers_payeur: tiersPayeurReducer,
    cas_ida: casIdaReducer
})
