import {connect} from 'react-redux'
import Circonstances from './Circonstances'
import {formValueSelector} from "redux-form";
import {postCasIda} from "../../../../../../../../../../redux/actions/app/cas_ida/cas_ida.actions";
import {casIdaLink} from "../../../../../../../../../../redux/selectors/links/links.selectors";
import {getIda, getRc} from "../../../../../../../../../../redux/selectors/cas_ida/cas_ida.selector";

const mapStateToProps = state => {
    const selector = formValueSelector('declaration-sinistre')

    return {
        query: casIdaLink(state),
        cas_ida: getIda(state),
        codegta: selector(state, 'codegta'),
        rc: getRc(state),
        conducteur: selector(state, 'conduct'),
        adversaireNom: selector(state, 'adversaires[0].nom'),
        adversairePrenom: selector(state, 'adversaires[0].prenom'),
        circonstances: [
            {
                number: 1,
                text: 'en stationnement / à l\'arrêt'
            },
            {
                number: 2,
                text: 'quittait un stationnement / ouvrait une portière'
            },
            {
                number: 3,
                text: 'prenait un stationnement'
            },
            {
                number: 4,
                text: 'sortait d\'un parking, d\'un lieu privé, d\'un chemin de terre',
            },
            {
                number: 5,
                text: 's\'engageait dans un parking, un lieu privé, un chemin de terre',
            },
            {
                number: 6,
                text: 's\'engageait sur une place à sens giratoire',
            },
            {
                number: 7,
                text: 'roulait sur une place à sens giratoire',
            },
            {
                number: 8,
                text: 'heurtait à l\'arrière en roulant dans le même sens et sur même file',
            },
            {
                number: 9,
                text: 'roulait dans le meme sens et sur une file d\'attente',
            },
            {
                number: 10,
                text: 'changeait de file',
            },
            {
                number: 11,
                text: 'doublait',
            },
            {
                number: 12,
                text: 'virait à droite',
            },
            {
                number: 13,
                text: 'virait à gauche',
            },
            {
                number: 14,
                text: 'reculait',
            },
            {
                number: 15,
                text: 'empiétait sur une voie reservée à la circulation et en sens inverse',
            },
            {
                number: 16,
                text: 'venait de droite (dans un carrefour)',
            },
            {
                number: 17,
                text: 'n\'avait pas observé un signal de priorité ou un feu rouge',
            },
        ]
    }
}

const mapDispatchToProps = {
    postCasIda
}

const CirconstancesContainer = connect(mapStateToProps, mapDispatchToProps)(Circonstances)

export default CirconstancesContainer
