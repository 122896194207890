import React from 'react';
import CardChoc from "./CardChoc/CardChoc";
import Cadre from "../../../../../../../../../Commun/Cadre/Cadre";

const Chocs = (props) => {
    const {produit} = props
    return (
        <div className={'form-content h-100'}>
            <Cadre icon={'fas fa-car-crash'} title={'Point de choc de l\'accident'}>
                <p className="h6 text-center">Cocher le point de choc initial de l'accident</p>

                <CardChoc produit={produit} />
            </Cadre>
        </div>
    );
};

export default Chocs;
