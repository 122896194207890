import React, {useState} from 'react';
import {Link} from 'react-router-dom'
import NotificationsContainer from "./Notifications/NotificationsContainer";
import {QUOTE} from "../../../../../redux/actions/app/quotes/quotes.actions";
import SearchContainer from "../../../../Commun/Inputs/Search/SearchContainer";
import {CONTRACT} from "../../../../../redux/actions/app/contracts/contracts.actions";

const Header = (props) => {
    const { isConcessionnaire, name, isCourtier } = props
    const [entity, setEntity] = useState(QUOTE)

    return (
        <div className={'bg-white row m-0 d-none d-lg-flex'}>

            <div className="col-4 pl-5" style={{maxHeight: 70}}>
                <p className={'m-0 p-4'}>Bienvenue sur votre espace APRIL Moto <b>{name}</b></p>
            </div>


            <div className={`col p-0 d-flex slider ${(isConcessionnaire || isCourtier) && 'border-left'}`}>
                {
                    (isConcessionnaire || isCourtier) &&
                    <>
                        <div className="col-2 p-0 min-width-1">
                            <select style={{fontSize: 14}} className="custom-select focus-border-secondary background-primary no-focus cursor-pointer form-control h-100 rounded-0 border-color-secondary border-top-0 border-left-0 border-right-0" onChange={(e) => setEntity(e.target.value)} value={entity}>
                                <option value={QUOTE}>Devis</option>
                                <option value={CONTRACT}>Contrats</option>
                            </select>
                        </div>
                        <div className="col p-0">
                            <SearchContainer className={'h-100'} entity={entity} placeholder={`${entity === QUOTE ? 'Rechercher un prospect par numéro de devis ou par nom' : 'Rechercher un client par numéro de contrat, nom du souscripteur, immatriculation...'}`} />
                        </div>
                    </>
                }
            </div>


            <div style={{maxHeight: 70}}>
                <div className="float-right h-100 border-left">
                    <Link to={'/deconnexion'} className={'text-dark'}>
                        <div className={'p-4 h-100 cursor-pointer hover-secondary-color'}>
                            <i className="fas fa-sign-out-alt font-size-20" />
                        </div>
                    </Link>
                </div>

                {!isConcessionnaire && <NotificationsContainer /> }

            </div>
        </div>
    );
};

export default Header;
