import React from 'react';
import './radioFieldRedux.scss'

const RadioAccidentRedux = (props) => {
    const { input, meta: {touched, error}, label, data, type } = props

    return (
        <div className={`form-group`}>
            {
                data.map(d => (
                    <label className={`amo-radio cursor-pointer d-block pl-4 ${touched && error ? 'is-invalid' :''} ${d.label} ${type}`} key={d.value}>
                        <input type="radio" name={input.name} value={d.value} onChange={input.onChange} className={'position-absolute cursor-pointer d-none'} checked={input.value === d.value}/>
                        <span className="checkmark position-absolute rounded-circle"/>
                    </label>
                ))
            }
            {touched && error && <small className={'invalid-feedback'}>{error}</small>}
        </div>
    );
};

export default RadioAccidentRedux;
