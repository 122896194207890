import axios from 'axios'
import moment from 'moment'
import {
    PASS_URL,
    WHEELS_URL,
    GRAVITEE_API_KEY_IBAN,
    GRAVITEE_URL,
    SERVICES_URL
} from "../constants/constants";
import * as Sentry from "@sentry/react";

/**
 * Format number phone FR xx.xx.xx.xx.xx
 * @param phone
 * @returns {string}
 */
export const formatPhone = phone => {
    return phone ? phone.toString().replace(/\./g,'').match(/.{2}/g).join('.') : ''
}

/**
 * Get city by postal code
 * use
 * async fetchCityByPostalCode(value){
 *      let cities = await getCity(value)
 *      console.log(cities)
 * }
 * @param value
 * @returns {string}
 */
export const getCity = value => {
    return new Promise(resolve => {
        axios.get(`${PASS_URL}/towns/${value}`)
            .then(response => {
                resolve(response.data.data)
            }).catch(error => {
            console.log(error)
            if(error.code !== 404) {
                Sentry.captureMessage(`${error.code} PASS - ${error.message}`);
            }
        })
    });
}

/**
 * Get city by search
 * use
 * async fetchCityBySearch(value){
 *      let cities = await getCity(value)
 *      console.log(cities)
 * }
 * @param value
 * @returns {string}
 */
export const getCityBySearch = value => {
    return new Promise(resolve => {
        axios.get(`${PASS_URL}/towns/search/${value}`)
            .then(response => {
                resolve(response.data.data)
            }).catch(error => {
            console.log(error)
            if(error.code !== 404) {
                Sentry.captureMessage(`${error.code} PASS - ${error.message}`);
            }
        })
    });
}

/**
 * Format number to euro value
 * @param number
 * @param style
 * @returns {string}
 */
export const formatEuro = (number, style = 'currency') => {
    return new Intl.NumberFormat('fr-FR', {style, currency: 'EUR'}).format(number)
}

/**
 * retourne les jours férié de l'année
 * use
 * JoursFeries(moment().weekYear())
 * @param année
 * @returns {moment Object}
 */
export const JoursFeries = an => {

    const JourAn = moment(`${an}-01-01`).format('YYYY-MM-DD')
    const FeteTravail = moment(`${an}-05-01`).format('YYYY-MM-DD')
    const Victoire1945 = moment(`${an}-05-08`).format('YYYY-MM-DD')
    const FeteNationale = moment(`${an}-07-14`).format('YYYY-MM-DD')
    const Assomption = moment(`${an}-08-15`).format('YYYY-MM-DD')
    const Toussaint = moment(`${an}-11-01`).format('YYYY-MM-DD')
    const Armistice = moment(`${an}-11-11`).format('YYYY-MM-DD')
    const Noel = moment(`${an}-12-25`).format('YYYY-MM-DD')
    const SaintEtienne = moment(`${an}-12-26`).format('YYYY-MM-DD')

    const G = an%19
    const C = Math.floor(an/100)
    const H = (C - Math.floor(C/4) - Math.floor((8*C+13)/25) + 19*G + 15)%30
    const I = H - Math.floor(H/28)*(1 - Math.floor(H/28)*Math.floor(29/(H + 1))*Math.floor((21 - G)/11))
    const J = (an*1 + Math.floor(an/4) + I + 2 - C + Math.floor(C/4))%7
    const L = I - J
    const MoisPaques = 3 + Math.floor((L + 40)/44)
    const JourPaques = L + 28 - 31*Math.floor(MoisPaques/4)
    const PaquesOrigin = moment(`${an}-0${MoisPaques}-${JourPaques}`)

    const Paques = moment(`${an}-0${MoisPaques}-${JourPaques}`).format("YYYY-MM-DD")
    const VendrediSaint = moment(PaquesOrigin._i).subtract(2, 'days').format("YYYY-MM-DD")
    const LundiPaques = moment(PaquesOrigin._i).add(1, 'days').format("YYYY-MM-DD")
    const Ascension = moment(PaquesOrigin._i).add(39, 'days').format("YYYY-MM-DD")
    const Pentecote = moment(PaquesOrigin._i).add(49, 'days').format("YYYY-MM-DD")
    const LundiPentecote = moment(PaquesOrigin._i).add(50, 'days').format("YYYY-MM-DD")

    return [
        JourAn,
        VendrediSaint,
        Paques,
        LundiPaques,
        FeteTravail,
        Victoire1945,
        Ascension,
        Pentecote,
        LundiPentecote,
        FeteNationale,
        Assomption,
        Toussaint,
        Armistice,
        Noel,
        SaintEtienne
    ]
}

/**
 * Get reparateur by code postal
 * use
 * async fetchReparateurByCodePostal(postalCode){
 *      let reparateurs = await getReparateurByCodePostal(postalCode)
 *      console.log(reparateurs)
 * }
 * @param postalCode
 * @returns {string}
 */
export const getReparateurByCodePostal = postalCode => {
    return new Promise(resolve => {
        axios.get(`${PASS_URL}/garages/${postalCode}`)
            .then(response => {
                resolve(response.data.data)
            }).catch(error => {
            console.log(error)
            if(error.code !== 404) {
                Sentry.captureMessage(`${error.code} PASS - ${error.message}`);
            }
        })
    });
}

/**
 * Get city by postal code
 * use
 * async fetchCompagnys(value){
 *      let compagnys = await getCompagnys(value)
 *      console.log(compagnys)
 * }
 * @param value
 * @returns {string}
 */
export const getCompagnys = () => {
    return new Promise(resolve => {
        axios.get(`${PASS_URL}/companys`)
            .then(response => {
                resolve(response.data.data)
            }).catch(error => {
            console.log(error)
            if(error.code !== 404) {
                Sentry.captureMessage(`${error.code} PASS - ${error.message}`);
            }
        })
    });
}

/**
 * Get city by postal code
 * use
 * async fetchInsurers(value){
 *      let compagnys = await getInsurers(value)
 *      console.log(compagnys)
 * }
 * @param value
 * @returns {string}
 */
export const getCountries = () => {
    return new Promise(resolve => {
        axios.get(`${PASS_URL}/countries`)
            .then(response => {
                resolve(response.data.data)
            }).catch(error => {
            console.log(error)
        })
    });
}


/**
 * Renvoie la liste des compagnies d'assurances
 * use
 * async fetchInsurers(value){
 *      let insurers = await getInsurers(value)
 *      console.log(insurers)
 * }
 * @returns {array}
 */
export const getInsurers = () => {
    return new Promise(resolve => {
        axios.get(`${PASS_URL}/insurers`)
            .then(response => {
                resolve(response.data.data)
            }).catch(error => {
            console.log(error)
        })
    });
}


/* WHEELS API */

/**
 * Get Types Vehicules in WHEELS
 * use
 * async fetchTypesVehicules(){
 *      let types = await getTypesByWheels()
 *      console.log(types)
 * }
 * @param value
 * @returns {string}
 */
export const getTypesByWheels = value => {
    return new Promise(resolve => {
        axios.get(`${WHEELS_URL}/types`)
            .then(response => {
                resolve(response.data.data)
            }).catch(error => {
            console.log(error)
        })
    });
}

/**
 * Get Brands Vehicules in WHEELS
 * use
 * async fetchBrandsVehicules(type){
 *      let brands = await getBrandsByTypes(type)
 *      console.log(brands)
 * }
 * @param type
 * @returns {string}
 */
export const getBrandsByTypes = ({type}) => {
    return new Promise(resolve => {
        axios.get(`${WHEELS_URL}/brands?type=${type}`)
            .then(response => {
                resolve(response.data.data)
            }).catch(error => {
            console.log(error)
        })
    });
}
/**
 * Get Sizes Vehicules in WHEELS
 * use
 * async fetchSizesVehicules(){
 *      let sizes = await getSizesByWheels()
 *      console.log(sizes)
 * }
 * @param type
 * @param brand
 * @returns {string}
 */
export const getSizesByWheels = ({type, brand}) => {
    return new Promise(resolve => {
        axios.get(`${WHEELS_URL}/sizes?type=${type}&brand=${brand}`)
            .then(response => {
                resolve(response.data.data)
            }).catch(error => {
            console.log(error)
        })
    });
}

/**
 * Get Vehicules in WHEELS
 * use
 * async fetchVehicules(){
 *      let vehicules = await getVehiculesByWheels()
 *      console.log(vehicules)
 * }
 * @param type
 * @param brand
 * @param size
 * @returns {string}
 */
export const getVehiculesByWheels = ({type, brand, size}) => {
    return new Promise(resolve => {
        axios.get(`${WHEELS_URL}/vehicles?type=${type}&brand=${brand}&size=${size}`)
            .then(response => {
                resolve(response.data.data)
            }).catch(error => {
            console.log(error)
        })
    });
}

/**
 * Check iban
 * use
 * async fetchCheckIban(value){
 *      let iban = await checkIban(value)
 *      console.log(iban)
 * }
 * @param value
 * @returns {object}
 */
export const checkIban = (value) => {
    return new Promise(resolve => {
        axios({
            method: "GET",
            url: `${GRAVITEE_URL}/ibancheck/?iban=${value}`,
            headers: {
                "X-Gravitee-Api-Key": GRAVITEE_API_KEY_IBAN
            },
        }).then((response) => {
            resolve(response.data);
        }).catch(error => {
            console.log(error)
        });
    });
}

/**
 * Get Address
 * use
 * async fetchAddress(value){
 *      let address = await getAddress(value)
 *      console.log(address)
 * }
 * @param value
 * @returns {object}
 */
export const getAddress = (value, validate = 'Undefined', localityId = null, postalCode = null, locality = null) => {
    return new Promise((resolve) => {

        const params = {
            value: encodeURIComponent(value),
            validate,
            localityId,
            postalCode,
            locality
        };

        const filteredParams = Object.entries(params)
            .filter(([key, val]) => val !== null)
            .map(([key, val]) => `${key}=${val}`)
            .join('&');

        const url = `${SERVICES_URL}/check-address?${filteredParams}`;

        axios.get(url)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    });
};

export const getCitiesFromFrance = (value, request = "SearchBirthplaceWS") => {
    return new Promise(resolve => {
        axios.get(`${SERVICES_URL}/check-birth?value=${value}&function=${request}`)
            .then(response => {
                resolve(response.data)
            }).catch(error => {
            console.log(error)
        })
    });
}
