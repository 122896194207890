import React from 'react';
import './large-spinner.scss'
import SmallSpinner from "../SmallSpinner";
import logo from '../../../../assets/images/april_moto_dark.svg'


const LargeSpinner = () => {
    return (
        <div id={'start-loader'}>
            <img src={logo} alt="Logo APRIL Moto" />
            <SmallSpinner />
        </div>
    );
};

export default LargeSpinner;
