import React from 'react';
import PropTypes from 'prop-types'
import Card from "../Card/Card";

const ErrorMessage = (props) => {
    const { error: {code, message, reason, html} } = props

    if (html){
        return <div dangerouslySetInnerHTML={{__html: html}} />
    }

    return (
        <div className={'p-4'}>
            <Card>
                <p className={'h5 text-center'}>Une erreur est survenue.</p>
                <p className={'h5 text-center'}>Erreur <span className={'text-danger font-italic'}>{code ? code : 'inconnu'}</span>, {message && <span className={'text-danger font-italic'}>{message}</span>}</p>
                {reason && <p className={'h5 text-center'}>Raison : <span className={'text-danger font-italic'}>{reason}</span></p>}
            </Card>
        </div>
    );
};

ErrorMessage.defaultProps = {
    error: {
        code: 400,
        message: 'Erreur du serveur',
        reason: ''
    }
}

ErrorMessage.propTypes = {
    code: PropTypes.number,
    message: PropTypes.string,
    reason: PropTypes.string
}

export default ErrorMessage;
