const validate = values => {
    const errors = {}

    const requiredFields = [
        'pays',
        'ville',
        'nom'
    ]

    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Le champ est obligatoire'
        }
    })

    return errors
}

export default validate
