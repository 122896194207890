
import _ from "lodash";
import {API_ERROR, API_SUCCESS, apiRequest} from '../../../actions/core/api/api.actions'
import {setError, setErrorResponse, setFilters, setLoader, setPayMail} from '../../../actions/app/ui/ui.actions'
import {FETCH_IMPAYES, IMPAYES, POST_PAY_MAIL_IMPAYES, setImpayes} from "../../../actions/app/impayes/impayes.actions";
import {setNotification} from "../../../actions/core/notifications/notifications.actions";

export const impayesMiddleware = () => next => action => {
    next(action)

    switch (action.type){

        case FETCH_IMPAYES:
            let url = _.includes(action.payload.data, '?') ? action.payload.data : `${action.payload.data}?`

            if (action.payload.filters){
                for (let [key, value] of Object.entries(action.payload.filters)) {
                    url = value ? `${url}&${key}=${value}` : url
                }
            }

            next([
                apiRequest({body: null, method: 'GET', url, entity: IMPAYES}),
                setFilters({entity: IMPAYES, filters: {...action.payload.filters}}),
                setLoader({state: true, entity: IMPAYES})
            ])
            break

        case `${IMPAYES} ${API_SUCCESS}`:
            next([
                setImpayes({data: action.payload.data}),
                setError({state: false, entity: IMPAYES}),
                setLoader({state: false, entity: IMPAYES})
            ])
            break

        case `${IMPAYES} ${API_ERROR}`:
            next([
                setError({state: true, entity: IMPAYES}),
                setErrorResponse({entity: IMPAYES, data: action?.payload?.data?.response?.data}),
                setLoader({state: false, entity: IMPAYES})
            ])
            break

        case POST_PAY_MAIL_IMPAYES:
            next([
                apiRequest({body: (action.payload.body), method: 'POST', url: action.payload.data, entity: POST_PAY_MAIL_IMPAYES, otherData: action.payload.body}),
                setLoader({state: true, entity: POST_PAY_MAIL_IMPAYES})
            ])
            break;

        case `${POST_PAY_MAIL_IMPAYES} ${API_SUCCESS}`:
            next([
                setPayMail({data: `[${action.payload.meta.otherData.contract}-${action.payload.meta.otherData.receipt}]`}),
                setNotification({
                    entity: POST_PAY_MAIL_IMPAYES,
                    body: null,
                    type: 'success', title: 'Le lien de paiement a bien été envoyé par email'
                }),
                setError({state: false, entity: POST_PAY_MAIL_IMPAYES}),
                setLoader({state: false, entity: POST_PAY_MAIL_IMPAYES})
            ])
            break

        case `${POST_PAY_MAIL_IMPAYES} ${API_ERROR}`:
            next([
                setNotification({
                    entity: POST_PAY_MAIL_IMPAYES,
                    body: 'Une erreur est survenue lors de l\'envoi de l\'email.',
                    type: 'error',
                    title: 'Attention !',
                    timeout: "0"
                }),
                setError({state: true, entity: POST_PAY_MAIL_IMPAYES}),
                setLoader({state: false, entity: POST_PAY_MAIL_IMPAYES})
            ])
            break

        default:
            break
    }
    return null
}
