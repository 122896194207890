import {
    FETCH_PUBLICITES,
    PUBLICITES,
    PUT_PUBLICITES,
    setPublicites
} from "../../../actions/app/publicites/publicites.actions";
import {API_ERROR, API_SUCCESS, apiRequest} from "../../../actions/core/api/api.actions";
import {setLoader, setError} from "../../../actions/app/ui/ui.actions";
import {setNotification} from "../../../actions/core/notifications/notifications.actions";

export const publicitesMiddleware = () => next => action => {
    next(action)

    const { payload } = action

    switch (action.type){
        case FETCH_PUBLICITES:
            next([
                apiRequest({body: null, method: 'GET', url: payload.data, entity: PUBLICITES}),
                setLoader({state: true, entity: PUBLICITES})
            ])
            break

        case `${PUBLICITES} ${API_SUCCESS}`:
            next([
                setPublicites({data: action.payload.data}),
                setError({state: false, entity: PUBLICITES}),
                setLoader({state: false, entity: PUBLICITES}),
            ])
            break

        case `${PUBLICITES} ${API_ERROR}`:
            next([
                setError({state: true, entity: PUBLICITES}),
                setLoader({state: false, entity: PUBLICITES}),
            ])
            break

        case PUT_PUBLICITES:
            let body = payload.body
            let cart = []

            for (let [key, value] of Object.entries(body.commande)) {
                cart.push({id: key, quantity: value})
            }

            body.cart = cart

            next([
                apiRequest({body: body, method: 'POST', url: payload.data, entity: PUT_PUBLICITES, header: payload.header}),
                setLoader({state: true, entity: PUT_PUBLICITES})
            ])
            break

        case `${PUT_PUBLICITES} ${API_SUCCESS}`:

            next([
                setError({state: false, entity: PUT_PUBLICITES}),
                setLoader({state: false, entity: PUT_PUBLICITES}),
            ])
            break

        case `${PUT_PUBLICITES} ${API_ERROR}`:

            next([
                setNotification({entity: PUT_PUBLICITES, body: payload.data.response.data.message, type: 'error', title: 'Attention !', timeout: "0"}),
                setError({state: true, entity: PUT_PUBLICITES}),
                setLoader({state: false, entity: PUT_PUBLICITES}),
            ])
            break

        default:
            break
    }
    return null
}
