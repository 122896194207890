import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import loader from '../Commun/Spinners/Loader/Loader'
import {PASS_URL} from '../../constants/constants'
import {fetchLinks, LINKS} from '../../redux/actions/app/links/links.actions'
import App from './App'
import {getMaintenance, getLoadingEntity, getLoading} from '../../redux/selectors/ui/ui.selectors'

if (window.location.search.startsWith('?j=')) {
    window.location = `/login/${window.location.search.slice(3)}?redirect_uri=${window.location.href.split('?')[0]}`
}

const mapStateToProps = state => {
    return {
        maintenance: getMaintenance(state),
        loaded: getLoading(state) !== undefined && !getLoadingEntity(state, LINKS),
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    load: () => fetchLinks({query: PASS_URL})
}, dispatch)

const AppContainer = compose(
    connect(mapStateToProps, mapDispatchToProps),
    loader()
)(App)

export default AppContainer
