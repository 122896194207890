import {API_ERROR, API_SUCCESS, apiRequest} from '../../../actions/core/api/api.actions'
import {setError, setErrorResponse, setFilters, setLoader} from '../../../actions/app/ui/ui.actions'
import {FETCH_QUOTE, QUOTE, setQuote} from "../../../actions/app/quotes/quotes.actions";
import _ from "lodash";

export const quotesMiddleware = () => next => action => {
    next(action)

    switch (action.type){

        case FETCH_QUOTE:
            let url = _.includes(action.payload.data, '?') ? action.payload.data : `${action.payload.data}?`

            if (action.payload.filters){
                for (let [key, value] of Object.entries(action.payload.filters)) {
                    url = `${url}&${key}=${value}`
                }
            }

            next([
                apiRequest({body: null, method: 'GET', url, entity: QUOTE, otherData: action.payload.otherData}),
                setFilters({entity: QUOTE, filters: {...action.payload.filters}}),
                setLoader({state: true, entity: QUOTE})
            ])
            break

        case `${QUOTE} ${API_SUCCESS}`:
            next([
                setQuote({data: action.payload.data}),
                setError({state: false, entity: QUOTE}),
                setLoader({state: false, entity: QUOTE})
            ])
            break

        case `${QUOTE} ${API_ERROR}`:
            let nextActions = [
                setError({state: true, entity: QUOTE}),
                setLoader({state: false, entity: QUOTE})
            ]

            const message = `<div class='p-4'>
                <div class="card w-auto mx-lg-5 p-2 h-100">
            <div class="card-body">
                    <p class='h5 text-center'>Votre devis a été réalisé <span class="text-danger">il y a plus de 30 jours</span><br/>ou celui-ci est indisponible.</p>
                    <p class='h6 text-center mb-3'>Merci de réaliser une nouvelle proposition ou de nous contacter au 02-47-51-07-07.</p>
                </div>
                </div>
            </div>`

            if(action.payload.meta.otherData.detail){
                nextActions.push(setErrorResponse({entity: QUOTE, data: action?.payload?.data?.response?.data, html: message}))
            } else{
                nextActions.push(setErrorResponse({entity: QUOTE, data: action?.payload?.data?.response?.data}))
            }

            next(nextActions)
            break

        default:
            break
    }
    return null
}
