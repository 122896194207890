import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { Field } from 'redux-form';
import moment from 'moment';
import { tiersPayeurRelation } from '../../../../../../../utils/optionsSelect';
import SelectFieldRedux from '../../../../../../Commun/Inputs/Select/SelectFieldRedux';
import RadioFieldRedux from '../../../../../../Commun/Inputs/Radio/RadioFieldRedux';
import TextFieldRedux from '../../../../../../Commun/Inputs/Text/TextFieldRedux';
import DatePickerFieldRedux from '../../../../../../Commun/Inputs/Date/DatePickerFieldRedux';
import Autocomplete from '../../../../../../Commun/Inputs/Autocomplete/Autocomplete';
import SmallSpinner from '../../../../../../Commun/Spinners/SmallSpinner';
import { getCountries } from '../../../../../../../utils/function';
import Card from '../../../../../../Commun/Card/Card';
import { useHistory } from 'react-router-dom';

const AddTiersPayeur = (props) => {
    const { handleSubmit, edit, loading, type, changeValue, relationship } = props;
    const history = useHistory();
    const [listCountries, setListCountries] = useState(false);

    async function fetchCountries() {
        let countries = await getCountries();
        let list = _.orderBy(countries, ['name'], ['asc']);

        list = list.map((c) => ({
            value: c.id,
            label: c.name,
        }));

        setListCountries(list);
    }

    useEffect(() => {
        if (!listCountries) {
            fetchCountries();
        }
    }, [listCountries]);

    return (
        <div className={'p-lg-4'}>
            <Card>
                <div>
                    <h3 className={'m-0 pb-3 border-bottom border-dark'}>{edit ? 'Editer le tiers payeur' : 'Ajouter un tiers payeur'}</h3>

                    <div className='mt-5'>
                        <span className={'btn btn-outline-secondary'} onClick={() => history.goBack()}>
                            <i className={'fas fa-chevron-left mr-3'} /> Retour
                        </span>
                    </div>

                    <div className='mt-5 col-lg-6 col-md-12 mx-auto'>
                        <form onSubmit={handleSubmit} className={'mt-5'}>
                            <div className='row'>
                                <div className='col-12'>
                                    <Field
                                        name='type'
                                        component={RadioFieldRedux}
                                        label={'Type de tiers payeur'}
                                        data={[
                                            { value: 'P', label: 'Physique' },
                                            { value: 'M', label: 'Morale' },
                                        ]}
                                        onChange={(e) => {
                                            if (e.target.value === 'P') {
                                                changeValue('birthdate', moment().subtract(16, 'year'));
                                            } else {
                                                changeValue('firstname', null);
                                                changeValue('birthname', null);
                                                changeValue('birthdate', null);
                                                changeValue('birthcountry', null);
                                                changeValue('birthcity', null);
                                                changeValue('relationship', null);
                                                changeValue('third_payeur_reason', null);
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-6'>
                                    <Field
                                        name='lastname'
                                        component={TextFieldRedux}
                                        label={type === 'P' ? 'Nom' : 'Nom de la société'}
                                        normalize={(v) => v.toUpperCase()}
                                        maxLength={50}
                                    />
                                </div>
                                {type === 'P' && (
                                    <>
                                        <div className='col-6'>
                                            <Field name='firstname' component={TextFieldRedux} label={'Prénom'} normalize={(v) => v.toUpperCase()} maxLength={20} />
                                        </div>
                                        <div className='col-6'>
                                            <Field name={'birthname'} component={TextFieldRedux} label={'Nom de naissance'} normalize={(v) => v.toUpperCase()} maxLength={20} />
                                        </div>
                                        <div className='col-6'>
                                            <Field
                                                name={'birthdate'}
                                                component={DatePickerFieldRedux}
                                                label={'Date de naissance'}
                                                maxDate={moment().subtract(16, 'Y').format('YYYY-MM-DD')}
                                            />
                                        </div>
                                        <div className='col-6'>
                                            <Field name={'birthcity'} component={TextFieldRedux} label={'Ville de naissance'} maxLength={250} />
                                        </div>
                                        <div className='col-6'>
                                            <Field name={'birthcountry'} component={Autocomplete} label='Pays de naissance' data={listCountries} />
                                        </div>

                                        <div className='col-6'>
                                            <Field
                                                name='relationship'
                                                component={SelectFieldRedux}
                                                label={'Lien souscripteur / payeur'}
                                                onChange={(e) => {
                                                    if (e.target.value !== 'A') {
                                                        changeValue('third_payeur_reason', null);
                                                    }
                                                }}
                                            >
                                                <option value={''}>Sélectionnez une relation</option>
                                                {tiersPayeurRelation.map((relation, index) => (
                                                    <option key={index} value={relation.code}>
                                                        {relation.libelle}
                                                    </option>
                                                ))}
                                            </Field>
                                        </div>
                                        {relationship && relationship === 'A' ? (
                                            <div className='col-6'>
                                                <Field name={'third_payeur_reason'} component={TextFieldRedux} label={"Motif d'intervention du payeur"} maxLength={250} />
                                            </div>
                                        ) : null}
                                    </>
                                )}
                            </div>
                            {loading ? (
                                <SmallSpinner style={{ display: 'block', margin: 'auto' }} />
                            ) : (
                                <button className={'btn btn-primary d-block'} type={'submit'}>
                                    {edit ? 'Editer le tiers payeur' : 'Ajouter le tiers payeur'}
                                </button>
                            )}
                        </form>
                    </div>
                </div>
            </Card>
        </div>
    );
};
export default AddTiersPayeur;
