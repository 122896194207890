import React from 'react';
import InitFormContainer from "../InitFormContainer";

const GetFleetForInitForm = (props) => {
    const { idVehicule } = props

    return <InitFormContainer idVehicule={idVehicule} />;
};

export default GetFleetForInitForm;
