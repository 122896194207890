import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import loader from '../../../../Commun/Spinners/Loader/Loader'
import Deconnexion from './Deconnexion'
import {LOGOUT, postLogout} from "../../../../../redux/actions/app/login/login.actions";
import {userLogoutLink} from "../../../../../redux/selectors/links/links.selectors";
import {getLoadingEntity} from "../../../../../redux/selectors/ui/ui.selectors";

const mapStateToProps = state => {
    return {
        uri: userLogoutLink(state),
        logout: getLoadingEntity(state, LOGOUT) === false,
    }
}
const mapDispatchToProps = dispatch => bindActionCreators({
    logout: (query) => postLogout({query})
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {logout} = dispatchProps
    const {uri} = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => logout(uri)
    }
}

const DeconnexionContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader()
)(Deconnexion)

export default DeconnexionContainer
