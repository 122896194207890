export const COMMISSIONS = '[Commissions]'

export const FETCH_COMMISSIONS = `${COMMISSIONS} Fetch`
export const SET_COMMISSIONS = `${COMMISSIONS} Set`

export const fetchCommissions = ({query}) => ({
    type: FETCH_COMMISSIONS,
    payload: {
        data: query
    }
})

export const setCommissions = ({data}) => ({
    type: SET_COMMISSIONS,
    payload: {
        data
    }
})
