import React, {useState} from 'react';
import PropTypes from 'prop-types'
import {Field} from "redux-form";
import moment from 'moment'
import _ from 'lodash'
import Cadre from "../../../../../../../../../Commun/Cadre/Cadre";
import RadioDesignButton from "../../../../../../../../../Commun/Inputs/Radio/RadioDesignButton/RadioDesignButton";
import {getCategoryByTypeAccident} from "./getCategoryByTypeAccident";
import DatePickerFieldRedux from "../../../../../../../../../Commun/Inputs/Date/DatePickerFieldRedux";
import SelectFieldRedux from "../../../../../../../../../Commun/Inputs/Select/SelectFieldRedux";
import {listePays} from "./listePays";
import PostalCodeCity from "../../../../../../../../../Commun/Inputs/PostalCodeCity/PostalCodeCity";

const Declaration = (props) => {
    const {
        type_sinistre,
        pays_lieu_sinistre,
        changeValue,
        garanties,
        dateFinContrat,
        datesurvorigin,
        dateAfnNouvelle,
        catsinorigin,
        setValidFirstCheck,
        setValidSecondCheck,
        setEmailToClient,
        fetchDocumentsAccident,
        accidents, isFleet} = props
    const [alert, setAlert] = useState(false)

    let accidentsDejaDeclare = _.find(accidents, a => {
        return moment(a.dateSurv).format('L') === moment(datesurvorigin).format('L')
    })

    /*
       Si le contrat n'est pas en cours à la date de survenance du sinistre
       qu'il ne possède pas la garantie vol pour un sinistre de type
       - Vol
       - Tentative de vol
       - Vol véhicule retrouvé
       - Vol de pièces et accessoires
       - Evènement climatique
   */
    const checkStep = () => {
        if (dateFinContrat != null){
            if (moment(dateFinContrat) < moment(datesurvorigin)){
                setAlert(true)
                setValidFirstCheck(false)
                setEmailToClient('contrat_resilie')
                return false
            }
        }

        if (moment(dateAfnNouvelle) > moment(datesurvorigin)){
            setAlert(true)
            setValidFirstCheck(false)
            setEmailToClient('contrat_resilie')
            return false
        }

        if (!_.find(garanties, ['code_famille', 'VI']) && _.includes(['10.0', '105.1', '9.0', '105.2', '19.0'], catsinorigin)){
            setAlert(true)
            setValidFirstCheck(false)
            if (catsinorigin === '19.0'){
                setEmailToClient('cat_naturelle')
            } else {
                setEmailToClient('vol')
            }
            return false
        }

        if (_.includes(['104.5', '19.0'], catsinorigin)) {
            setAlert(false)
            setValidFirstCheck(true)
            setValidSecondCheck(true)
            return false
        }

        setAlert(false)
        catsinorigin ? setValidFirstCheck(true) : setValidFirstCheck(false)

    }

    const changeCatSinOrigin = arrayCatSin => {
        fetchDocumentsAccident({categorie: arrayCatSin[0], souscategorie: arrayCatSin[1]})
    }

    return (
        <div className={'form-content'}>
            <Cadre icon={'fas fa-edit'} title={'Que s\'est il passé ?'}>
                <Field
                    name={'type_sinistre'}
                    component={RadioDesignButton}
                    label={'Le type de sinistre :'}
                    data={[{value: "accident", label:"Accident"}, {value: "vol", label:"Vol - Tentative de vol"}, {value: "autres", label:"Autres"}]}
                />

                {
                    type_sinistre && <Field
                        name='catsinorigin'
                        component={RadioDesignButton}
                        label='Catégorie du sinistre'
                        data={getCategoryByTypeAccident(type_sinistre)}
                        onChange={(e) => changeCatSinOrigin(_.split(e.target.value, '.'))}
                    />
                }

                <Field
                    name={"datesurvorigin"}
                    component={DatePickerFieldRedux}
                    label={<p>Date du sinistre {type_sinistre === 'vol' ? <span style={{color: "#ff0000"}}>(Si la date du vol se trouve entre 2 dates, indiquer la date la plus ancienne)</span> : ''}</p>}
                    maxDate={moment()}
                />

                {
                    ['10.0', '105.1', '9.0', '105.2'].includes(catsinorigin) && <small className={'mt-2 mb-3 d-block font-weight-bold'}>Indiquez le jour de la disparition de votre véhicule. Si vous ne connaissez pas le jour exact, indiquez la 1ère date inscrite sur le procès verbal.</small>
                }

                <Field
                    name={'pays_lieu_sinistre'}
                    component={RadioDesignButton}
                    label={'Le sinistre a-t-il eu lieu dans un pays étranger ?'}
                    data={[{value: "T", label:"Oui"}, {value: "F", label:"Non"}]}
                    onChange={() => changeValue('lieu', '')}
                />

                {
                    pays_lieu_sinistre === "T" &&
                    <Field
                        name='pays'
                        component={SelectFieldRedux}
                        label={'Pays où le sinistre au eu lieu'}
                        input={{
                            onChange: (e) => {
                                changeValue('lieu', e.target.value)
                                changeValue('cplieu', "99000")
                            }
                        }}
                    >
                        <option value={""}>Choisissez un pays</option>
                        {
                            listePays.map((pays, index) =>
                                <option key={index} value={pays.value}>{pays.label}</option>
                            )
                        }
                    </Field>
                }

                {
                    pays_lieu_sinistre === "F" && <PostalCodeCity
                        nameCodePostal='cplieu'
                        nameVille='lieu'
                        label={'Lieu du sinistre'}
                        changeValue={changeValue}
                    />
                }

                {
                    alert && <div className="alert alert-danger" role="alert">
                        <p>Les garanties du contrat et les éléments de la déclaration ne permettent pas de donner suite à cette déclaration.</p>
                        <p>Une confirmation est adressée au client.</p>
                    </div>
                }

                {
                    accidentsDejaDeclare && !isFleet && <div className="alert alert-danger" role="alert">
                        <p>Attention, un sinistre a déjà été déclaré à cette date, vous ne pouvez pas poursuivre la déclaration.</p>
                    </div>
                }

                <div className={'text-right'}>
                    <span className={`${accidentsDejaDeclare && !isFleet && 'disabled'} btn btn-primary max-rounded`} onClick={() => accidentsDejaDeclare && !isFleet ? null : checkStep()}>Poursuivre</span>
                </div>

            </Cadre>
        </div>
    );
};

Declaration.propTypes = {
    type_sinistre: PropTypes.string,
    catsinorigin: PropTypes.string,
    datesurvorigin: PropTypes.object,
    pays_lieu_sinistre: PropTypes.string,
    garanties: PropTypes.array,
    dateFinContrat: PropTypes.string,
    dateAfnNouvelle: PropTypes.string,
    changeValue: PropTypes.func,
    fetchDocumentsAccident: PropTypes.func
};

export default Declaration;
