import {createSelector} from 'reselect'

const statsSelector = state => state.stats

export const getStatsData = createSelector(
    statsSelector,
    stats => stats
)
export const getTotalAfn = createSelector(
    statsSelector,
    stats => stats.total_afn || 0
)
export const getChuteAfn = createSelector(
    statsSelector,
    stats => stats.chute_afn || 0
)
export const getAccidentWaiting = createSelector(
    statsSelector,
    stats => stats.accident_waiting || 0
)
export const getAccidentsOpened = createSelector(
    statsSelector,
    stats => stats.accidents_opened || 0
)
export const getContractsDocumentWaiting = createSelector(
    statsSelector,
    stats => stats.contracts_document_waiting || 0
)
export const getContractsNewWaiting = createSelector(
    statsSelector,
    stats => stats.contracts_new_waiting || 0
)
export const getReceiptsRejet = createSelector(
    statsSelector,
    stats => stats.receipts_rejet || 0
)
export const getReceiptsWaiting = createSelector(
    statsSelector,
    stats => stats.receipts_waiting || 0
)

export const getTotalStats = createSelector(
    getAccidentWaiting,
    getAccidentsOpened,
    getContractsDocumentWaiting,
    getContractsNewWaiting,
    getReceiptsRejet,
    getReceiptsWaiting,
    (accident_waiting,
     accidents_opened,
     contracts_document_waiting,
     contracts_new_waiting,
     receipts_rejet,
     receipts_waiting) => accident_waiting
        + accidents_opened
        + contracts_document_waiting
        + contracts_new_waiting
        + receipts_rejet
        + receipts_waiting
)

export const getContractsWaitingStats = createSelector(
    getAccidentWaiting,
    getAccidentsOpened,
    getContractsDocumentWaiting,
    getContractsNewWaiting,
    (accident_waiting,
    accidents_opened,
    contracts_document_waiting,
    contracts_new_waiting) => accident_waiting + accidents_opened + contracts_document_waiting + contracts_new_waiting
)
