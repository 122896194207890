import React from 'react';
import PropTypes from 'prop-types'
import {getPrimeByFractionnement} from "../../../../../../redux/selectors/quotes/quotes.selectors";
import moment from "moment";

const SouscriptionsTable = (props) => {
    const { quotes, meta, fetchQuote, filters } = props

    return (
        <div>
            <table className="amo-table table table-hover table-bordered table-striped table-responsive mt-5">
                <thead>
                <tr>
                    <th scope="col" className={'align-text-top text-white'}>Numéro devis</th>
                    <th scope="col" className={'align-text-top text-white'}>Prénom, Nom</th>
                    <th scope="col" className={'align-text-top text-white'}>Marque, Modèle, Immatriculation</th>
                    <th scope="col" className={'align-text-top text-white'}>Garantie</th>
                    <th scope="col" className={'align-text-top text-white'}>Date de création</th>
                    <th scope="col" className={'align-text-top text-white'}>Télécharger</th>
                </tr>
                </thead>
                <tbody>
                {
                    quotes.map(q => (
                        <tr key={q.id}>
                            <td className={'p-4'}>{q.id}</td>
                            <td className={'p-4'}>{q.conducteur.prenom} {q.conducteur.nom}</td>
                            <td>{q.vehicule.marque} {q.vehicule.modele}<br/>
                                {q.vehicule.immatriculation ? q.vehicule.immatriculation : '-'}
                            </td>
                            <td>{q.formuleChoisieLibelle}<br/>
                                {getPrimeByFractionnement({prime: q.primeFC, code: q.fractionnement, per: true})}
                            </td>
                            <td className={'p-4'}>{moment(q.created_at).format('L')}</td>
                            <td className={'h4 p-4'}>
                                {
                                    q.url_pdf_contrat
                                        ? <a href={q.url_pdf_contrat}>
                                            <i className={'far fa-file-pdf text-color-secondary hover-dark-color'} />
                                        </a>
                                        : '-'
                                }
                            </td>
                        </tr>
                    ))
                }
                </tbody>
            </table>

            <nav>
                <ul className="pagination">
                    {meta.current_page > 1 && <li className="page-item" onClick={() => fetchQuote({filters: {...filters, page: meta.current_page-1}})}><span className="page-link"><i className="fas fa-angle-left" /></span></li>}
                    <li className="page-item"><span className={`page-link current`} >{meta.current_page}</span></li>
                    {quotes.length >= 10 && <li className="page-item" onClick={() => fetchQuote({filters: {...filters, page: meta.current_page+1}})}><span className="page-link"><i className="fas fa-angle-right" /></span></li>}
                </ul>
            </nav>
        </div>
    );
};

SouscriptionsTable.propTypes = {
    quotes: PropTypes.array,
    meta: PropTypes.object,
    filters: PropTypes.object,
    fetchQuote: PropTypes.func
};

export default SouscriptionsTable;
