export const ACCIDENTS_CONTRACT = '[Contract Accidents]'

export const FETCH_ACCIDENTS_CONTRACT = `${ACCIDENTS_CONTRACT} Fetch`
export const SET_ACCIDENTS_CONTRACT = `${ACCIDENTS_CONTRACT} Set`

export const fetchAccidentsContract = ({query}) => ({
    type: FETCH_ACCIDENTS_CONTRACT,
    payload: {
        data: query
    }
})

export const setAccidentsContract = ({data}) => ({
    type: SET_ACCIDENTS_CONTRACT,
    payload:{
        data
    }
})
