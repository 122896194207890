import React, {useState} from 'react';
import './cadre_collapse_services.scss'
import newImg from "../../../../../../assets/images/publicites/new.svg";

const CadreCollapseServices = (props) => {
    const { icon, title, secondTitle, subtitle, children, className, newBloc = false } = props
    const [collapse, setCollapse] = useState(true)

    return (
        <div className={`cursor-pointer amo-cadre-container mt-5 ${className}`}>
            <div className={`amo-cadre border border-dark p-3 pb-5 position-relative h-100`}>
                {newBloc && <img src={newImg} style={{zIndex: 10}} alt="Nouveauté" width={45} className={'position-absolute new'}/> }

                <div className={`container-title-cadre px-2 bg-white`}>
                    <i className={`${icon} mr-2 p-2 text-white rounded-circle text-center background-ternary position-relative d-inline-block`} />
                    <div className={'title-cadre'}>
                        <h4 className={` font-weight-bold m-0`}>
                            {title}{secondTitle && <> - <span className={'text-color-secondary'}>{secondTitle}</span></>}
                        </h4>
                        {subtitle && <h5 className={'font-italic p-0 m-0 text-color-ternary-light font-size-18'}>{subtitle}</h5>}
                    </div>
                </div>

                <div className={`px-2 content ${collapse ? 'collapsed' : ''}`}>
                    {children}
                </div>
                <div className={'text-center'}>
                    <span onClick={() => setCollapse(!collapse)} className={`btn btn-link btn-sm w-auto bg-white p-2 position-absolute`}><i className={`icon-custom-down ${!collapse ? 'collapsed' : ''} icon-custom-down d-inline-block`}/></span>
                </div>
            </div>
        </div>
    );
};

export default CadreCollapseServices;
