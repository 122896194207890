export const SIGNATURES = '[Signatures]'
export const SEND_SIGNATURE = '[Send Signatures]'

export const FETCH_SIGNATURES = `${SIGNATURES} Fetch`
export const SET_SIGNATURES = `${SIGNATURES} Set`

export const POST_SEND_SIGNATURE = `${SEND_SIGNATURE} Post`


export const fetchSignatures = ({query}) => ({
    type: FETCH_SIGNATURES,
    payload: {
        data: query
    }
})

export const setSignatures = ({data}) => ({
    type: SET_SIGNATURES,
    payload: { data },
    meta:{
        entity: SIGNATURES
    }
})

export const postSendSignatures = ({query, form}) => ({
    type: POST_SEND_SIGNATURE,
    payload: {
        data: query,
        body: form
    }
})
