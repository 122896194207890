import React from 'react';
import './radioFieldRedux.scss'

const RadioFieldRedux = (props) => {
    const { input, meta: {touched, error}, label, data } = props

    return (
       <div className="form-group">
           <label>{label}</label>
           {
               data.map(d => (
                   <label className={`amo-radio position-relative cursor-pointer d-block pl-4 ${touched && error ? 'is-invalid' :''}`} key={d.value}> {d.label}
                       <input type="radio" name={input.name} value={d.value} onChange={input.onChange} className={'position-absolute cursor-pointer d-none'} checked={input.value === d.value}/>
                       <span className="checkmark position-absolute rounded-circle"/>
                   </label>
               ))
           }
           {touched && error && <small className={'invalid-feedback'}>{error}</small>}
       </div>
    );
};

export default RadioFieldRedux;
