import React from 'react'

/*
Usage :

<Field
  name={String}
  component={TextAreaFieldRedux}
  label={String}
  InputProps={{
    placeholder: {String}
  }}
/>
 */

const TextAreaFieldRedux = ({
    input,
    meta: {touched, error},
    classes,
    label,
    subLabel,
    InputLabelProps,
    ...custom
}) => {
    return (
        <div className={'form-group'}>
            <label {...InputLabelProps}>
                {label}
            </label>
            <small className={'float-right'}>{subLabel}</small>
            <textarea
                className={'form-control '+ (touched ? Boolean(error) ? 'is-invalid' : 'is-valid' : '')}
                {...input}
                {...custom}
            />
            {touched && error && <small className="invalid-feedback">{error}</small>}
        </div>
    )
}

export default (TextAreaFieldRedux)
