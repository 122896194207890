import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import moment from 'moment'
import {change, formValueSelector, getFormValues, reduxForm} from "redux-form";
import _ from 'lodash'
import InitForm from "./InitForm";
import validate from './validate'
import {
    getAccidentClaimLinkContract,
    getAdhesionPrincipaleContract,
    getCodeProduitVehicule,
    getContractGarantiesDA,
    getContractPrelevement,
    getIdContract,
    getIsFleetContract,
    getNomSouscripteurContract
} from "../../../../../../../../../redux/selectors/contracts/contracts.selectors";
import {
    fetchDocumentsAccident,
    POST_ACCIDENTS,
    postAccidents,
    postRejectAccident
} from "../../../../../../../../../redux/actions/app/accidents/accidents.actions";
import {
    getAccidentResponse,
    getEaseUi,
    getErrorEntity,
    getLoadingEntity
} from "../../../../../../../../../redux/selectors/ui/ui.selectors";
import {
    getContractsAccidentRejectPostLink, getDocumentsNeededAccidentGetLink,
    getFleetGetLink
} from "../../../../../../../../../redux/selectors/links/links.selectors";

const mapStateToProps = (state, ownProps) => {
    const { idVehicule } = ownProps
    const selector = formValueSelector('declaration-sinistre')

    return {
        accidentResonse: getAccidentResponse(state),
        formValues: getFormValues("declaration-sinistre")(state),
        codeProduit: getCodeProduitVehicule(state),
        nom: getNomSouscripteurContract(state),
        idContract: getIdContract(state),
        uriRejectAccident: getContractsAccidentRejectPostLink(state),
        query: getAccidentClaimLinkContract(state),
        queryGetFleet: getFleetGetLink(state),
        queryDocs: getDocumentsNeededAccidentGetLink(state),
        categorie: selector(state, 'catsinorigin'),
        type_sinistre: selector(state, 'type_sinistre'),
        vehicule: selector(state, 'vehicule'),
        identify: selector(state, 'indentify'),
        blesse: selector(state, 'blesse'),
        temoin: selector(state, 'temoin'),
        constatCheck: selector(state, 'constat_accident'),
        constatTiersCheck: selector(state, 'constat_sign_tiers'),
        constatAssureCheck: selector(state, 'constat_sign_assure'),
        documents: getEaseUi(state).documentsAccident,
        isFleet: getIsFleetContract(state),
        hasDta: _.includes(getContractGarantiesDA(state)[0], 'DA'),
        initialValues: {
            'conduct': getIsFleetContract(state) ? "" : getNomSouscripteurContract(state),
            'datesurvorigin': moment(),
            'corpoconduct': 'F',
            'temoin': 'F',
            'vehiculeDommage': 'F',
            'equipementsDommage': 'F',
            'risque': idVehicule || getAdhesionPrincipaleContract(state).toString(),
            'blesse': 'F',
            'type_contrat': idVehicule ? 'cooltra' : null,
        },
        isPrelevement: getContractPrelevement(state),
        loading: getIsFleetContract(state) ? getLoadingEntity(state, `${POST_ACCIDENTS}-${idVehicule}`) : getLoadingEntity(state, POST_ACCIDENTS),
        success: getIsFleetContract(state) ? getLoadingEntity(state, `${POST_ACCIDENTS}-${idVehicule}`) === false && getErrorEntity(state, `${POST_ACCIDENTS}-${idVehicule}`) === false : getLoadingEntity(state, POST_ACCIDENTS) === false && getErrorEntity(state, POST_ACCIDENTS) === false
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    changeValue: (field, value) => change('declaration-sinistre', field, value),
    postAccidents: ({form, query, documents, idVehicule}) => postAccidents({form, query, documents, idVehicule}),
    fetchDocumentsAccident: ({query}) => fetchDocumentsAccident({query}),
    postRejectAccident: ({categorie, query}) => postRejectAccident({categorie, query})
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { query, documents, idContract, uriRejectAccident, codeProduit, queryDocs, hasDta, isPrelevement } = stateProps
    const { postAccidents, postRejectAccident, fetchDocumentsAccident } = dispatchProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        onSubmit: (form) => postAccidents({form, query, documents, idVehicule: ownProps.idVehicule}),
        fetchDocumentsAccident: ({categorie, souscategorie, equipements = false, doc_police}) => fetchDocumentsAccident({
            query: _.replace(_.replace(_.replace(_.replace(_.replace(_.replace(_.replace(queryDocs,
                                        '{categorie}', categorie),
                                    '{souscategorie}', souscategorie),
                                '{codeProduit}', codeProduit),
                            '/{docPolice?}', '/'+doc_police !== undefined ? '/'+ doc_police : '/'+null),
                        '/{DTA?}', '/'+hasDta),
                    '/{DommageACCESSEQP?}', '/'+equipements !== undefined ? '/'+equipements : '/'+false),
                '/{prelevement?}', '/' + isPrelevement)
        }),
        postRejectAccident: (categorie) => postRejectAccident({categorie, query: _.replace(uriRejectAccident, '{id}', idContract)})
    }
}

const InitFormContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    reduxForm({
        form: 'declaration-sinistre',
        validate
    })
)(InitForm)

export default InitFormContainer
