import {connect} from 'react-redux'
import DeclarationTer from "./DeclarationTer";
import {formValueSelector} from "redux-form";
import {getIsWakamContract} from "@/redux/selectors/contracts/contracts.selectors";

const mapStateToProps = state => {
    const selector = formValueSelector('declaration-sinistre')

    return {
        categorie: selector(state, 'catsinorigin'),
        isWakam: getIsWakamContract(state),
    }
}

const DeclarationTerContainer = connect(mapStateToProps)(DeclarationTer)

export default DeclarationTerContainer
