import {createSelector} from 'reselect'
import _ from 'lodash'

const publicitesSelector = state => state.publicites

export const getPublicites = createSelector(
    publicitesSelector,
    publicites => publicites || []
)

export const getPlvPublicites = createSelector(
    publicitesSelector,
    publicites => _.filter(publicites, p => {
        return p.category.id === 2
    })
)

export const getDispositionGeneralesPublicites = createSelector(
    publicitesSelector,
    publicites => _.filter(publicites, p => {
        return p.category.id === 1
    })
)
