import React from 'react';
import './RadioBlocDesignButton.scss'
import SmallSpinner from "../../../Spinners/SmallSpinner";

const RadioBlocDesignButton = (props) => {
    const { input, meta: {touched, error}, label, data, type = 'radio', className, loading } = props

    return (
        <div className={`form-group ${className}`}>
            <label className={'d-block mb-4 font-size-22'}>{label}</label>
            { loading ? <SmallSpinner /> : <>
                {
                    data.map(d => (
                        <label className={'d-inline-block mx-4'}>
                        <span className={`btn-outline-secondary d-inline-block bloc-checkbox ${input.value === d.value ? 'active' : ''}`} key={d.value}>
                            <input type={type} name={input.name} value={d.value} onChange={input.onChange} checked={input.value === d.value} hidden/>
                        </span>
                            <span className={'d-block font-size-22'}>{d.label}</span>
                        </label>
                    ))
                }
            </> }

            {touched && error && <small className={'invalid-feedback'}>{error}</small>}
        </div>
    );
};

export default RadioBlocDesignButton;
