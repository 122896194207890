import React from 'react';
import Card from "../../../../../../../Commun/Card/Card";
import VehiculeDetailContainer from "./VehiculeDetail/VehiculeDetailContainer";

const ContractFleet = (props) => {
    const { contract: { souscripteur, numeroPolice }, match: { params: { ids, id } } } = props

    return (
        <div className={'p-4'}>
            <Card>
                <VehiculeDetailContainer ids={ids} id={id} souscripteur={souscripteur} numeroPolice={numeroPolice} />
            </Card>
        </div>
    );
};

export default ContractFleet;
