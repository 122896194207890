import React from 'react';
import PropTypes from 'prop-types'
import _ from 'lodash'
import {Bar, Line} from 'react-chartjs-2'
import {
    CategoryScale,
    Chart,
    LinearScale,
    BarElement,
    Title,
    Legend,
    Tooltip,
    PointElement,
    LineElement
} from "chart.js";
import moment from 'moment'
import Cadre from "../../../../../Commun/Cadre/Cadre";
import Indicateur from "./Indicateur/Indicateur";
import DashboardTable from "./DashboardTable/DashboardTable";
import {formatEuro} from "../../../../../../utils/function";

const DashboardData = (props) => {
    const { data: { AFFNOU, ENCOUR, SSURP, RESIL, EVOL, P_CLI, COMMI, SINOUV, REGSIN, P_CIE }, primary, secondary, stats } = props
    Chart.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
        Legend,
        PointElement,
        LineElement
    );

    const oneYearAgo = moment().subtract(1, 'year').subtract(72, 'days').format('L');
    const endDate = moment().subtract(72, 'days').format('L');

    return (
        <div>
            <div className="row">
                <div className="col-lg-4 col-md-12 mt-5">
                    <Indicateur data={AFFNOU} title={'Affaires nouvelles'} />
                </div>
                <div className="col-lg-4 col-md-12 mt-5">
                    <Indicateur data={ENCOUR} title={'Affaires en cours'} />
                </div>
                <div className="col-lg-4 col-md-12 mt-5">
                    <Cadre title={'Taux de chute (année glissante)'} color={'secondary'}>
                        <span className={'h1'}>{((stats.chute_afn / stats.total_afn) * 100).toFixed(2)}%</span><br/>
                        <small>Du {oneYearAgo} au {endDate}</small>
                    </Cadre>
                </div>
            </div>

            <div className="row mt-5">
                <div className="col">
                    <Cadre icon={'fas fa-folder-open'} title={'Portefeuille'} large>
                        <div className="row">
                            <div className="col-lg-4 d-none d-lg-block">
                                <Bar data={
                                    {
                                        labels: _.keys(AFFNOU),
                                        datasets: [
                                            {
                                                backgroundColor: primary,
                                                data: _.values(AFFNOU),
                                                label: 'Affaires nouvelles'
                                            },
                                            {
                                                backgroundColor: secondary,
                                                data: [0, 0, 0, _.round((AFFNOU[moment().year()] *365 / moment().dayOfYear()), 2)],
                                                label: 'Projection fin d\'année'
                                            }
                                        ]
                                    }
                                }/>
                            </div>
                            <div className="col-lg-8 col-md-12">
                                <DashboardTable
                                    columns={_.keys(AFFNOU)}
                                    data={[
                                        {
                                            title: 'Affaires nouvelles',
                                            nb: _.values(AFFNOU)
                                        },
                                        {
                                            title: 'Résiliations',
                                            nb: _.values(RESIL)
                                        },
                                        {
                                            title: 'Affaires en cours',
                                            nb: _.values(ENCOUR)
                                        },
                                        {
                                            title: 'Évolution portefeuille',
                                            nb: _.values(EVOL)
                                        }
                                    ]}
                                />
                            </div>
                        </div>
                    </Cadre>
                </div>
            </div>

            <div className="row mt-5">
                <div className="col">
                    <Cadre icon={'fas fa-euro-sign'} title={'Performances économiques'} large>
                        <div className="row mt-5 mt-lg-0">
                            <div className="col-lg-4 d-none d-lg-block">
                                <Bar
                                    data={{
                                        labels: _.keys(COMMI),
                                        datasets: [
                                            {
                                                backgroundColor: primary,
                                                data: _.values(COMMI),
                                                label: 'Commissions'
                                            },
                                            {
                                                backgroundColor: secondary,
                                                data: [0, 0, 0, _.round((COMMI[moment().year()] *365 / moment().dayOfYear()), 2)],
                                                label: 'Projection fin d\'année'
                                            }
                                        ]
                                    }}
                                    options={{
                                        tooltips: {
                                            callbacks: {
                                                label: function (tooltipItem, data) {
                                                    return `${data.datasets[tooltipItem.datasetIndex].label}: ${formatEuro(tooltipItem.yLabel)}`
                                                }
                                            }
                                        }
                                    }}
                                />
                            </div>
                            <div className="col-lg-8 col-md-12">
                                <DashboardTable
                                    columns={_.keys(P_CLI)}
                                    data={[
                                        {
                                            title: 'Prime clients',
                                            nb: _.values(P_CLI),
                                            format: formatEuro
                                        },
                                        {
                                            title: 'Commissions',
                                            nb: _.values(COMMI),
                                            format: formatEuro
                                        }
                                    ]}
                                />
                            </div>
                        </div>
                    </Cadre>
                </div>
            </div>

            <div className="row mt-5">
                <div className="col">
                    <Cadre icon={'fas fa-exclamation-triangle'} title={'Performances techniques'} large>
                        <div className="row mt-5 mt-lg-0">
                            <div className="col-lg-4 d-none d-lg-block">
                                <Line
                                    data={{
                                        labels: _.keys(SSURP),
                                        datasets: [
                                            {
                                                backgroundColor: "transparent",
                                                pointBackgroundColor: secondary,
                                                data: _.values(SSURP),
                                                label: 'S/P'
                                            }
                                        ]
                                    }}
                                    options={{
                                        scales: {
                                            yAxes: [{
                                                ticks: {
                                                    beginAtZero: true,
                                                    max: 1
                                                }
                                            }]
                                        }
                                    }}
                                />
                            </div>
                            <div className="col-lg-8 col-md-12">
                                <DashboardTable
                                    columns={_.keys(SINOUV)}
                                    data={[
                                        {
                                            title: 'Sinistres ouverts',
                                            nb: _.values(SINOUV)
                                        },
                                        {
                                            title: 'Règlements sinistres',
                                            nb: _.values(REGSIN),
                                            format: formatEuro
                                        },
                                        {
                                            title: 'Prime compagnie',
                                            nb: _.values(P_CIE),
                                            format: formatEuro
                                        },
                                        {
                                            title: 'S/P',
                                            nb: _.values(SSURP)
                                        }
                                    ]}
                                />
                            </div>
                        </div>
                    </Cadre>
                </div>
            </div>
        </div>
    );
};

DashboardData.defaultProps = {
    data: {},
    primary: '#c9d200',
    secondary: '#f49e00',
};

DashboardData.propTypes = {
    data: PropTypes.object,
    primary: PropTypes.string,
    secondary: PropTypes.string,
};

export default DashboardData;
