import React from 'react';
import './footer.scss'
import moment from 'moment';

const Footer = (props) => {
    const { website, name } = props

    return (
        <div id={"footer"} className={'position-fixed bg-white text-right'}>
            {website && <span>© <a href={website} target='_blank' rel="noopener noreferrer">{name}</a> {moment().year()}</span>}
        </div>
    );
};

export default Footer;
