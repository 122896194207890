import React, {useState} from 'react';
import "./poppin-charte.scss";
import charteConcessionnaire from "../../../../assets/pdf/chartes/Charte_indicateurs_d_affaires_2023.pdf";

const PoppinCharte = (props) => {
    const {isClosed, postPoppinCharte, isConcessionnaireGP} = props
    const [ btnValid, setBtnValid ] = useState(false)

    if(!isClosed) {
        document.body.classList.add('modal-open')
    } else{
        document.body.classList.remove('modal-open')
    }

    return (
        <div className={`modal fade ${!isClosed && 'show d-block'}`} tabIndex="-1" role="dialog" >
            <div className="modal-dialog modal-charte my-0" role="document">
                <div className="modal-content">
                    <div className="modal-header border-0">
                        <h4 className={'text-color-secondary mb-0'}>Validation de notre charte des indicateurs d’affaires</h4>
                    </div>

                    <div className="modal-body d-flex flex-column flex-nowrap align-items-stretch" data-test={'children'}>

                        <div className={'mb-3 font-weight-bold'}>Cher Partenaire, conformément à la législation en vigueur, nous vous informons sur votre statut d'indicateur d'affaires. Nous vous invitons à déclarer avoir pris connaissance de la Charte ci-dessous pour accéder à votre Espace.</div>


                        <div className={'pdfscrollable flex-fill'}>
                            <object data={charteConcessionnaire} aria-label={'Charte APRIL Moto'} />
                        </div>

                        <div>
                            <div className="my-4 styled-checkbox">
                                <input type="checkbox" id="charteValide" onChange={ (e) => { setBtnValid(e.target.checked) } }/>
                                <label htmlFor="charteValide">Je déclare avoir lu et pris connaissance de la charte des indicateurs d'affaires.</label>
                            </div>

                            <button className={'btn btn-block max-width-3 btn-outline-secondary max-rounded d-block'} disabled={ !btnValid } onClick={ () => postPoppinCharte() }>Accepter</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PoppinCharte;
