import React from 'react';
import PropTypes from 'prop-types'
import _ from "lodash";
import moment from 'moment'
import Cadre from "../../../../../../Commun/Cadre/Cadre";

const Indicateur = (props) => {
    const { data, title, currentYear, previousYear } = props
    const evolve = _.round(((data[currentYear] - data[previousYear])/data[previousYear])*100, 2) || 0

    return (
        <Cadre icon={`fas ${evolve > 0 ? 'fa-arrow-up' : evolve < 0 ? 'fa-arrow-down' : 'fa-equals'}`} title={title} color={evolve > 0 ? 'primary' : evolve < 0 ? 'secondary' : false}>
            <p className={'max-width-4'}>{currentYear} : <b className={'float-right'}>{data[currentYear]}</b></p>
            <p className={'max-width-4'}>{previousYear} : <b className={'float-right'}>{data[previousYear]}</b></p>
            {/*stupid request*/}{title !== 'Affaires nouvelles' && <p className={'max-width-4'}>Évolution : <b className={`${evolve > 0 ? 'text-color-primary' : evolve < 0 ? 'text-color-secondary' : false} float-right`}>{evolve} %</b></p>}
        </Cadre>
    );
};

Indicateur.defaultProps = {
    data: {},
    title: 'Titre',
    currentYear: moment().year(),
    previousYear: moment().subtract(1, 'year').year()
};

Indicateur.propTypes = {
    data: PropTypes.object,
    title: PropTypes.string
};

export default Indicateur;
