import React, {Fragment, useState} from 'react';
import moment from 'moment'
import {getReason} from "../../../../../../../utils/translate";
import {Link} from "react-router-dom";

const QuittancesContract = (props) => {
    const { quittances, idContrat } = props
    const [quittancesDisplay, setQuittancesDisplay] = useState(quittances[0] ? [quittances[0]] : [])

    return (
        <div>
            <div className="table-responsive pt-1">
                <table className="table table-hover">
                    <thead>
                    <tr>
                        <th scope="col">Type</th>
                        <th scope="col">Période</th>
                        <th scope="col">Infos</th>
                        <th scope="col">Montant</th>
                        <th scope="col">Solde restant</th>
                        <th scope="col">Commission</th>
                        <th scope="col">Paiement</th>
                        <th scope="col">Tableau de prélèvement</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        quittancesDisplay.map(q => (
                            <tr key={q.id}>
                                <td>{q.has_prelevement_rejete ? 'Rejet' : q.type}</td>
                                <td>{`du ${moment(q.dateDebut).format('L')} au ${moment(q.dateFin).format('L')}`}</td>
                                <td>{q.infos ? q.infos : '-'}</td>
                                <td>{q.montant.toFixed(2)} €</td>
                                <td className={q.solde < 0 ? 'amo-tooltip' : ''}>
                                    {q.solde.toFixed(2)} € {q.solde < 0 && <Fragment>
                                    <i className={'fas fa-question-circle text-color-secondary'} />
                                    <small className={'tooltiptext mega-text-tooltip'}>
                                        Cette somme ne représente pas le montant d'un éventuel remboursement,
                                        mais uniquement celui de la partie prime non échue. Elle est déduite sur les prochaines quittances.
                                    </small>
                                </Fragment>}
                                </td>
                                <td>{q.commission} €</td>
                                <td className={`${!q.pay_link && 'amo-tooltip cursor-not-allowed'} text-center`}>
                                    <a target={'_blank'} rel="noopener noreferrer" href={q.pay_link ? `${q.pay_link}?url_return=${window.location.href}` : null}
                                       className={'h2'}
                                    >
                                        <i className={'fas fa-credit-card'} />
                                    </a>
                                    {!q.pay_link && <small className={'tooltiptext'}>{getReason(q.pay_link_reason)}</small>}
                                </td>
                                <td className={`text-center ${!q.has_tableau_prelevement && 'amo-tooltip'}`}>
                                    <Link to={q.has_tableau_prelevement ? `/mon-contrat/${idContrat}/ma-quittance/${q.id_belair}` : window.location.pathname} className={`${q.has_tableau_prelevement ? 'text-color-secondary' : 'text-dark cursor-not-allowed'}`}>
                                        <span className={'h2'}><i className={'fas fa-table'}/></span>
                                        {!q.has_tableau_prelevement && <small className={'tooltiptext'}>Le tableau de prélévement n'est pas disponible.</small>}
                                    </Link>
                                </td>
                            </tr>
                        ))
                    }
                    </tbody>
                </table>
            </div>
            {
                quittancesDisplay.length <= 1 && quittances.length > 1 && <span className={'btn btn-secondary max-rounded'} onClick={() => setQuittancesDisplay(quittances)}>
                    Voir toutes les quittances
                </span>
            }
        </div>
    );
};

export default QuittancesContract;

