import React from 'react';
import Logo from "../../../assets/images/april_moto_dark.svg";
import {Field} from "redux-form";
import TextFieldRedux from "../../Commun/Inputs/Text/TextFieldRedux";
import SmallSpinner from "../../Commun/Spinners/SmallSpinner";
import {Redirect} from "react-router-dom";

const ChangeMdp = (props) => {
    const { isLogin, loading, handleSubmit, erreur } = props

    if (isLogin){
        return <Redirect to={'/'} />
    }

    return (
        <div className={'text-center full-screen background-color-login '}>
            <img src={Logo} alt="Logo" className={'mt-5'} width={300}/>
            <h1 className={'mb-5 text-secondary'}>Activation de votre accès à l'espace partenaire APRIL&nbsp;Moto</h1>

            <div className={'p-5 border-color-secondary border max-width-7 m-auto medium-rounded'}>
                <p>Merci de choisir votre mot de passe et de bien vouloir le confirmer.<br/>(au moins 8 caractères, 1 chiffre, 1 caractère spécial et 1 majuscule)</p>
                <form onSubmit={handleSubmit} className={'text-left'} autoComplete={'off'}>
                    <Field
                        name={'password'}
                        component={TextFieldRedux}
                        label={'Saisir votre nouveau mot de passe'}
                    />
                    <Field
                        name={'password_confirmation'}
                        component={TextFieldRedux}
                        type={'password'}
                        label={'Confirmer votre mot de passe'}
                    />
                    { loading ? <SmallSpinner /> : <button className={'btn btn-secondary w-100 max-rounded'}>
                        Activer mon mot de passe et se connecter automatiquement
                    </button> }

                    {erreur && <p>Une erreur est survenue</p>}
                </form>
            </div>

        </div>
    );
};

export default ChangeMdp;
