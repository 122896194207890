import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import Impayes from "./Impayes";
import {getAllReceiptsLink} from "../../../../../../redux/selectors/links/links.selectors";
import {fetchImpayes, IMPAYES} from "../../../../../../redux/actions/app/impayes/impayes.actions";
import {getLinksImpayes, getMetaImpayes} from "../../../../../../redux/selectors/impayes/impayes.selectors";
import {getFiltersEntity} from "../../../../../../redux/selectors/ui/ui.selectors";
import {CONTRACT} from "../../../../../../redux/actions/app/contracts/contracts.actions";

const mapStateToProps = state => {
    return {
        links: getLinksImpayes(state),
        meta: getMetaImpayes(state),
        query: getAllReceiptsLink(state),
        filters: getFiltersEntity(state, IMPAYES),
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    fetchImpayes: ({query, filters}) => fetchImpayes({query, filters})
}, dispatch)

const ImpayesContainer = connect(mapStateToProps, mapDispatchToProps)(Impayes)

export default ImpayesContainer
