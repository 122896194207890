import React from 'react';
import './cadre.scss'

const Cadre = (props) => {
    const { icon, title, children, large, color, edit, boolEdit } = props

    return (
        <div className={`amo-cadre border border-${color ? color : 'dark'} p-3 position-relative h-100`}>
            <h4 className={`${color && `text-color-${color}`} position-absolute bg-white title-cadre p-2 w-100 font-weight-bold max-width-${large ? '7' : '4'}`}>
                { icon && <i className={`${icon} mr-2 p-2 text-white rounded-circle text-center background-${color ? `color-${color}` : 'ternary'}`} /> }
                {title}
                { edit && <i className={'fas fa-edit position-absolute text-color-secondary cursor-pointer h4 hover-dark-color'} onClick={() => edit(!boolEdit)}/> }
            </h4>
            <div className={'mt-3 px-1'}>
                {children}
            </div>
        </div>
    );
};

export default Cadre;
