import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import _ from "lodash";
import {getLoadingEntity} from "../../../../../../../redux/selectors/ui/ui.selectors";
import {
    getContractLink,
    postChangeSituationContractLink
} from "../../../../../../../redux/selectors/links/links.selectors";
import {
    CONTRACT, CONTRACT_SITUATION,
    fetchContract,
    postChangeSituationContract
} from "../../../../../../../redux/actions/app/contracts/contracts.actions";
import {reduxForm, change} from "redux-form";
import validate from "./validate";
import ResilContrat from "./ResilContrat";
import loader from "../../../../../../Commun/Spinners/Loader/Loader";
import {getContract} from "../../../../../../../redux/selectors/contracts/contracts.selectors";


const mapStateToProps = (state, ownProps) => {
    return {
        uri: postChangeSituationContractLink(state),
        contract: getContract(state),
        uriContrat: getContractLink(state),
        loaded: getLoadingEntity(state, CONTRACT) === false,
        loading: getLoadingEntity(state, CONTRACT_SITUATION),
        id: ownProps.match.params.id,
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    postResilContract: ({form, type, query}) => postChangeSituationContract({form, type, query}),
    fetchContract: ({query}) => fetchContract({query}),
    changeValue: (field, value) => change('resil_contrat', field, value)
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { postResilContract, fetchContract } = dispatchProps
    const { uri, uriContrat, id } = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => fetchContract({query: _.replace(uriContrat, '{id}', id)}),
        onSubmit: (form) => postResilContract({
            form,
            type: 'terminate',
            query: _.replace(uri, '{id}', id)
        })
    }
}

const ResilContratContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    reduxForm({
        form: 'resil_contrat',
        validate
    }),
    loader()
)(ResilContrat)

export default ResilContratContainer
