import React, {useState} from 'react';
import Card from "../../../../../../../Commun/Card/Card";
import SmallSpinner from "../../../../../../../Commun/Spinners/SmallSpinner";
import {Field} from "redux-form";
import TextFieldRedux from "../../../../../../../Commun/Inputs/Text/TextFieldRedux";
import _ from "lodash";
import {getCity, getReparateurByCodePostal, JoursFeries} from "../../../../../../../../utils/function";
import moment from "moment";
import SelectFieldRedux from "../../../../../../../Commun/Inputs/Select/SelectFieldRedux";

const AjoutReparateurForm = (props) => {
    const {nom, history, handleSubmit, loading, immatriculation, marqueModele, changeValue, garanties, categorieCode, reparateur, success, isWakam} = props

    const [reparateurs, setReparateurs] = useState([])
    const [addressReparateur, setAddressReparateurs] = useState(null)
    const [missions, setMissions] = useState([])
    const [city, setCity] = useState([])

    /* Recherche du reparateur par code postal */
    const setPostalCode = (postalCode) => {
        fetchCityByPostalCode(postalCode)
        fetchReparateurs(postalCode)
    }

    /* ajout l'id du reparateur dans le form */
    const changeValueReparateurVisites = (reparateur, missionsReparateur) => {
        changeValue('reparateur', reparateur)
        if (!(!_.find(garanties, ['code_famille', 'DTA']) && _.includes(['104.2', '104.3', '104.5', '104.1'], categorieCode))){
            if(missionsReparateur[0]){
                changeValue('visit', moment(missionsReparateur[0]).format('L'))
            } else {
                changeValue('visit', '')
            }
        }
    }

    /* Retire les jours feries d'un tableau de date */
    const removeJoursFeries = (array) => {
        const joursFeries = JoursFeries(moment().weekYear())

        _.remove(array, n => {
            return _.includes(joursFeries, n)
        })
    }

    /* Function async qui va chercher les villes via le code postal */
    async function fetchCityByPostalCode(value){
        let cities = await getCity(value)
        setCity(cities)
    }

    /* Function async qui va chercher les reparateur via le code postal */
    async function fetchReparateurs(postalCode){
        let arrayReparateurs = await getReparateurByCodePostal(postalCode)
        let reparateurs = _.orderBy(arrayReparateurs, ['raisonSociale'], ['asc'])
        reparateurs.push({id: 'NONE', raisonSociale: 'Autre garage', datesVisite: []})

        removeJoursFeries(reparateurs[0].datesVisite)
        setReparateurs(reparateurs)
        setMissions(reparateurs[0].datesVisite)
        setAddressReparateurs(reparateurs[0].rue1)

        changeValueReparateurVisites(reparateurs[0].id, reparateurs[0].datesVisite)
    }

    /* Ajout l'addresse du réparateur et les missions disponible apres la selection du reparateur */
    const getAdresseReparateur = (id) => {
        const item = _.find(reparateurs, ['id', id])

        removeJoursFeries(item.datesVisite)

        setAddressReparateurs(item.rue1)
        setMissions(item.datesVisite)

        changeValueReparateurVisites(item.id, item.datesVisite)
    }

    return (
        <div className={'p-4'}>
            <Card>
                <div>
                    <h3 className={'m-0 pb-3 border-bottom border-dark'}>
                        <span className={'text-color-secondary'}>Ajouter un réparateur</span> - {nom}
                    </h3>

                    <div className="mt-5">
                        <span className={'btn btn-outline-secondary max-rounded'} onClick={() => history.goBack()}><i className={'fas fa-chevron-left mr-3'}/> Retour</span>
                    </div>

                    {
                        success
                        ? <div className={'mt-5'}>
                            <p>Votre demande d'ajout de réparateur a été traitée avec succès.</p>
                        </div>
                        : <div className="mt-5 col-lg-8 col-md-12 m-auto">
                            <form onSubmit={handleSubmit}>
                                <div className={'form-content'}>
                                    <div className="alert alert-warning" role="alert">
                                        <p className={'m-2'}>Garage où se trouve le
                                            véhicule: <b>{marqueModele}</b> {immatriculation && `- ${immatriculation}`}
                                        </p>
                                    </div>

                                    <Field
                                        name='cpreparateur'
                                        component={TextFieldRedux}
                                        label={'Code postal du garage/réparateur'}
                                        required
                                        input={{
                                            maxLength: '5',
                                            onChange: (e) => e.target.value.length === 5 && setPostalCode(e.target.value)
                                        }}
                                    />

                                    <Field
                                        name='reparateur'
                                        component={SelectFieldRedux}
                                        label={'Garage / Réparateur'}
                                        disabled={city.length === 0}
                                        required
                                        input={{
                                            onChange: (e) => getAdresseReparateur(e.target.value)
                                        }}
                                    >
                                        {
                                            reparateurs.map((reparateur, index) =>
                                                <option key={index}
                                                        value={reparateur.id}>{reparateur.raisonSociale}</option>
                                            )
                                        }
                                    </Field>

                                    {
                                        reparateur && <div className="alert alert-warning" role="alert">
                                            <p style={{margin: 20}}>Adresse : {addressReparateur}</p>
                                        </div>
                                    }

                                    {
                                        !_.find(garanties, ['code_famille', 'DTA']) && _.includes(['104.2', '104.3', '104.5', '104.1'], categorieCode)
                                            ? null
                                            : isWakam ? null : <>
                                                <Field
                                                    name='visit'
                                                    component={SelectFieldRedux}
                                                    label={'Date de passage de l\'expert'}
                                                    disabled={city.length === 0}
                                                >
                                                    {
                                                        missions.map((mission, index) =>
                                                            <option key={index}
                                                                    value={moment(mission).format('L')}>{moment(mission).format('L')}</option>
                                                        )
                                                    }
                                                </Field>
                                                <p className={'text-danger mt-2 fw-bold'}>Cette date est susceptible d'être modifiée, un mail de confirmation vous sera envoyé lorsque l'expertise sera validée.</p>
                                            </>
                                    }
                                </div>

                                {
                                    loading
                                        ? <SmallSpinner/>
                                        : <button className={'btn btn-primary d-block max-rounded'}>
                                            J'ajoute le réparateur
                                        </button>
                                }
                            </form>
                        </div>
                    }
                </div>
            </Card>
        </div>
    );
};

export default AjoutReparateurForm;
