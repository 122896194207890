import {connect} from 'react-redux'
import GarantiesContract from './GarantiesContract'
import {getAccessoireContract,getGarantiesContract} from "../../../../../../../redux/selectors/contracts/contracts.selectors";


const mapStateToProps = state => {
    return {
        garanties: getGarantiesContract(state),
        accessoire: getAccessoireContract(state)
    }
}

const GarantiesContractContainer = connect(mapStateToProps)(GarantiesContract)

export default GarantiesContractContainer
