import {API_ERROR, API_SUCCESS, apiRequest} from '../../../actions/core/api/api.actions'
import {setClosePoppinCharte, setError} from '../../../actions/app/ui/ui.actions'
import {FETCH_POPINS, POPINS, POST_POPPIN_CHARTE, setPopins} from "../../../actions/app/popins/popins.actions";

export const popinsMiddleware = () => next => action => {
    next(action)

    switch (action.type){

        case FETCH_POPINS:
            next(apiRequest({body: null, method: 'GET', url: action.payload.data, entity: POPINS}))
            break

        case `${POPINS} ${API_SUCCESS}`:
            next([
                setPopins({data: action.payload.data}),
                setError({state: false, entity: POPINS})
            ])
            break

        case `${POPINS} ${API_ERROR}`:
            next(setError({state: true, entity: POPINS}))
            break

        case POST_POPPIN_CHARTE:
            next(apiRequest({body: null, method: 'POST', url: action.payload.data, entity: POST_POPPIN_CHARTE}))
            break

        case `${POST_POPPIN_CHARTE} ${API_SUCCESS}`:
            next([
                setClosePoppinCharte({state: true}),
                setError({state: false, entity: POST_POPPIN_CHARTE})
            ])
            break

        case `${POST_POPPIN_CHARTE} ${API_ERROR}`:
            next(setError({state: true, entity: POST_POPPIN_CHARTE}))
            break

        default:
            break
    }
    return null
}
