import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import {getUserIsConcessionnaireGP} from "../../../../redux/selectors/user/user.selectors";
import loader from "../../../Commun/Spinners/Loader/Loader";
import PoppinCharte from "./PoppinCharte";
import {getClosedPoppinCharte} from "../../../../redux/selectors/ui/ui.selectors";
import {postPoppinCharte} from "../../../../redux/actions/app/popins/popins.actions";
import {getPoppinCharteLink} from "../../../../redux/selectors/links/links.selectors";


const mapStateToProps = state => {
    return {
        isConcessionnaireGP: getUserIsConcessionnaireGP(state),
        isClosed: getClosedPoppinCharte(state),
        query: getPoppinCharteLink(state)
    }
}
const mapDispatchToProps = dispatch => bindActionCreators({
    postPoppinCharte: ({query}) => postPoppinCharte({query})
}, dispatch)


const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {query} = stateProps
    const {postPoppinCharte} = dispatchProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        postPoppinCharte: () => postPoppinCharte({query})
    }
}


const PoppinCharteContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader()
)(PoppinCharte)


export default PoppinCharteContainer