import React from 'react';
import Card from "../../../../Commun/Card/Card";
import RadioFieldRedux from "../../../../Commun/Inputs/Radio/RadioFieldRedux"
import {Field} from 'redux-form'
import SelectFieldRedux from "../../../../Commun/Inputs/Select/SelectFieldRedux";
import TextAreaFieldRedux from "../../../../Commun/Inputs/Text/TextAreaFieldRedux";
import SmallSpinner from "../../../../Commun/Spinners/SmallSpinner";

const FeedBack = (props) => {
    const { handleSubmit, typeRetour, loading } = props

    return (
        <div className={'p-4'}>
            <Card>
                <h3 className={'m-0 pb-3 border-bottom border-dark'}>
                    Participez à <span className={'text-color-secondary'}>l'amélioration de votre espace !</span>
                </h3>
                <p className={'mt-4'}>Vous avez rencontré des difficultés lors de votre navigation ? Vous avez des idées pour améliorer votre espace ? <b className={'text-color-secondary'}>On vous écoute !</b></p>

                <form className={'mt-4'} onSubmit={handleSubmit}>

                    <Field
                        name={'type_retour'}
                        component={RadioFieldRedux}
                        label={'Votre retour concerne :'}
                        data={[{value: "erreur", label:'Une erreur rencontrée sur le site'}, {value:"amelioration", label: 'Une idée d\'amélioration'}]}
                    />
                    { typeRetour === "erreur" && <div className="row">
                        <div className=" col-lg-4 col-md-12">
                            <Field
                                name={'categorie_probleme'}
                                label={'Sur quelle rubrique avez-vous rencontré cette erreur ?'}
                                component={SelectFieldRedux}
                            >
                                <option value={''}>Choisissez une rubrique</option>
                                <option value={'ACCUEIL'}>Accueil</option>
                                <option value={'TABLEAU DE BORD'}>Tableau de bord</option>
                                <option value={'MES DEVIS'}>Mes devis</option>
                                <option value={'MES SOUSCRIPTIONS'}>Mes souscriptions</option>
                                <option value={'MON PORTEFEUILLE'}>Mon portefeuille</option>
                                <option value={'MES COMMISSIONS'}>Mes commissions</option>
                                <option value={'MA GESTION'}>Ma gestion</option>
                                <option value={'MON PROFIL'}>Mon profil</option>
                                <option value={'NOS PRODUITS'}>Nos produits</option>
                                <option value={'NOS DOCUMENTS'}>Nos documents</option>
                                <option value={'FLYERS PUB'}>Flyers, pub</option>
                                <option value={'MES CONTACTS'}>Mes contacts</option>

                            </Field>
                        </div>
                    </div>
                    }
                    <div className="row">
                        <div className="col-lg-4 col-md-12">
                            <Field
                                name={'description_probleme'}
                                component={TextAreaFieldRedux}
                                label={`Décrivez-nous votre ${typeRetour === "error" ? 'erreur' : 'amélioration'} en quelques lignes...`}

                            />
                        </div>
                    </div>
                    {
                        loading
                            ? <div className="row">
                                <div className="col-lg-4 col-md-12">
                                    <SmallSpinner />
                                </div>
                            </div>
                            : <button className={'btn btn-primary'}>j'envoie mon retour pour améliorer l'espace</button>
                    }
                </form>

            </Card>
        </div>

    );
};


export default FeedBack;
