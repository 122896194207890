import {createSelector} from 'reselect'

const tiersSelector = state => state.tiers.data

export const getTiersData = createSelector(
    tiersSelector,
    data => data
)

export const getTiersBelairId = createSelector(
    tiersSelector,
    data => data.belair_id
)
