export const CREATE_ACCOUNT = '[Create Account]'

export const POST_CREATE_ACCOUNT = `${CREATE_ACCOUNT} Post`

export const postCreateAccount = ({email, owner_belair_id, nom, date_naissance, belair_id, query}) => ({
    type: POST_CREATE_ACCOUNT,
    payload: {
        data: query,
        body: {email, owner_belair_id, nom, date_naissance, belair_id},
    }
})
