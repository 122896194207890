import React from 'react';
import {Field} from "redux-form";
import TextAreaFieldRedux from "../../../../../../../../../Commun/Inputs/Text/TextAreaFieldRedux";
import TextFieldRedux from "../../../../../../../../../Commun/Inputs/Text/TextFieldRedux";
import Cadre from "../../../../../../../../../Commun/Cadre/Cadre";
import DatePickerFieldRedux from "@/components/Commun/Inputs/Date/DatePickerFieldRedux";

const DeclarationTer = (props) => {
    const {categorie, isWakam} = props

    return (
        <div className={'form-content'}>

            <Cadre icon={'fas fa-car-crash'} title={'Quelles sont les circonstances ?'} large>
                <Field
                    name='commentaire'
                    component={TextAreaFieldRedux}
                    label={['103.1', '103.2', '103.3', '104.1', '104.2', '104.3', '104.4'].includes(categorie) ? 'Quel était le motif du déplacement au moment du sinistre ?' : 'Précisez les circonstances du sinistre'}
                    maxLength={'120'}
                    subLabel={'120 caractères maximum'}
                />

                {
                    isWakam && categorie !== "10.0" && <Field
                        name={"birthdate_conduct"}
                        component={DatePickerFieldRedux}
                        label={'Date de naissance du conducteur'}
                    />
                }

                {
                    !isWakam &&
                    <Field
                        name='conduct'
                        component={TextFieldRedux}
                        label='Nom du conducteur'
                    />
                }
            </Cadre>

        </div>
    );
};

export default DeclarationTer;
