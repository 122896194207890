import {API_ERROR, API_SUCCESS, apiRequest} from '../../../actions/core/api/api.actions'
import {setError, setErrorResponse, setLoader} from '../../../actions/app/ui/ui.actions'
import {
    ACCIDENTS_CONTRACT,
    FETCH_ACCIDENTS_CONTRACT,
    setAccidentsContract
} from "../../../actions/app/contracts/accidents.contract.actions";

export const accidentsContractsMiddleware = () => next => action => {
    next(action)

    switch (action.type){

        case FETCH_ACCIDENTS_CONTRACT:
            next([
                apiRequest({body: null, method: 'GET', url: action.payload.data, entity: ACCIDENTS_CONTRACT}),
                setLoader({state: true, entity: ACCIDENTS_CONTRACT})
            ])
            break

        case `${ACCIDENTS_CONTRACT} ${API_SUCCESS}`:
            next([
                setAccidentsContract({data: action.payload.data.data ? action.payload.data.data : action.payload.data}),
                setError({state: false, entity: ACCIDENTS_CONTRACT}),
                setLoader({state: false, entity: ACCIDENTS_CONTRACT})
            ])
            break

        case `${ACCIDENTS_CONTRACT} ${API_ERROR}`:
            next([
                setError({state: true, entity: ACCIDENTS_CONTRACT}),
                setErrorResponse({entity: ACCIDENTS_CONTRACT, data: action?.payload?.data?.response?.data}),
                setLoader({state: false, entity: ACCIDENTS_CONTRACT})
            ])
            break

        default:
            break
    }
    return null
}
