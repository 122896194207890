import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import {setTrackPage} from '../../../redux/actions/core/analytics/analytics.actions'

const WithTrackerComponent = WrappedComponent => {
    const HOC = class extends Component {
        componentDidMount () {
            const page = this.props.location.pathname
            this.props.trackPage(page)
        }

        UNSAFE_componentWillReceiveProps (nextProps) {
            const currentPage = this.props.location.pathname
            const nextPage = nextProps.location.pathname

            if (currentPage !== nextPage) {
                this.props.trackPage(nextPage)
            }
        }

        render () {
            return <WrappedComponent {...this.props} />
        }
    }

    const mapDispatchToProps = dispatch => bindActionCreators({
        trackPage: (page) => setTrackPage({page})
    }, dispatch)

    return connect(null, mapDispatchToProps)(HOC)

}

WithTrackerComponent.propTypes = {
    WrappedComponent: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    options: PropTypes.object
}

export default WithTrackerComponent
