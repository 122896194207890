import React from 'react';
import APILink from "@/components/Commun/APILink/APILink";
import Cadre from "@/components/Commun/Cadre/Cadre";

const DecCirconstanciee = (props) => {
    const {getDecCirconstanciee} = props

    return (
        <div className={'form-content h-100'}>
            <Cadre icon={'fas fa-file-alt'} color={'error'} title={'Déclaration circonstanciée'} large>
                <p>N'ayant pas de constat complété et/ou signé par le Tiers adverse, veuillez télécharger le
                    document <APILink className='btn-link btn' href={getDecCirconstanciee} target={'_blank'} download>en cliquant sur le
                        lien</APILink>,
                    le compléter et nous le renvoyer via votre espace après la validation du formulaire de déclaration. Sans
                    ce document, l'ouverture de votre dossier ne pourra se faire.</p>
            </Cadre>
        </div>
    );
};

export default DecCirconstanciee;