import {connect} from 'react-redux'
import DecCirconstanciee from "./DecCirconstanciee.jsx";
import {getDecCirconstanciee} from "@/redux/selectors/links/links.selectors.js";
import _ from "lodash";
import {getIdContract} from "@/redux/selectors/contracts/contracts.selectors";

const mapStateToProps = state => {
    return {
        getDecCirconstanciee: _.replace(getDecCirconstanciee(state), '{idContrat}', getIdContract(state)),
    }
}

const DecCirconstancieeContainer = connect(mapStateToProps)(DecCirconstanciee)

export default DecCirconstancieeContainer