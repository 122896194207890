export const QUITTANCES_CONTRACT = '[Contract Quittances]'

export const FETCH_QUITTANCES_CONTRACT = `${QUITTANCES_CONTRACT} Fetch`
export const SET_QUITTANCES_CONTRACT = `${QUITTANCES_CONTRACT} Set`

export const fetchQuittancesContract = ({query}) => ({
    type: FETCH_QUITTANCES_CONTRACT,
    payload: {
        data: query
    }
})

export const setQuittancesContract = ({data}) => ({
    type: SET_QUITTANCES_CONTRACT,
    payload:{
        data
    }
})
