export const FLEET_CONTRACT = '[Contract fleet]'
export const DELETE_VEHICLES = '[Delete Vehicles]'

export const FETCH_FLEET_CONTRACT = `${FLEET_CONTRACT} Fetch`
export const SET_FLEET_CONTRACT = `${FLEET_CONTRACT} Set`
export const POST_OUT_FLEET_CONTRACT = `${FLEET_CONTRACT} Post Out`
export const POST_ADD_FLEET_CONTRACT = `${FLEET_CONTRACT} Post Add`
export const POST_DELETE_VEHICLES = `${DELETE_VEHICLES} Post`

export const fetchFleetContract = ({query, filters}) => ({
    type: FETCH_FLEET_CONTRACT,
    payload: {
        data: query,
        filters
    }
})

export const setFleetContract = ({data}) => ({
    type: SET_FLEET_CONTRACT,
    payload:{
        data
    }
})

export const postOutFleetContract = ({ form, query, formName }) => ({
    type: POST_OUT_FLEET_CONTRACT,
    payload: {
        data: query,
        body: form,
        meta: {
            otherData: formName
        }
    }
})

export const postAddFleetContract = ({ form, query }) => ({
    type: POST_ADD_FLEET_CONTRACT,
    payload: {
        data: query,
        body: form
    }
})


export const postDeleteVehicles = ({form, query}) => ({
    type: POST_DELETE_VEHICLES,
    payload: {
        data: query,
        body: form
    }
})