import {connect} from 'react-redux'
import {
    getImmatriculationContract, getIsWakamContract,
    getMarqueVehiculeContract, getModeleVehiculeContract,
    getNomSouscripteurContract
} from "../../../../../../../../redux/selectors/contracts/contracts.selectors";
import {fetchContract} from "../../../../../../../../redux/actions/app/contracts/contracts.actions";
import {
    getAccidentRepairerUpdateLink,
    getContractLink
} from "../../../../../../../../redux/selectors/links/links.selectors";
import _ from "lodash";
import loader from "../../../../../../../Commun/Spinners/Loader/Loader";
import AjoutReparateurForm from "./AjoutReparateurForm";
import {compose} from "redux";
import {
    POST_ACCIDENT_REPAIRER,
    postAccidentRepairer
} from "../../../../../../../../redux/actions/app/accidents/accidents.actions";
import {change, formValueSelector, reduxForm} from "redux-form";
import {getErrorEntity, getLoadingEntity} from "../../../../../../../../redux/selectors/ui/ui.selectors";
import {
    getCategorieCodeAccident,
    getGarantiesAccident
} from "../../../../../../../../redux/selectors/accidents/accidents.selectors";

const mapStateToProps = (state, ownProps) => {
    const { id } = ownProps.match.params
    const { ids } = ownProps.match.params
    const selector = formValueSelector('change-repairer')

    return {
        idContract: id,
        reparateur: selector(state, 'reparateur'),
        isWakam: getIsWakamContract(state),
        nom: getNomSouscripteurContract(state),
        query_contract: _.replace(getContractLink(state), '{id}', id),
        query: _.replace(_.replace(getAccidentRepairerUpdateLink(state), '{id}', id), '{ids}', ids),
        loading: getLoadingEntity(state, POST_ACCIDENT_REPAIRER),
        success: getLoadingEntity(state, POST_ACCIDENT_REPAIRER) === false && getErrorEntity(state, POST_ACCIDENT_REPAIRER) === false,
        immatriculation: getImmatriculationContract(state),
        marqueModele: `${getMarqueVehiculeContract(state)} ${getModeleVehiculeContract(state)}`,
        garanties: getGarantiesAccident(state),
        categorieCode: getCategorieCodeAccident(state)
    }
}

const mapDispatchToProps = {
    fetchContract,
    postAccidentRepairer,
    changeValue: (field, value) => change('change-repairer', field, value)
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {query, query_contract} = stateProps
    const {fetchContract, postAccidentRepairer} = dispatchProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => fetchContract({query: query_contract}),
        onSubmit: (form) => postAccidentRepairer({form, query})
    }
}

const AjoutReparateurFormContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    reduxForm({
        form: 'change-repairer'
    }),
    loader()
)(AjoutReparateurForm)

export default AjoutReparateurFormContainer