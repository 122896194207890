import {API_ERROR, API_SUCCESS, apiRequest} from '../../../actions/core/api/api.actions'
import {setError, setLoader} from '../../../actions/app/ui/ui.actions'
import {
    GARANTIE_MOBILITE,
    POST_GARANTIE_MOBILITE
} from "../../../actions/app/contracts/garantie.mobilite.contract.actions";
import {setNotification} from "../../../actions/core/notifications/notifications.actions";

export const garantieMobiliteMiddleware = () => next => action => {
    next(action)

    switch (action.type){

        case POST_GARANTIE_MOBILITE:
            let dataForm = new FormData()

            for (let [key, value] of Object.entries(action.payload.body)) {
                if (key === 'files'){
                    for (let [vKey, vValue] of Object.entries(value)) {
                        dataForm.append(`${key}[${vKey}]`, vValue)
                    }
                } else {
                    dataForm.append([key], value)
                }
            }

            next([
                apiRequest({body: dataForm, method: 'POST', url: action.payload.data, entity: GARANTIE_MOBILITE, otherData: action.payload.meta}),
                setLoader({state: true, entity: action.payload.meta})
            ])
            break

        case `${GARANTIE_MOBILITE} ${API_SUCCESS}`:
            next([
                setError({state: false, entity: action.payload.meta.otherData}),
                setLoader({state: false, entity: action.payload.meta.otherData})
            ])
            break

        case `${GARANTIE_MOBILITE} ${API_ERROR}`:
            next([
                setNotification({entity: GARANTIE_MOBILITE, body: 'Une erreur est survenue lors de votre demande.' ,type: 'error' , title: 'Attention !'}),
                setError({state: true, entity: action.payload.meta.otherData}),
                setLoader({state: false, entity: action.payload.meta.otherData})
            ])
            break

        default:
            break
    }
    return null
}
