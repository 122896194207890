import React from 'react';
import './checkbox_accident.scss';
import check from "../../../../assets/images/icons/close_light.png";

const CheckboxAccident = (props) => {
    const {children, input, type, className} = props

    return (
        <label className={className}>
            {children}
            <input className={'position-absolute cursor-pointer'} {...input} type={type} />
            <span className={'checkmark bg-white border position-absolute'}>
                {input.checked && <img alt='icon' src={check} width={15}/>}
            </span>
        </label>
    );
};


export default CheckboxAccident;
