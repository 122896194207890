import {SET_PROFIL} from "../../actions/app/profil/profil.actions";
import {SET_USERS_PROFIL} from "../../actions/app/profil/users.profil.actions";
import {SET_MARQUES_PROFIL} from "../../actions/app/profil/marques.profil.actions";


export const profilReducer = (profil = {}, action = {}) => {
    const {payload} = action

    switch (action.type) {
        case SET_PROFIL:
            return {...profil, ...payload.data}
        case SET_USERS_PROFIL:
            return {...profil, users: payload.data}
        case SET_MARQUES_PROFIL:
            return {...profil, marques: payload.data}
        default:
            return profil
    }
}
