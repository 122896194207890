import React from 'react';
import CardCirconstances from "../../../../../../../../../Commun/Card/CardCirconstances";
import CheckboxAccident from "../../../../../../../../../Commun/Inputs/CheckboxAccident/CheckboxAccident";
import {Field} from "redux-form";
import _ from "lodash";
import Cadre from "../../../../../../../../../Commun/Cadre/Cadre";

const Circonstances = (props) => {
    const {circonstances, form, conducteur, adversaireNom, adversairePrenom, query, postCasIda, cas_ida, rc, setValidThirdCheck, changeValue, codegta} = props

    let totalA = 0
    let totalB = 0

    let formCirc = _.get(form, ['circonstances']) || {}
    _.map(formCirc, function(o, n) {

        if (_.startsWith(n, 'coche_a') && o === true){
            totalA++
        }
        if (_.startsWith(n, 'coche_b') && o === true){
            totalB++
        }
    });

    const handleClick = () => {
        let arrayA = []
        let arrayB = []
        let obj = []
        let formCirconstances = _.get(form, ['circonstances']) || {}
        let responsabilite = rc
        let casIda = cas_ida

        _.map(formCirconstances, function(o, n) {
            if (_.startsWith(n, 'coche_a') && o === true) {
                arrayA.push(_.split(n, 'coche_a')[1]);
            }
        });
        arrayA.sort()
        _.map(arrayA, function(a, i) {
            if (i === 0) {
                obj.vehicule_a = a
            } else if (i === 1) {
                obj.vehicule_a_bis = a
            }
        })
        _.map(formCirconstances, function(o, n) {
            if (_.startsWith(n, 'coche_b') && o === true) {
                arrayB.push(_.split(n, 'coche_b')[1]);

            }
        });
        arrayB.sort()
        _.map(arrayB, function(a, i) {
            if (i === 0) {
                obj.vehicule_b = a
            } else if (i === 1) {
                obj.vehicule_b_bis = a
            }
        })

        postCasIda({
            query: query,
            vehicule_a: obj.vehicule_a,
            vehicule_b: obj.vehicule_b,
            vehicule_a_bis: obj.vehicule_a_bis,
            vehicule_b_bis: obj.vehicule_b_bis
        })

        if(codegta === undefined){
            if(rc === 100){
                casIda = 'HIZ'
            } else if(rc === 75) {
                casIda = 'HIW'
            } else if(rc === 50) {
                casIda = 'HIY'
            } else if(rc === 25) {
                casIda = 'HIV'
            } else if(rc === 0) {
                casIda = 'HIX'
            }
        }

        changeValue('manuel_resp', responsabilite)
        changeValue('manuel_ida', casIda)
        setValidThirdCheck(true)
    }

    return (
        <div className={'form-content h-100'}>
            <Cadre icon={'fas fa-car-crash'} title={'Circonstances'}>
                <div className="col-md-12 col-sm-12">
                    <p className="h6 text-center mb-4">Attention de bien mettre les cases bien identiques à celles cochées sur le constat amiable.</p>
                    <span className={'text-left d-inline-block w-50 fw-bold h5'}>Votre client<br/>{conducteur}</span>
                    <span className={'text-right d-inline-block w-50 fw-bold h5'}>Adversaire<br/>{adversaireNom} {adversairePrenom}</span>
                </div>
                <div className="col-md-12 col-sm-12">
                    {
                        circonstances.map((c, i) =>
                            <CardCirconstances key={i}>
                                <Field name={`circonstances[coche_a${c.number}]`} component={CheckboxAccident}
                                       type={"checkbox"} className={'checkboxField float-left position-relative'}
                                />
                                <p style={{display: 'inline-block', width: '88%'}}>
                                    <b className={'h5 fw-bold'} style={{float: 'left'}}>{c.number}</b>
                                    {c.text}
                                    <b className={'h5 fw-bold'} style={{float: 'right'}}>{c.number}</b>
                                </p>
                                <Field name={`circonstances[coche_b${c.number}]`} component={CheckboxAccident}
                                       type={"checkbox"} className={'checkboxField float-right position-relative'}
                                />
                            </CardCirconstances>
                        )
                    }
                    <p className={'text-center font-weight-bold'} style={{fontSize: 20}}>
                        <span className={'float-left'} style={{marginLeft: 25}}>{totalA}</span>
                        Total
                        <span className={'float-right'} style={{marginRight: 25}}>{totalB}</span>
                    </p>
                </div>
                <div className={'text-right'}>
                    <span className={'btn btn-primary max-rounded'} onClick={() => handleClick()}>Poursuivre</span>
                </div>
            </Cadre>
        </div>
    );
};

export default Circonstances;
