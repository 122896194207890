import {connect} from 'react-redux'
import Documents from './Documents'
import {
    getUserIsConcessionnaire, getUserIsConcessionnaireGP,
    getUserIsConcessionnairePremium,
    getUserIsCourtier
} from "../../../../../redux/selectors/user/user.selectors";

const mapStateToProps = state => {
    return {
        isCourtier: getUserIsCourtier(state),
        isConcessionnaire: getUserIsConcessionnaire(state),
        isConcessionnaireGP: getUserIsConcessionnaireGP(state),
        isConcessionnairePremium: getUserIsConcessionnairePremium(state),
    }
}

const DocumentsContainer = connect(mapStateToProps)(Documents)

export default DocumentsContainer
