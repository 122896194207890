export const LOGIN = '[Login]'
export const LOGOUT = '[Logout]'
export const CUSTOMER = '[Customer]'

export const POST_LOGIN = `${LOGIN} Post`
export const POST_LOGOUT = `${LOGOUT} Post`
export const POST_UPDATE_PASSWORD = `${CUSTOMER} Post update password`

export const postLogin = ({username, password, token, source, query}) => ({
    type: POST_LOGIN,
    payload: {
        data: query,
        body: {username, password, token, source, query}
    }
})

export const postLogout = ({query}) => ({
    type: POST_LOGOUT,
    payload: {
        data: query
    }
})

export const postUpdatePassword = ({form, query}) => ({
    type: POST_UPDATE_PASSWORD,
    payload: {
        data: query,
        body: form,
        otherData: {
            password: form.password
        }
    }
})
