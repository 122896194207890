import React from 'react';
import PropTypes from 'prop-types'
import {FieldArray} from "redux-form";
import Cadre from "../../../../../../../../../Commun/Cadre/Cadre";
import FieldArrayTemoins from "./FieldArrayTemoins";

const Temoins = (props) => {
    const { changeValue } = props

    return (
        <div className={'form-content'}>
            <Cadre icon={'fas fa-user-shield'} title={'Témoin(s)'}>
                <FieldArray
                    name='temoins'
                    component={FieldArrayTemoins}
                    changeValue={changeValue}
                />
            </Cadre>
        </div>
    );
};

Temoins.propTypes = {
    changeValue: PropTypes.func
};

export default Temoins;
