import {SET_TIERS} from '../../actions/app/tiers/tiers.actions'

export const tiersReducer = (tiers = {}, action = {}) => {
    const {payload} = action

    if (action.type === SET_TIERS) {
        return payload
    } else {
        return tiers
    }
}
