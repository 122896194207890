import React, {useState} from 'react';
import Card from '../../../../../Commun/Card/Card'
import DevisTableContainer from "./DevisTable/DevisTableContainer";
import moment from "moment";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";

const DevisListe = (props) => {
    const { fetchQuote, filters } = props
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)

    const changeDate = (startDate, endDate) => {
        setStartDate(startDate)
        setEndDate(endDate)

        startDate && endDate && fetchQuote({
            filters: {
                ...filters,
                start_date: moment(startDate).format('YYYY-MM-DD'),
                end_date: moment(endDate).format('YYYY-MM-DD')
            }
        })
    }

    const changeTypeQuotes = acteGestion => {
        fetchQuote({
            filters: {
                ...filters,
                acteGestion
            }
        })
    }

    return (
        <div className={'p-lg-4'}>
            <Card>
                <div>
                    <h3 className={'m-0 pb-3 border-bottom border-dark'}>
                        <span className={'text-color-secondary'}>Vos devis</span> de moins de 30 jours
                    </h3>

                    <div className="row mt-5">
                        <div className="col-3">
                            <label>Type de devis</label>
                            <select className="form-control" onChange={(e) => changeTypeQuotes(e.target.value)} value={filters.acteGestion}>
                                <option value={''}>Tous les types de devis</option>
                                <option value={'AN'}>Devis d'affaires nouvelles</option>
                                <option value={'AV'}>Devis d'avenants</option>
                            </select>
                        </div>
                    </div>

                    <div className="row mt-5">
                        <div className="col p-0 pl-lg-3">
                            <label>Période de création</label>
                            <div>
                                <div className={"col pl-0"}>
                                    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="fr">
                                        <DatePicker
                                            className={'mr-4'}
                                            label='Date de début'
                                            value={startDate}
                                            onChange={(newValue) => {
                                                changeDate(newValue, endDate);
                                            }}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </LocalizationProvider>

                                    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="fr">
                                        <DatePicker
                                            label='Date de fin'
                                            value={endDate}
                                            onChange={(newValue) => {
                                                changeDate(startDate, newValue);
                                            }}
                                            renderInput={(params) => <TextField {...params} />}
                                            minDate={startDate}
                                        />
                                    </LocalizationProvider>
                                </div>
                            </div>
                        </div>

                    </div>

                    <DevisTableContainer />

                </div>
            </Card>
        </div>
    );
};

export default DevisListe;
