import React, {Fragment} from 'react';
import moment from "moment";
import { withRouter } from "react-router";
import sinistreOrange from '../../../../../../../assets/pictos/icon-sinistre-orange.png'

const ContractsTable = (props) => {
    const { contracts, history, meta, links, fetchContract, filters, isUserFlotte } = props

    return (
        <div>
            <table className="amo-table table table-hover table-bordered table-responsive table-striped mt-3">
                <thead>
                <tr>
                    <th scope="col" className={'align-text-top text-white'}>Numéro contrat</th>
                    <th scope="col" className={'align-text-top text-white'}>Nom, Prénom</th>
                    {isUserFlotte && <th scope="col" className={'align-text-top text-white'}>Type de contrat</th> }
                    <th scope="col" className={'align-text-top text-white'}>Marque, Modèle, Immatriculation</th>
                    <th scope="col" className={'align-text-top text-white'}>Date</th>
                    <th scope="col" className={'align-text-top text-white'}>Sinistre</th>
                    <th scope="col" className={'align-text-top text-white'}>Statuts</th>
                    <th scope="col" className={'align-text-top text-white'}>Détails</th>
                </tr>
                </thead>
                <tbody>
                {
                    contracts && contracts.map(c => (
                        <tr key={c.id} onClick={() => history.push(`/mon-contrat/${c.id}`)}>
                            <td className={'align-middle'}>{c.numeroPolice}</td>
                            <td className={'align-middle'}>{c.souscripteur.nom}</td>
                            {isUserFlotte && <td className={'align-middle'}>{c.libelleContrat}</td>}
                            <td>{c.vehicule.marque} {c.vehicule.modele}<br/>
                                {c.vehicule.immatriculation ? c.vehicule.immatriculation : '-'}
                            </td>
                            <td className={'align-middle'}>{moment(c.situation.date).format('L')}</td>
                            <td className={'h4 align-middle'}>{c.nbSinistres > 0 ? <img src={sinistreOrange} alt="sinistre"/> : ''}</td>
                            <td className={'align-middle'}>
                                <span className={`sticker sticker-${c.situation.statutCode}`}>{c.situation.statut}</span>
                                {c.situation.dateFin && <Fragment><br/><em>{moment(c.situation.dateFin).format('L')}</em></Fragment>}
                            </td>
                            <td className={'h4 align-middle'}><i className={'fas fa-search text-color-secondary'} /></td>
                        </tr>
                    ))
                }
                </tbody>
            </table>

            <nav>
                <ul className="pagination">
                    {meta.current_page > 2 && <li className="page-item" onClick={() => fetchContract({query: links.first, filters})}><span className="page-link"><i className="fas fa-angle-double-left" /></span></li>}
                    {meta.current_page > 1 && <li className="page-item" onClick={() => fetchContract({query: links.prev, filters})}><span className="page-link"><i className="fas fa-angle-left" /></span></li>}
                    <li className="page-item"><span className={`page-link current`} >{meta.current_page}</span></li>
                    {meta.last_page > meta.current_page && <li className="page-item" onClick={() => fetchContract({query: links.next, filters})}><span className="page-link"><i className="fas fa-angle-right" /></span></li>}
                    {meta.last_page >= meta.current_page + 2 && <li className="page-item" onClick={() => fetchContract({query: links.last, filters})}><span className="page-link"><i className="fas fa-angle-double-right" /></span></li>}
                </ul>
            </nav>

        </div>
    );
};

export default withRouter(ContractsTable);
