import {SET_USER, SET_USER_REMINDER_PREFERENCES_PMQ} from '../../actions/app/user/user.actions'

export const userReducer = (user = {}, action = {}) => {
    const {payload} = action

    if (action.type === SET_USER) {
        return payload.data
    } else if (action.type === SET_USER_REMINDER_PREFERENCES_PMQ) {
        return {...user, 'pmq_remind': payload.data}
    } else {
        return user
    }
}
