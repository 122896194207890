import {API_ERROR, API_SUCCESS, apiRequest} from '../../../actions/core/api/api.actions'
import {setError, setLoader} from '../../../actions/app/ui/ui.actions'
import {POPUP, POST_POPUP} from "../../../actions/app/popup/popup.actions";
import {setNotification} from "../../../actions/core/notifications/notifications.actions";

export const popupMiddleware = () => next => action => {
    next(action)

    switch (action.type){
        case POST_POPUP:
            let dataForm = new FormData()

            for (let [key, value] of Object.entries(action.payload.body)) {
                dataForm.append([key], value)
            }

            next([
                apiRequest({body: dataForm, method: 'POST', url: action.payload.data, entity: POPUP, otherData: action.payload.otherData}),
                setLoader({state: true, entity: POPUP})
            ])
            break

        case `${POPUP} ${API_SUCCESS}`:
            const message = action.payload.meta.otherData.confirmMessage || 'Votre demande à bien été enregistrée.';
            next([
                setNotification({
                    entity: POPUP,
                    body: message,
                    type: 'success', title: 'Merci !'
                }),
                setError({state: false, entity: POPUP}),
                setLoader({state: false, entity: POPUP})
            ])
            break

        case `${POPUP} ${API_ERROR}`:
            next([
                setNotification({
                    entity: POPUP,
                    body: 'Une erreur est survenune, votre demande n\'a pas été enregistrée.',
                    type: 'error', title: 'Attention !'
                }),
                setError({state: true, entity: POPUP}),
                setLoader({state: false, entity: POPUP})
            ])
            break

        default:
            break
    }
    return null
}
