import {linksMiddleware} from './links/links.middleware'
import {userMiddleware} from "./user/user.middleware";
import {loginMiddleware} from "./login/login.middleware";
import {whitelabelMiddleware} from "./whitelabel/whitelabel.middleware";
import {quotesMiddleware} from "./quotes/quotes.middleware";
import {contractsMiddleware} from "./contracts/contracts.middleware";
import {documentsContractsMiddleware} from "./contracts/documents.contract.middleware";
import {accidentsContractsMiddleware} from "./contracts/accidents.contract.middleware";
import {quittancesContractsMiddleware} from "./contracts/quittances.contract.middleware";
import {statsMiddleware} from "./stats/stats.middleware";
import {searchMiddleware} from "./search/search.middleware";
import {commissionsMiddleware} from "./commissions/commissions.middleware";
import {accidentsMiddleware} from "./accidents/accidents.middleware";
import {publicitesMiddleware} from "./publicites/publicites.middleware";
import {documentsMiddleware} from "./documents/documents.middleware";
import {produitsMiddleware} from "./produits/produits.middleware";
import {contactsMiddleware} from "./contacts/contacts.middleware";
import {feedbackMiddleware} from "./feedback/feedback.middleware";
import {profilMiddleware} from "./profil/profil.middleware";
import {logoProfilMiddleware} from "./profil/logo.profil.middleware";
import {usersProfilMiddleware} from "./profil/users.profil.middleware";
import {marquesProfilMiddleware} from "./profil/marques.profil.middleware";
import {articlesMiddleware} from "./articles/articles.middleware";
import {impayesMiddleware} from "./impayes/impayes.middleware";
import {dashboardMiddleware} from "./dashboard/dashboard.middleware";
import {popupMiddleware} from "./popup/popup.middleware";
import {uploadMiddleware} from "./upload/upload.middleware";
import {garantieMobiliteMiddleware} from "./contracts/garantie.mobilite.middleware";
import {avenantsMiddleware} from "./contracts/avenants.contract.middleware";
import {accountContractMiddleware} from "./contracts/account.contract.middleware";
import {tiersMiddleware} from "./tiers/tiers.middleware";
import {activitiesContractsMiddleware} from "./contracts/activities.contract.middleware";
import {popinsMiddleware} from "./popins/popins.middleware";
import {fleetContractsMiddleware} from "./contracts/fleet.contract.middleware";
import {webcallbackMiddleware} from "./webcallback/webcallback.middleware";
import {paymentMiddleware} from "./payment/payment.middleware";
import {signaturesMiddleware} from "./signatures/signatures.middleware";
import {casIdaMiddleware} from "./cas_ida/cas_ida.middleware";
import { tiersPayeurMiddleware } from './tiers_payeur/tiers_payeur.middleware';
import {emailMiddleware} from "./email/email.middleware";
import {changeFractionnementMiddleware} from "./contracts/fractionnement.contract.middleware";
import {terminateMiddleware} from "@/redux/middlewares/app/terminate/terminate.middleware";

export const appMiddleware = [
    linksMiddleware,
    userMiddleware,
    loginMiddleware,
    whitelabelMiddleware,
    quotesMiddleware,
    contractsMiddleware,
    tiersPayeurMiddleware,
    documentsContractsMiddleware,
    accidentsContractsMiddleware,
    quittancesContractsMiddleware,
    statsMiddleware,
    searchMiddleware,
    commissionsMiddleware,
    accidentsMiddleware,
    publicitesMiddleware,
    contactsMiddleware,
    documentsMiddleware,
    produitsMiddleware,
    feedbackMiddleware,
    profilMiddleware,
    logoProfilMiddleware,
    usersProfilMiddleware,
    marquesProfilMiddleware,
    articlesMiddleware,
    impayesMiddleware,
    dashboardMiddleware,
    popupMiddleware,
    uploadMiddleware,
    garantieMobiliteMiddleware,
    avenantsMiddleware,
    changeFractionnementMiddleware,
    accountContractMiddleware,
    tiersMiddleware,
    activitiesContractsMiddleware,
    popinsMiddleware,
    fleetContractsMiddleware,
    webcallbackMiddleware,
    paymentMiddleware,
    signaturesMiddleware,
    casIdaMiddleware,
    emailMiddleware,
    terminateMiddleware
]
