import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import loader from "../../../../../../../Commun/Spinners/Loader/Loader";
import ChangeAddress from "./ChangeAddress";
import {change, formValueSelector, getFormValues, reduxForm} from "redux-form";
import {CONTRACT, fetchContract} from "../../../../../../../../redux/actions/app/contracts/contracts.actions";
import _ from "lodash";
import {
    getAdresseSouscripteurContract,
    getContract
} from "../../../../../../../../redux/selectors/contracts/contracts.selectors";
import {getEaseUi, getErrorEntity, getLoadingEntity} from "../../../../../../../../redux/selectors/ui/ui.selectors";
import {
    getAmendmentChangeAddressLink,
    getContractLink
} from "../../../../../../../../redux/selectors/links/links.selectors";
import {postChangeAddress} from "../../../../../../../../redux/actions/app/contracts/avenants.contract.actions";
import validate from './validate'

const mapStateToProps = (state, ownProps) => {
    const formName = `change_address-${ownProps.match.params.id}`
    const selector = formValueSelector(formName)

    return {
        data: getFormValues(formName)(state),
        contract: getContract(state),
        uriPostChangeAddress: getAmendmentChangeAddressLink(state),
        uriContract: getContractLink(state),
        idContract: ownProps.match.params.id,
        address: getAdresseSouscripteurContract(state),
        loaded: getLoadingEntity(state, CONTRACT) === false,
        loading: getLoadingEntity(state, formName),
        newRue: `${selector(state, 'adresseNew')} ${selector(state, 'adresse2New') !== undefined ? selector(state, 'adresse2New') : ''} ${selector(state, 'adresse3New') !== undefined ? selector(state, 'adresse3New') : ''}, ${selector(state, 'codePostalNew')} ${selector(state, 'villeNew')}`,
        newAddr: `${selector(state, 'codePostalB')}, ${selector(state, 'villeB')}`,
        error: getErrorEntity(state, CONTRACT),
        form: formName,
        sansStationnement: selector(state, 'sansStationnement'),
        addressNotFound: selector(state, 'addressNotFound'),
        ease: getEaseUi(state)
    }
}

const mapDispatchToProps = (dispatch, ownProps) => bindActionCreators({
    fetchContract: (query) => fetchContract({query}),
    changeValue: (field, value) => change(`change_address-${ownProps.match.params.id}`, field, value),
    postChangeAddress: ({query, form, formName}) => postChangeAddress({query, form, formName})
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { uriPostChangeAddress, uriContract, idContract, form} = stateProps
    const { fetchContract, postChangeAddress } = dispatchProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => fetchContract(_.replace(uriContract, '{id}', idContract)),
        onSubmit: (formulaire) => postChangeAddress({query: uriPostChangeAddress, form: formulaire, formName:form})
    }
}

const ChangeAddressContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    reduxForm({
        validate
    }),
    loader()
)(ChangeAddress)

export default ChangeAddressContainer
