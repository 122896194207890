import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import {change} from 'redux-form'
import Temoins from "./Temoins";

const mapDispatchToProps = dispatch => bindActionCreators({
    changeValue: (field, value) => change('declaration-sinistre', field, value)
}, dispatch)

const TemoinsContainer = connect(null, mapDispatchToProps)(Temoins)

export default TemoinsContainer
