export const ACTIVITIES_CONTRACT = '[Contract Activities]'

export const FETCH_ACTIVITIES_CONTRACT = `${ACTIVITIES_CONTRACT} Fetch`
export const SET_ACTIVITIES_CONTRACT = `${ACTIVITIES_CONTRACT} Set`

export const fetchActivitiesContract = ({query}) => ({
    type: FETCH_ACTIVITIES_CONTRACT,
    payload: {
        data: query
    }
})

export const setActivitiesContract = ({data}) => ({
    type: SET_ACTIVITIES_CONTRACT,
    payload:{
        data
    }
})
