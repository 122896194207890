import React from 'react';
import Card from "../../../../Commun/Card/Card";
import DocumentsActiveContainer from "./DocumentsActive/DocumentsActiveContainer";
import DocumentsInactiveContainer from "./DocumentsInactive/DocumentsInactiveContainer";
import charteIndicateurAffairesAprilMoto from '../../../../../assets/pdf/Charte_indicateurs_affaires_APRIL-Moto.pdf'
import lettreRGPD from '../../../../../assets/pdf/Lettre-RGPD-APRIL-Moto-AM21-11.pdf'
import fichePermisVehicule from '../../../../../assets/pdf/April-Moto-fiche-permis-vehicule.pdf'
import conditionsRemunerationIndicationAffaires from '../../../../../assets/pdf/conditions_concess-50e-offert.pdf'
import annexeAssistance from '../../../../../assets/pdf/Annexe_Assistance-Avril2023.pdf'

const Documents = (props) => {
    const { isCourtier, isConcessionnaire, isConcessionnairePremium, isConcessionnaireGP } = props

    return (
        <div className={'p-lg-4'}>
            <Card>
                <div>
                    <h3 className={'m-0 pb-3 border-bottom border-dark'}>
                        Centre de <span className={'text-color-secondary'}>documentation</span>
                    </h3>
                </div>

                <div className="mt-5">
                    <a className="text-dark font-weight-bold d-inline-block hover-underline" href={isCourtier ? lettreRGPD : charteIndicateurAffairesAprilMoto} target={'_blank'} rel="noopener noreferrer">
                        <span className={'h6'}><i className='fas fa-file-alt mr-2 text-color-secondary'/></span>
                        {
                            isCourtier
                                ? "Téléchargez la Politique de protection des données personnelles d'APRIL Moto"
                                : "Téléchargez la charte d'indicateur d'affaire"
                        }
                    </a>
                    { (isConcessionnaire || isConcessionnairePremium) && !isConcessionnaireGP &&
                        <>
                            <br/>
                            <a className="text-dark font-weight-bold d-inline-block mt-3 hover-underline" href={conditionsRemunerationIndicationAffaires} target={'_blank'} rel="noopener noreferrer">
                                <span className={'h6'}><i className='fas fa-file-alt mr-2 text-color-secondary'/></span>
                                Voir les conditions de rémunération d'indication d'affaires
                            </a>
                        </>
                    }
                    <br/>
                    <a className="text-dark font-weight-bold d-inline-block mt-3 hover-underline" href={fichePermisVehicule} target={'_blank'} rel="noopener noreferrer">
                        <span className={'h6'}><i className='fas fa-motorcycle mr-2 text-color-secondary'/></span>
                        Quel permis pour quel véhicule ? Téléchargez notre Fiche Permis 2-roues
                    </a>
                </div>

                <p className={'mt-4'}>Consultez directement toutes nos Dispositions Générales, nos Fiches Conseil et nos Fiches Produits.</p>

                <p className={'mt-4'}>
                    <span className={'h6'}><i className="fas fa-info-circle mr-2 text-color-secondary" /></span>
                    L'Annexe Assistance des produits Moto, Scooter, Quad et Moto Verte est disponible <a className="hover-underline text-dark font-weight-bold d-inline-block" href={annexeAssistance} target={'_blank'} rel="noopener noreferrer">ici en téléchargement.</a>
                </p>

                <DocumentsActiveContainer />

                <p className="mt-5">
                    <i className="far fa-clock mr-2" />
                    Retrouver les anciennes versions des Conditions ou Dispositions Générales en-dessous&nbsp;:
                </p>

                <DocumentsInactiveContainer />
            </Card>
        </div>
    );
};

export default Documents;
