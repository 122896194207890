export const PAYMENT = '[Payment]'

export const POST_PAYMENT = `${PAYMENT} Post`
export const SET_PAYMENT = `${PAYMENT} Set`

export const postPayment = ({id_tiers, amount, url_return, by_mail, query}) => ({
    type: POST_PAYMENT,
    payload: {
        data: query,
        body: {id_tiers, amount, url_return, by_mail},
    }
})

export const setPayment = ({state}) => ({
    type: SET_PAYMENT,
    payload: {
        data: state
    }
})
