import React, {useState} from 'react';
import Card from "../Card/Card";
const Poppin = (props) => {
    const { title, children, onClose = () => {} } = props
    const [close, setClose] = useState(false)

    if (!close){
        return (
            <div className={'w-100 position-fixed h-100 results-search-content'}>
                <div className={'results-card'}>
                    <Card>
                        {
                            title &&
                            <div className="row">
                                <h4 className={'p-3 text-color-secondary'}>{title}</h4>
                            </div>
                        }
                        <div className="row">
                            <div className={'p-3 col-12'} style={{whiteSpace: 'pre-line'}}>
                                {children}
                            </div>
                        </div>
                        <i className="fas fa-times text-color-secondary" onClick={() => {
                            onClose();
                            setClose(true);
                        }}/>
                    </Card>
                </div>
            </div>
        );
    }
    return null
};
export default Poppin;
