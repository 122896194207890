const validate = values => {
    const errors = {}

    const requiredFields = [
        'type',
        'marque',
        'cylindree',
        'modele',
        'date_mec',
        'immatriculation',
        'assistance'
    ]

    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Ce champ est obligatoire'
        }
    })

    if (!/^[a-zA-Z]{2} ?-?[0-9]{3} ?-?[a-zA-Z]{2}$/.test(values.immatriculation)){
        errors.immatriculation = 'Plaque d\'immatriculation non valide'
    }

    return errors
}

export default validate
