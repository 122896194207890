import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import loader from '../../../../../Commun/Spinners/Loader/Loader'
import DevisDetail from './DevisDetail'
import {getQuoteLink} from "../../../../../../redux/selectors/links/links.selectors";
import _ from 'lodash';
import {fetchQuote, QUOTE} from "../../../../../../redux/actions/app/quotes/quotes.actions";
import {getErrorEntity, getLoadingEntity} from "../../../../../../redux/selectors/ui/ui.selectors";
import {
    getCodeProduitQuote,
    getConducteurQuote,
    getCreatedAtQuote,
    getDateEffetQuote,
    getFormuleChoisieLibelleQuote,
    getFractionnementQuote,
    getHonorairesQuote, getIdQuote,
    getLibelleProduitQuote,
    getPrimeFAQuote,
    getPrimeFractionneTTCQuote,
    getSouscripteurQuote,
    getTauxApporteur1Quote,
    getUrlPdfQuote,
    getUrlRepriseQuote,
    getUrlSouscriptionQuote,
    getVehiculeQuote
} from "../../../../../../redux/selectors/quotes/quotes.selectors";
import moment from 'moment'
import {getUserIsCourtier} from "../../../../../../redux/selectors/user/user.selectors";

const mapStateToProps = (state, ownProps) => {
    const { id } = ownProps.match.params

    return {
        conducteur: getConducteurQuote(state),
        souscripteur: getSouscripteurQuote(state),
        vehicule: getVehiculeQuote(state),
        isCourtier: getUserIsCourtier(state),
        isWakam: getCodeProduitQuote(state) === 'DeliveryAMO',
        quote:{
            id: getIdQuote(state),
            libelleFormule: getFormuleChoisieLibelleQuote(state),
            libelleProduit: getLibelleProduitQuote(state),
            endValidity: moment(getCreatedAtQuote(state)).add(30, 'days').format('L'),
            desiredDate: getDateEffetQuote(state) ? moment(getDateEffetQuote(state)).format('L') : '-',
            createdDate: moment(getCreatedAtQuote(state)).format('L'),
            fractionnement: getFractionnementQuote(state),
            primeFractionneTTC: getPrimeFractionneTTCQuote(state),
            primeFA: getPrimeFAQuote(state),
            tauxApporteur: getTauxApporteur1Quote(state),
            honoraire: getHonorairesQuote(state),
            urlSouscription: getUrlSouscriptionQuote(state),
            urlReprise: getUrlRepriseQuote(state),
            urlPdf: getUrlPdfQuote(state)
        },
        query: _.replace(getQuoteLink(state), '{id}', id),
        loaded: getLoadingEntity(state, QUOTE) === false,
        error: getErrorEntity(state, QUOTE)
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    fetchQuote: ({query}) => fetchQuote({query, detail: true})
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { fetchQuote } = dispatchProps
    const { query } = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => fetchQuote({query})
    }
}

const DevisDetailContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader()
)(DevisDetail)

export default DevisDetailContainer
