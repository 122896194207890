import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import loader from '../../../../../Commun/Spinners/Loader/Loader'
import DocumentsActive from './DocumentsActive'
import {getDocumentsActiveLink} from "../../../../../../redux/selectors/links/links.selectors";
import {fetchDocumentsActive} from "../../../../../../redux/actions/app/documents/documents.actions";
import {getDocumentsActiveDocuments} from "../../../../../../redux/selectors/documents/documents.selectors";


const mapStateToProps = state => {
    return {
        active: getDocumentsActiveDocuments(state),
        query: getDocumentsActiveLink(state)
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    fetchDocumentsActive: ({query}) => fetchDocumentsActive({query})
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { query } = stateProps
    const { fetchDocumentsActive } = dispatchProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => fetchDocumentsActive({query})
    }
}

const DocumentsActiveContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader()
)(DocumentsActive)

export default DocumentsActiveContainer
