import {createSelector} from 'reselect'

/* UI */
const uiSelector = state => state.ui

/* Maintenance */
export const getMaintenance = createSelector(
    uiSelector,
    ui => ui.maintenance
)

/* Login */
export const getLogin = createSelector(
    uiSelector,
    ui => ui.login
)

/* Loading */
const loadingEntitySelector = (state, entity) => state.ui.loading[entity]

export const getLoading = createSelector(
    uiSelector,
    ui => ui.loading
)

export const getLoadingEntity = createSelector(
    loadingEntitySelector,
    loading => loading
)

/* Error */
const errorEntitySelector = (state, entity) => state.ui.error[entity]

export const getErrorEntity = createSelector(
    errorEntitySelector,
    error => error
)

export const getErrorResponse = createSelector(
    uiSelector,
    ui => ui.errorResponse
)

/* Filters */
const filtersEntitySelector = (state, entity) => state.ui.filters[entity]

export const getFiltersEntity = createSelector(
    filtersEntitySelector,
    filters => filters || {}
)

/* Search */
const searchEntitySelector = (state, entity) => state.ui.search[entity]

export const getSearchEntity = createSelector(
    searchEntitySelector,
    search => search || {}
)

/* Ease */
export const getEaseUi = createSelector(
    uiSelector,
    ui => ui.ease
)

/* Popins */
export const getPopinUi = createSelector(
    uiSelector,
    ui => ui.popin
)

/* Poppin de Charte */
export const getClosedPoppinCharte = createSelector(
    uiSelector,
    ui => ui.closedPoppinCharte
)

/* Message retour décla accident */
export const getAccidentResponse = createSelector(
    uiSelector,
    ui => ui.postAccidentResponse
)

/* Retour maj préférences */
export const getUserReminderPMQNewValue = createSelector(
    uiSelector,
    ui => ui.postNewValueUserPMQReminder
)

/* Retour maj hivernage/fin hivernage */
const uiSelectorhivernagePost = (state, entity) => (state.ui?.hivernagePost ? state.ui?.hivernagePost[entity] : null)
export const getHivernagePost = createSelector(
    uiSelectorhivernagePost,
    hivernage => hivernage
)

const uiSelectorhivernageReduction = (state, entity) => (state.ui?.hivernageReduction ? state.ui?.hivernageReduction[entity] : null)
export const getHivernageReduction = createSelector(
    uiSelectorhivernageReduction,
    hivernageReduction => hivernageReduction
)
