export const WEBCALLBACK = '[Webcallback]'

export const POST_WEBCALLBACK = `${WEBCALLBACK} Post`

export const postWebcallback = ({query, form}) => ({
    type: POST_WEBCALLBACK,
    payload: {
        data: query,
        body: form
    }
})
