import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import _ from 'lodash';
import { formValueSelector, reduxForm } from 'redux-form';
import validate from './validate';
import InfosNaissance from './InfosNaissance';
import loader from '../../../../../../../Commun/Spinners/Loader/Loader';
import {
    changeBirthInfosTiersLink,
    getAmendmentChangeContractLink,
    getContractLink,
} from '../../../../../../../../redux/selectors/links/links.selectors';
import {
    getContract,
    getEmailSouscripteurContract,
    getIdSouscripteurContract,
    getNomNaissanceSouscripteurContract,
    getCodePaysNaissanceSouscripteurContract,
    getPhoneSouscripteurContract,
    getVilleNaissanceSouscripteurContract,
    getSouscripteurContract,
    getStatutJuridiqueSouscripteurContract,
    getSirenSiretSouscripteurContract,
} from '../../../../../../../../redux/selectors/contracts/contracts.selectors';
import { getLoadingEntity } from '../../../../../../../../redux/selectors/ui/ui.selectors';
import { CONTRACT, fetchContract } from '../../../../../../../../redux/actions/app/contracts/contracts.actions';
import { postUpdateBirthInfosTiers } from '../../../../../../../../redux/actions/app/tiers/tiers.actions';

const mapStateToProps = (state, ownProps) => {
    const { id } = ownProps.match.params;
    const formName = `infos-naissance-${id}`;
    const selector = formValueSelector(formName);

    return {
        query: _.replace(getContractLink(state), '{id}', id),
        tiers: getIdSouscripteurContract(state),
        contract: getContract(state),
        phone: getPhoneSouscripteurContract(state),
        email: getEmailSouscripteurContract(state),
        queryPost: changeBirthInfosTiersLink(state),
        form: formName,
        loading: getLoadingEntity(state, formName),
        loaded: getLoadingEntity(state, CONTRACT) === false,
        initialValues: {
            pays: getCodePaysNaissanceSouscripteurContract(state) || '',
            ville: getVilleNaissanceSouscripteurContract(state) || '',
            nom: getNomNaissanceSouscripteurContract(state) || '',
            statut_juridique: getStatutJuridiqueSouscripteurContract(state) || '',
            siren_siret: getSirenSiretSouscripteurContract(state) || '',
        },
        pays: selector(state, 'pays'),
        ville: selector(state, 'ville'),
        societe: getSouscripteurContract(state).titre === 'SOC' ? true : false,
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            fetchContract: ({ query }) => fetchContract({ query }),
            postInfosNaissance: ({ form, query, formName }) => postUpdateBirthInfosTiers({ form, query, formName }),
        },
        dispatch,
    );

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { query, contract, form, queryPost, tiers } = stateProps;
    const { fetchContract, postInfosNaissance } = dispatchProps;

    return {
        ...ownProps,
        ...stateProps,
        ...dispatchProps,
        load: () => (_.isEmpty(contract) ? fetchContract({ query }) : null),
        onSubmit: (formulaire) =>
            postInfosNaissance({ form: { ...formulaire, tiers }, query: _.replace(queryPost, `{id}`, tiers), formName: form }),
    };
};

const InfosNaissanceContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader(),
    reduxForm({
        validate,
    }),
)(InfosNaissance);

export default InfosNaissanceContainer;
