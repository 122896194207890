import React from 'react';

const DocumentsInactive = (props) => {
    const { inactive } = props

    return (
        <div>
            {
                inactive.map(i => (
                    <div key={i.id}>
                        <i className={'fas fa-circle text-color-secondary mr-2'} /><a className="text-color-secondary" href={i.url} target={'_blank'} rel="noopener noreferrer">{i.title}</a>
                    </div>
                ))
            }
        </div>
    );
};

export default DocumentsInactive;
