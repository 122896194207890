import React from 'react';
import HeaderContainer from "./Header/HeaderContainer";
import FooterContainer from './Footer/FooterContainer'
import './pages.scss'
import {Switch, Redirect} from "react-router-dom";
import {PagesRoutes} from "./PagesRoutes";
import PrivateRouteContainer from '../../PrivateRoute/PrivateRouteContainer'
import _ from "lodash";
import MobileMenuContainer from "./MobileMenu/MobileMenuContainer";

const Pages = (props) => {
    const { permissions, hideSidebarAndHeader } = props

    return (
        <div id="page" className={`mb-5 ${hideSidebarAndHeader && 'w-100'}`}>
            
            {
                !hideSidebarAndHeader && <HeaderContainer />
            }

            {
                !hideSidebarAndHeader && <MobileMenuContainer />
            }

            <Switch>
                {
                    PagesRoutes.map((r, i) => (
                        r.children
                        ? r.children.map((rc, ic) => (
                            (_.includes(permissions, rc.permission) || rc.permission === 'all') && <PrivateRouteContainer key={ic} exact path={rc.path} component={rc.main} />
                            ))
                        : (_.includes(permissions, r.permission) || r.permission === 'all') && <PrivateRouteContainer key={i} exact path={r.path} component={r.main} />
                    ))
                }
                <Redirect to='/accueil' />
            </Switch>

            {
                !hideSidebarAndHeader && <FooterContainer />
            }
        </div>
    );
};

export default Pages;
