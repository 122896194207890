export const SEARCH = '[Search]'

export const FETCH_SEARCH = `${SEARCH} Post`
export const SET_SEARCH = `${SEARCH} Set`
export const CLEAR_SEARCH = `${SEARCH} Clear`

export const fetchSearch = ({entity, query}) => ({
    type: FETCH_SEARCH,
    payload: {
        data: query
    },
    meta: entity
})

export const setSearch = ({data, entity}) => ({
    type: SET_SEARCH,
    payload: {
        data
    },
    meta: entity
})

export const clearSearch = ({entity}) => ({
    type: CLEAR_SEARCH,
    payload: entity
})
