import {connect} from 'react-redux'
import {compose} from "redux";
import Hivernage from "./Hivernage";
import {getIsEligibleHivernageContract} from "../../../../../../../redux/selectors/contracts/contracts.selectors";
import _ from "lodash";
import {CONTRACT, fetchContract} from "../../../../../../../redux/actions/app/contracts/contracts.actions";
import {getContractLink} from "../../../../../../../redux/selectors/links/links.selectors";
import {getLoadingEntity} from "../../../../../../../redux/selectors/ui/ui.selectors";
import loader from "../../../../../../Commun/Spinners/Loader/Loader";

const mapStateToProps = (state, ownProps) => {
    return {
        uriContract: getContractLink(state),
        id_contrat: ownProps.match.params.id,
        is_eligible_hivernage : getIsEligibleHivernageContract(state),
        loaded: getLoadingEntity(state, CONTRACT) === false
    }
}

const mapDispatchToProps = {
    fetchContract: (query) => fetchContract({query})
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { uriContract, id_contrat } = stateProps
    const { fetchContract } = dispatchProps
	
    return {
        load: () => fetchContract(_.replace(uriContract, '{id}', id_contrat)),
        ...ownProps, ...stateProps, ...dispatchProps
    }
}

const HivernageContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader()
)(Hivernage)

export default HivernageContainer