export const AVENANT = '[Avenant]'

export const POST_INFOS_PERSO_AVENANT = `${AVENANT} Infos perso Post`
export const POST_MODIFIE_RIB_AVENANT = `${AVENANT} Modifie rib Post`
export const POST_CHANGE_ADDRESS = `${AVENANT} Changement d'adresse Post`
export const POST_HIVERNAGE = `${AVENANT} Post Hivernage`
export const GET_HIVERNAGE_REDUC = `${AVENANT} Get Hivernage Reduc`
export const POST_FIN_HIVERNAGE = `${AVENANT} Post Fin Hivernage`

export const postInfosPerso = ({form, query, formName}) => ({
    type: POST_INFOS_PERSO_AVENANT,
    payload:{
        data: query,
        body: form,
        meta: formName
    }
})

export const postModifieRib = ({form, query, formName}) => ({
    type: POST_MODIFIE_RIB_AVENANT,
    payload: {
        data: query,
        body: form,
        meta: formName
    }
})

export const postChangeAddress = ({query, form, formName}) => ({
    type: POST_CHANGE_ADDRESS,
    payload: {
        data: query,
        body: form,
        meta: formName
    }
})

export const postHivernage = ({query, form, formName}) => ({
    type: POST_HIVERNAGE,
    payload: {
        data: query,
        body: form,
        meta: formName
    }
})
export const postFinHivernage = ({query, formName}) => ({
    type: POST_FIN_HIVERNAGE,
    payload: {
        data: query,
        meta: formName
    }
})

export const getHivernageReduc = ({query, form, formName}) => ({
    type: GET_HIVERNAGE_REDUC,
    payload: {
        data: query,
        body: form,
        meta: formName
    }
})