export const STATS = '[Stats]'

export const FETCH_STATS = `${STATS} Fetch`
export const SET_STATS = `${STATS} Set`

export const fetchStats = ({query}) => ({
    type: FETCH_STATS,
    payload: {
        data: query
    }
})

export const setStats = ({data}) => ({
    type: SET_STATS,
    payload: {
        data
    }
})
