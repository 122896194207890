import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash'
import moment from 'moment'

const ActivitiesContract = (props) => {
    const { activities } = props

    return (
        <div>
            {
                activities.length === 0
                    ? <p>Aucune activité</p>
                    : _.map(activities, (a, i) => (
                        <div key={i}>
                            <div>
                                <p><b>{moment(a.created_at).format('L')} - {a.title}</b></p>
                                {a.description && a.description !== "" && <p><u>Description</u> : <em>{a.description}</em></p>}
                            </div>
                            <hr/>
                        </div>
                    ))
            }
        </div>
    );
};

ActivitiesContract.defaultProps = {
    activities: []
};

ActivitiesContract.propTypes = {
    activities: PropTypes.array
};

export default ActivitiesContract;
