import React from 'react';
import {Field} from "redux-form";
import RadioBlocDesignButton from "../../../../../Commun/Inputs/Radio/RadioBlocDesignButton/RadioBlocDesignButton";
import Poppin from "../../../../../Commun/Poppin/Poppin";

const RelanceUser = (props) => {
    const {loading, handleSubmit, submit, submitReminderPreferences, new_value, setPostNewValueUserReminderPreferences} = props

    return (
        <div>
            {
                new_value &&
                <Poppin onClose={() => setPostNewValueUserReminderPreferences({data: ''})}>
                    <div className={'text-center'}>
                        {
                            new_value === 'T' ?
                                <>
                                    <p className={'font-weight-bold'}>Vous avez souscrit au service dédié aux pièces manquantes !</p>
                                    <p className={'mb-0'}>Vous pouvez vous désabonner à tout moment en cliquant sur « <span className={'text-color-secondary font-weight-bold'}>NON</span> »</p>
                                </> :
                                <>
                                    <p className={'font-weight-bold'}>Vous n'avez pas souscrit au service dédié aux pièces manquantes !</p>
                                    <p className={'mb-0'}>Vous pouvez changer d'avis et souscrire à tout moment en cliquant sur « <span className={'text-color-secondary font-weight-bold'}>OUI</span> »</p>
                                </>
                        }
                    </div>
                </Poppin>
            }

            <p><b>Nouveau Service :</b> APRIL Moto vous accompagne pour optimiser la validation de vos contrats en établissement !</p>

            <p>En activant ce service gratuit, vos clients seront automatiquement alertés par email des documents manquants pour la validation de leur contrat.</p>

            <p className={'mb-5'}><u>Vous restez l'interlocuteur privilégié de vos clients :</u> ces relances en marque blanche seront adressées avec le nom et les coordonnées de votre cabinet.</p>

            <form onSubmit={handleSubmit(submit)} className={'mb-4'}>
                <div className={'text-center'}>
                    <Field
                        name={`pmq_reminder_activate`}
                        component={RadioBlocDesignButton}
                        label={'Mon cabinet souhaite bénéficier de ce service :'}
                        data={[
                            {value: "T", label:"OUI"},
                            {value: "F", label:"NON"}
                        ]}
                        className={'text-color-secondary font-weight-bold font-size-18'}
                        onChange={(event) => submitReminderPreferences({values: { pmq_reminder_activate: event.target.value } }) }
                        loading={loading}
                    />
                </div>
            </form>

            <p className={'text-center font-weight-bold font-italic text-color-ternary-light mb-0'}>Veuillez vérifier que vous avez les droits au sein de votre cabinet pour adhérer à ce service</p>
        </div>
    );
};

export default RelanceUser;