import React from 'react';
import {Field} from "redux-form";
import {Redirect} from 'react-router-dom'
import TextFieldRedux from "../../Commun/Inputs/Text/TextFieldRedux";
import SmallSpinner from "../../Commun/Spinners/SmallSpinner";
import Logo from '../../../assets/images/april_moto_dark.svg'

const LoginAprilMoto = (props) => {
    const { login, handleSubmit, loading, erreur } = props

    if (login){
        return <Redirect to={'/'} />
    }

    return (
        <div className={'text-center full-screen background-color-login'}>
            <img src={Logo} alt="Logo" className={'m-5'} width={300}/>

            <h1 className={'mb-5 text-secondary'}>Bienvenue sur votre espace partenaire</h1>

            <div className={'p-5 border-color-secondary border max-width-7 m-auto medium-rounded'}>
                <form onSubmit={handleSubmit} className={'text-left'}>
                    <Field
                        name={'username'}
                        component={TextFieldRedux}
                        label={'Votre identifiant'}
                    />
                    <Field
                        name={'password'}
                        component={TextFieldRedux}
                        type={'password'}
                        label={'Votre mot de passe'}
                    />
                    { loading ? <SmallSpinner /> : <button className={'btn btn-secondary w-100 max-rounded'}>
                        Je me connecte à mon espace
                    </button> }

                    {erreur && <p>Erreur de connexion</p>}
                </form>
            </div>

            <p className={'m-0 mt-5'}>Vous n'avez pas de code ou vous avez oublié vos identifiants ?</p>

            <p className={'m-0 my-2'}>Contactez le <b>02 47 51 50 60</b></p>

            <p className={'m-0'}>APRIL Moto - 14 Quai de Marmoutier - BP 7233 - TOURS CEDEX 2</p>

        </div>
    );
};

export default LoginAprilMoto;
