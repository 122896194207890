import React, {useState} from 'react';
import Card from "../../../../Commun/Card/Card";
import DossiersAttenteContainer from "./DossiersAttente/DossiersAttenteContainer";
import PieceAttenteContainer from "./PieceAttente/PieceAttenteContainer";
import SinistreOuvertContainer from "./SinistreOuvert/SinistreOuvertContainer";
import moment from "moment";


const Gestion = (props) => {
    const { total, newAfnWaiting, waitingDocument, accidentsOpen, accidentsWaiting, history, chuteAfn, totalAfn } = props
    const [active, setActive] = useState('DossiersAttente')
    const oneYearAgo = moment().subtract(1, 'year').subtract(72, 'days').format('L');
    const endDate = moment().subtract(72, 'days').format('L');

    return (
        <div className={'p-lg-4'}>
            <Card>
                <div>
                    <h3 className={'m-0 pb-3 border-bottom border-dark'}>
                        Vous avez <span className={'text-color-secondary'}>{total} dossiers en attente</span> à régulariser
                    </h3>

                    <div className="row mt-5">
                        <div className="col-lg-3 col-md-6 mb-3">
                            <div className={`border bg-white text-center p-3 cursor-pointer hover-translateY hover-border-secondary h-100 ${active === 'DossiersAttente' && 'border-color-secondary'}`} onClick={() => setActive('DossiersAttente')}>
                                <span className={'h1'}>{newAfnWaiting}</span><p className={'font-weight-bold m-0'}>Affaire(s) nouvelles en attente</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 mb-3">
                            <div className={`border bg-white text-center p-3 cursor-pointer hover-translateY hover-border-secondary h-100`}>
                                <span className={'h1'}>{((chuteAfn / totalAfn) * 100).toFixed(2)}%</span><p className={'font-weight-bold m-0'}>Taux de chute (année glissante)</p>
                                <small>Du {oneYearAgo} au {endDate}</small>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 mb-3">
                            <div className={`border bg-white text-center p-3 cursor-pointer hover-translateY hover-border-secondary h-100 ${active === 'SinistreOuvert' && 'border-color-secondary'}`} onClick={() => setActive('SinistreOuvert')}>
                                <span className={'h1'}>{accidentsOpen}</span><p className={'font-weight-bold m-0'}>Sinistre(s) ouvert(s)</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 mb-3">
                            <div className={`border bg-white text-center p-3 cursor-pointer hover-translateY hover-border-secondary h-100 ${active === 'SinistreAttente' && 'border-color-secondary'}`} onClick={() => setActive('SinistreAttente')}>
                                <span className={'h1'}>{accidentsWaiting}</span><p className={'font-weight-bold m-0'}>Sinistre(s) en attente</p>
                            </div>
                        </div>
                    </div>

                    <div className="mt-5">
                        {
                            active === 'DossiersAttente' && <DossiersAttenteContainer history={history} />
                        }
                        {
                            active === 'PieceAttente' && <PieceAttenteContainer history={history} />
                        }
                        {
                            active === 'SinistreOuvert' && <SinistreOuvertContainer history={history} situationCode={'1'} />
                        }
                        {
                            active === 'SinistreAttente' && <SinistreOuvertContainer history={history} situationCode={'A'} />
                        }
                    </div>

                </div>
            </Card>
        </div>
    );
};

export default Gestion;
