export const CONTACTS = '[contacts]'

export const FETCH_CONTACTS = `${CONTACTS} Get`
export const SET_CONTACTS = `${CONTACTS} Set`

export const fetchContacts = ({query}) => ({
    type: FETCH_CONTACTS,
    payload: {
        data: query
    }
})

export const setContacts = ({data}) => ({
    type: SET_CONTACTS,
    payload: {
        data
    }
})