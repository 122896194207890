import React from 'react'

/*
Usage :
<Field
    name={string}
    component={SelectFieldRedux}
    label={string}
    placeholder={string}
>
    <option></option>
</Field>
 */

const SelectFieldRedux = (props) => {
    const { meta: {touched, error}, input, label, InputLabelProps, ...custom } = props

    return (
        <div className={'form-group'}>
            { label && <label {...InputLabelProps}>
                {label}
            </label> }
            <select
                className={'form-control '+ (touched ? Boolean(error) ? 'is-invalid' : 'is-valid' : '')}
                style={{backgroundPosition: 'right calc(.375em + 1.1875rem) center'}}
                {...input}
                {...custom}
            />
            {error && <small className="invalid-feedback">{error}</small>}
        </div>
    )
}

SelectFieldRedux.defaultProps = {
    meta: {}
}

export default SelectFieldRedux
