export const DOCUMENTS_CONTRACT = '[Contract documents]'

export const FETCH_DOCUMENTS_CONTRACT = `${DOCUMENTS_CONTRACT} Fetch`
export const SET_DOCUMENTS_CONTRACT = `${DOCUMENTS_CONTRACT} Set`

export const fetchDocumentsContract = ({query}) => ({
    type: FETCH_DOCUMENTS_CONTRACT,
    payload: {
        data: query
    }
})

export const setDocumentsContract = ({data}) => ({
    type: SET_DOCUMENTS_CONTRACT,
    payload:{
        data
    }
})
