const validate = values => {
    const errors = {}

    const requiredFields = [
        'password',
        'password_confirmation'
    ]

    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Champ obligatoire'
        }

        if (values.password !== undefined && values.password.length < 8){
            errors.password = '8 caractères minimum'
        }
        if (!/\d/.test(values.password)){
            errors.password = '1 chiffre minimum'
        }

        if (!/[A-Z]/.test(values.password)){
            errors.password = '1 majuscule minimum'
        }

        if (!/[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(values.password)){
            errors.password = '1 caractère spécial minimum'
        }

        if (values.password !== values.password_confirmation) {
            errors.password = 'Les mots de passe ne sont pas identiques'
            errors.password_confirmation = 'Les mots de passe ne sont pas identiques'
        }
    })


    return errors
}

export default validate
