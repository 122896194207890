import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import loader from "../../../../../../Commun/Spinners/Loader/Loader";
import Signatures from './Signatures'
import {
    SIGNATURES,
    fetchSignatures
} from '../../../../../../../redux/actions/app/signatures/signatures.actions'
import {CONTRACT} from '../../../../../../../redux/actions/app/contracts/contracts.actions'
import {getLoadingEntity} from '../../../../../../../redux/selectors/ui/ui.selectors'
import { signaturesGetLink } from '../../../../../../../redux/selectors/links/links.selectors'
import {getDataSignatures} from '../../../../../../../redux/selectors/signatures/signatures.selector'
import _ from "lodash";
import {getIdContract} from "../../../../../../../redux/selectors/contracts/contracts.selectors";

const mapStateToProps = state => {
    return {
        idContrat: getIdContract(state),
        signatures : getDataSignatures(state),
        uri: signaturesGetLink(state, CONTRACT),
        loaded: getLoadingEntity(state, SIGNATURES) === false
    }
}
const mapDispatchToProps = dispatch => bindActionCreators({
    getSignatures: (query) => fetchSignatures({query})
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { getSignatures } = dispatchProps
    const { uri, idContrat } = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => getSignatures(_.replace(uri, '{id}', idContrat))
    }
}

const SignaturesContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader()
)(Signatures)

export default SignaturesContainer
