import {connect} from 'react-redux'
import {compose} from "redux";
import FormHivernage from "./FormHivernage";
import {
    getDateEcheanceContract,
    getDernierHivernageContract,
    getIsEligibleHivernageContract,
    getCanActivateHivernageContract
} from "../../../../../../../../redux/selectors/contracts/contracts.selectors";
import _ from "lodash";
import {
    amendmentFinHivernageGetLink,
    amendmentHivernageGetLink,
    getContractLink
} from "../../../../../../../../redux/selectors/links/links.selectors";
import moment from 'moment'
import {
    getHivernagePost,
    getHivernageReduction,
    getLoadingEntity
} from "../../../../../../../../redux/selectors/ui/ui.selectors";
import {formValueSelector, getFormValues, reduxForm, isValid} from "redux-form";
import validate from "./validate";
import {
    getHivernageReduc,
    postFinHivernage,
    postHivernage
} from "../../../../../../../../redux/actions/app/contracts/avenants.contract.actions";

const mapStateToProps = (state, ownProps) => {
    const dernier_hivernage = getDernierHivernageContract(state);
    const echeance = getDateEcheanceContract(state);
    const formName = `hivernage-${ownProps.id_contrat}`
    const formNameFin = `hivernage-fin-${ownProps.id_contrat}`
    let maxDateEcheance = false;
    let finHivernagePeriode = moment('01/03', 'DD/MM');
    const selector = formValueSelector(formName);

    if(moment().startOf('day').isAfter(finHivernagePeriode)){
        finHivernagePeriode = finHivernagePeriode.add(1, 'year');
    }

    let maxDate = moment().add(120, 'day');
    if(dernier_hivernage && dernier_hivernage?.periodeCourante && dernier_hivernage.statutCode !== '1'){
        // si on a déjà eu une période hivernale terminée
        maxDate = moment().add((120 - dernier_hivernage.duree), 'day');
    }
    if(maxDate.isAfter(finHivernagePeriode)){
        maxDate = finHivernagePeriode;
    }
    if(!moment(echeance, 'YYYY/MM/DD').isSame(moment().startOf('day')) && maxDate.isAfter(moment(echeance, 'YYYY/MM/DD').subtract(1, 'day'))){
        //La date max ne peut pas dépasser le terme sauf si le terme est aujourd'hui
        maxDate = moment(echeance, 'YYYY/MM/DD').subtract(1, 'day')
        maxDateEcheance = true;
    }

    return {
        form: formName,
        formNameFin: formNameFin,
        uriContract: getContractLink(state),
        id_contrat: ownProps.id_contrat,
        query: amendmentHivernageGetLink(state),
        query_fin: amendmentFinHivernageGetLink(state),
        is_eligible_hivernage : getIsEligibleHivernageContract(state),
        can_activate_hivernage : getCanActivateHivernageContract(state),
        dernier_hivernage: dernier_hivernage,
        maxDate: maxDate,
        maxDateEcheance: maxDateEcheance,
        loading: getLoadingEntity(state, formName),
        loading_get_reduc: getLoadingEntity(state, formNameFin),
        loading_fin: getLoadingEntity(state, formNameFin),
        hivernagePost: getHivernagePost(state, formName),
        hivernagePostFin: getHivernagePost(state, formNameFin),
        hivernage_reduction: getHivernageReduction(state, formName),
        form_values: getFormValues(formName)(state),
        dateFin: selector(state, 'dateFin'),
        formIsValid: isValid(formName)(state),
        initialValues: {
            dateFin: maxDate,
        },
    }
}

const mapDispatchToProps = {
    postHivernage,
    postFinHivernage,
    getHivernageReduc
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { id_contrat, query, query_fin, form, formNameFin, form_values } = stateProps
    const { postHivernage, postFinHivernage, getHivernageReduc } = dispatchProps

    return {
        onSubmit: (formulaire) => postHivernage({query: _.replace(query, '{id}', id_contrat), form: formulaire, formName: form}),
        submitFinHivernage: () => postFinHivernage({query: _.replace(query_fin, '{id}', id_contrat), formName: formNameFin}),
        submitGetHivernageReduc: () => getHivernageReduc({query: _.replace(query, '{id}', id_contrat), form: form_values, formName: form}),
        ...ownProps, ...stateProps, ...dispatchProps
    }
}

const FormHivernageContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    reduxForm({
        validate,
    })
)(FormHivernage)

export default FormHivernageContainer