import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import loader from "../../../../../../../Commun/Spinners/Loader/Loader";
import Email from './Email'
import {change, reduxForm} from "redux-form";
import {getContractLink, emailPMQPostLinks} from "../../../../../../../../redux/selectors/links/links.selectors";
import _ from "lodash";
import {CONTRACT, fetchContract} from "../../../../../../../../redux/actions/app/contracts/contracts.actions";
import {getErrorEntity, getLoadingEntity} from "../../../../../../../../redux/selectors/ui/ui.selectors";
import {getContract} from "../../../../../../../../redux/selectors/contracts/contracts.selectors";
import {EMAIL, postEmail} from "../../../../../../../../redux/actions/app/email/email.actions";
import {getTiersData} from "../../../../../../../../redux/selectors/tiers/tiers.selectors";
import {getTiers} from "../../../../../../../../redux/actions/app/tiers/tiers.actions";

const mapStateToProps = (state, ownProps) => {
    const {idContract, idTiers} = ownProps.match.params

    return {
        idTiers,
        idContract,
        uriPostEmail: emailPMQPostLinks(state),
        uriContract: getContractLink(state),
        tiers: getTiersData(state),
        contract: getContract(state),
        loaded: getLoadingEntity(state, CONTRACT) === false,
        error: getErrorEntity(state, CONTRACT),
        loading: getLoadingEntity(state, EMAIL),
        is_send: getLoadingEntity(state, EMAIL) === false && getErrorEntity(state, EMAIL) === false,
        initialValues: {
            'text': 'Bonjour,',
        }
    }
}

const mapDispatchToProps = (dispatch, ownProps) => bindActionCreators({
    fetchContract: ({query, id}) => fetchContract({query, id}),
    getTiers: ({query, id}) => getTiers({query, id}),
    postEmail: ({form, query, tiers_id}) => postEmail({form, query, tiers_id}),
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {idEmail, uriContract, uriPostEmail, idContract, idTiers, contract} = stateProps
    const {fetchContract, postEmail} = dispatchProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => fetchContract({query: _.replace(uriContract, '{id}', idContract), id: idContract}),
        onSubmit: (form) => {
            form.contract_id = idContract
            form.tiers_id = idTiers
            form.profile = 'client'
            form.name = contract.souscripteur.firstname + ' ' + contract.souscripteur.lastname
            form.email = contract.souscripteur.email
            postEmail({form, query: _.replace(uriPostEmail, '{id}', idEmail), tiers_id: idTiers})
        }
    }
}

const EmailContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader(),
    reduxForm({
        form: 'send-email'
    })
)(Email)

export default EmailContainer
