import React from 'react';
import {Field} from "redux-form";
import TextFieldRedux from "../../../../../../../Commun/Inputs/Text/TextFieldRedux";
import Card from "../../../../../../../Commun/Card/Card";
import SmallSpinner from "../../../../../../../Commun/Spinners/SmallSpinner";
import SelectFieldRedux from "../../../../../../../Commun/Inputs/Select/SelectFieldRedux";

const SendSignatures = (props) => {
    const { handleSubmit, loading, method, history } = props

    return (
        <div className={'p-4'}>
            <Card>
                <div>
                    <h3 className={'m-0 pb-3 border-bottom border-dark'}>
                        Renvoyer <span className="text-color-secondary">la procédure de signature</span> à votre client
                    </h3>

                    <div className="mt-5">
                        <span className={'btn btn-outline-secondary'} onClick={() => history.goBack()}><i className={'fas fa-chevron-left mr-3'}/> Retour</span>
                    </div>

                    <div className="mt-5 col-lg-6 col-md-12 mx-auto">
                        <form className={'form-content'} onSubmit={handleSubmit} >
                            <Field
                                name={'method'}
                                component={SelectFieldRedux}
                                label={'Envoyer la procédure par'}
                            >
                                <option value={null}>Sélectionner</option>
                                <option value={`email`}>Email</option>
                                <option value={`sms`}>SMS</option>
                            </Field>
                            {
                                method === 'email' &&
                                <Field
                                    name='email'
                                    component={TextFieldRedux}
                                    label={'Adresse email'}
                                    required
                                />
                            }

                            {
                                method === 'sms' &&
                                <Field
                                    name='phone'
                                    component={TextFieldRedux}
                                    label={'Téléphone mobile'}
                                    required
                                />
                            }

                            <div className="text-center">
                                {
                                    loading
                                        ? <SmallSpinner />
                                        : <button className={'btn btn-primary'}>Envoyer la procédure</button>
                                }
                            </div>
                        </form>
                    </div>
                </div>
            </Card>
        </div>
    );
};

export default SendSignatures;
