import React, {Component} from 'react'
import Autosuggest from 'react-autosuggest'
import AutosuggestHighlightMatch from 'autosuggest-highlight/match';
import AutosuggestHighlightParse from 'autosuggest-highlight/parse';
import _ from 'lodash'
import './autocomplete.scss'

/* Wait data = [{label: 'stringLabel', value: 'stringValue'}] */

// https://developer.mozilla.org/en/docs/Web/JavaScript/Guide/Regular_Expressions#Using_Special_Characters
const escapeRegexCharacters = str => {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

const getSuggestionValue = suggestion => {
    return `${suggestion.label}`;
}

const renderSuggestion = (suggestion, { query }) => {
    const suggestionText = `${suggestion.label}`;
    const matches = AutosuggestHighlightMatch(suggestionText, query);
    const parts = AutosuggestHighlightParse(suggestionText, matches);

    return (
        <span className={'suggestion-content ' + suggestion.twitter}>
      <span className="name">
        {
            parts.map((part, index) => {
                const className = part.highlight ? 'highlight' : null;

                return (
                    <span className={className} key={index}>{part.text}</span>
                );
            })
        }
      </span>
    </span>
    );
}

class Autocomplete extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: this.props.defaultValue || '',
            suggestions: [],
            setQuery: this.props.setQuery || false,
        };
    }

    getSuggestions(value){
        const escapedValue = escapeRegexCharacters(value.trim());

        if (escapedValue === '') {
            return [];
        }

        const regex = new RegExp('\\b' + escapedValue, 'i');

        return _.slice(this.props.data.filter(item => regex.test(getSuggestionValue(item))), 0, 10);
    }

    onChange = (event, { newValue, method }) => {
        this.setState({
            value: newValue
        });

        if (this.state.setQuery) {
            this.props.setQuery(newValue);
        }

        const item = _.find(this.state.suggestions, ['label', newValue])

        if (item){
            this.props.input.onChange(item.value)
        }
    };

    onSuggestionsFetchRequested = ({ value }) => {
        this.setState({
            suggestions: this.getSuggestions(value)
        });
    };

    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };

    render() {
        const { value = false, suggestions } = this.state;

        const inputProps = {
            placeholder: this.props.placeholder || '',
            value,
            onChange: this.onChange,
        };

        return (
            <div className={'form-group'}>
                <label>
                    {this.props.label}
                </label>
                <Autosuggest
                    suggestions={suggestions}
                    onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                    onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                    getSuggestionValue={getSuggestionValue}
                    renderSuggestion={renderSuggestion}
                    inputProps={inputProps} />
            </div>
        );
    }
}

export default Autocomplete
