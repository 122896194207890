import moment from "moment/moment";
const validate = values => {
    const errors = {}
    let requiredFields = [
        'dateFin',
    ];
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Le champ est obligatoire'
        }
    })
    if (values['dateFin'] && moment(values['dateFin']).isSameOrBefore(moment().startOf('day'))) {
        errors['dateFin'] = 'La durée de Ma période hivernale doit être d\'au moins une journée'
    }
    return errors
}

export default validate;