import React from 'react';
import InitFormContainer from "./InitForm/InitFormContainer";
import GetFleetForInitFormContainer from "./InitForm/GetFleetForInitForm/GetFleetForInitFormContainer";

const AccidentForm = (props) => {
    const { match: { params: { idVehicule } }, isFleet } = props

    return (
        <div>
            { isFleet ? <GetFleetForInitFormContainer idVehicule={idVehicule} /> : <InitFormContainer idVehicule={idVehicule} /> }
        </div>
    );
};

export default AccidentForm;
