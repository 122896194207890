import React, {useEffect, useState} from 'react';
import Card from "../../../../../../../Commun/Card/Card";
import _ from 'lodash'
import {useHistory} from "react-router-dom";
import Cadre from "../../../../../../../Commun/Cadre/Cadre";
import PostalCodeCity from "../../../../../../../Commun/Inputs/PostalCodeCity/PostalCodeCity";
import {Field} from "redux-form";
import TextFieldRedux from "../../../../../../../Commun/Inputs/Text/TextFieldRedux";
import CheckboxFieldRedux from "../../../../../../../Commun/Inputs/Checkbox/CheckboxFieldRedux";
import SmallSpinner from "../../../../../../../Commun/Spinners/SmallSpinner";
import {normalizeBooleanForNoobBelair} from "../../../../../../../../utils/normalize";
import {getAddress} from "../../../../../../../../utils/function";

const ChangeAddress = (props) => {
    const { data, onSubmit, address, ease, changeValue, newRue, newAddr, loading, contract, handleSubmit, form, sansStationnement, addressNotFound } = props
    const [stationnement, setStationnement] = useState(true)
    const [ listAddresses, setListAddresses ] = useState([])
    const [ addressSelected, setAddressSelected ] = useState(null)
    const [ defaultvaluePostalCodeCity, setDefaultvaluePostalCodeCity ] = useState(null)
    const [ adresseNewIsTooLong, setAdresseNewIsTooLong ] = useState(null)
    const [hasSearchAddress, setHasSearchAddress] = useState(false)
    const [localityId, setLocalityId] = useState(null);

    const history = useHistory()

    useEffect(() => {
        changeValue('codePostalA', contract.vehicule.lieuStationnementCodePostal)
        changeValue('villeA', contract.vehicule.lieuStationnementVille)
        changeValue('codeProduit', contract.vehicule.codeProduit)
        changeValue('contrat', contract.id)
        changeValue('sansStationnement', (contract.vehicule.codeProduit === "7311_VAE"))
        changeValue('assuranceCorse', (contract.vehicule.codeProduit !== "7311_VAE"))
        changeValue('new_adress_is_new_lieuStationnement', "T")
    }, [contract, changeValue])

    useEffect(() => {
        if(_.get(data, 'villeNew') && _.get(data, 'codePostalNew')){
            fetchAddress(`${_.get(data, 'villeNew')} ${_.get(data, 'codePostalNew')}`, 'city')
        }
    }, [_.get(data, 'villeNew'), _.get(data, 'codePostalNew')]);


    const fetchAddress = async (value, where) => {
        setAddressSelected(null);
        let addressParams;
        if (where === 'city') {
            addressParams = [value, 'Undefined'];
        } else {
            addressParams = [
                value,
                'Undefined',
                localityId,
                _.get(data, 'codePostalNew'),
                _.get(data, 'villeNew')
            ];
        }
        let address = await getAddress(...addressParams);
        if (where === 'city') {
            setLocalityId(address[0].localityId);
            setListAddresses([])
            const adresseFields = ['adresseNew', 'adresse2New', 'adresse3New'];

            adresseFields.forEach(field => {
                changeValue(field, null);
            });
        } else {
            setListAddresses(address?.slice(0, 15));
        }
        setHasSearchAddress(true);

        if (value.length > 31){
            changeValue('addressNotFound', true)
            setAdresseNewIsTooLong(true)
        } else {
            setAdresseNewIsTooLong(false)
        }
    };

    const selectAddr = (address) => {
        setAddressSelected(address)
        changeValue('adresseNew', address.additionalInfo_1)
        changeValue('adresse3New', address.buildingName || '')
        setDefaultvaluePostalCodeCity(`${address.postalCode} ${address.localitySynonym || address.locality}`)
    }

    const myOnSubmit = (form) => {
        if(hasSearchAddress){
            getAddress(_.get(form, 'adresseNew'), "ValidateForm")
        }
        onSubmit(form)
    }

    return (
        <div className={'p-lg-4'}>
            <Card>
                <div>
                    <h3 className={'m-0 pb-3 border-bottom border-dark'}>
                        Modifier <span className="text-color-secondary">L'adresse postale</span> de votre client
                    </h3>

                    <div className="mt-5">
                        <span className={'btn btn-outline-secondary'} onClick={() => history.goBack()}><i
                            className={'fas fa-chevron-left mr-3'}/> Retour</span>
                    </div>
                    <div className="mt-5 col-lg-6 col-md-12 mx-auto">
                        {
                            ease.changeAddress && ease.changeAddress.formName === form && !sansStationnement
                                ?
                                ease.changeAddress.data &&
                                <Cadre icon={'fas fa-exclamation-triangle'} title={'ATTENTION !'}  color={'secondary'}>
                                    <div className="mt-3 col-lg-12 col-md-12 mx-auto text-justify">
                                        <p>L'adresse que vous venez de valider correspond à une nouvelle zone de
                                            tarification.</p>
                                        <p>Le formulaire d'avenant s'est ouvert dans un nouvel onglet. Merci de le compléter
                                            avec la nouvelle adresse pour que le client reçoive ses nouvelles Dispositions
                                            Particulières.</p>

                                        <a href={ease.changeAddress.data} className={'btn btn-outline-secondary max-rounded'} target={'_blank'} rel="noopener noreferrer">Cliquez ici si la fenêtre ne s'ouvre pas</a>
                                    </div>
                                </Cadre>

                                :
                                <Cadre icon={'fa fa-id-card'} title={'Informations actuelles'} large>
                                    <div className="mt-5 mt-lg-0">
                                        <p className={'max-width-4'}>Adresse : <b
                                            className={'float-right'}>{address.rue1}</b></p>
                                        <p className={'max-width-4'}>Ville, Code Postal : <b
                                            className={'float-right'}>{address.ville}, {address.codePostal}</b>
                                        </p>
                                    </div>
                                </Cadre>
                        }

                    </div>

                    { ease.changeAddress && ease.changeAddress.formName === form
                        ?
                        !ease.changeAddress.data && <div className="mt-5 col-lg-6 col-md-12 mx-auto">
                            <Cadre icon={'fa fa-id-card'} title={'Les nouvelles informations'} large>
                                <p className={'max-width-7'}>Nouvelle adresse : <b className={'float-right'}>{newRue}</b></p>
                                {!sansStationnement && <p className={'max-width-7'}>Nouvelle adresse de stationnement : <b className={'float-right'}>{newAddr}</b></p>}
                            </Cadre>
                        </div>
                        : <form onSubmit={handleSubmit(form => myOnSubmit(form))} className={'mt-5 col-lg-6 col-md-12 mx-auto'}>

                            <PostalCodeCity
                                nameCodePostal='codePostalNew'
                                nameVille='villeNew'
                                label={'Code postal, ville : (Ex: 75011 PARIS)'}
                                changeValue={changeValue}
                                id={'address'}
                                defaultValue={defaultvaluePostalCodeCity}
                            />


                            <Field
                                name='adresseNew'
                                component={TextFieldRedux}
                                label={'Nouvelle adresse : (Ex: ENTREE F BATIMENT 5 37 BIS RUE DE LA GARE 75011 PARIS)'}
                                normalize={changeAddress => changeAddress.toUpperCase()}
                                onChange={(e) => fetchAddress(e.target.value)}
                            />

                            <Field
                                component={CheckboxFieldRedux}
                                name={'addressNotFound'}
                                label={'Je ne trouve pas mon adresse'}
                            />

                            {
                                adresseNewIsTooLong && <div className="alert alert-warning mt-3" role="alert">
                                    <p>Attention, l'adresse dépasse le nombre de caractère autorisé. (31) <br/> Merci de rentrer manuellement l'adresse et d'utiliser le complément d'adresse.</p>
                                </div>
                            }

                            {
                                !adresseNewIsTooLong && !addressNotFound && !addressSelected && _.map(listAddresses, (adresse, i) => (
                                    <div key={i} onClick={() => selectAddr(adresse)} className={"list-group"}>
                                        <p className={'cursor-pointer m-0 list-group-item list-group-item-action'}>{adresse.inputOutput}</p>
                                    </div>
                                ))
                            }


                            {
                                addressSelected && !addressNotFound && <>
                                    <Field
                                        name='adresse2New'
                                        component={TextFieldRedux}
                                        label={"Complément d'adresse : (Ex: Appartement 25)"}
                                        normalize={changeAddress => changeAddress.toUpperCase()}
                                    />

                                    <Field
                                        name='adresse3New'
                                        component={TextFieldRedux}
                                        label={"Bâtiment, escalier, étage : (Ex: ENTREE F BATIMENT 5)"}
                                        normalize={changeAddress => changeAddress.toUpperCase()}
                                        disabled
                                    />
                                </>
                            }

                            {
                                addressNotFound && <>
                                    <Field
                                        name='adresse2New'
                                        component={TextFieldRedux}
                                        label={"Complément d'adresse : (Ex: Appartement 25)"}
                                        normalize={changeAddress => changeAddress.toUpperCase()}
                                    />

                                    <Field
                                        name='adresse3New'
                                        component={TextFieldRedux}
                                        label={"Batiment, escalier, étages : (Ex: ENTREE F BATIMENT 5)"}
                                        normalize={changeAddress => changeAddress.toUpperCase()}
                                    />
                                </>
                            }

                            {!sansStationnement &&
                            <>
                                <Field
                                    component={CheckboxFieldRedux}
                                    name={'new_adress_is_new_lieuStationnement'}
                                    label={'Cette adresse est également l\'adresse de stationnement'}
                                    onChange={() => setStationnement(!stationnement)}
                                    normalize={normalizeBooleanForNoobBelair}
                                />

                                {
                                    !stationnement && <PostalCodeCity
                                        nameCodePostal='cp_new_lieuStationnement'
                                        nameVille='ville_new_lieuStationnement'
                                        label={'Code postal, ville de stationnement : (si différent de l\'adresse postale)'}
                                        changeValue={changeValue}
                                        id={'lieuStationnement'}
                                    />
                                }
                            </>
                            }

                            <div className="text-center">
                                {
                                    loading
                                        ? <SmallSpinner/>
                                        : <button
                                            className={`btn btn-primary max-rounded ${adresseNewIsTooLong && 'disabled'}`}
                                            onClick={(e) => adresseNewIsTooLong ? e.preventDefault() : null}
                                        >
                                            Je valide la nouvelle adresse
                                        </button>
                                }
                            </div>

                        </form>
                    }
                    {
                        !ease.changeAddress || (ease.changeAddress && !ease.changeAddress.data) ?
                            <div className="mt-5 col-lg-6 col-md-12 mx-auto">
                                <div className="alert alert-warning mt-5 mb-0 medium-rounded">
                                    <p><i className="fas fa-exclamation-triangle"/> Attention, cette modification d'adresse ne concerne que le contrat :</p>
                                    <p>- N°{contract.numeroPolice}, {contract.title}</p>
                                    <p className={'mt-1'}>Si votre client possède plusieurs contrats, merci de faire la
                                        modification d'adresse pour chaque contrat.</p>
                                </div>
                            </div>
                            : null
                    }

                </div>
            </Card>
        </div>
    )
}

export default ChangeAddress;
