export const CHANGE_FRACTIONNEMENT = '[Contract Accidents]'

export const POST_CHANGE_FRACTIONNEMENT = `${CHANGE_FRACTIONNEMENT} Post`

export const postChangeFractionnementContract = ({query, form, formName}) => ({
    type: POST_CHANGE_FRACTIONNEMENT,
    payload: {
        data: query,
        body: form,
        meta: {createChrono: form.createChrono, formName}
    }
})
