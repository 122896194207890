

export const TIERS = '[Tiers]'

export const GET_TIERS = `${TIERS} Get`
export const SET_TIERS = `${TIERS} Set`
export const POST_UPDATE_BIRTH_INFOS_TIERS = `${TIERS} Post update birth infos`

export const getTiers = ({query, id}) => ({
    type: GET_TIERS,
    payload: {
        data: query,
        otherData: {
            id: id
        }
    }
})

export const setTiers = ({data}) => ({
    type: SET_TIERS,
    payload: {data}
})

export const postUpdateBirthInfosTiers = ({query, form, formName}) => ({
    type: POST_UPDATE_BIRTH_INFOS_TIERS,
    payload: {
        data: query,
        body: (form),
        meta: formName
    }
})
