export const POPUP = '[Popup]'

export const POST_POPUP = `${POPUP} Post`

export const postPopup = ({query, form, confirmMessage}) => ({
    type: POST_POPUP,
    payload: {
        data: query,
        body: form,
        otherData:{
            confirmMessage
        }
    }
})
