export const DOCUMENTS_ACTIVE = '[Documents Active]'
export const DOCUMENTS_INACTIVE = '[Documents Inactive]'

export const FETCH_DOCUMENTS_ACTIVE = `${DOCUMENTS_ACTIVE} Fetch`
export const SET_DOCUMENTS_ACTIVE = `${DOCUMENTS_ACTIVE} Set`
export const FETCH_DOCUMENTS_INACTIVE = `${DOCUMENTS_INACTIVE} Fetch`
export const SET_DOCUMENTS_INACTIVE = `${DOCUMENTS_INACTIVE} Set`

export const fetchDocumentsActive = ({query}) => ({
    type: FETCH_DOCUMENTS_ACTIVE,
    payload: {
        data: query
    }
})

export const setDocumentsActive = ({data}) => ({
    type: SET_DOCUMENTS_ACTIVE,
    payload: {
        data
    }
})

export const fetchDocumentsInactive = ({query}) => ({
    type: FETCH_DOCUMENTS_INACTIVE,
    payload: {
        data: query
    }
})

export const setDocumentsInactive = ({data}) => ({
    type: SET_DOCUMENTS_INACTIVE,
    payload: {
        data
    }
})
