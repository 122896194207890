export const TERMINATE = '[Terminate]'

export const POST_TERMINATE = `${TERMINATE} Post`

export const postTerminate = ({query, form, otherData}) => ({
    type: POST_TERMINATE,
    payload: {
        data: query,
        body: form,
        otherData
    }
})
