import {connect} from 'react-redux'
import Documents from "./Documents";
import {formValueSelector} from "redux-form";
import {getEaseUi} from "../../../../../../../../../../redux/selectors/ui/ui.selectors";

const mapStateToProps = state => {
    const selector = formValueSelector('declaration-sinistre')

    return {
        categorie: selector(state, 'catsinorigin'),
        listDocuments: getEaseUi(state).documentsAccident
    }
}

const DocumentsContainer = connect(mapStateToProps)(Documents)

export default DocumentsContainer
