import React, {useEffect} from 'react';
import {Field} from "redux-form";
import TextFieldRedux from "../../../../../../../../../Commun/Inputs/Text/TextFieldRedux";
import RadioDesignButton from "../../../../../../../../../Commun/Inputs/Radio/RadioDesignButton/RadioDesignButton";

const FieldArrayBlesses = (props) => {
    const { fields } = props

    useEffect(() => {
        fields.length === 0 && fields.push({})
    }, [fields])

    return (
        <div>
            {
                fields.map((v, i) => (
                    <div key={i} className={'border border-color-secondary p-3 my-3'}>
                        <p className="h4">
                            Victime n°{i + 1}
                            <i
                                className={'ml-2 fas fa-trash-alt text-color-secondary cursor-pointer hover-dark-color'}
                                onClick={() => fields.remove(i)}
                            />
                        </p>

                        <Field
                            name={`${v}.nom`}
                            component={TextFieldRedux}
                            label='Nom'
                        />

                        <Field
                            name={`${v}.prenom`}
                            component={TextFieldRedux}
                            label='Prénom'
                        />

                        <Field
                            name={`${v}.type_victime`}
                            component={RadioDesignButton}
                            label={'Situation'}
                            data={[
                                {value: "P", label:"Passager"},
                                {value: "N", label:"Passager adverse"},
                                {value: "W", label:"Piéton"},
                                {value: "V", label:"Cycliste"}
                            ]}
                        />

                    </div>
                ))
            }
            <div className={'text-right'}>
                <span className={'btn btn-primary'} onClick={() => fields.push({})}>Ajouter une victime</span>
            </div>
        </div>
    );
};

export default FieldArrayBlesses;
