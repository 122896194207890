import React from 'react';
import _ from 'lodash'

const DocumentsActive = (props) => {
    const { active } = props

    return (
        <div>
            {
                Object.keys(active).map(key =>(
                    <div key={key} className="row mt-5">
                        <div className="col-lg-2 col-md-12">
                            <span className={'h4'}><i className="fas fa-file-pdf mr-2 p-2 text-white rounded-circle text-center background-color-primary" style={{width: 40}}/>{key}</span>
                        </div>

                        <div className={"col-lg-10 col-md-12"}>
                            {
                                _.map(active[key], (a, index) => (
                                    <a key={a.id} className={`text-white btn btn-secondary max-rounded d-inline-block mt-1 mr-lg-5`} href={a.url} target={'_blank'} rel="noopener noreferrer">
                                        <i className="fas fa-file-pdf mr-2" />
                                        {a.title}
                                    </a>
                                ))
                            }
                        </div>
                    </div>
                ))
            }
        </div>
    );
};

export default DocumentsActive;

