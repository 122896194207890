import React from 'react';
import {Redirect} from "react-router-dom";
import LargeSpinner from "../../Commun/Spinners/LargeSpinner/LargeSpinner";

const LoginAprilOn = (props) => {
    const { login, action, id, erreur, user, idWhitelabel } = props

    if (login && idWhitelabel){
        switch (action) {
            case 'souscriptions':
                if (user.bu_children.length === 0){
                    window.location = user.subscribe_links[id]
                    return null
                } else {
                    return <Redirect to={`/mon-formulaire/${id}`} />
                }
            case 'collection':
            case 'campingcar':
                return <Redirect to={`/mon-formulaire/collection`} />
            case 'trottinette':
                if (user.bu_children.length === 0){
                    window.location = user.subscribe_links[action]
                    return null
                } else {
                    return <Redirect to={`/mon-formulaire/${action}`} />
                }
            case 'contrats':
                return id ? <Redirect to={`/mon-contrat/${id}`} /> : <Redirect to={'/mon-portefeuille'} />
            case 'devis':
                return id ? <Redirect to={`/mon-devis/${id}/reprise`} /> : <Redirect to={'/mes-devis'} />
            default:
                return <Redirect to={`/${action ? action : ''}${id ? `/${id}` : ''}`} />
        }
    }

    if (erreur){
        return <Redirect to={'/bienvenue'} />
    }

    return (
        <LargeSpinner />
    );
};

export default LoginAprilOn;
