import {connect} from 'react-redux'
import DeclarationBis from "./DeclarationBis";
import {
    getCylindreeContract, getFleetContract,
    getGarantiesContract, getIsFleetContract, getIsWakamContract
} from "../../../../../../../../../../redux/selectors/contracts/contracts.selectors";
import {formValueSelector} from "redux-form";

const mapStateToProps = state => {
    const selector = formValueSelector('declaration-sinistre')

    return {
        categorie: selector(state, 'catsinorigin'),
        vehiculeDommage: selector(state, 'vehiculeDommage'),
        type_sinistre: selector(state, 'type_sinistre'),
        vehiculeIsAttached: selector(state, "vehiculeIsAttached"),
        antiVolMecanique: selector(state, "antiVolMecanique"),
        garanties: !getIsFleetContract(state) ? getGarantiesContract(state) : getFleetContract(state).garanties,
        blesse: selector(state, 'blesse'),
        indentify: selector(state, 'indentify'),
        temoin: selector(state, 'temoin'),
        sraCertified: selector(state, 'sraCertified'),
        cylindree: getCylindreeContract(state),
        isWakam: getIsWakamContract(state),
        moreDamages: selector(state, 'moreDamages'),
        corpoconduct: selector(state, 'corpoconduct'),
        document_police: selector(state, 'document_police'),
        catsin: selector(state, 'catsinorigin'),
        equipementsDommage: selector(state, 'equipementsDommage'),
        equipementsDommageAutre: selector(state, 'equipementsDommageAutre')
    }
}

const DeclarationBisContainer = connect(mapStateToProps)(DeclarationBis)

export default DeclarationBisContainer
