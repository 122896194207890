import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import Profil from "./Profil";
import loader from '../../../../Commun/Spinners/Loader/Loader'
import {
    getUserData,
    getUserIsConcessionnaire
} from "../../../../../redux/selectors/user/user.selectors";
import {setProfil} from "../../../../../redux/actions/app/profil/profil.actions";
import {getBusinessUnitProfil} from "../../../../../redux/selectors/profil/profil.selectors";

const mapStateToProps = state => {
    return {
        isConcessionnaire: getUserIsConcessionnaire(state),
        buAlrearyInState: getBusinessUnitProfil(state),
        business_unit: getUserData(state)
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    setBuProfil: ({user}) => setProfil({user})
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { business_unit, buAlrearyInState } = stateProps
    const { setBuProfil } = dispatchProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => buAlrearyInState.name ? false : setBuProfil({user: business_unit})
    }
}

const ProfilContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader()
)(Profil)

export default ProfilContainer
