import React, {useState} from 'react';
import PropTypes from 'prop-types'
import ImpayesTableContainer from "./ImpayesTable/ImpayesTableContainer";
import Card from "../../../../../Commun/Card/Card";
import APILink from "../../../../../Commun/APILink/APILink";
import moment from "moment/moment";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";

const Impayes = (props) => {
    const { query, fetchImpayes, links, meta, filters } = props
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)

    const changeDate = (startDate, endDate) => {
        setStartDate(startDate)
        setEndDate(endDate)

        startDate && endDate && endDate._isValid && fetchImpayes({
            query,
            filters: {
                ...filters,
                dateDebut: moment(startDate).format('YYYY-MM-DD'),
                dateFin: moment(endDate).format('YYYY-MM-DD')
            }
        })
    }

    return (
        <div className={'p-lg-4'}>
            <Card>
                <div>
                    <h3 className={'m-0 pb-3 border-bottom border-dark'}>
                        Vous avez <span className={'text-color-secondary'}>{meta.total} quittances</span> à régulariser
                    </h3>

                    <div className="row mt-5">
                        <div className="col">
                            <label>Période :</label>
                            <div className={'mt-3'}>
                                <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="fr">
                                    <DatePicker
                                        className={'mr-4'}
                                        label='Date de début'
                                        value={startDate}
                                        onChange={(newValue) => {
                                            changeDate(newValue, endDate);
                                        }}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>

                                <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="fr">
                                    <DatePicker
                                        label='Date de fin'
                                        value={endDate}
                                        onChange={(newValue) => {
                                            changeDate(startDate, newValue);
                                        }}
                                        renderInput={(params) => <TextField {...params} />}
                                        minDate={startDate}
                                    />
                                </LocalizationProvider>
                            </div>
                        </div>
                        <div className="col-lg-4 col">
                            <label>Afficher les quittances à régulariser :</label>
                            <select className="form-control my-4" onChange={(e) => fetchImpayes({filters: {...filters, status: e.target.value}, query})}>
                                <option value={'all'}>Toutes les quittances impayées</option>
                                <option value={'misendemeure'}>Quittances mises en demeure</option>
                                <option value={'contentieux'}>Quittances au contentieux</option>
                            </select>
                        </div>
                        <div className="col-lg-3 mt-auto text-right">
                            <APILink href={links.export} download rel="noopener noreferrer" className={'btn btn-ternary max-rounded d-block d-lg-inline-block mt-3 mt-lg-0'}>
                                Télécharger les quittances
                            </APILink>
                        </div>
                    </div>

                    <ImpayesTableContainer />

                </div>
            </Card>
        </div>
    );
};

Impayes.defaultProps = {
    query: '#',
    fetchImpayes: () => {},
    links: {},
    meta: {}
};

Impayes.propTypes = {
    query: PropTypes.string,
    fetchImpayes: PropTypes.func,
    links: PropTypes.object,
    meta: PropTypes.object
};

export default Impayes;
