import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import _ from 'lodash'
import ActivitiesContract from "./ActivitiesContract";
import {getCustomerActivityLogsLink} from "../../../../../../../redux/selectors/links/links.selectors";
import {
    getActivitiesContract,
    getIdSouscripteurContract
} from "../../../../../../../redux/selectors/contracts/contracts.selectors";
import {
    ACTIVITIES_CONTRACT,
    fetchActivitiesContract
} from "../../../../../../../redux/actions/app/contracts/activities.contract.actions";
import {getErrorEntity, getLoadingEntity} from "../../../../../../../redux/selectors/ui/ui.selectors";
import loader from "../../../../../../Commun/Spinners/Loader/Loader";

const mapStateToProps = state => {
    return {
        activities: getActivitiesContract(state),
        query: _.replace(getCustomerActivityLogsLink(state), '{id}', getIdSouscripteurContract(state)),
        loaded: getLoadingEntity(state, ACTIVITIES_CONTRACT) === false,
        error: getErrorEntity(state, ACTIVITIES_CONTRACT)
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    fetchActivitiesContract: ({query}) => fetchActivitiesContract({query})
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { query } = stateProps
    const { fetchActivitiesContract } = dispatchProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => fetchActivitiesContract({query})
    }
}

const ActivitiesContractContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader()
)(ActivitiesContract)

export default ActivitiesContractContainer
