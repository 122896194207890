import {connect} from 'react-redux'
import Gestion from './Gestion'
import {
    getAccidentsOpened, getAccidentWaiting, getChuteAfn,
    getContractsDocumentWaiting,
    getContractsNewWaiting,
    getContractsWaitingStats, getTotalAfn
} from "../../../../../redux/selectors/stats/stats.selectors";

const mapStateToProps = state => {
    return {
        total: getContractsWaitingStats(state),
        newAfnWaiting: getContractsNewWaiting(state),
        waitingDocument: getContractsDocumentWaiting(state),
        accidentsOpen: getAccidentsOpened(state),
        accidentsWaiting: getAccidentWaiting(state),
        totalAfn: getTotalAfn(state),
        chuteAfn: getChuteAfn(state)
    }
}

const GestionContainer = connect(mapStateToProps)(Gestion)

export default GestionContainer
