import React from 'react';
import Card from "../../../../../Commun/Card/Card";
import Cadre from "../../../../../Commun/Cadre/Cadre";
import BrochurePDFCollection from '../../../../../../assets/pdf/collection/Brochure_ACL_2021_VF.pdf'

const Collection = (props) => {
    const { subscribeLinks } = props

    return (
        <div className={'p-lg-4'}>
            <Card>
                <div>
                    <h3 className={'m-0 pb-3 border-bottom border-dark'}>
                        Choisissez une offre <span className={'text-color-secondary'}>APRIL Collection & loisirs</span>
                    </h3>
                </div>

                <p className="mt-4">Fidélisez vos clients et développez votre portefeuille.</p>

                <p className="mt-4">APRIL Collection & Loisirs, c'est une <b>garantie de compétences et de services spécifiquement dédiés à l'assurance des véhicules de collection et de loisirs.</b></p>

                <div className={'row'}>
                    <div className="col-lg-6 col-md-12 mt-5">
                        <Cadre icon={'fas fa-info'} title={'Moto & Auto de collection'} large>
                            <div className="mt-5 mt-lg-0">
                                <p className={'pl-5'}><i className={'fas fa-circle text-color-secondary mr-2'} /> Véhicules de plus de 9 ans</p>
                                <p className={'pl-5'}><i className={'fas fa-circle text-color-secondary mr-2'} /> Usage loisir</p>
                                <p className={'pl-5'}><i className={'fas fa-circle text-color-secondary mr-2'} /> Cartes grises normales acceptées</p>
                                <p className={'pl-5'}><i className={'fas fa-circle text-color-secondary mr-2'} /> Pas de limitation kilométrique ni géographique</p>
                                <p className={'pl-5'}><i className={'fas fa-circle text-color-secondary mr-2'} /> Prêt de véhicule, pas de conducteur désigné</p>
                                <p className={'pl-5'}><i className={'fas fa-circle text-color-secondary mr-2'} /> Formule flotte dès le deuxième véhicule</p>

                                <div className={'text-center mt-4'}>
                                    <a href={subscribeLinks.collection} className={'btn btn-primary max-rounded'}>
                                        Je fais ou reprends un devis
                                    </a>
                                </div>
                            </div>
                        </Cadre>
                    </div>
                    <div className="col-lg-6 col-md-12 mt-5">
                        <Cadre icon={'fas fa-info'} title={'Camping-car'}>
                            <div className="mt-5 mt-lg-0">
                                <p className={'pl-5'}><i className={'fas fa-circle text-color-secondary mr-2'} /> 3 formules : Tiers, Tiers + Vol/Incendie, Tous Risques</p>
                                <p className={'pl-5'}><i className={'fas fa-circle text-color-secondary mr-2'} /> Pour tout camping-car et fourgon aménagé, y compris les plus de 3,5 tonnes</p>
                                <p className={'pl-5'}><i className={'fas fa-circle text-color-secondary mr-2'} /> Garantie du conducteur</p>
                                <p className={'pl-5'}><i className={'fas fa-circle text-color-secondary mr-2'} /> Assistance véhicule et personnes 24h/24</p>
                                <p className={'pl-5'}><i className={'fas fa-circle text-color-secondary mr-2'} /> Contenu du véhicule (objets et effets personnels) jusqu'à 3 050€</p>
                                <p className={'pl-5'}><i className={'fas fa-circle text-color-secondary mr-2'} /> Equipement du véhicule (panneau solaire, climatiseur, parabole..) jusqu'à 10% de la valeur du véhicule avec un plafond de 10 000€ par événement</p>

                                <div className={'text-center mt-4'}>
                                    <a href={subscribeLinks.campingcar} className={'btn btn-primary max-rounded'}>
                                        Je fais ou reprends un devis
                                    </a>
                                </div>
                            </div>
                        </Cadre>
                    </div>
                </div>

                <div className="text-center mt-5">
                    <a href={BrochurePDFCollection} target={'_blank'} rel="noopener noreferrer" className={'btn btn-ternary max-rounded'}>
                        Je télécharge la brochure produit
                    </a>
                </div>

                <div className="text-center mt-5">
                    <p className={'text-color-secondary font-weight-bold'}><i className={'fas fa-question-circle'}/> Besoin d'un conseil ?</p>
                    <p className={'font-weight-bold'}>Appelez-nous au 09 70 77 71 13</p>
                </div>

            </Card>
        </div>
    );
};

export default Collection;
