import {SET_CONTRACT} from "../../actions/app/contracts/contracts.actions";
import {SET_DOCUMENTS_CONTRACT} from "../../actions/app/contracts/documents.contract.actions";
import {SET_ACCIDENTS_CONTRACT} from "../../actions/app/contracts/accidents.contract.actions";
import {SET_QUITTANCES_CONTRACT} from "../../actions/app/contracts/quittances.contract.actions";
import {SET_ACTIVITIES_CONTRACT} from "../../actions/app/contracts/activities.contract.actions";
import {SET_FLEET_CONTRACT} from "../../actions/app/contracts/fleet.contract.actions";

export const contractsReducer = (contracts = {}, action = {}) => {
    const {payload} = action

    switch (action.type) {
        case SET_CONTRACT:
            return payload.data
        case SET_DOCUMENTS_CONTRACT:
            return {...contracts, documents: payload.data}
        case SET_ACCIDENTS_CONTRACT:
            return {...contracts, accidents: payload.data}
        case SET_QUITTANCES_CONTRACT:
            return {...contracts, quittances: payload.data}
        case SET_ACTIVITIES_CONTRACT:
            return {...contracts, activities: payload.data}
        case SET_FLEET_CONTRACT:
            return {...contracts, fleet: payload.data}
        default:
            return contracts
    }
}
