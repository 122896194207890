import {SET_PUBLICITES} from "../../actions/app/publicites/publicites.actions";

export const publicitesReducer = (publicites = {}, action = {}) => {
    const {payload} = action

    if (action.type === SET_PUBLICITES) {
        return payload.data
    } else {
        return publicites
    }
}
