import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import {change} from 'redux-form'
import UploadInline from "./UploadInline";

const mapDispatchToProps = (dispatch, ownProps) => bindActionCreators({
    changeValue: (field, value) => change(ownProps.formName, field, value)
}, dispatch)

const UploadInlineContainer = connect(null, mapDispatchToProps)(UploadInline)

export default UploadInlineContainer
