import React from 'react';
import PropTypes from 'prop-types'
import {Field} from "redux-form";
import _ from 'lodash'
import TextFieldRedux from "../../../../../../Commun/Inputs/Text/TextFieldRedux";
import SelectFieldRedux from "../../../../../../Commun/Inputs/Select/SelectFieldRedux";
import CheckboxFieldRedux from "../../../../../../Commun/Inputs/Checkbox/CheckboxFieldRedux";
import SmallSpinner from "../../../../../../Commun/Spinners/SmallSpinner";

const ChangeOrias = (props) => {
    const { setEditOrias, handleSubmit, changeValue, partenaires, insurers, postProfil, formeJuridique } = props

    const submit = form => {
        setEditOrias(false)

        let clone = _.clone(form)

        clone.partenaires = ""

        form.partenaires.forEach(p => {
            clone.partenaires += `${p}, `
        })
        clone.partenaires = clone.partenaires.slice(0, -1)
        clone.partenaires = clone.partenaires.substring(0, clone.partenaires.length - 1);

        clone['formeJuridique[id]'] = form.formeJuridique.id

        postProfil(clone)
    }

    const setInPartenairesArray = value => {
        const newArray = partenaires || []
        newArray.includes(value) ? _.remove(newArray, item => {return item === value}) : newArray.push(value)
        changeValue('partenaires', newArray)
    }

    return (
        <form onSubmit={handleSubmit(submit)}>
            <div className="row">
                <div className="col">
                    <Field
                        name={'immatOrias'}
                        component={TextFieldRedux}
                        label={'Numéro Orias :'}
                    />
                </div>
                <div className="col">
                    <Field
                        name={'formeJuridique[id]'}
                        component={SelectFieldRedux}
                        label={'Type de société :'}
                    >
                        <option value="EI">Entreprise Individuelle</option>
                        <option value="EURL">EURL</option>
                        <option value="SA">Société Anonyme</option>
                        <option value="SARL">SARL</option>
                        <option value="SAS">Société par Action Simplifiée</option>
                        <option value="SASU">Société par Action Unipersonnelle</option>
                        <option value="SCA">Société en Commandite par Action</option>
                        <option value="SCM">Société Civile de Moyens</option>
                        <option value="SCP">Société Civile Professionnelle</option>
                        <option value="SCS">Société en Commandite Simple</option>
                        <option value="SELAF">SELAFA</option>
                        <option value="SELAR">SELARL</option>
                        <option value="SELCA">SELCA</option>
                        <option value="SELU">Société Libérale Unipersonnelle</option>
                        <option value="SEP">Société en Participation</option>
                        <option value="SNC">Société en Nom Collectif</option>
                        <option value="PF">Profession Libérale</option>
                    </Field>
                </div>
            </div>
            <div className="row">
                {
                    formeJuridique !== 'EI' && <div className="col">
                    <Field
                    name={'capital'}
                    component={TextFieldRedux}
                    label={'Capital :'}
                    />
                    </div>
                }
                <div className="col">
                    <Field
                        name={'nrc'}
                        component={TextFieldRedux}
                        label={'RCS :'}
                    />
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <Field
                        name={'villercs'}
                        component={TextFieldRedux}
                        label={'Ville RCS :'}
                    />
                </div>
            </div>

            <label className={'d-block'}>Liste des partenaires :</label>
            <div className="row p-3" style={{height: 300, overflow: 'scroll'}}>
                {
                    insurers.map(p => (
                        <Field
                            key={p.code}
                            component={CheckboxFieldRedux}
                            name={p.libelle}
                            label={p.libelle}
                            className={'d-inline-block col-4 align-top'}
                            onChange={() => setInPartenairesArray(p.libelle)}
                        />
                    ))
                }
                {insurers.length === 0 && <SmallSpinner style={{margin: 'auto', width: '100%'}}/>}
            </div>
            <button className={'btn btn-primary d-block max-rounded mt-3'}>
                J'enregistre mes informations
            </button>
        </form>
    );
};

ChangeOrias.defaultProps = {
    business_unit: {},
    setEditOrias: () => {}
};

ChangeOrias.propTypes = {
    business_unit: PropTypes.object,
    setEditOrias: PropTypes.func
};

export default ChangeOrias;
