import {EMAIL, POST_EMAIL, setEmailsEnvoyes} from "../../../actions/app/email/email.actions";
import {API_ERROR, API_SUCCESS, apiRequest} from "../../../actions/core/api/api.actions";
import {setError, setLoader} from "../../../actions/app/ui/ui.actions";
import {setNotification} from "../../../actions/core/notifications/notifications.actions";
import {reset} from "redux-form";

export const emailMiddleware = () => next => action => {
    next(action)
    let nextAction = []

    switch (action.type) {
        case POST_EMAIL:
            let data = new FormData()
            let body = action.payload.body
            if (body.text) {
                body.text = body.text.replace(/\n/gi, '<br/>')
            }

            Object.keys(body).forEach(key => {
                data.append(key, body[key])
                return true
            })

            next([
                apiRequest({
                    body: data, method: 'POST', url: action.payload.data, entity: EMAIL
                }),
                setLoader({state: true, entity: EMAIL})
            ])
            break

        case `${EMAIL} ${API_SUCCESS}`:
            nextAction = [
                setError({state: false, entity: EMAIL}),
                setLoader({state: false, entity: EMAIL}),
                setNotification({
                    entity: EMAIL,
                    body: `<p>L'email a été envoyé avec succès à votre client</p>`,
                    type: 'success',
                    title: 'Email envoyé',
                    timeout: '0'
                }),
                reset('send-email'),
            ]
            next(nextAction)
            break

        case `${EMAIL} ${API_ERROR}`:
            next([
                setError({state: true, entity: EMAIL}),
                setLoader({state: false, entity: EMAIL}),
                setNotification({
                    entity: EMAIL,
                    body: action.payload.data.response.data.reason,
                    type: 'error',
                    title: 'Une erreur est survenue.',
                    timeout: '0'
                }),
            ])
            break

        default:
            break
    }
    return null
}
