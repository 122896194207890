import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import DevisTable from "./DevisTable";
import {getDataQuotes, getMetaQuotes} from "../../../../../../../redux/selectors/quotes/quotes.selectors";
import {getErrorEntity, getFiltersEntity, getLoadingEntity} from "../../../../../../../redux/selectors/ui/ui.selectors";
import {fetchQuote, QUOTE} from "../../../../../../../redux/actions/app/quotes/quotes.actions";
import {getQuotesLink} from "../../../../../../../redux/selectors/links/links.selectors";
import loader from "../../../../../../Commun/Spinners/Loader/Loader";

const mapStateToProps = state => {
    return {
        quotes: getDataQuotes(state),
        meta: getMetaQuotes(state),
        filters: getFiltersEntity(state, QUOTE),
        query: getQuotesLink(state),
        loaded: getLoadingEntity(state, QUOTE) === false,
        error: getErrorEntity(state, QUOTE)
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    fetchQuote: ({query, filters}) => fetchQuote({query, filters})
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { query } = stateProps
    const { fetchQuote } = dispatchProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        fetchQuote: ({filters}) => fetchQuote({query, filters})
    }
}

const DevisTableContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader()
)(DevisTable)

export default DevisTableContainer
