import {
    USERS_PROFIL,
    FETCH_USERS_PROFIL,
    setUsersProfil, POST_USERS_PROFIL
} from "../../../actions/app/profil/users.profil.actions";
import {API_ERROR, API_SUCCESS, apiRequest} from "../../../actions/core/api/api.actions";
import {setError, setLoader} from "../../../actions/app/ui/ui.actions";
import {setNotification} from "../../../actions/core/notifications/notifications.actions";

export const usersProfilMiddleware = () => next => action => {
    next(action)

    const { payload } = action

    switch (action.type) {
        case FETCH_USERS_PROFIL:
            next([
                apiRequest({body: null, method: 'GET', url: payload.data, entity: USERS_PROFIL}),
                setLoader({state: true, entity: USERS_PROFIL})
            ])
            break

        case `${USERS_PROFIL} ${API_SUCCESS}`:
            next([
                setUsersProfil({data: payload.data.data}),
                setError({state: false, entity: USERS_PROFIL}),
                setLoader({state: false, entity: USERS_PROFIL})
            ])
            break

        case `${USERS_PROFIL} ${API_ERROR}`:
            next([
                setError({state: true, entity: USERS_PROFIL}),
                setLoader({state: false, entity: USERS_PROFIL})
            ])
            break

        case POST_USERS_PROFIL:

            next([
                apiRequest({body: payload.body, method: 'POST', url: payload.data, entity: POST_USERS_PROFIL, header: payload.header, otherData: action.meta.uriGetUsers}),
                setLoader({state: true, entity: POST_USERS_PROFIL})
            ])
            break

        case `${POST_USERS_PROFIL} ${API_SUCCESS}`:
            next([
                apiRequest({body: null, method: 'GET', url: payload.meta.otherData, entity: USERS_PROFIL}),
                setNotification({entity: POST_USERS_PROFIL, body: `<p>Votre contact a bien été mise à jours.</p>`, type: 'success', title: 'Mise à jours des contacts réussi !'}),
                setError({state: false, entity: POST_USERS_PROFIL}),
                setLoader({state: false, entity: POST_USERS_PROFIL})
            ])
            break

        case `${POST_USERS_PROFIL} ${API_ERROR}`:
            next([
                setNotification({entity: POST_USERS_PROFIL, body: payload.data.response.data.message, type: 'error', title: 'Attention !', timeout: "0"}),
                setError({state: true, entity: POST_USERS_PROFIL}),
                setLoader({state: false, entity: POST_USERS_PROFIL})
            ])
            break
        default:
            break
    }
}
