import React from 'react';
import {Link} from 'react-router-dom'

const ErreurDeConnexion = () => {
    return (
        <div className={'text-center pt-5 full-screen background-light text-dark'}>
            <h1 className={'text-secondary'}>Bienvenue sur votre espace partenaire</h1>

            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-12 mt-5">
                        <div className={'p-3 py-5 border border-color-secondary'}>
                            <p className={'h2'}>Vous êtes courtier ?</p>
                            <p>Reconnectez vous depuis APRIL ON.</p>
                            <a
                                href={'https://www.april-on.fr/home'}
                                className={'btn btn-secondary max-rounded'}
                            >
                                Je me reconnecte depuis APRIL ON
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12 mt-5">
                        <div className={'p-3 py-5 border border-color-secondary'}>
                            <p className={'h2'}>Vous êtes concessionnaire ?</p>
                            <p>Reconnectez vous avec vos identifiants APRIL Moto.</p>
                            <Link
                                to={'/connexion'}
                                className={'btn btn-secondary max-rounded'}
                            >
                                Je me reconnecte avec mes identifiants APRIL Moto
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ErreurDeConnexion;
