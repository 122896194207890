/* APP */
export const APP_NAME = 'pro-v2'
export const APP_ENV = import.meta.env.VITE_VERCEL_ENV ?? 'development';

/* API */
export const PASS_URL = import.meta.env.VITE_PASS_URL
export const WHEELS_URL = import.meta.env.VITE_WHEELS_URL
export const WS_FORMULAIRE_URL = import.meta.env.VITE_WS_FORMULAIRE_URL
export const SERVICES_URL = import.meta.env.VITE_SERVICES_URL

export const PASS_OAUTH_URL = import.meta.env.VITE_PASS_OAUTH_URL
export const APP_CLIENT_ID = import.meta.env.VITE_CLIENT_ID
export const APP_GRANT_TYPE = import.meta.env.VITE_GRANT_TYPE
export const APP_PROVIDER = import.meta.env.VITE_PROVIDER

export const GRAVITEE_URL = import.meta.env.VITE_GRAVITEE_URL
export const GRAVITEE_API_KEY_IBAN = import.meta.env.VITE_GRAVITEE_API_KEY_IBAN
