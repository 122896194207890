import React from 'react';
import PropType from 'prop-types'
import Cadre from "../../../../Commun/Cadre/Cadre";
import Card from "../../../../Commun/Card/Card";
import Eric from "../../../../../assets/images/imageContacts/eric_mizera.jpg";
import Sandra from "../../../../../assets/images/imageContacts/sandra_foulon.jpg";
import Florian from "../../../../../assets/images/imageContacts/Florian.png"
import Bertrand from "../../../../../assets/images/imageContacts/bertrand_guerin.jpg";
import Angelique from "../../../../../assets/images/imageContacts/angelique_pinheiro.jpg";
import Christophe from "../../../../../assets/images/imageContacts/christophe_moulin.jpg";
import Erwan from "../../../../../assets/images/imageContacts/erwan_phelipot.jpg";
import Nikita from "../../../../../assets/images/imageContacts/Nikita.png"
import Aicha from "../../../../../assets/images/imageContacts/Aicha.png"
import Alexis from "../../../../../assets/images/imageContacts/alexis_hemond.jpg"
import Maxime from "../../../../../assets/images/imageContacts/mcollardeau.png"
import _ from "lodash";

const Contacts = (props) => {
    const { commercial, assistants, isCourtierPremium, isConcessionnaire, isConcessionnairePremium } = props

    const commerciaux = {
        eric: {
            image: Eric,
            poste: "Responsable commercial région IDF/Nord"
        },
        angelique: {
            image: Angelique,
            poste: "Responsable commercial région Sud Est"
        },
        christophe: {
            image: Christophe,
            poste: "Responsable commercial région Sud Ouest"
        },
        erwan: {
            image: Erwan,
            poste: "Responsable commercial région Rhône Alpes/Est"
        },
        alexis: {
            image: Alexis,
            poste: "Responsable commercial région Ouest"
        }
    }

    return (
        <div className={'p-lg-4'}>
            <Card>
                <div>
                    <h3 className={'m-0 pb-3 border-bottom border-dark'}>
                        Nous sommes <span className={'text-color-secondary'}>à votre écoute</span> - du Lundi au Vendredi 9H/18h
                    </h3>

                    <div className="mt-5">
                        <Cadre icon={'fas fa-address-card'} title={'Votre équipe commerciale'} large>
                            <p><b>Une équipe commerciale à votre écoute</b></p>
                            <p>Nous avons mis en place une carte interactive pour retrouver le commercial de votre région&nbsp;!</p>
                            <a
                                href={"https://www.april-moto.com/Doc/carte-interactive/"}
                                target={"_blank"}
                                className={`d-inline-block btn btn-primary max-rounded`}
                            >
                                Découvrir la carte interactive
                            </a>
                        </Cadre>
                    </div>

                    <div className="pr-0 mt-5">
                        <Cadre icon={'fas fa-address-card'} title={isCourtierPremium || isConcessionnairePremium ? 'Votre service Premium' : 'Vos Services de Gestion'} large>
                            {
                                isCourtierPremium || isConcessionnairePremium
                                    ? <div className="row">
                                        <div className="col mt-3 ml-1">
                                            <p className="mt-3">Tél : <b>02 47 51 36 15</b> pour la gestion ( 1 ), sinistre ( 2 ), commercial ( 3 )</p>
                                            <p className="mt-3">Email : <b><a className="text-body" href='mailto:premium@april-moto.com'>premium@april-moto.com</a></b></p>
                                        </div>
                                    </div>
                                    :  <div className="row ml-2">
                                        <div className="col mt-3">
                                            <b className={'text-color-secondary'}>PRODUCTION</b>
                                            <p className="mt-3">Tél :<b> 02 47 51 07 07</b></p>
                                            <p className="mt-3">Email :<b><a className="text-body" href="mailto:moncontrat@april-moto.com"> moncontrat@april-moto.com</a></b></p>
                                        </div>
                                        <div className="col mt-3">
                                            <b className={'text-color-secondary'}>INDEMNISATION</b>
                                            <p className="mt-3">Tél :<b> 02 47 51 07 07</b></p>
                                            <p className="mt-3">Email :<b><a className="text-body" href="mailto:indemnisation@april-moto.com"> indemnisation@april-moto.com</a></b></p>
                                        </div>
                                        <div className="col mt-3">
                                            <b className={'text-color-secondary'}>DEVIS</b>
                                            <p className="mt-3">Tél :<b> {isConcessionnaire ? '02 46 99 04 34' : '02 47 51 07 07'}</b></p>
                                            <p className="mt-3">Email :<b><a className="text-body" href="mailto:deviscourtier@april-moto.com"> deviscourtier@april-moto.com</a></b></p>
                                        </div>
                                    </div>
                            }
                        </Cadre>
                    </div>
                    
                    <div className="mt-5">
                        <p>Vous pouvez nous écrire à l'adresse suivante : <b>April Moto - 14 Quai Marmoutier BP7233 - 37072 TOURS Cedex2</b> ou nous appeler au <b>02&nbsp;47&nbsp;51&nbsp;07&nbsp;07</b></p>
                    </div>
                </div>
            </Card>
        </div>
    );
};

Contacts.defaultProps = {
    commerciale: {},
    assistants: {},
    isCourtierPremium: false,
    isConcessionnairePremium: false
}

Contacts.propTypes = {
    commerciale: PropType.object,
    assistants: PropType.object,
    isCourtierPremium: PropType.bool,
    isConcessionnairePremium: PropType.bool
}

export default Contacts;
