export const PUBLICITES = '[Publicites]'

export const SET_PUBLICITES = `${PUBLICITES} Set`
export const FETCH_PUBLICITES = `${PUBLICITES} Fetch`
export const PUT_PUBLICITES = `${PUBLICITES} Put`

export const fetchPublicites = ({query}) => ({
    type: FETCH_PUBLICITES,
    payload: {
        data: query
    }
})

export const setPublicites = ({data}) => ({
    type: SET_PUBLICITES,
    payload: {
        data
    }
})

export const putPublicites = ({query, form}) => ({
    type: PUT_PUBLICITES,
    payload:{
        data: query,
        body: form,
        header: 'application/json'
    }
})
