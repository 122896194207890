import {connect} from 'react-redux'
import Header from "./Header";
import {
    getBuNameUser,
    getUserIsConcessionnaire,
    getUserIsCourtier
} from "../../../../../redux/selectors/user/user.selectors";

const mapStateToProps = state => {
    return {
        isConcessionnaire: getUserIsConcessionnaire(state),
        isCourtier: getUserIsCourtier(state),
        name: getBuNameUser(state),
    }
}

const HeaderContainer =  connect(mapStateToProps)(Header)

export default HeaderContainer
