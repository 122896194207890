import React from 'react';
import {FieldArray} from "redux-form";
import Cadre from "../../../../../../../../../Commun/Cadre/Cadre";
import FieldArrayBlesses from "./FieldArrayBlesses";

const Blesses = () => {

    return (
        <div className={'form-content'}>
            <Cadre icon={'fas fa-plus-square'} title={'Bléssé(e)(s)'}>
                <FieldArray
                    name='victimes'
                    component={FieldArrayBlesses}
                />
            </Cadre>
        </div>
    );
};

export default Blesses;
