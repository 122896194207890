import React from 'react';
import PropTypes from 'prop-types'
import Card from "../../../../Commun/Card/Card";
import Cadre from "../../../../Commun/Cadre/Cadre";
import {Link} from "react-router-dom";

const Articles = (props) => {
    const { articles } = props

    return (
        <div className={'p-4'}>
            <Card>
                <div>
                    <h3 className={'m-0 pb-3 border-bottom border-dark'}>
                        Toutes vos <span className={'text-color-secondary'}>actualités</span>
                    </h3>
                </div>

                <div className={'row mx-0'}>
                    {
                        articles.map((a, i) => (
                            <div className={`col-lg-6 col-md-12 ${i % 2 ? 'pr-0' : 'pl-0'} mt-5`} key={i}>
                                <Cadre icon={'fas fa-newspaper'} title={a.title} large color={'secondary'}>
                                    <div className={'mb-4'}>
                                        <img src={a.thumbnail} alt={a.title} className={'img-fluid d-block mx-auto my-3'}/>
                                        {a.lead}
                                    </div>
                                    <Link to={`/mon-article/${a.id}`} className={'text-color-secondary float-right m-3 font-weight-bold'}>Lire l'article...</Link>
                                </Cadre>
                            </div>
                        ))
                    }
                </div>
            </Card>
        </div>
    );
};

Articles.defaultProps = {
    articles: []
};

Articles.propTypes = {
    articles: PropTypes.array
};

export default Articles;
