import React from 'react';
import {Link} from "react-router-dom";
import {Field} from "redux-form";
import moment from "moment";
import Card from "../../../../../../../../Commun/Card/Card";
import DatePickerFieldRedux from "../../../../../../../../Commun/Inputs/Date/DatePickerFieldRedux";
import SmallSpinner from "../../../../../../../../Commun/Spinners/SmallSpinner";

const OutFleet = (props) => {
    const { match: { params: { id, ids } }, handleSubmit, loading, success } = props

    return (
        <div className={'p-4'}>
            <Card>
                <div>
                    <h3 className={'m-0 pb-3 border-bottom border-dark'}>
                        <span className={'text-color-secondary'}>Sortie d'un véhicule</span> de votre flotte
                    </h3>

                    <div className="mt-5">
                        <Link className={'btn btn-outline-secondary max-rounded'} to={`/mon-contrat/${id}/ma-flotte/vehicule/${ids}`}>
                            <i className={'fas fa-chevron-left mr-3'}/> Retour au véhicule
                        </Link>
                    </div>

                    {
                        success ? <div className="mt-5 col-lg-6 col-md-12 mx-auto border text-center p-5">
                                <h3 className={'text-color-primary'}>La demande a bien été enregistrée, les informations seront visibles d'ici 24h.</h3>

                            </div>
                            : <form onSubmit={handleSubmit} className={'mt-5 col-lg-6 col-md-12 mx-auto border text-center p-5'}>
                            <Field
                                name={'date'}
                                component={DatePickerFieldRedux}
                                label={'Date de sortie du véhicule'}
                                maxDate={moment()}
                            />
                            {
                                loading ? <SmallSpinner /> : <button className={'btn btn-primary max-rounded'}>
                                    Je valide la sortie du véhicule
                                </button>
                            }
                        </form>
                    }

                </div>
            </Card>
        </div>
    );
};

OutFleet.defaultProps = {};

OutFleet.propTypes = {};

export default OutFleet;
