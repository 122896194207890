import React from 'react'

/*
Usage :

<Field
  name={String}
  component={TextFieldRedux}
  label={String}
  subLabel={String}
  InputProps={{
    placeholder: {String}
  }}
/>
 */

const TextFieldRedux = (props) => {
    const { input, meta: {touched, error}, label, subLabel, type, InputLabelProps, ...custom } = props

    return (
        <div className={'form-group'}>
            <label {...InputLabelProps}>
                {label}
            </label>
            <small className={'float-right'}>{subLabel}</small>
            <input
                className={'form-control '+ (touched ? Boolean(error) ? 'is-invalid' : 'is-valid' : '')}
                type={type}
                {...input}
                {...custom}
            />
            {touched && error && <small className="invalid-feedback">{error}</small>}
        </div>
    )
}

export default TextFieldRedux
