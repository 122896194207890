import React, {useState} from 'react';
import PropTypes from 'prop-types'
import moment from 'moment'
import _ from 'lodash'
import {getCity, getReparateurByCodePostal, JoursFeries} from "../../../../../../../../../../utils/function";
import Cadre from "../../../../../../../../../Commun/Cadre/Cadre";
import TextFieldRedux from "../../../../../../../../../Commun/Inputs/Text/TextFieldRedux";
import {Field} from "redux-form";
import SelectFieldRedux from "../../../../../../../../../Commun/Inputs/Select/SelectFieldRedux";
import RadioDesignButton from "../../../../../../../../../Commun/Inputs/Radio/RadioDesignButton/RadioDesignButton";
import {normalizeIban} from "../../../../../../../../../../utils/normalize";

const Reparation = (props) => {
    const { changeValue, garanties, categorie, marqueModele, immatriculation, reparateur, remb_garage, prelevement, isWakam } = props

    const [reparateurs, setReparateurs] = useState([])
    const [addressReparateur, setAddressReparateurs] = useState(null)
    const [missions, setMissions] = useState([])
    const [city, setCity] = useState([])

    /* Recherche du reparateur par code postal */
    const setPostalCode = (postalCode) => {
        fetchCityByPostalCode(postalCode)
        fetchReparateurs(postalCode)
    }

    /* ajout l'id du reparateur dans le form */
    const changeValueReparateurVisites = (reparateur) => {
        changeValue('reparateur', reparateur)
        if (!(!_.find(garanties, ['code_famille', 'DTA']) && _.includes(['104.2', '104.3', '104.5', '104.1'], categorie))){
            reparateur !== 'NONE' ? changeValue('visit', missions[0]) : changeValue('visit', '')
        }
    }

    /* Retire les jours feries d'un tableau de date */
    const removeJoursFeries = (array) => {
        const joursFeries = JoursFeries(moment().weekYear())

        _.remove(array, n => {
            return _.includes(joursFeries, n)
        })
    }

    /* Function async qui va chercher les villes via le code postal */
    async function fetchCityByPostalCode(value){
        let cities = await getCity(value)
        setCity(cities)
    }

    /* Function async qui va chercher les reparateur via le code postal */
    async function fetchReparateurs(postalCode){
        let arrayReparateurs = await getReparateurByCodePostal(postalCode)
        let reparateurs = _.orderBy(arrayReparateurs, ['raisonSociale'], ['asc'])
        reparateurs.push({id: 'NONE', raisonSociale: 'Autre garage', datesVisite: []})

        removeJoursFeries(reparateurs[0].datesVisite)
        setReparateurs(reparateurs)
        setMissions(reparateurs[0].datesVisite)
        setAddressReparateurs(reparateurs[0].rue1)

        changeValueReparateurVisites(reparateurs[0].id)
    }

    /* Ajout l'addresse du réparateur et les missions disponible apres la selection du reparateur */
    const getAdresseReparateur = (id) => {
        const item = _.find(reparateurs, ['id', id])

        removeJoursFeries(item.datesVisite)

        setAddressReparateurs(item.rue1)
        setMissions(item.datesVisite)

        changeValueReparateurVisites(item.id)
    }

    return (
        <div className={'form-content'}>
            <Cadre icon={'fas fa-tools'} title={'Réparation'}>
                <div className="alert alert-warning" role="alert">
                    <p className={'m-2'}>Garage où se trouve le véhicule: <b>{marqueModele}</b> - <b>{immatriculation}</b></p>
                </div>

                <Field
                    name='cpreparateur'
                    component={TextFieldRedux}
                    label={'Code postal du garage/réparateur'}
                    input={{
                        maxLength: '5',
                        onChange: (e) => e.target.value.length === 5 && setPostalCode(e.target.value)
                    }}
                />

                <Field
                    name='reparateur'
                    component={SelectFieldRedux}
                    label={'Garage / Réparateur'}
                    disabled={city.length === 0}
                    input={{
                        onChange: (e) => getAdresseReparateur(e.target.value)
                    }}
                >
                    {
                        reparateurs.map((reparateur, index) =>
                            <option key={index} value={reparateur.id}>{reparateur.raisonSociale}</option>
                        )
                    }
                </Field>

                {
                    reparateur && reparateur !== 'NONE' && <div className="alert alert-warning" role="alert">
                        <p style={{margin: 20}}>Adresse : {addressReparateur}</p>
                    </div>
                }

                {
                    (reparateur && reparateur === 'NONE') && <div>
                        <Field
                            name='reparateurAutreNom'
                            component={TextFieldRedux}
                            label='Nom du garage'
                        />

                        <Field
                            name='reparateurAutreAdresse'
                            component={TextFieldRedux}
                            label='Adresse du garage'
                        />

                        <Field
                            name='reparateurAutreVille'
                            component={SelectFieldRedux}
                            label={'Ville du garage'}
                            disabled={city.length === 0}
                        >
                            {
                                city.map((city, index) =>
                                    <option key={index} value={city}>{city}</option>
                                )
                            }
                        </Field>
                    </div>
                }

                {
                    (reparateur && reparateur === 'NONE') || ((!_.find(garanties, ['code_famille', 'DTA']) && _.includes(['104.2', '104.3', '104.5', '104.1'], categorie))) ? null : isWakam ? null : <>
                        <Field
                        name='visit'
                        component={SelectFieldRedux}
                        label={'Date de passage de l\'expert'}
                        disabled={city.length === 0}
                    >
                        {
                            missions.map((mission, index) =>
                                <option key={index} value={mission}>{moment(mission).format('dddd DD MMMM YYYY')}</option>
                            )
                        }
                    </Field>
                        <p className={'text-danger mt-2 fw-bold'}>Cette date est susceptible d'être modifiée, un mail de confirmation vous sera envoyé lorsque l'expertise sera validée.</p>
                    </>
                }

                <h4 className={'fw-bold text-primary-light d-inline-block mt-5'}>Dans le cas du remboursement du sinistre, votre client préfére</h4>
                <Field
                    name='remb_garage'
                    component={RadioDesignButton}
                    label={'Ne pas avoir à avancer des frais auprès du garagiste'}
                    data={[{value: 'T', label: 'Oui'}]}
                />
                <Field
                    name='remb_garage'
                    component={RadioDesignButton}
                    label={'Régler le garagiste lui-même puis être remboursé par APRIL Moto ultérieurement'}
                    data={[{value: 'F', label: 'Oui'}]}
                />

                {
                    (remb_garage === 'F' && !prelevement) && <>
                        <Field
                            name='iban'
                            component={TextFieldRedux}
                            label='IBAN'
                            normalize={normalizeIban}
                        />

                        <Field
                            name='domiciliation'
                            component={TextFieldRedux}
                            label='Domiciliation de la banque'
                        />

                        <Field
                            name='bic'
                            component={TextFieldRedux}
                            label='Code BIC'
                        />

                        <div className="alert alert-info mt-5" role="alert">
                            Les informations bancaires de votre client serviront uniquement pour le remboursement de ce sinistre.
                        </div>
                    </>
                }
            </Cadre>
        </div>
    );
};

Reparation.defaultProps = {
    garanties: [],
    marqueModele: '',
    immatriculation: '',
    reparateur: ''
};

Reparation.propTypes = {
    changeValue: PropTypes.func,
    garanties: PropTypes.array,
    categorie: PropTypes.string,
    marqueModele: PropTypes.string,
    immatriculation: PropTypes.string,
    reparateur: PropTypes.string
};

export default Reparation;
