import {connect} from 'react-redux'
import {formValueSelector} from 'redux-form'
import Constat from './Constat'

const mapStateToProps = state => {
    const selector = formValueSelector('declaration-sinistre')

    return {
        constatCheck: selector(state, 'constat_accident'),
    }
}

const ConstatContainer = connect(mapStateToProps)(Constat)

export default ConstatContainer
