import React from 'react';
import moment from "moment";


const VaeVehicule = ({vehicule}) => {
    const { dateMEC, genre, valeur, VIN} = vehicule

    return (
        <>
            <p>Date de mise en circulation : <b className={'float-right'}>{dateMEC ? moment(dateMEC).format('L') : '-'}</b></p>
            <p>Genre : <b className={'float-right'}>{genre ? genre : '-'}</b></p>
            <p>Valeur déclarée : <b className={'float-right'}>{valeur}&nbsp;€</b></p>
            <p>Numéro de marquage : <b className={'float-right'}>{VIN}</b></p>
        </>
    );
};

export default VaeVehicule;