import React from 'react';
import Card from "../../../../../../../Commun/Card/Card";
import moment from 'moment'

const QuittanceDetail = (props) => {
    const { quittance: {tableau}, history } = props

    return (
        <div className={'p-4'}>
            <Card>
                <div>
                    <h3 className={'m-0 pb-3 border-bottom border-dark'}>
                        Tableau de prélèvement
                    </h3>
                </div>
                <div className="mt-5">
                    <span className={'btn btn-outline-secondary max-rounded'} onClick={() => history.goBack()}>
                        <i className={'fas fa-chevron-left mr-3'}/> Retour
                    </span>
                </div>

                <table className="amo-table table table-hover table-bordered table-striped table-responsive mt-5">
                    <thead>
                    <tr>
                        <th scope="col" className={'align-text-top text-white'}>Date</th>
                        <th scope="col" className={'align-text-top text-white'}>Montant à prélever</th>
                        <th scope="col" className={'align-text-top text-white'}>Effectué le</th>
                        <th scope="col" className={'align-text-top text-white'}>Motif du rejet</th>
                        <th scope="col" className={'align-text-top text-white'}>Date du rejet</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        tableau && tableau.map((q, i) => (
                            <tr key={i}>
                                <td className={'p-4'}>{moment(q.date).format('L')}</td>
                                <td className={'p-4'}>{q.montant}&nbsp;€</td>
                                <td className={'p-4'}>{q.datePrelevement ? moment(q.datePrelevement).format('L') : '-'}</td>
                                <td className={'p-4'}>{q.motifRejet ? q.motifRejet : '-'}</td>
                                <td className={'p-4'}>{q.dateRejet ? moment(q.dateRejet).format('L') : '-'}</td>
                            </tr>
                        ))
                    }
                    </tbody>
                </table>
            </Card>
        </div>
    );
};

export default QuittanceDetail;
