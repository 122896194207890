import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import {reduxForm} from 'redux-form'
import LoginAprilMoto from './LoginAprilMoto'
import validate from './validate'
import {getErrorEntity, getLoadingEntity, getLogin} from "../../../redux/selectors/ui/ui.selectors";
import {LOGIN, postLogin} from "../../../redux/actions/app/login/login.actions";
import {PASS_OAUTH_URL} from "../../../constants/constants";

const mapStateToProps = state => {

    return {
        login: getLogin(state),
        loading: getLoadingEntity(state, LOGIN),
        erreur: getErrorEntity(state, LOGIN)
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    onSubmit: ({username, password}) => postLogin({username, password, source: 'aprilmoto', query: PASS_OAUTH_URL})
}, dispatch)

const LoginAprilMotoContainer = compose(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm({
        form: 'login',
        validate
    })
)(LoginAprilMoto)

export default LoginAprilMotoContainer
