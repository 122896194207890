import React from 'react';
import Card from "../../../../../Commun/Card/Card";
import Cadre from "../../../../../Commun/Cadre/Cadre";

const Electrique = (props) => {
    const { subscribeLinks, produitMoto, produitCyclo } = props

    return (
        <div className={'p-lg-4'}>
            <Card>
                <div>
                    <h3 className={'m-0 pb-3 border-bottom border-dark'}>
                        Choisissez une offre <span className={'text-color-primary font-weight-bold'}>2-roues électrique</span>
                    </h3>
                </div>

                <p className="mt-4">Comme vos clients, nous sommes friands d’innovation et nous accompagnons tous les conducteurs de 2-roues électrique, quelle que soit leur cylindrée.<br />
                    Nous proposons ainsi des formules d'assurance pour 2-roues électrique adaptée à leurs  besoins.</p>

                <p className={'font-weight-bold my-3'}>Nouveau : depuis le 1er janvier, faites bénéficier à vos clients de l'exonération de taxe. Jusqu'à -25% sur la cotisation d'assurance des véhicules électriques ! <a href="https://www.april-on.fr/kiosk/article/jusqua-25-sur-la-cotisation-dassurance-des-vehicules-electriques" rel={'noopener noreferrer'} target={'_blank'}>En savoir plus en cliquant ici</a></p>

                <div className={'row'}>
                    <div className="col-lg-6 col-md-12 mt-5">
                        <Cadre icon={'fas fa-info'} title={'Moto & Scooter électrique'} large>
                            <div className="mt-5 mt-lg-0">
                                <p className={'pl-5'}><i className={'fas fa-circle text-color-secondary mr-2'} />Assistance en cas de panne de batterie</p>
                                <p className={'pl-5'}><i className={'fas fa-circle text-color-secondary mr-2'} />Jusqu’à -25% de réduction sur la cotisation grâce à l’exonération de TSCA</p>
                                <p className={'pl-5'}><i className={'fas fa-circle text-color-secondary mr-2'} />Toutes les motos et tous les scooters électriques</p>
                                <p className={'pl-5'}><i className={'fas fa-circle text-color-secondary mr-2'} />Tous les profils à partir de 18 ans et 16 ans pour les 125cm³, du jeune conducteur au risque aggravé</p>
                                <p className={'pl-5'}><i className={'fas fa-circle text-color-secondary mr-2'} />Options à la carte : Rachat de Franchise, Valeur à neuf 24 mois et Accessoires/Équipement jusqu'à 10 000€…</p>
                                <p className={'pl-5'}><i className={'fas fa-circle text-color-secondary mr-2'} />Protection Corporelle du Conducteur jusqu'à 1,2M d'euros</p>

                                <div className={'text-center mt-4'}>
                                    <a href={produitMoto.file} download className={'text-color-secondary font-weight-bold cursor-pointer'} rel={'noopener noreferrer'} target={'_blank'}>Je télécharge la fiche produit</a>
                                    <a href={subscribeLinks.moto} className={'btn btn-primary max-rounded mx-3'}>
                                        Je fais un devis
                                    </a>
                                </div>
                            </div>
                        </Cadre>
                    </div>
                    <div className="col-lg-6 col-md-12 mt-5">
                        <Cadre icon={'fas fa-info'} title={'Cyclo 50 électrique'}>
                            <div className="mt-5 mt-lg-0">
                                <p className={'pl-5'}><i className={'fas fa-circle text-color-secondary mr-2'} />Assistance en cas de panne de batterie</p>
                                <p className={'pl-5'}><i className={'fas fa-circle text-color-secondary mr-2'} />Jusqu’à -25% de réduction sur la cotisation grâce à l’exonération de TSCA</p>
                                <p className={'pl-5'}><i className={'fas fa-circle text-color-secondary mr-2'} />Tous les cyclos 50 cm3 équivalent électrique</p>
                                <p className={'pl-5'}><i className={'fas fa-circle text-color-secondary mr-2'} />Tous les profils à partir de 14 ans, du jeune conducteur au risque aggravé.</p>
                                <p className={'pl-5'}><i className={'fas fa-circle text-color-secondary mr-2'} />Acceptation Suspension ou Annulation pour excès de vitesse, défaut de point et alcoolémie jusqu'à 0,8mg/L</p>
                                <p className={'pl-5'}><i className={'fas fa-circle text-color-secondary mr-2'} />Option Accessoires et Équipements jusqu'à 1 000€</p>

                                <div className={'text-center mt-4'}>
                                    <a href='https://cms.april-moto.com/storage/documents/fiche-produit-cyclo-aout-2024.pdf' download className={'text-color-secondary font-weight-bold cursor-pointer'} rel={'noopener noreferrer'} target={'_blank'}>Je télécharge la fiche produit</a>
                                    <a href={subscribeLinks['50']} className={'btn btn-primary max-rounded mx-3'}>
                                        Je fais un devis
                                    </a>
                                </div>
                            </div>
                        </Cadre>
                    </div>
                </div>

            </Card>
        </div>
    );
};

Electrique.defaultProps = {
    produitMoto: {},
    produitCyclo: {}
}

export default Electrique;
