import {createSelector} from 'reselect'
import _ from 'lodash'

const profilSelector = state => state.profil

export const getBusinessUnitProfil = createSelector(
    profilSelector,
    profil => profil
)

export const getUsersBusinessUnitProfil = createSelector(
    profilSelector,
    profil => profil.users
)

export const getMarquesBusinessUnitProfil = createSelector(
    profilSelector,
    profil => profil.marques
)

export const getImmatOriasBusinessUnitProfil = createSelector(
    profilSelector,
    profil => profil.immatOrias
)

export const getFormeJuridiqueBusinessUnitProfil = createSelector(
    profilSelector,
    profil => profil.formeJuridique
)
export const getCapitalBusinessUnitProfil = createSelector(
    profilSelector,
    profil => profil.capital
)
export const getNrcBusinessUnitProfil = createSelector(
    profilSelector,
    profil => profil.nrc
)
export const getPartenairesBusinessUnitProfil = createSelector(
    profilSelector,
    profil => profil.partenaires ? _.split(profil.partenaires, ', ') : []
)
export const getVilleRCSBusinessUnitProfil = createSelector(
    profilSelector,
    profil => profil.villercs
)
