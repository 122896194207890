import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import _ from 'lodash'
import GarantieMobilite from "./GarantieMobilite";
import loader from "../../../../../../Commun/Spinners/Loader/Loader";
import {getContractLink} from "../../../../../../../redux/selectors/links/links.selectors";
import {fetchContract} from "../../../../../../../redux/actions/app/contracts/contracts.actions";
import {
    getAccidentMobClaimLinkContract,
    getContract
} from "../../../../../../../redux/selectors/contracts/contracts.selectors";
import {reduxForm} from "redux-form";
import {postGarantieMobilite} from "../../../../../../../redux/actions/app/contracts/garantie.mobilite.contract.actions";
import validate from './validate'
import {getErrorEntity, getLoadingEntity} from "../../../../../../../redux/selectors/ui/ui.selectors";

const mapStateToProps = (state, ownProps) => {
    const { id } = ownProps.match.params
    const formName = `garantie-mobilite-${id}`

    return {
        query: _.replace(getContractLink(state), '{id}', id),
        contract: getContract(state),
        queryPost: getAccidentMobClaimLinkContract(state),
        form: formName,
        loading: getLoadingEntity(state, formName),
        success: getLoadingEntity(state, formName) === false && getErrorEntity(state, formName) === false
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    fetchContract: ({query}) => fetchContract({query}),
    postGarantieMobilite: ({form, query, formName}) => postGarantieMobilite({form, query, formName})
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { query, contract, form, queryPost } = stateProps
    const { fetchContract, postGarantieMobilite } = dispatchProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => _.isEmpty(contract) ? fetchContract({query}) : null,
        onSubmit: (formulaire) => postGarantieMobilite({form: formulaire, query: queryPost, formName: form})
    }
}

const GarantieMobiliteContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    reduxForm({
        validate
    }),
    loader()
)(GarantieMobilite)

export default GarantieMobiliteContainer
