import {connect} from 'react-redux'
import {compose} from 'redux'
import ChangeMdp from "./ChangeMdp";
import {reduxForm} from "redux-form";
import {getCustomerResetPasswordLink} from "../../../redux/selectors/links/links.selectors";
import {LOGIN, POST_UPDATE_PASSWORD, postUpdatePassword} from "../../../redux/actions/app/login/login.actions";
import {getErrorEntity, getLoadingEntity, getLogin} from "../../../redux/selectors/ui/ui.selectors";
import validate from './validate'

const mapStateToProps = (state, ownProps) => {
    const { token } = ownProps.match.params

    return {
        query: getCustomerResetPasswordLink(state),
        token,
        loading: getLoadingEntity(state, POST_UPDATE_PASSWORD) || getLoadingEntity(state, LOGIN),
        isLogin: getLogin(state),
        erreur: getErrorEntity(state, POST_UPDATE_PASSWORD)
    }
}

const mapDispatchToProps = {
    postUpdatePassword
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { query, token } = stateProps
    const { postUpdatePassword } = dispatchProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        onSubmit: (form) => postUpdatePassword({query, form: {...form, token}})
    }
}

const ChangeMdpContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    reduxForm({
        form: 'validation-mdp',
        validate
    }),
)(ChangeMdp)

export default ChangeMdpContainer
