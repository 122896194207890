import React from 'react';
import moment from 'moment/moment';

const BeneficiaireEffectifItem = (props) => {
    const { beneficiaire } = props;

    return (
        <tr>
            <td>
                {beneficiaire.nom} {beneficiaire.prenom}
            </td>
            <td>{beneficiaire.nom_naissance}</td>
            <td>{moment(beneficiaire.naissance).format('L')}</td>
            <td>{beneficiaire.ville_naissance}</td>
            <td>{beneficiaire.pays_naissance}</td>
        </tr>
    );
};

export default BeneficiaireEffectifItem;
