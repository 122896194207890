import {connect} from 'react-redux'
import InformationsUser from "./InformationsUser";
import {
    getBusinessUnitProfil,
    getCapitalBusinessUnitProfil,
    getFormeJuridiqueBusinessUnitProfil,
    getImmatOriasBusinessUnitProfil,
    getNrcBusinessUnitProfil,
    getPartenairesBusinessUnitProfil,
    getVilleRCSBusinessUnitProfil
} from "../../../../../../redux/selectors/profil/profil.selectors";

const mapStateToProps = state => {
    return {
        business_unit: getBusinessUnitProfil(state),
        immatOrias: getImmatOriasBusinessUnitProfil(state),
        formeJuridique: getFormeJuridiqueBusinessUnitProfil(state),
        capital: getCapitalBusinessUnitProfil(state),
        nrc: getNrcBusinessUnitProfil(state),
        villercs: getVilleRCSBusinessUnitProfil(state),
        partenaires: getPartenairesBusinessUnitProfil(state),
        listeTypeSociete: [
            {
                id: "EI",
                value: "Entreprise Individuelle"
            },
            {
                id: "EURL",
                value: "EURL"
            },
            {
                id: "SA",
                value: "Société Anonyme"
            },
            {
                id: "SARL",
                value: "SARL"
            },
            {
                id: "SAS",
                value: "Société par Action Simplifiée"
            },
            {
                id: "SASU",
                value: "Société par Action Unipersonnelle"
            },
            {
                id: "SCA",
                value: "Société en Commandite par Action"
            },
            {
                id: "SCM",
                value: "Société Civile de Moyens"
            },
            {
                id: "SCP",
                value: "Société Civile Professionnelle"
            },
            {
                id: "SCS",
                value: "Société en Commandite Simple"
            },
            {
                id: "SELAF",
                value: "SELAFA"
            },
            {
                id: "SELAR",
                value: "SELARL"
            },
            {
                id: "SELCA",
                value: "SELCA"
            },
            {
                id: "SELU",
                value: "Société Libérale Unipersonnelle"
            },
            {
                id: "SEP",
                value: "Société en Participation"
            },
            {
                id: "SNC",
                value: "Société en Nom Collectif"
            },
            {
                id: "PF",
                value: "Profession Libérale"
            }
        ]
    }
}

const InformationsUserContainer = connect(mapStateToProps)(InformationsUser)

export default InformationsUserContainer
