import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import loader from '../../../../Commun/Spinners/Loader/Loader'
import Produits from './Produits'
import {getProductsLink} from "../../../../../redux/selectors/links/links.selectors";
import {fetchProduits, PRODUITS} from "../../../../../redux/actions/app/produits/produits.actions";
import {getProduit} from "../../../../../redux/selectors/produits/produits.selectors";
import {getErrorEntity, getLoadingEntity} from "../../../../../redux/selectors/ui/ui.selectors";
import {getSubscribeLinksUser} from "../../../../../redux/selectors/user/user.selectors";

const mapStateToProps = (state, ownProps) => {
    return {
        produit: getProduit(state, ownProps.product),
        query: getProductsLink(state),
        loaded: getLoadingEntity(state, PRODUITS) === false,
        error: getErrorEntity(state, PRODUITS),
        subscribeLinks: getSubscribeLinksUser(state)
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    fetchProduits: ({query}) => fetchProduits({query})
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { query } = stateProps
    const { fetchProduits } = dispatchProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => fetchProduits({query})
    }
}

const ProduitsContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader()
)(Produits)

export default ProduitsContainer
