import React, {useState} from 'react';
import {Field} from "redux-form";
import {motifResilNVEI, motifResilVAE} from "../../../../../../../utils/optionsSelect"
import moment from "moment";
import Card from "../../../../../../Commun/Card/Card";
import {useHistory} from "react-router-dom";
import SelectFieldRedux from "../../../../../../Commun/Inputs/Select/SelectFieldRedux";
import DatePickerFieldRedux from "../../../../../../Commun/Inputs/Date/DatePickerFieldRedux";
import SmallSpinner from "../../../../../../Commun/Spinners/SmallSpinner";
import _ from 'lodash'

const ResilContrat = (props) => {
    const {contract, changeValue, handleSubmit, loading} = props
    const history = useHistory()
    const { dateEcheance, date, dateAffaireNouvelle } = contract.situation
    const [motif, setMotif] = useState(null)
    let listeMotifs = contract.vehicule.codeProduit && contract.vehicule.codeProduit === '7311_VAE'
        ? motifResilVAE
        : contract.vehicule.codeProduit && contract.vehicule.codeProduit === '7311_VAE'
            ? motifResilNVEI
            :[]

    let deuxMoisAvtDateEcheance = new Date(dateEcheance);
    deuxMoisAvtDateEcheance.setMonth(deuxMoisAvtDateEcheance.getMonth() - 2)

    //si on est dans les 2 mois avant la date d'échéance on bloque option "B"
    if(new Date() >= deuxMoisAvtDateEcheance.getTime()){
        listeMotifs = _.filter(listeMotifs, function(m) {
            return m.code !== 'B';
        });
    }

    const handleChange = (e) => {
        setMotif(e.target.value);

        switch (e.target.value) {
            case 'X':
            case '9':
                changeValue('date', moment(date))
                break;
            case 'Z':
                changeValue('date', moment(date).add(60, 'days'))
                break;
            case 'B':
            case 'H':
                changeValue('date', moment(dateEcheance).subtract(1, 'days'))
                break;
            case 'K':
            case 'F':
                changeValue('date', moment().add(10, 'days'))
                break;
            case 'C':
                changeValue('date', moment().add(30, 'days'))
                break;
            case '2':
            case '4':
            default:
                changeValue('date', moment())
                break;
        }
    }

    return (
        <div className={'p-lg-4'}>
            <Card>
                <div>
                    <h3 className={'m-0 pb-3 border-bottom border-dark'}>
                        Résiliation <span className="text-color-secondary">du contrat</span> de votre client
                    </h3>

                    <div className="mt-5">
                        <span className={'btn btn-outline-secondary'} onClick={() => history.goBack()}><i
                            className={'fas fa-chevron-left mr-3'}/> Retour</span>
                    </div>

                    <form onSubmit={handleSubmit} className={'mt-5 col-lg-6 col-md-12 mx-auto'}>
                        <Field
                            name='motif'
                            component={SelectFieldRedux}
                            label='Sélectionnez un motif de résiliation'
                            onChange={handleChange}
                        >
                            <option value={null}>Sélectionner un motif</option>
                            {listeMotifs.map((motif, index) => (
                                <option key={index} value={motif.code}>{motif.libelle}</option>
                            ))}
                        </Field>
                        {motif &&
                        <Field
                            name='date'
                            component={DatePickerFieldRedux}
                            label={'Date de la résiliation'}
                            minDate={dateAffaireNouvelle}
                        />
                        }

                        <div className="text-center">
                            {
                                loading
                                    ? <SmallSpinner/>
                                    : <button className={'btn btn-primary max-rounded'}>
                                        Je valide la résiliation
                                    </button>
                            }
                        </div>
                    </form>
                </div>
            </Card>
        </div>
    )
}
export default ResilContrat;
