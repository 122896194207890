import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import loader from '../../../../../../Commun/Spinners/Loader/Loader'
import AccidentsContract from './AccidentsContract'
import _ from 'lodash';
import {getAccidentsContractLink} from "../../../../../../../redux/selectors/links/links.selectors";
import {
    getAccidentsContract,
    getIdContract,
    getIsFleetContract
} from "../../../../../../../redux/selectors/contracts/contracts.selectors";
import {
    ACCIDENTS_CONTRACT,
    fetchAccidentsContract
} from "../../../../../../../redux/actions/app/contracts/accidents.contract.actions";
import {getErrorEntity, getLoadingEntity} from "../../../../../../../redux/selectors/ui/ui.selectors";

const mapStateToProps = state => {
    return {
        accidents: getAccidentsContract(state),
        idContrat: getIdContract(state),
        isFleet: getIsFleetContract(state),
        query: _.replace(getAccidentsContractLink(state), '{id}', getIdContract(state)),
        loaded: getLoadingEntity(state, ACCIDENTS_CONTRACT) === false,
        error: getErrorEntity(state, ACCIDENTS_CONTRACT)
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    fetchAccidentsContract: ({query}) => fetchAccidentsContract({query})
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { fetchAccidentsContract } = dispatchProps
    const { query } = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => fetchAccidentsContract({query})
    }
}

const AccidentsContractContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader()
)(AccidentsContract)

export default AccidentsContractContainer
