import React from 'react';
import PropType from 'prop-types'
import Card from "../../../../Commun/Card/Card";
import Cadre from "../../../../Commun/Cadre/Cadre";
import {Link} from "react-router-dom";

const Produits = (props) => {
    const { produit, subscribeLinks } = props

    return (
        <div className={'p-lg-4'}>
            <Card>
                <div>
                    <h3 className={'m-0 pb-3 border-bottom border-dark'}>
                        Assurance <b className={'text-color-secondary'}>{produit.name}</b> sur-mesure
                    </h3>

                    <p className={'mt-5'} dangerouslySetInnerHTML={{__html: produit.intro}} />

                    <div className="row mt-5 align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <Cadre icon={'fas fa-info'} title={produit.name}>
                                <div className="mt-5 mt-lg-0">
                                    <ul className={'list-circle-secondary mx-lg-4 p-0'} dangerouslySetInnerHTML={{__html: produit.list}}/>

                                    <div className={'text-center mt-5 mb-3'}>
                                        {
                                            produit.file && <a href={produit.file} download className={'text-color-secondary font-weight-bold cursor-pointer'}>Je télécharge la fiche produit</a>
                                        }

                                        {
                                            subscribeLinks.hasOwnProperty(produit.type) &&
                                            <Link to={`/mon-formulaire/${produit.type}`} className={'btn btn-primary mx-3 max-rounded'}>
                                                Je fais un devis {produit.name}
                                            </Link>
                                        }
                                    </div>
                                </div>
                            </Cadre>
                        </div>
                        <div className="col-lg-6 col-md-12 mt-md-5 mt-lg-0">
                            <div className="row align-items-center">
                                <div className="col-4 offset-1">
                                    {produit.info_image && <img src={produit.info_image} alt="le saviez vous ?" className={'img-fluid'}/>}
                                </div>
                                <div className="col-6">
                                    <p className={'h4 text-color-secondary'} dangerouslySetInnerHTML={{__html: produit.info_title}} />
                                    <p dangerouslySetInnerHTML={{__html: produit.info_text}} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Card>
        </div>
    );
};

Produits.defaultProps = {
    produit: {}
}

Produits.propTypes = {
    produit: PropType.object
}

export default Produits;
