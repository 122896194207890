import React, {Fragment} from 'react';
import {Field} from "redux-form";
import UploadInlineContainer from "../../../../../../../../../Commun/Inputs/Upload/UploadInline/UploadInlineContainer";
import Cadre from "../../../../../../../../../Commun/Cadre/Cadre";
import RadioDesignButton from "../../../../../../../../../Commun/Inputs/Radio/RadioDesignButton/RadioDesignButton";

const Constat = (props) => {
    const {constatCheck} = props

    return (
        <div className={'form-content h-100'}>
            <Cadre icon={'fas fa-file-alt'} title={'Constat amiable'}>
                <Field
                    name='constat_accident'
                    component={RadioDesignButton}
                    label={'Il y a-t-il un constat amiable ?'}
                    data={[{value: "T", label:"Oui"}, {value: "F", label:"Non"}]}
                    required
                />
                {constatCheck === 'T' && <Fragment>
                    <Field
                        name='constat_sign_tiers'
                        component={RadioDesignButton}
                        label={'Est-il signé par le Tiers adverse ?'}
                        data={[{value: "T", label:"Oui"}, {value: "F", label:"Non"}]}
                    />
                    <Field
                        name='constat_sign_assure'
                        component={RadioDesignButton}
                        label={'Est-il signé par votre client ?'}
                        data={[{value: "T", label:"Oui"}, {value: "F", label:"Non"}]}
                    />

                    <Field
                        name='upload'
                        component={UploadInlineContainer}
                        formName='declaration-sinistre'
                        title={'Constat amiable signé ou déclaration circonstanciée (Recto)'}
                        code={`files[C]`}
                    />
                    <Field
                        name='upload'
                        component={UploadInlineContainer}
                        formName='declaration-sinistre'
                        title={'Constat amiable signé ou déclaration circonstanciée (Verso)'}
                        code={`files[CC]`}
                    />
                </Fragment>}
            </Cadre>
        </div>
    );
};

export default Constat;
