import React from 'react';
import {Field} from "redux-form";
import Cadre from "../../../../../../../../../Commun/Cadre/Cadre";
import RadioDesignButton from "../../../../../../../../../Commun/Inputs/Radio/RadioDesignButton/RadioDesignButton";

const DeclarationReparation = () => {
    return (
        <div className={'form-content h-100'}>
            <Cadre icon={'fas fa-info'} title={'Réparation du véhicule'} large>
                <Field
                    name='vehicule'
                    component={RadioDesignButton}
                    label={'Connaissez-vous le garage qui fera les réparations de votre véhicule ?'}
                    data={[{value: "T", label:"Oui"}, {value: "F", label:"Non"}]}
                    required
                />
            </Cadre>
        </div>
    );
};

export default DeclarationReparation;
