export const MARQUES_PROFIL = '[Profil Marques]'

export const FETCH_MARQUES_PROFIL = `${MARQUES_PROFIL} Fetch`
export const SET_MARQUES_PROFIL = `${MARQUES_PROFIL} Set`
export const POST_MARQUES_PROFIL = `${MARQUES_PROFIL} Post`

export const fetchMarquesProfil = ({query}) => ({
    type: FETCH_MARQUES_PROFIL,
    payload: {
        data: query
    }
})

export const setMarquesProfil = ({data}) => ({
    type: SET_MARQUES_PROFIL,
    payload: {
        data
    }
})

export const postMarquesProfil = ({form, query, uriGetMarques}) => ({
    type: POST_MARQUES_PROFIL,
    payload: {
        data: query,
        body: form,
        meta: {
            uriGetMarques
        }
    }
})
