import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import LoginAprilOn from './LoginAprilOn'
import loader from '../../Commun/Spinners/Loader/Loader';
import {LOGIN, postLogin} from "../../../redux/actions/app/login/login.actions";
import {PASS_OAUTH_URL} from "../../../constants/constants";
import {getErrorEntity, getLoadingEntity, getLogin} from "../../../redux/selectors/ui/ui.selectors";
import {WHITELABEL} from "../../../redux/actions/app/whitelabel/whitelabel.actions";
import {getUserData, getWhitelabelUser} from "../../../redux/selectors/user/user.selectors";

const mapStateToProps = (state, ownProps) => {
    const { token, action, id } = ownProps.match.params

    return {
        token, action, id,
        login: getLogin(state),
        idWhitelabel: getWhitelabelUser(state),
        user: getUserData(state),
        loading: getLoadingEntity(state, LOGIN),
        erreur: getErrorEntity(state, LOGIN) || getErrorEntity(state, WHITELABEL),
        loaded: getLoadingEntity(state, LOGIN)
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    postLogin: ({token}) => postLogin({token, source: 'aprilon', query: PASS_OAUTH_URL})
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { postLogin } = dispatchProps
    const { token } = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => postLogin({token}),
    }
}

const LoginAprilOnContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader()
)(LoginAprilOn)

export default LoginAprilOnContainer
