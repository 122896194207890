import {connect} from 'react-redux'
import {compose} from 'redux'
import GetFleetForInitForm from "./GetFleetForInitForm";
import {
    fetchFleetContract,
    FLEET_CONTRACT
} from "../../../../../../../../../../redux/actions/app/contracts/fleet.contract.actions";
import loader from "../../../../../../../../../Commun/Spinners/Loader/Loader";
import {getFleetGetLink} from "../../../../../../../../../../redux/selectors/links/links.selectors";
import {getErrorEntity, getLoadingEntity} from "../../../../../../../../../../redux/selectors/ui/ui.selectors";
import _ from "lodash";

const mapStateToProps = state => {
    return {
        query: getFleetGetLink(state),
        loaded: getLoadingEntity(state, FLEET_CONTRACT) === false,
        error: getErrorEntity(state, FLEET_CONTRACT)
    }
}

const mapDispatchToProps = {
    fetchFleetContract
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { query } = stateProps
    const { fetchFleetContract } = dispatchProps
    const { idVehicule } = ownProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => fetchFleetContract({query: _.replace(query, '{id}', idVehicule)}),
    }
}

const GetFleetForInitFormContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader()
)(GetFleetForInitForm)

export default GetFleetForInitFormContainer
