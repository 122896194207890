import React, {useState} from 'react';
import FleetTableContainer from "./FleetTable/FleetTableContainer";
import CheckboxFieldRedux from "../../../../../../Commun/Inputs/Checkbox/CheckboxFieldRedux";

const Fleet = (props) => {
    const { fleet: { data, links, meta }, fetchFleetContract, id, history, query, filters } = props
    const [immat, setImmat] = useState('')

    return (
        <div>

            <div className="row mt-5">
                <div className="col-lg-4 col-md-6 mb-2">
                    <label>Recherche par Immatriculation</label>
                    <div className="input-group mb-2">
                        <input type="text" className="form-control text-uppercase" placeholder="Immatriculation" onChange={(e) => setImmat(e.target.value.toUpperCase())} onKeyPress={(e) => e.key === 'Enter' && fetchFleetContract({query, filters: {contrat: id, immat: immat.toUpperCase()}})}/>
                        <div className="input-group-append" onClick={() => fetchFleetContract({query, filters: {contrat: id, immat: immat.toUpperCase()}})}>
                            <div className="input-group-text bg-warning border-warning cursor-pointer"><i className={'fas fa-search text-white'} /></div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-3 col-md-6 mb-2">
                    <label>Tri des véhicules</label>
                    <select className={'form-control'} onChange={(e) => fetchFleetContract({query, filters: {...filters, contrat: id, sort: e.target.value}})} value={filters.sort}>
                        <option value={''}>Adhesion</option>
                        <option value={'date'}>Mise en circulation croissant</option>
                        <option value={'-date'}>Mise en circulation décroissant</option>
                    </select>
                </div>

                <div className="col-lg-3 col-md-6 mb-2">
                    <label />
                    <CheckboxFieldRedux className="mt-1" label="Véhicules actifs uniquement" input={{name: 'actif', onChange:(e) => fetchFleetContract({query, filters: {...filters, contrat: id, active: e.target.checked || null}})}} />
                </div>
            </div>

            <FleetTableContainer data={data} history={history} id={id} />

            <nav>
                <p>{meta.to}/{meta.total}</p>
                <ul className="pagination">
                    {meta.current_page > 2 && <li className="page-item" onClick={() => fetchFleetContract({query: links.first, filters: {...filters}})}><span className="page-link"><i className="fas fa-angle-double-left" /></span></li>}
                    {meta.current_page > 1 && <li className="page-item" onClick={() => fetchFleetContract({query: links.prev, filters: {...filters}})}><span className="page-link"><i className="fas fa-angle-left" /></span></li>}
                    <li className="page-item"><span className={`page-link current`} >{meta.current_page}</span></li>
                    {meta.last_page > meta.current_page && <li className="page-item" onClick={() => fetchFleetContract({query: links.next, filters: {...filters}})}><span className="page-link"><i className="fas fa-angle-right" /></span></li>}
                    {meta.last_page >= meta.current_page + 2 && <li className="page-item" onClick={() => fetchFleetContract({query: links.last, filters: {...filters}})}><span className="page-link"><i className="fas fa-angle-double-right" /></span></li>}
                </ul>
            </nav>
        </div>
    );
};

Fleet.defaultProps = {
    fleet: {
        data: [],
        links: {},
        meta: {}
    }
}

export default Fleet;
