import {API_ERROR, API_SUCCESS, apiRequest} from '../../../actions/core/api/api.actions'
import {setError, setErrorResponse, setLoader} from '../../../actions/app/ui/ui.actions'
import {
    DOCUMENTS_ACTIVE, DOCUMENTS_INACTIVE,
    FETCH_DOCUMENTS_ACTIVE, FETCH_DOCUMENTS_INACTIVE,
    setDocumentsActive, setDocumentsInactive
} from "../../../actions/app/documents/documents.actions";

export const documentsMiddleware = () => next => action => {
    next(action)

    switch (action.type){
        case FETCH_DOCUMENTS_ACTIVE:
            next([
                apiRequest({body: null, method: 'GET', url: action.payload.data, entity: DOCUMENTS_ACTIVE}),
                setLoader({state: true, entity: DOCUMENTS_ACTIVE})
            ])
            break

        case `${DOCUMENTS_ACTIVE} ${API_SUCCESS}`:
            next([
                setDocumentsActive({data: action.payload.data.data}),
                setError({state: false, entity: DOCUMENTS_ACTIVE}),
                setLoader({state: false, entity: DOCUMENTS_ACTIVE})
            ])
            break

        case `${DOCUMENTS_ACTIVE} ${API_ERROR}`:
            next([
                setError({state: true, entity: DOCUMENTS_ACTIVE}),
                setErrorResponse({entity: DOCUMENTS_ACTIVE, data: action?.payload?.data?.response?.data}),
                setLoader({state: false, entity: DOCUMENTS_ACTIVE})
            ])
            break

        case FETCH_DOCUMENTS_INACTIVE:
            next([
                apiRequest({body: null, method: 'GET', url: action.payload.data, entity: DOCUMENTS_INACTIVE}),
                setLoader({state: true, entity: DOCUMENTS_INACTIVE})
            ])
            break

        case `${DOCUMENTS_INACTIVE} ${API_SUCCESS}`:
            next([
                setDocumentsInactive({data: action.payload.data.data}),
                setError({state: false, entity: DOCUMENTS_INACTIVE}),
                setLoader({state: false, entity: DOCUMENTS_INACTIVE})
            ])
            break

        case `${DOCUMENTS_INACTIVE} ${API_ERROR}`:
            next([
                setError({state: true, entity: DOCUMENTS_INACTIVE}),
                setErrorResponse({entity: DOCUMENTS_INACTIVE, data: action?.payload?.data?.response?.data}),
                setLoader({state: false, entity: DOCUMENTS_INACTIVE})
            ])
            break

        default:
            break
    }
    return null
}
