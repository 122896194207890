import React from 'react';
import PropTypes from 'prop-types'
import Card from "../../../../Commun/Card/Card";
import DashboardDataContainer from "./DashboardData/DashboardDataContainer";

const Dashboard = (props) => {
    const { apporteurs, query, fetchDashboard } = props

    return (
        <div className={'p-lg-4'}>
            <Card>
                <div>
                    <h3 className={'m-0 pb-3 border-bottom border-dark'}>
                        Votre <span className={'text-color-secondary'}>tableau de bord</span>
                    </h3>

                    <div className="row mt-5">
                        <div className="col-lg-4 col-md-12">
                            <label>Choisissez un apporteur d'affaires :</label>
                            <select className={'form-control'} onChange={(e) => fetchDashboard({filters: {id_apporteur: e.target.value}, query})}>
                                <option value={''}>Tous les apporteurs</option>
                                {
                                    apporteurs.map(a => (
                                        <option key={a.codeBelair} value={a.codeBelair}>{a.name} - {a.ville}, {a.codePostal}</option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>

                    <DashboardDataContainer />

                </div>
            </Card>
        </div>
    );
};

Dashboard.defaultProps = {
    apporteurs: [],
    query: '#',
    filters: [],
    fetchDashboard: () => {}
};

Dashboard.propTypes = {
    apporteurs: PropTypes.array,
    query: PropTypes.string,
    filters: PropTypes.array,
    fetchDashboard: PropTypes.func
};

export default Dashboard;
