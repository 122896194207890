import React from 'react';

const Card = (props) => {
    const { children } = props

    return (
        <div className="card w-auto mx-lg-5 p-2 h-100">
            <div className="card-body">
                {children}
            </div>
        </div>
    );
};

export default Card;
