import {connect} from 'react-redux'
import {compose} from 'redux'
import MobileMenu from "./MobileMenu";
import {getLogoLightUrlWhitelabel} from "../../../../../redux/selectors/whitelabel/whitelabel.selectors";
import {
    getPermissionsUser,
    getRolesUser,
    getUserIsConcessionnaire, getUserIsCourtier
} from "../../../../../redux/selectors/user/user.selectors";
import {
    getContractsWaitingStats, getReceiptsRejet,
    getReceiptsWaiting,
    getTotalStats
} from "../../../../../redux/selectors/stats/stats.selectors";

const mapStateToProps = state => {
    return {
        logo: getLogoLightUrlWhitelabel(state),
        isConcessionnaire: getUserIsConcessionnaire(state),
        isCourtier: getUserIsCourtier(state),
        permissions: getPermissionsUser(state),
        userRoles: getRolesUser(state),
        stats: {
            total: getTotalStats(state),
            dossiersAttente: getContractsWaitingStats(state),
            impayes: getReceiptsWaiting(state),
            rejets: getReceiptsRejet(state)
        }
    }
}

const MobileMenuContainer = compose(
    connect(mapStateToProps)
)(MobileMenu)

export default MobileMenuContainer
