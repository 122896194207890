import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import loader from '../../../../../Commun/Spinners/Loader/Loader'
import SinistreOuvert from './SinistreOuvert'
import {getAccidentsLink} from "../../../../../../redux/selectors/links/links.selectors";
import {getErrorEntity, getLoadingEntity} from "../../../../../../redux/selectors/ui/ui.selectors";
import {ACCIDENTS, fetchAccidents} from "../../../../../../redux/actions/app/accidents/accidents.actions";
import {
    getDataAccidents,
    getLinksAccidents,
    getMetaAccidents
} from "../../../../../../redux/selectors/accidents/accidents.selectors";

const mapStateToProps = (state, ownProps) => {
    return {
        filters: {situationCode: ownProps.situationCode},
        accidents: getDataAccidents(state),
        meta: getMetaAccidents(state),
        links: getLinksAccidents(state),
        query: getAccidentsLink(state),
        loaded: getLoadingEntity(state, ACCIDENTS) === false,
        error: getErrorEntity(state, ACCIDENTS)
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    fetchAccidents: ({query, filters}) => fetchAccidents({query, filters})
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { query, filters } = stateProps
    const { fetchAccidents } = dispatchProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => fetchAccidents({query, filters})
    }
}

const SinistreOuvertContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader()
)(SinistreOuvert)

export default SinistreOuvertContainer
