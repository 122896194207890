import {SEARCH, FETCH_SEARCH, setSearch, clearSearch} from '../../../actions/app/search/search.actions'
import {API_ERROR, API_SUCCESS, apiRequest} from '../../../actions/core/api/api.actions'
import {setError, setLoader} from '../../../actions/app/ui/ui.actions'
import {setNotification} from "../../../actions/core/notifications/notifications.actions";

export const searchMiddleware = () => next => action => {
    next(action)

    switch (action.type){

        case FETCH_SEARCH:
            next([
                apiRequest({body: null, method: 'GET', url: action.payload.data, entity: SEARCH, otherData: action.meta}),
                clearSearch({entity: action.meta}),
                setLoader({state: true, entity: SEARCH})
            ])
            break

        case `${SEARCH} ${API_SUCCESS}`:
            const { data, meta } = action.payload

            next([
                setSearch({data, entity: meta.otherData}),
                setError({state: false, entity: SEARCH}),
                setLoader({state: false, entity: SEARCH})
            ])
            break

        case `${SEARCH} ${API_ERROR}`:
            next([
                setNotification({entity: SEARCH, body: `La recherche est trop imposante pour trouver le devis. Pensez à chercher le devis par le nom de famille seulement pour plus de précision.`, title: 'Une erreur est survenue !', type: 'error'}),
                setError({state: true, entity: SEARCH}),
                setLoader({state: false, entity: SEARCH})
            ])
            break

        default:
            break
    }
    return null
}
