import React, {useState} from 'react';
import _ from 'lodash'
import {Field} from 'redux-form'
import PropTypes from 'prop-types'
import {getCity, getCityBySearch} from "../../../../utils/function";
import TextFieldRedux from "../Text/TextFieldRedux";

const PostalCodeCity = (props) => {
    const { label, id, nameVille, changeValue, nameCodePostal, defaultValue, disabled } = props
    const [city, setCity] = useState([])
    const [selected, setSelected] = useState(false)
    const [labelSelected, setLabelSelected] = useState(defaultValue)

    const formatObj = ({codePostal, villes}) => {
        let data = []
        _.forEach(villes, ville => {
            data.push({ville, codePostal})
        })
        return data
    }

    async function fetchCityByPostalCode(value){
        let cities = await getCity(value)
        let arrayCities = formatObj({codePostal: value, villes: cities})
        setCity(arrayCities)
    }

    async function fetchCityBySearch(value){
        let cities = await getCityBySearch(value)
        setCity(cities)
    }

    const searchCity = value =>{
        setSelected(false)
        if (_.parseInt(value)){
            value.length === 5 && fetchCityByPostalCode(value)
        } else {
            return fetchCityBySearch(value)
        }
    }

    const setCityAndPostalCode = clickCity =>{
        changeValue(nameVille, clickCity.ville)
        changeValue(nameCodePostal, clickCity.codePostal)
        setSelected(true)
        setLabelSelected(`${clickCity.ville} ${clickCity.codePostal}`)
    }

    return (
        <div>
            <div className="row">
                <div className="col">
                    <Field
                        name={`postalcodecity${id ? `-${id}` : ''}`}
                        component={TextFieldRedux}
                        label={label}
                        input={{
                            value: labelSelected,
                            onChange: (e) => e.target.value.length >= 3 && searchCity(e.target.value),
                            onFocus: () => setLabelSelected(null)
                        }}
                        disabled={disabled}
                    />
                </div>
            </div>
            {
                !selected && <div className="row">
                    <div className="col z-index-1 position-absolute">
                        <div className="list-group">
                            {
                                city.map((city, index) => (
                                    <li className={'cursor-pointer list-group-item list-group-item-action'} key={index} value={city.ville} onClick={() => setCityAndPostalCode(city)}>{city.ville} {city.codePostal}</li>
                                ))
                            }
                        </div>
                    </div>
                </div>
            }
        </div>
    );
};

PostalCodeCity.propTypes = {
    label: PropTypes.string,
    id: PropTypes.string,
    nameVille: PropTypes.string,
    changeValue: PropTypes.func,
    nameCodePostal: PropTypes.string,
    defaultValue: PropTypes.string
};

export default PostalCodeCity;
