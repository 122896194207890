import React from 'react';


const SinistreOuvert = (props) => {
    const { accidents, meta, links, fetchAccidents, history, filters } = props

    return (
        <div>
            <table className="amo-table table table-hover table-bordered table-responsive table-striped mt-3">
                <thead>
                <tr>
                    <th scope="col" className={'align-text-top text-white'}>Assuré / N° du contrat</th>
                    <th scope="col" className={'align-text-top text-white'}>Voir le sinistre</th>
                </tr>
                </thead>
                <tbody>
                {
                    accidents.map(a => (
                        <tr key={a.id} onClick={() => history.push(`/mon-contrat/${a.id_contrat}/mon-sinistre/${a.id}`)}>
                            <td>
                                <b>{a.conducteur}</b> <br/> {a.numCbt}
                            </td>
                            <td className={'h4 p-4'}><i className={'fas fa-search text-color-secondary'} /></td>
                        </tr>
                    ))
                }
                </tbody>
            </table>

            <nav>
                <ul className="pagination">
                    {meta.current_page > 2 && <li className="page-item" onClick={() => fetchAccidents({query: links.first, filters})}><span className="page-link"><i className="fas fa-angle-double-left" /></span></li>}
                    {meta.current_page > 1 && <li className="page-item" onClick={() => fetchAccidents({query: links.prev, filters})}><span className="page-link"><i className="fas fa-angle-left" /></span></li>}
                    <li className="page-item"><span className={`page-link current`} >{meta.current_page}</span></li>
                    {meta.last_page > meta.current_page && <li className="page-item" onClick={() => fetchAccidents({query: links.next, filters})}><span className="page-link"><i className="fas fa-angle-right" /></span></li>}
                    {meta.last_page >= meta.current_page + 2 && <li className="page-item" onClick={() => fetchAccidents({query: links.last, filters})}><span className="page-link"><i className="fas fa-angle-double-right" /></span></li>}
                </ul>
            </nav>
        </div>
    );
};

export default SinistreOuvert;
