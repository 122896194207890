import { connect } from 'react-redux';
import { getBeneficiairesEffectifsContract, getIdContract } from '../../../../../../../redux/selectors/contracts/contracts.selectors';
import BeneficiairesEffectif from './BeneficiairesEffectif';

const mapStateToProps = (state) => {
    return {
        id: getIdContract(state),
        beneficiairesEffectif: getBeneficiairesEffectifsContract(state),
    };
};

const BeneficiairesEffectifContainer = connect(mapStateToProps)(BeneficiairesEffectif);

export default BeneficiairesEffectifContainer;
