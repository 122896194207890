import React, {useEffect, useState} from 'react';
import {Field} from "redux-form";
import TextFieldRedux from "../../Commun/Inputs/Text/TextFieldRedux";
import RadioFieldRedux from "../../Commun/Inputs/Radio/RadioFieldRedux";
import DatePickerFieldRedux from "../../Commun/Inputs/Date/DatePickerFieldRedux";
import moment from "moment";
import SelectFieldRedux from "../../Commun/Inputs/Select/SelectFieldRedux";
import TextAreaFieldRedux from "../../Commun/Inputs/Text/TextAreaFieldRedux";
import SmallSpinner from "../../Commun/Spinners/SmallSpinner";
import {normalizeTel} from "../../../utils/normalize";

const Webcallback = (props) => {
    const { handleSubmit, loading, isLSA, date } = props
    const [horaires, setHoraires] = useState([])

    const horairesBasiques = [
        {
            value: '09:00 - 10:00',
            label: '09:00 - 10:00',
            max: '10:00'
        },
        {
            value: '10:00 - 11:00',
            label: '10:00 - 11:00',
            max: '11:00'
        },
        {
            value: '11:00 - 12:00',
            label: '11:00 - 12:00',
            max: '12:00'
        },
        {
            value: '12:00 - 13:00',
            label: '12:00 - 13:00',
            max: '13:00'
        },
        {
            value: '13:00 - 14:00',
            label: '13:00 - 14:00',
            max: '14:00'
        },
        {
            value: '14:00 - 15:00',
            label: '14:00 - 15:00',
            max: '15:00'
        },
        {
            value: '15:00 - 16:00',
            label: '15:00 - 16:00',
            max: '16:00'
        },
        {
            value: '16:00 - 17:00',
            label: '16:00 - 17:00',
            max: '17:00'
        },
        {
            value: '17:00 - 18:00',
            label: '17:00 - 18:00',
            max: '18:00'
        },
    ]

    useEffect(() => {
        const interval = setInterval(() => {
            const newHoraires = horairesBasiques.map(h => {
                /* Si la date selectionné avec une heure à 00:00 est inférieur à maintenant ET si l'horaire est passé par rapport à l'heure actuelle */
                if(moment(date).startOf('day') < moment() && moment(h.max, 'hh:mm').hour() <= moment().hour()){
                    h.disabled = true
                }
                return h;
            })
            setHoraires(newHoraires)
        }, 1000);
        return () => clearInterval(interval);
    }, [date, horairesBasiques]);

    return (
        <div className={'text-center full-screen'}>
            <div className={'p-5 border-color-secondary border max-width-7 m-auto medium-rounded'}>
                {isLSA ?
                    <h2 className={'mb-5 text-secondary'}>Compléter les informations pour transmettre le contact à un
                        conseiller AM Gestion</h2>
                    : <h2 className={'mb-5 text-secondary'}>Compléter les informations pour transmettre le contact à un
                        conseiller LSA</h2>
                }
                <form onSubmit={handleSubmit} className={'text-left'}>
                    <Field
                        name={'sexe'}
                        component={RadioFieldRedux}
                        label={''}
                        data={[{value: "Monsieur", label:'Monsieur'}, {value:"Madame", label: 'Madame'}]}
                    />
                    <Field
                        name={'prenom'}
                        component={TextFieldRedux}
                        label={'Prénom'}
                    />
                    <Field
                        name={'nom'}
                        component={TextFieldRedux}
                        label={'Nom'}
                    />
                    <Field
                        name={'tel'}
                        component={TextFieldRedux}
                        label={'Numéro de téléphone'}
                        normalize={normalizeTel}
                    />
                    <Field
                        name={'date'}
                        component={DatePickerFieldRedux}
                        label={'Date du prochain contact (du lundi au vendredi)'}
                        minDate={moment().startOf('day')}
                    />
                    <Field
                        name={'heure'}
                        component={SelectFieldRedux}
                        label={"Heure du prochain contact"}
                    >
                        <option value="">Choisir une plage horaire</option>
                        {
                            horaires.map((h, i) => (
                                <option value={h.value} key={i} disabled={h.disabled} className={`${h.disabled && 'background-color-login'}`}>{h.label}</option>
                            ))
                        }
                    </Field>
                    <Field
                        name={'service'}
                        component={SelectFieldRedux}
                        label={"Service"}
                    >
                        <option value="">Choisir le service de destination</option>
                        <option value="Gestion">Gestion</option>
                        <option value="Vente">Vente</option>
                        <option value="Sinistre">Sinistre</option>
                    </Field>
                    <Field
                        name={'provenance'}
                        component={SelectFieldRedux}
                        label={"Provenance"}
                    >
                        <option value="">Choisir la provenance</option>
                        <option value="ASSURPEOPLE">ASSURPEOPLE</option>
                        <option value="RVA ASSURANCES">RVA ASSURANCES</option>
                        <option value="LSA COURTAGE">LSA COURTAGE</option>
                    </Field>
                    <Field
                        name={'indicateur'}
                        component={TextFieldRedux}
                        label={'Indicateur'}
                    />
                    <Field
                        name='commentaire'
                        component={TextAreaFieldRedux}
                        label={'Commentaire'}
                        maxLength={'250'}
                        subLabel={'250 caractères maximum'}i
                    />
                    { loading ? <SmallSpinner /> : <button className={'btn btn-secondary w-100 max-rounded'}>
                        Transférer le contact à un conseiller
                    </button> }
                </form>
            </div>
            {
                !isLSA && <div className={'mt-5 container-fluid pb-5'}>
                    <div className="row justify-content-around">
                        <div className="col-12 col-md-3 mb-3 border-color-secondary border medium-rounded py-3">
                            <h5>LSA Courtage</h5>
                            <h6>02 85 29 64 42</h6>
                            <p>info-lsa@amgestionassurance.com</p>
                            <p>moncontrat-lsa@amgestionassurance.com</p>
                            <p className={'m-0'}>indemnisation-lsa@amgestionassurance.com</p>
                        </div>
                        <div className="col-12 col-md-3 mb-3 border-color-secondary border medium-rounded py-3">
                            <h5>RVA</h5>
                            <h6>02 85 29 64 41</h6>
                            <p>info-rva@amgestionassurance.com</p>
                            <p>moncontrat-rva@amgestionassurance.com</p>
                            <p className={'m-0'}>indemnisation-rva@amgestionassurance.com</p>
                        </div>
                        <div className="col-12 col-md-3 mb-3 border-color-secondary border medium-rounded py-3">
                            <h5>AssurPeople</h5>
                            <h6>02 85 29 64 40</h6>
                            <p>info-assurpeople@amgestionassurance.com</p>
                            <p>moncontrat-assurpeople@amgestionassurance.com</p>
                            <p className={'m-0'}>indemnisation-assurpeople@amgestionassurance.com</p>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
};

export default Webcallback;
