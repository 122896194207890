import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import DeleteVehiculesFlotte from './DeleteVehiculesFlotte'
import {change, formValueSelector, reduxForm} from "redux-form";
import {
    fetchFleetContract,
    FLEET_CONTRACT,
    POST_DELETE_VEHICLES,
    postDeleteVehicles
} from "@/redux/actions/app/contracts/fleet.contract.actions";
import {getLoadingEntity} from "@/redux/selectors/ui/ui.selectors";
import {getFleetContract} from "@/redux/selectors/contracts/contracts.selectors";
import loader from "@/components/Commun/Spinners/Loader/Loader";
import {getFleetLink, getFleetVehiclesDeleteLink} from "@/redux/selectors/links/links.selectors";

const mapStateToProps = (state, ownProps) => {
    const selector = formValueSelector('delete_vehicules')

    return {
        loading: getLoadingEntity(state, POST_DELETE_VEHICLES),
        loaded: getLoadingEntity(state, FLEET_CONTRACT) === false,
        uri: getFleetLink(state),
        data: getFleetContract(state) || {},
        contractId: ownProps.match.params.id,
        selected_vehicules: selector(state, 'selected_vehicules'),
        uri_delete: getFleetVehiclesDeleteLink(state)
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    changeValue: (field, value) => change('delete_vehicules', field, value),
    postDeleteVehicles,
    fetchFleetContract
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {fetchFleetContract, postDeleteVehicles} = dispatchProps
    const {uri, contractId, uri_delete} = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => fetchFleetContract({query: uri, filters: { contrat: contractId }}),
        onSubmit: (form) => {
            form.contract_id = contractId
            postDeleteVehicles({form, query: uri_delete});
        }
    }
}

const DeleteVehiculesFlotteContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    reduxForm({
        form: 'delete_vehicules',
    }),
    loader()
)(DeleteVehiculesFlotte)

export default DeleteVehiculesFlotteContainer
