import React from 'react';
import { useHistory } from 'react-router-dom';
import Card from '../../../../../../../Commun/Card/Card';
import { Field } from 'redux-form';
import SmallSpinner from '../../../../../../../Commun/Spinners/SmallSpinner';
import TextAreaFieldRedux from "../../../../../../../Commun/Inputs/Text/TextAreaFieldRedux";
import Cadre from "../../../../../../../Commun/Cadre/Cadre";
import img from '../../../../../../../../assets/images/pmq_exemple.png';

const Email = (props) => {
    const { handleSubmit, loading } = props;
    const history = useHistory();

    return (
        <div className={'p-lg-4'}>
            <Card>
                <div>
                    <h3 className={'m-0 pb-3 border-bottom border-dark'}>
                        <span className='text-color-secondary'>Envoyer l'email des pièces manquantes</span> à votre client
                    </h3>

                    <div className='mt-5'>
                        <span className={'btn btn-outline-secondary'} onClick={() => history.goBack()}>
                            <i className={'fas fa-chevron-left mr-3'} /> Retour
                        </span>
                    </div>
                    {loading ? <SmallSpinner/> :
                        <div className={'row'}>
                            <div className='col-lg-6 col-md-12'>
                                <form onSubmit={handleSubmit} className={'mt-5'}>
                                    <p>Un email type (voir capture d'écran à droite) sera envoyé à votre client pour lui détailler les pièces manquantes à son dossier.<br/><br/> Vous pouvez personnaliser cet email en renseignant du texte complémentaire dans l’encadré ci-dessous.<br/> Le texte renseigné viendra alors remplacer le champ <b>“%TEXTE COMPLEMENTAIRE%”</b>.<br/> Si vous ne souhaitez pas personnaliser le message, merci de simplement laisser “Bonjour,” inscrit dans l’encadré.</p>
                                    <Field
                                        name='text'
                                        component={TextAreaFieldRedux}
                                        label={''}
                                    />

                                    <div className='text-center'>
                                        <button type='submit' className={'btn btn-primary'}>
                                            Envoyer l'email
                                        </button>
                                    </div>
                                </form>
                            </div>
                            <div className='mt-5 col-lg-6 col-md-12'>
                                <Cadre icon={'fas fa-search'} title={'Aperçu de l’email type'}>
                                    <div className={'mt-5 mt-lg-0'}>
                                        <img src={img} alt="email" className={'img-fluid'}/>
                                    </div>
                                </Cadre>
                            </div>
                        </div>
                    }
                </div>
            </Card>
        </div>
    );
};

export default Email;
