import React from 'react';
import PropTypes from 'prop-types'
import moment from "moment";
import {formatEuro} from "../../../../../../utils/function";
import {getReason} from "../../../../../../utils/translate";
import Card from "../../../../../Commun/Card/Card";

const Rejets = (props) => {
    const { rejets, meta, links, fetchImpayes, filters, history, postPayMail, easeUi: {paymail} } = props

    return (
        <div className={'p-4'}>
            <Card>
                <div>
                    <h3 className={'m-0 pb-3 border-bottom border-dark'}>
                        Vous avez <span className={'text-color-secondary'}>{meta.total} quittances</span> en rejets de prélèvement
                    </h3>

                    <div className={'mt-5'}>
                        <table className="amo-table table table-hover table-bordered table-responsive table-striped mt-3">
                            <thead>
                            <tr>
                                <th scope="col" className={'align-text-top text-white'}>Type situation</th>
                                <th scope="col" className={'align-text-top text-white'}>Assuré / N° du contrat</th>
                                <th scope="col" className={'align-text-top text-white'}>Période</th>
                                <th scope="col" className={'align-text-top text-white'}>Infos</th>
                                <th scope="col" className={'align-text-top text-white'}>Montant en rejet</th>
                                <th scope="col" className={'align-text-top text-white'}>Paiement</th>
                                <th scope="col" className={'align-text-top text-white'}>Envoyer un lien de paiement</th>
                                <th scope="col" className={'align-text-top text-white'}>Voir le contrat</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                rejets.map(q => (
                                    <tr key={q.id}>
                                        <td className={'text-danger font-weight-bold'}>
                                            {q.type} {q.situation}
                                            <br/> {q.id}
                                        </td>
                                        <td>
                                            <b>{q.souscripteur.prenom} {q.souscripteur.nom}</b>
                                            <br/> {q.numeroPolice}
                                        </td>
                                        <td>
                                            Du {moment(q.dateDebut).format('L')}
                                            <br/>au {moment(q.dateFin).format('L')}
                                        </td>
                                        <td className={'align-middle'}>{q.infos ? q.infos : '-'}</td>
                                        <td className={'align-middle'}>{formatEuro(q.solde_a_payer)}</td>
                                        <td className={`${!q.pay_link && 'amo-tooltip'} align-middle`}>
                                            <a href={q.pay_link ? `${q.pay_link}?url_return=${window.location.href}` : null}
                                               className={'h2'}
                                            >
                                                <i className={'fas fa-credit-card'} />
                                            </a>
                                            {!q.pay_link && <small className={'tooltiptext'}>{getReason(q.pay_link_reason)}</small>}
                                        </td>
                                        <td className={`${!q.pay_link && 'amo-tooltip'} align-middle`}>
                                            <span className={`btn btn-outline-secondary ${(q.pay_link_mail_reason[0] || (paymail && paymail[`[${q.id_contrat}-${q.id}]`]) ) && 'disabled'} max-rounded`} onClick={() => { return paymail && paymail[`[${q.id_contrat}-${q.id}]`] ? null : postPayMail({email: q.souscripteur.email, idQuittance: q.id, idContract: q.id_contrat})}}>Envoyer le lien par email</span>
                                            {!q.pay_link && <small className={'tooltiptext'}>{getReason(q.pay_link_reason)}</small>}
                                        </td>
                                        <td className="align-middle h4" onClick={() => history.push(`/mon-contrat/${q.id_contrat}`)}>
                                            <i className={'fas fa-search text-color-secondary'} />
                                        </td>
                                    </tr>
                                ))
                            }
                            </tbody>
                        </table>

                        <nav>
                            <ul className="pagination">
                                {meta.current_page > 2 && <li className="page-item" onClick={() => fetchImpayes({query: links.first, filters})}><span className="page-link"><i className="fas fa-angle-double-left" /></span></li>}
                                {meta.current_page > 1 && <li className="page-item" onClick={() => fetchImpayes({query: links.prev, filters})}><span className="page-link"><i className="fas fa-angle-left" /></span></li>}
                                <li className="page-item"><span className={`page-link current`} >{meta.current_page}</span></li>
                                {meta.last_page > meta.current_page && <li className="page-item" onClick={() => fetchImpayes({query: links.next, filters})}><span className="page-link"><i className="fas fa-angle-right" /></span></li>}
                                {meta.last_page >= meta.current_page + 2 && <li className="page-item" onClick={() => fetchImpayes({query: links.last, filters})}><span className="page-link"><i className="fas fa-angle-double-right" /></span></li>}
                            </ul>
                        </nav>
                    </div>
                </div>
            </Card>
        </div>
    );
};

Rejets.defaultProps = {
    rejets: [],
    meta: {},
    links: {},
    filters: {},
    fetchImpayes: () => {}
};

Rejets.propTypes = {
    rejets: PropTypes.array,
    meta: PropTypes.object,
    links: PropTypes.object,
    filters: PropTypes.object,
    fetchImpayes: PropTypes.func
};

export default Rejets;
