import {
    FETCH_USER,
    POST_USER_REMINDER_PREFERENCES_PMQ,
    setUser,
    setUserPMQReminder,
    USER
} from '../../../actions/app/user/user.actions'
import {API_ERROR, API_SUCCESS, apiRequest} from '../../../actions/core/api/api.actions'
import {
    setError,
    setErrorResponse,
    setIsLogin,
    setLoader,
    setPostNewValueUserReminderPreferences
} from '../../../actions/app/ui/ui.actions'

import {setNotification} from "../../../actions/core/notifications/notifications.actions";

export const userMiddleware = () => next => action => {
    next(action)

    switch (action.type){

        case FETCH_USER:
            next([
                apiRequest({body: null, method: 'GET', url: action.payload.data, entity: USER}),
                setLoader({state: true, entity: USER})
            ])
            break

        case `${USER} ${API_SUCCESS}`:
            next([
                setIsLogin({state: true, entity: USER}),
                setUser({user: action.payload.data}),
                setError({state: false, entity: USER}),
                setLoader({state: false, entity: USER})
            ])
            break

        case `${USER} ${API_ERROR}`:
            next([
                setIsLogin({state: false, entity: USER}),
                setError({state: true, entity: USER}),
                setErrorResponse({entity: USER, data: action?.payload?.data?.response?.data}),
                setLoader({state: false, entity: USER})
            ])
            break

        case POST_USER_REMINDER_PREFERENCES_PMQ:
            next([
                apiRequest({body: (action.payload.body), method: 'POST', url: action.payload.data, entity: POST_USER_REMINDER_PREFERENCES_PMQ, otherData: action.payload.otherData}),
                setLoader({state: true, entity: POST_USER_REMINDER_PREFERENCES_PMQ})
            ])
            break

        case `${POST_USER_REMINDER_PREFERENCES_PMQ} ${API_SUCCESS}`:
            next([
                setPostNewValueUserReminderPreferences({data: action.payload.meta.otherData.pmq_reminder_activate}),
                setUserPMQReminder({value: action.payload.meta.otherData.pmq_reminder_activate}),
                setError({state: false, entity: POST_USER_REMINDER_PREFERENCES_PMQ}),
                setLoader({state: false, entity: POST_USER_REMINDER_PREFERENCES_PMQ})
            ])
            break

        case `${POST_USER_REMINDER_PREFERENCES_PMQ} ${API_ERROR}`:
            next([
                setNotification({
                    entity: POST_USER_REMINDER_PREFERENCES_PMQ,
                    body: 'Une erreur est survenue lors de la mise à jour de vos préférences.',
                    type: 'error',
                    title: 'Attention !',
                    timeout: "0"
                }),
                setError({state: true, entity: POST_USER_REMINDER_PREFERENCES_PMQ}),
                setLoader({state: false, entity: POST_USER_REMINDER_PREFERENCES_PMQ})
            ])
            break
        default:
            break
    }
    return null
}
