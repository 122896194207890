export const QUOTE = '[Quote]'

export const FETCH_QUOTE = `${QUOTE} Fetch`
export const SET_QUOTE = `${QUOTE} Set`

export const fetchQuote = ({query, filters, detail}) => ({
    type: FETCH_QUOTE,
    payload: {
        data: query,
        filters,
        otherData: {
            detail
        }
    }
})
export const setQuote = ({data}) => ({
    type: SET_QUOTE,
    payload:{
        data
    }
})
