import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import DashboardData from "./DashboardData";
import {getDashboardLink, getStatsLink} from "../../../../../../redux/selectors/links/links.selectors";
import {DASHBOARD, fetchDashboard} from "../../../../../../redux/actions/app/dashboard/dashboard.actions";
import loader from '../../../../../Commun/Spinners/Loader/Loader'
import {getErrorEntity, getFiltersEntity, getLoadingEntity} from "../../../../../../redux/selectors/ui/ui.selectors";
import {getDataDashboard} from "../../../../../../redux/selectors/dashboard/dashboard.selectors";
import {
    getColor1Whitelabel,
    getColor2Whitelabel
} from "../../../../../../redux/selectors/whitelabel/whitelabel.selectors";
import {getStatsData} from "@/redux/selectors/stats/stats.selectors";
import {fetchStats} from "@/redux/actions/app/stats/stats.actions";

const mapStateToProps = state => {
    return {
        primary: getColor1Whitelabel(state),
        secondary: getColor2Whitelabel(state),
        query: getDashboardLink(state),
        filters: getFiltersEntity(state, DASHBOARD),
        data: getDataDashboard(state),
        loaded: getLoadingEntity(state, DASHBOARD) === false,
        error: getErrorEntity(state, DASHBOARD),
        stats: getStatsData(state),
        queryStats: getStatsLink(state),
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    fetchDashboard: ({query, filters}) => fetchDashboard({query, filters}),
    fetchStats: ({query}) => fetchStats({query})
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { query, filters, queryStats } = stateProps
    const { fetchDashboard, fetchStats } = dispatchProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => {
            fetchDashboard({query, filters})
            fetchStats({queryStats})
        }
    }
}

const DashboardDataContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader()
)(DashboardData)

export default DashboardDataContainer
