import {connect} from 'react-redux'
import {compose} from 'redux'
import {reduxForm} from "redux-form";
import OutFleet from "./OutFleet";
import {
    postOutFleetContract
} from "../../../../../../../../../redux/actions/app/contracts/fleet.contract.actions";
import moment from "moment";
import {getErrorEntity, getLoadingEntity} from "../../../../../../../../../redux/selectors/ui/ui.selectors";
import {getFleetDeleteLink} from "../../../../../../../../../redux/selectors/links/links.selectors";
import {getIdSouscripteurContract} from "../../../../../../../../../redux/selectors/contracts/contracts.selectors";

const mapStateToProps = (state, ownProps) => {
    const { id, ids } = ownProps.match.params
    const formName = `out-fleet-${ids}`

    return {
        query: getFleetDeleteLink(state),
        form: formName,
        initialValues: {
            date: moment(),
            id: ids,
            'id_contrat': id,
            'id_tiers': getIdSouscripteurContract(state)
        },
        loading: getLoadingEntity(state, formName),
        success: getLoadingEntity(state, formName) === false && getErrorEntity(state, formName) === false
    }
}

const mapDispatchToProps = {
    postOutFleetContract
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { query, form } = stateProps
    const { postOutFleetContract } = dispatchProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        onSubmit: (formulaire) => postOutFleetContract({form: formulaire, query, formName: form})
    }
}

const OutFleetContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    reduxForm()
)(OutFleet)

export default OutFleetContainer
