import React from 'react';

const ClausesHivernage = (props) => {
    const {} = props

    return (
        <div className='mt-5'>
            <p className={'font-weight-bold mb-0'}>Vous client n'utilise plus sa moto pendant l’hiver&nbsp;?</p>
            <p>Déclarez la période pendant laquelle sa moto est remisée au garage et faites-lui bénéficier d’une réduction sur sa cotisation d’assurance.</p>

            <p className={'font-weight-bold mb-0'}><span className={'text-warning'}>Ma période hivernale</span> est activable 1 seule fois</p>
            <p>Pour une durée maximale de 120 jours : du 1e octobre au 1e  mars de l’année suivante</p>

            <p className={'font-weight-bold mb-0'}><span className={'text-warning'}>Le + APRIL Moto</span> : une baisse de prime sans baisse de garanties !</p>
            <p>Pendant la durée de Ma période hivernale, votre client conserve les garanties de son contrat en circulation et en stationnement.</p>

            <p><span className={'font-weight-bold'}>Avantage</span> : il peut reprendre le guidon de manière occasionnelle et profiter d’une belle journée ensoleillée !</p>

            <div className={'alert alert-warning my-5 medium-rounded text-color-ternary'}>
                <p>En contrepartie de la réduction de cotisation applicable pendant la période stipulée, au cours de laquelle les garanties du contrat seront maintenues, le souscripteur :</p>

                <ul className={'font-size-14'}>
                    <li><span className={'font-weight-bold'}>accepte de supporter une franchise additionnelle de 450€ pour tout sinistre survenu pendant cette période</span> (hors sinistre survenu sur le lieu de stationnement du véhicule dans le garage clos et couvert individuel ou collectif pendant la période hivernale,  et sauf en cas de sinistre catastrophes naturelles)</li>
                </ul>

                <p className={'mb-0'}>A l’issue de la Période Hivernale stipulée, le contrat s’appliquera de nouveau dans les conditions de garanties et de prix antérieures à la signature de la lettre avenant.</p>
                <p className={'mb-0'}>Toute modification ultérieure sur le contrat qui interviendrait après la signature de la lettre avenant pendant la période hivernale, mettra automatiquement fin à la présente clause et à la réduction liée, au prorata du temps écoulé.</p>
            </div>
        </div>
    );
};

export default ClausesHivernage;