import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import loader from '../../../../../Commun/Spinners/Loader/Loader'
import DocumentsInactive from './DocumentsInactive'
import {getDocumentsInactiveLink} from "../../../../../../redux/selectors/links/links.selectors";
import {fetchDocumentsInactive} from "../../../../../../redux/actions/app/documents/documents.actions";
import {getDocumentsInactiveDocuments} from "../../../../../../redux/selectors/documents/documents.selectors";


const mapStateToProps = state => {
    return {
        inactive: getDocumentsInactiveDocuments(state),
        query: getDocumentsInactiveLink(state)
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    fetchDocumentsInactive: ({query}) => fetchDocumentsInactive({query})
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { query } = stateProps
    const { fetchDocumentsInactive } = dispatchProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => fetchDocumentsInactive({query})
    }
}

const DocumentsInactiveContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader()
)(DocumentsInactive)

export default DocumentsInactiveContainer
