import React from 'react'

/*
Usage :
<Field
    name={string}
    component={LargeSelectFieldRedux}
    label={string}
    subLabel={string}
    img={string}
>
    <option></option>
</Field>
 */

const LargeSelectFieldRedux = (props) => {
    const { meta: {touched, error}, input, label, sublabel, img, disabled, unit, ...custom } = props

    return (
        <div className={`form-group border text-center p-2 pb-5 h-100 rounded position-relative ${disabled && 'background-primary'}`}>
            <img src={img} alt={label} className={'max-width-2 w-100'}/>
            <div className={'mt-3'}>
                <p className={'font-weight-bold m-0'}>{label}</p>
                { sublabel && <p className={'m-0'}>{sublabel}</p> }
                { unit === "lots de 25 exemplaires" && <small><em>Lot de 25</em></small> }
                { unit === "lot de 10 exemplaires" && <small><em>Lot de 10</em></small> }

                <div className={'position-absolute'} style={{width: '90%', left: '50%', transform: 'translateX(-50%)', bottom: 20}}>
                    <select
                        className={'form-control'}
                        {...input}
                        {...custom}
                        disabled={disabled}
                    />
                    {touched && error && <small className="invalid-feedback">{error}</small>}
                </div>

            </div>
        </div>
    )
}

export default LargeSelectFieldRedux
