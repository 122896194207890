import React from 'react';
import PropTypes from 'prop-types'
import Card from "../../../../Commun/Card/Card";
import bordereauExplicatif from '../../../../../assets/pdf/explication_bordereau_2022.pdf'
import presentationRemuneration from '../../../../../assets/pdf/APRIL Moto_Remuneration courtiers_2024.pdf'
import charteCourtierGrandPrix from "../../../../../assets/pdf/Charte_Courtiers_Grand_Prix_APRIL-Moto-2024.pdf"
import _ from 'lodash'
import Collapse from '../../../../Commun/Collapse/Collapse'
import APILink from "../../../../Commun/APILink/APILink";

const Commissions = (props) => {
    const { commissions, dads } = props
    let properties = Object.keys(commissions).reverse()

    return (
        <div className={'p-lg-4'}>
            <Card>
                <div>
                    <h3 className={'m-0 pb-3 border-bottom border-dark'}>
                        Retrouvez l'historique des <span className={'text-color-secondary'}>commissions versées</span>
                    </h3>

                    <div className="mt-4">
                        <p><b>Information :</b> Le virement de vos commissions APRIL Moto apparaîtra sur votre compte bancaire sous le libellé <b><em>AM Gestion</em></b>.</p>
                    </div>

                    <div className="mt-4">
                        <p><span className={'h5'}><i className="far fa-question-circle text-color-secondary" /></span> Vous avez besoin d'aide pour lire vos bordereaux ? <a href={bordereauExplicatif} download className={'text-color-secondary font-weight-bold'}>Télécharger le bordereau explicatif&nbsp;!</a></p>
                    </div>

                    <h5><i className="fas fa-arrow-right text-color-secondary mr-3 mb-3 mt-5"/> Liste des commissions versées</h5>

                    <div className="row">
                        <div className="col-lg-8">
                            {
                                _.map(properties, p => (
                                    <Collapse key={p} title={`Commissions versées en ${p}`}>
                                        {
                                            _.map(commissions[p], (c, index) => (
                                                <div key={index} className={`p-3 border-${index > 0 ? 'top' : '0'} border-dark hover-light-background`}>
                                                    <p className={'m-0 d-inline-block'} style={{maxWidth: "60%"}}>
                                                        Commissions de <b>{c.libelle}</b>
                                                    </p>
                                                    <div className="d-inline-block float-right">
                                                        <APILink href={c.link_xls} download rel="noopener noreferrer" className={'h3 float-right'} >
                                                            <i className="text-color-secondary far fa-file-excel hover-dark-color" />
                                                        </APILink>
                                                        <APILink href={c.link_pdf} target={'_blank'} rel="noopener noreferrer" className={'h3 float-right mx-3'} >
                                                            <i className="text-color-secondary far fa-file-pdf hover-dark-color" />
                                                        </APILink>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </Collapse>
                                ))
                            }
                        </div>
                        <div className="col-lg-4">
                            <div className={'py-3 px-4 border-color-secondary border text-center medium-rounded'}>
                                <span className={'h4'}><i className="fas fa-info-circle text-color-secondary" /></span>
                                <p>Vous pouvez obtenir votre déclaration fiscale (DADS) en un clic&nbsp;!</p>
                                {
                                    dads.map(d => (
                                        d.year !== 2024 && (
                                            <div className="col-12" key={d.year}>
                                                <APILink href={d.link} download rel="noopener noreferrer" className={'btn btn-ternary text-center my-3 max-rounded'}>
                                                    <i className="far fa-file-pdf" /> Télécharger ma déclaration fiscale {d.year}
                                                </APILink>
                                            </div>
                                        )
                                    ))
                                }
                            </div>

                            <div className={'mt-4 py-3 px-4 border-color-secondary border text-center medium-rounded'}>
                                <span className={'h4'}><i className="fas fa-info-circle text-color-secondary" /></span>
                                <p>Retrouvez la présentation complète des rémunérations de nos contrats en un clic&nbsp;!</p>
                                <div className="col-12">
                                    <a href={presentationRemuneration} download className={'btn btn-ternary text-center my-3 max-rounded'}>
                                        <i className="far fa-file-pdf" /> Télécharger la présentation
                                    </a>
                                </div>
                            </div>

                            <div className={'mt-4 py-3 px-4 border-color-secondary border text-center medium-rounded'}>
                                <span className={'h4'}><i className="fas fa-info-circle text-color-secondary" /></span>
                                <p>Retrouvez la présentation du statut Courtier Grand Prix.</p>
                                <div className="col-12">
                                    <a href={charteCourtierGrandPrix} download className={'btn btn-ternary text-center my-3 max-rounded'}>
                                        <i className="far fa-file-pdf" /> Télécharger la charte
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </Card>
        </div>
    );
};

Commissions.defaultProps = {
    commissions: {},
    dads: []
}

Commissions.propTypes = {
    commissions: PropTypes.object,
    dads: PropTypes.array
}

export default Commissions;
