import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import ChangeOrias from "./ChangeOrias";
import {reduxForm, change, formValueSelector} from "redux-form";
import _ from "lodash";
import {getUpdateBuLink} from "../../../../../../../redux/selectors/links/links.selectors";
import {getOroBuIdUser} from "../../../../../../../redux/selectors/user/user.selectors";
import {postProfil} from "../../../../../../../redux/actions/app/profil/profil.actions";
import {
    getCapitalBusinessUnitProfil,
    getFormeJuridiqueBusinessUnitProfil,
    getImmatOriasBusinessUnitProfil,
    getNrcBusinessUnitProfil,
    getPartenairesBusinessUnitProfil,
    getVilleRCSBusinessUnitProfil
} from "../../../../../../../redux/selectors/profil/profil.selectors";

const mapStateToProps = (state) => {
    const selector = formValueSelector('change-orias-business_unit')
    let arrayIdPartenaires = []

    getPartenairesBusinessUnitProfil(state).map(p => {
        arrayIdPartenaires[p] = true
    })

    return {
        partenaires: selector(state, 'partenaires'),
        formeJuridique: selector(state, 'formeJuridique[id]'),
        query: _.replace(getUpdateBuLink(state), '{id}', getOroBuIdUser(state)),
        initialValues: {
            immatOrias: getImmatOriasBusinessUnitProfil(state),
            capital: getCapitalBusinessUnitProfil(state),
            nrc: getNrcBusinessUnitProfil(state),
            partenaires: getPartenairesBusinessUnitProfil(state),
            villercs: getVilleRCSBusinessUnitProfil(state),
            formeJuridique: {
                id: getFormeJuridiqueBusinessUnitProfil(state)
            },
            ...arrayIdPartenaires
        }
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    changeValue: (field, value) => change('change-orias-business_unit', field, value),
    postProfil: ({form, query}) => postProfil({form, query})
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {query} = stateProps
    const {postProfil} = dispatchProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        postProfil: form => postProfil({form, query})
    }
}

const ChangeOriasContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    reduxForm({
        form: 'change-orias-business_unit'
    })
)(ChangeOrias)

export default ChangeOriasContainer
