import {connect} from 'react-redux'
import {compose} from 'redux'
import {change, formValueSelector, reduxForm, reset} from "redux-form";
import moment from "moment";
import AddFleet from "./AddFleet";
import {getFleetAddLink} from "../../../../../../../redux/selectors/links/links.selectors";
import {
    POST_ADD_FLEET_CONTRACT,
    postAddFleetContract
} from "../../../../../../../redux/actions/app/contracts/fleet.contract.actions";
import {
    getErrorEntity,
    getLoadingEntity
} from "../../../../../../../redux/selectors/ui/ui.selectors";
import validate from './validate'
import {
    getIdSouscripteurContract,
    getNumeroPoliceContract
} from "../../../../../../../redux/selectors/contracts/contracts.selectors";

const mapStateToProps = (state, ownProps) => {
    const selector = formValueSelector('add-fleet')

    return {
        query: getFleetAddLink(state),
        typeSelected: selector(state, 'type'),
        brandSelected: selector(state, 'marque'),
        file: selector(state, 'file'),
        loading: getLoadingEntity(state, POST_ADD_FLEET_CONTRACT),
        success: getLoadingEntity(state, POST_ADD_FLEET_CONTRACT) === false && getErrorEntity(state, POST_ADD_FLEET_CONTRACT) === false,
        numeroPolice: getNumeroPoliceContract(state),
        initialValues:{
            id: ownProps.id,
            'date_mec': moment(),
            'id_tiers': getIdSouscripteurContract(state),
            'assistance': getNumeroPoliceContract(state) === '61866386/1' && 'F'
        }
    }
}

const mapDispatchToProps = {
    postAddFleetContract,
    changeValue: (field, value) => change('add-fleet', field, value),
    reset: () => reset('add-fleet')
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { query } = stateProps
    const { postAddFleetContract } = dispatchProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        onSubmit: (form) => postAddFleetContract({query, form})
    }
}

const AddFleetContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    reduxForm({
        form: 'add-fleet',
        validate
    })
)(AddFleet)

export default AddFleetContainer
