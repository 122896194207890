import {createSelector} from 'reselect'

const linksSelector = state => state.links.links

/***** USER *****/
export const getUserLink = createSelector(
    linksSelector,
    links => links['user.get']?.uri
)

export const userLogoutLink = createSelector(
    linksSelector,
    links => links['user.logout'].uri
)

export const getPoppinCharteLink = createSelector(
    linksSelector,
    links => links['user.charterSigned'].uri
)

/***** WHITELABEL *****/
export const getWhitelabelLink = createSelector(
    linksSelector,
    links => links['whitelabel.get'].uri
)

/***** QUOTES *****/
export const getQuoteLink = createSelector(
    linksSelector,
    links => links['quotes.get'].uri
)
export const getQuotesLink = createSelector(
    linksSelector,
    links => links['quotes'].uri
)

/**Tiers**/
export const getTiersLink = createSelector(
    linksSelector,
    links => links['tiers.get'].uri
)

/***** CONTRACTS *****/
export const getContractsLink = createSelector(
    linksSelector,
    links => links['contracts'].uri
)
export const getContractLink = createSelector(
    linksSelector,
    links => links['contracts.get'].uri
)
export const getDocumentsContractLink = createSelector(
    linksSelector,
    links => links['contracts.documents'].uri
)
export const getDocumentsPostContractsLink = createSelector(
    linksSelector,
    links => links['contracts.documents.post'].uri
)
export const getAccidentsContractLink = createSelector(
    linksSelector,
    links => links['contracts.accidents'].uri
)
export const getAccidentContractLink = createSelector(
    linksSelector,
    links => links['contracts.accidents.get'].uri
)
export const getQuittancesContractLink = createSelector(
    linksSelector,
    links => links['contracts.receipts'].uri
)
export const getContractsAccidentRejectPostLink = createSelector(
    linksSelector,
    links => links['contracts.accident.reject.post'].uri
)
export const getContractsAccidentDocumentsPostLink = createSelector(
    linksSelector,
    links => links['contracts.accident.documents.post'].uri
)

export const getAccidentRepairerUpdateLink = createSelector(
    linksSelector,
    links => links['contracts.accident.repairer.update'].uri
)

export const postChangeSituationContractLink = createSelector(
    linksSelector,
    (links) => links['contracts.validate'].uri
)

/***** CONTRACT *****/
export const postContractFractionnementUpdate = createSelector(
    linksSelector,
    links => links['contract.fractionnement.update'].uri
)

/***** STATS *****/
export const getStatsLink = createSelector(
    linksSelector,
    links => links['stats'].uri
)

/***** COMMISSIONS *****/
export const getCommissionsLink = createSelector(
    linksSelector,
    links => links['commissions'].uri
)

/***** ACCIDENTS *****/
export const getAccidentsLink = createSelector(
    linksSelector,
    links => links['accidents'].uri
)

/***** QUITTANCES ******/
export const getReceiptsLink = createSelector(
    linksSelector,
    links => links['receipts.get'].uri
)
export const getAllReceiptsLink = createSelector(
    linksSelector,
    links => links['receipts'].uri
)

/***** FLYERS ******/
export const getFlyersLink = createSelector(
    linksSelector,
    links => links['flyers'].uri
)
export const getFlyersOrderLink = createSelector(
    linksSelector,
    links => links['flyer.order'].uri
)

/***** DOCUMENTS *****/
export const getDocumentsActiveLink = createSelector(
    linksSelector,
    links => links['documents.active'].uri
)
export const getDocumentsInactiveLink = createSelector(
    linksSelector,
    links => links['documents.inactive'].uri
)
export const getDocumentsNeededAccidentGetLink = createSelector(
    linksSelector,
    links => links['documents.needed.accident.get'].uri
)
export const getQueryDocumentLink = createSelector(
    linksSelector,
    links => links['query.document'].uri
)

/***** PRODUITS *****/
export const getProductsLink = createSelector(
    linksSelector,
    links => links['products'].uri
)

/***** CONTACTS *****/
export const getRepresentativesLink = createSelector(
    linksSelector,
    links => links['representatives'].uri
)

/***** FEEDBACK *****/
export const getFeedBackLink = createSelector(
    linksSelector,
    links => links['query.feedback'].uri
)

/***** BUSINESS UNIT *****/
export const getUpdateBuLink = createSelector(
    linksSelector,
    links => links['update.bu'].uri
)

export const getUpdateSelligentUserLink = createSelector(
    linksSelector,
    links => links['update.selligent.user'].uri
)

export const getUpdateLogoLink = createSelector(
    linksSelector,
    links => links['update.logo'].uri
)

export const getUsersBuLink = createSelector(
    linksSelector,
    links => links['users_bu'].uri
)

export const getUpdateMarqueLink = createSelector(
    linksSelector,
    links => links['update.marque'].uri
)

/***** ARTICLES *****/
export const getArticlesLink = createSelector(
    linksSelector,
    links => links['articles'].uri
)

/***** DASHBOARD *****/
export const getDashboardLink = createSelector(
    linksSelector,
    links => links['dashboard'].uri
)

/***** AMENDMENT *****/
export const getAmendmentChangeContractLink = createSelector(
    linksSelector,
    links => links['amendment.change_contact'].uri
)
export const getAmendmentChangeIbanContractLink = createSelector(
    linksSelector,
    links => links['amendment.change_iban'].uri
)
export const amendmentHivernageGetLink = createSelector(
    linksSelector,
    links => links['amendment.hivernage'].uri
)
export const amendmentFinHivernageGetLink = createSelector(
    linksSelector,
    links => links['amendment.fin_hivernage'].uri
)
export const getAmendmentChangeAddressLink = createSelector(
    linksSelector,
    links => links['amendment.change_address'].uri
)

/***** CUSTOMER *****/
export const getCustomerCreateLink = createSelector(
    linksSelector,
    (links) => links['customer.create'].uri
)
export const getCustomerActivityLogsLink = createSelector(
    linksSelector,
    links => links['customer.activitylogs'].uri
)

export const getCustomerResetPasswordLink = createSelector(
    linksSelector,
    links => links['pro.reset_password'].uri
)

/***** POPINS *****/
export const getPopinsLink = createSelector(
    linksSelector,
    links => links['popins'].uri
)

/***** PAY *****/
export const getPayMailLink = createSelector(
    linksSelector,
    links => links['pay.mail'].uri
)

export const getPayDepositLink = createSelector(
    linksSelector,
    links => links['pay.deposit'].uri
)

/***** Fleet *****/
export const getFleetLink = createSelector(
    linksSelector,
    links => links['fleet'].uri
)
export const getFleetGetLink = createSelector(
    linksSelector,
    links => links['fleet.get'].uri
)
export const getFleetDeleteLink = createSelector(
    linksSelector,
    links => links['fleet.delete'].uri
)

export const getFleetVehiclesDeleteLink = createSelector(
    linksSelector,
    links => links['fleet.vehicles.delete'].uri
)

export const getFleetAddLink = createSelector(
    linksSelector,
    links => links['fleet.add'].uri
)
export const getFleetReactivateLink = createSelector(
    linksSelector,
    links => links['fleet.reactivate'].uri
)

export const InsurersGetLink = createSelector(
    linksSelector,
    links => links['insurer.get'].uri
)

export const signaturesGetLink = createSelector(
    linksSelector,
    links => links['contracts.signatures'].uri
)

export const contractsSendSignLink = createSelector(
    linksSelector,
    links => links['contracts.send_sign'].uri
)

export const casIdaLink = createSelector(
    linksSelector,
    links => links['contracts.accident.ida'].uri
)

export const getDecCirconstanciee = createSelector(
    linksSelector,
    links => links['contracts.declarationcirconstanciee'].uri
)

export const changeBirthInfosTiersLink = createSelector(
    linksSelector,
    links => links['tiers.birth.update'].uri
)

export const ThirdPartyPayerGetLink = createSelector(
    linksSelector,
    links => links['contracts.third_party_payer.get'].uri
)

export const updateThirdPartyPayerGetLink = createSelector(
    linksSelector,
    links => links['contracts.third_party_payer.update'].uri
)

export const deleteThirdPartyPayerGetLink = createSelector(
    linksSelector,
    links => links['contracts.third_party_payer.delete'].uri
)

export const emailPMQPostLinks = createSelector(
    linksSelector,
    links => links['email.pmq'].uri
)

export const updateReminderPreferences = createSelector(
    linksSelector,
    links => links['update.reminder.preferences'].uri
)

export const terminateGetLink = createSelector(
    linksSelector,
    links => links['contracts.resiliation.post'].uri
)
export const confirmationResilHamonGetLink = createSelector(
    linksSelector,
    links => links['contracts.hamon.update_resil'].uri
)
