import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import _ from 'lodash'
import {reduxForm} from "redux-form";
import validate from './validate'
import InfosPerso from "./InfosPerso";
import loader from "../../../../../../../Commun/Spinners/Loader/Loader";
import {
    getAmendmentChangeContractLink,
    getContractLink
} from "../../../../../../../../redux/selectors/links/links.selectors";
import {
    getContract, getEmailSouscripteurContract,
    getIdSouscripteurContract, getPhoneSouscripteurContract
} from "../../../../../../../../redux/selectors/contracts/contracts.selectors";
import {getLoadingEntity} from "../../../../../../../../redux/selectors/ui/ui.selectors";
import {fetchContract} from "../../../../../../../../redux/actions/app/contracts/contracts.actions";
import {postInfosPerso} from "../../../../../../../../redux/actions/app/contracts/avenants.contract.actions";

const mapStateToProps = (state, ownProps) => {
    const { id } = ownProps.match.params
    const formName = `infos-personnelles-${id}`

    return {
        query: _.replace(getContractLink(state), '{id}', id),
        tiers: getIdSouscripteurContract(state),
        contract: getContract(state),
        phone: getPhoneSouscripteurContract(state),
        email: getEmailSouscripteurContract(state),
        queryPost: getAmendmentChangeContractLink(state),
        form: formName,
        loading: getLoadingEntity(state, formName),
        initialValues:{
            tel_mobile: getPhoneSouscripteurContract(state) || '',
            tel_fixe: getPhoneSouscripteurContract(state) || '',
            email: getEmailSouscripteurContract(state) || ''
        }
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    fetchContract: ({query}) => fetchContract({query}),
    postInfosPerso: ({form, query, formName}) => postInfosPerso({form, query, formName})
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { query, contract, form, queryPost, tiers } = stateProps
    const { fetchContract, postInfosPerso } = dispatchProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => _.isEmpty(contract) ? fetchContract({query}) : null,
        onSubmit: (formulaire) => postInfosPerso({form: {...formulaire, tiers}, query: queryPost, formName: form})
    }
}

const InfosPersoContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    reduxForm({
        validate
    }),
    loader()
)(InfosPerso)

export default InfosPersoContainer
