export const FEEDBACK = '[FeedBack]'


export const POST_FEEDBACK = `${FEEDBACK} Post`


export const postFeedBack = ({query, form}) => ({
    type: POST_FEEDBACK,
    payload: {
        data: query,
        body: form
    }
})