import React from 'react';
import LayoutContainer from "../Layout/LayoutContainer";

const Whitelabel = () => {
    return (
        <div>
            <LayoutContainer />
        </div>
    );
};

export default Whitelabel;
