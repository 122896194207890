export const POPINS = '[Popins]'

export const FETCH_POPINS = `${POPINS} Fetch`
export const SET_POPINS = `${POPINS} Set`
export const POST_POPPIN_CHARTE = `${POPINS} POST_POPPIN_CHARTE`

export const fetchPopins = ({query}) => ({
    type: FETCH_POPINS,
    payload: {
        data: query
    }
})

export const setPopins = ({data}) => ({
    type: SET_POPINS,
    payload:{
        data
    }
})

export const postPoppinCharte = ({ query }) => ({
    type: POST_POPPIN_CHARTE,
    payload:{
        data: query
    }
})
