export const UPLOAD = '[Upload]'

export const POST_UPLOAD = `${UPLOAD} Post`

export const postUpload = ({query, form, formName}) => ({
    type: POST_UPLOAD,
    payload: {
        data: query,
        body: form,
        meta: formName
    }
})
