import {
    POST_PROFIL, setProfil,
} from "../../../actions/app/profil/profil.actions";
import {API_ERROR, API_SUCCESS, apiRequest} from "../../../actions/core/api/api.actions";
import {setNotification} from "../../../actions/core/notifications/notifications.actions";
import {
    setLoader,
    setError
} from "../../../actions/app/ui/ui.actions";

export const profilMiddleware = dispatch => next => action => {
    next(action)

    const { payload } = action

    switch (action.type){

        case POST_PROFIL:
            next([
                apiRequest({body: (payload.body), method: 'POST', url: payload.data, entity: POST_PROFIL}),
                setLoader({state: true, entity: POST_PROFIL})
            ])
            break

        case `${POST_PROFIL} ${API_SUCCESS}`:
            next([
                setProfil({user: action.payload.data}),
                setError({state: false, entity: POST_PROFIL}),
                setLoader({state: false, entity: POST_PROFIL})
            ])
            break

        case `${POST_PROFIL} ${API_ERROR}`:

            next([
                setNotification({entity: POST_PROFIL, body: payload.data.response.data.message, type: 'error', title: 'Attention ! Modification des informations :', timeout: "0"}),
                setError({state: true, entity: POST_PROFIL}),
                setLoader({state: false, entity: POST_PROFIL})
            ])
            break

        default:
            break
    }
    return null
}
