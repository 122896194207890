import {connect} from 'react-redux'
import {compose} from "redux";
import RelanceUser from "./RelanceUser";
import {reduxForm} from "redux-form";
import {
    getLoadingEntity,
    getUserReminderPMQNewValue
} from "../../../../../../redux/selectors/ui/ui.selectors";
import {updateReminderPreferences} from "../../../../../../redux/selectors/links/links.selectors";
import {getPMQRemind} from "../../../../../../redux/selectors/user/user.selectors";
import {
    setPostNewValueUserReminderPreferences
} from "../../../../../../redux/actions/app/ui/ui.actions";
import {
    POST_USER_REMINDER_PREFERENCES_PMQ,
    postUserReminderPreferences
} from "../../../../../../redux/actions/app/user/user.actions";

const mapStateToProps = state => {
    return {
        new_value: getUserReminderPMQNewValue(state),
        loading: getLoadingEntity(state, POST_USER_REMINDER_PREFERENCES_PMQ),
        query: updateReminderPreferences(state),
        initialValues:{
            pmq_reminder_activate: getPMQRemind(state),
        }
    }
}

const mapDispatchToProps = {
    postUserReminderPreferences,
    setPostNewValueUserReminderPreferences
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { query } = stateProps
    const { postUserReminderPreferences } = dispatchProps
	
    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        submitReminderPreferences: ({values}) => postUserReminderPreferences({query, values}),
    }
}

const RelanceUserContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    reduxForm({
        form: 'change-reminder-preferences'
    })
)(RelanceUser)

export default RelanceUserContainer