export const UI = '[Ui]'

export const SET_LOADER = `${UI} SET_LOADER`
export const RESET_LOADER = `${UI} RESET_LOADER`
export const SET_IS_LOGIN = `${UI} SET_IS_LOGIN`
export const SET_IS_LOGOUT = `${UI} SET_IS_LOGOUT`
export const SET_MAINTENANCE = `${UI} SET_MAINTENANCE`
export const SET_ERROR = `${UI} SET_ERROR`
export const SET_RESPONSE_ERROR = `${UI} SET_RESPONSE_ERROR`
export const SET_FILTER = `${UI} SET_FILTER`
export const SET_CHANGE_ADDRESS = `${UI} SET_CHANGE_ADDRESS`
export const SET_PAY_MAIL = `${UI} SET_PAY_MAIL`
export const SET_CLOSE_POPPIN_CHARTE = `${UI} SET_CLOSE_POPPIN_CHARTE`
export const SET_CV_AFTER_ADD_FLEET = `${UI} SET_CV_AFTER_ADD_FLEET`
export const SET_RESPONSE_POST_ACCIDENT = `${UI} SET_RESPONSE_POST_ACCIDENT`
export const SET_NEW_VALUE_POST_USER_REMINDER_PREFERENCES_PMQ = `${UI} SET_NEW_VALUE_POST_USER_REMINDER_PREFERENCE_PMQ`
export const SET_HIVERNAGE_SUCCESS = `${UI} SET_HIVERNAGE_SUCCESS`
export const SET_HIVERNAGE_REDUCTION = `${UI} SET_HIVERNAGE_REDUCTION`


export const setLoader = ({state, entity}) => ({
    type: `${entity} ${SET_LOADER}`,
    payload: state,
    meta: entity
})

export const resetLoader = ({entity}) => ({
    type: `${entity} ${RESET_LOADER}`,
    payload: true
})

export const setError = ({state, entity}) => ({
    type: `${entity} ${SET_ERROR}`,
    payload: state,
    meta: entity
})

export const setErrorResponse = ({entity, data, html}) => ({
    type: `${entity} ${SET_RESPONSE_ERROR}`,
    payload: {
        data, html
    }
})

export const setIsLogin = ({state, entity}) => ({
    type: `${entity} ${SET_IS_LOGIN}`,
    payload: state
})

export const setIsLogout = ({state, entity}) => ({
    type: `${entity} ${SET_IS_LOGOUT}`,
    payload: state
})

export const setMaintenance = ({state, entity}) => ({
    type: `${entity} ${SET_MAINTENANCE}`,
    payload: state
})

export const setFilters = ({entity, filters}) => ({
    type: `${entity} ${SET_FILTER}`,
    payload: {
        ...filters
    },
    meta: entity
})

export const setChangeAddress = ({data, formName}) => ({
    type: SET_CHANGE_ADDRESS,
    payload:{
        data, formName
    }
})

export const setPayMail = ({data}) => ({
    type: SET_PAY_MAIL,
    payload: {
        data
    }
})

export const setClosePoppinCharte = ({state}) => ({
    type: SET_CLOSE_POPPIN_CHARTE,
    payload: {
        data: state
    }
})

export const setPostAccidentResponse = ({data}) => ({
    type: SET_RESPONSE_POST_ACCIDENT,
    payload: {
        data
    }
})

export const setPostNewValueUserReminderPreferences = ({data}) => ({
    type: SET_NEW_VALUE_POST_USER_REMINDER_PREFERENCES_PMQ,
    payload: {
        data
    }
})

export const setHivernageSuccess = ({state, entity}) => ({
    type: SET_HIVERNAGE_SUCCESS,
    payload: {
        state: state,
        entity: entity
    }
})

export const setHivernageReduction = ({state, entity}) => ({
    type: SET_HIVERNAGE_REDUCTION,
    payload: {
        state: state,
        entity: entity
    }
})
