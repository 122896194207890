import { connect } from 'react-redux';
import { compose } from "redux";
import EditionAttestationNvei from "./EditionAttestationNvei";
import _ from "lodash";
import {
    getQuittancesContractLink,
} from "../../../../../../../redux/selectors/links/links.selectors";
import { getQuittancesContract } from "../../../../../../../redux/selectors/contracts/contracts.selectors";
import loader from "../../../../../../Commun/Spinners/Loader/Loader";
import {
    fetchQuittancesContract,
    QUITTANCES_CONTRACT
} from "../../../../../../../redux/actions/app/contracts/quittances.contract.actions";
import { reduxForm } from "redux-form";
import { getLoadingEntity } from "../../../../../../../redux/selectors/ui/ui.selectors";

const mapStateToProps = (state, ownProps) => {
    return {
        query_quittances: _.replace(getQuittancesContractLink(state), '{id}', ownProps.match.params.id),
        quittances: getQuittancesContract(state),
        loaded: getLoadingEntity(state, QUITTANCES_CONTRACT) === false
    }
}

const mapDispatchToProps = {
    fetchQuittancesContract
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { query_quittances, quittances } = stateProps;
    const { fetchQuittancesContract } = dispatchProps;

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => _.isEmpty(quittances) ? fetchQuittancesContract({ query: query_quittances }) : null,
    }
}

const EditionAttestationNveiContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    reduxForm({
        form: 'edition-attestation-assurance-nvei'
    }),
    loader()
)(EditionAttestationNvei);

export default EditionAttestationNveiContainer;
