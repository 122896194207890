import React, {useState} from 'react';
import PropTypes from 'prop-types'
import './uploadinline.scss'

const UploadInline = (props) => {
    const { changeValue, label, code, meta: {touched, error}, file, isForm } = props
    const [name, setName] = useState(null)

    const handleChange = file => {
        if (file){
            setName(file.name)
            changeValue(code, file)
        }
    }

    isForm && !file && name && setName(null)

    return (
        <div className={'form-group'}>
            {label && <label>{label}</label> }
            <div className="position-relative">
                <input type="file" className="custom-file-input" onChange={(e) => handleChange(e.target.files[0])}/>
                <label className={`${name ? 'valid' : ''} custom-file-label overflow-hidden w-100`}>{`${name || 'Choisissez un fichier...'}`}</label>
            </div>
            {touched && error && <small className="invalid-feedback">{error}</small>}
        </div>
    );
};

UploadInline.defaultProps = {
    changeValue: () => console.log('changeValue'),
    code: 'X',
    label: '',
    input: {},
    touched: false,
    error: ''
};

UploadInline.propTypes = {
    changeValue: PropTypes.func,
    label: PropTypes.string,
    code: PropTypes.string,
    input: PropTypes.object,
    touched: PropTypes.bool,
    error: PropTypes.string,
    reset: PropTypes.func,
    file: PropTypes.object
};

export default UploadInline;
