import {createSelector} from 'reselect'

const contactsSelector = state => state.contacts

export const getSalesRepresentativeContacts = createSelector(
    contactsSelector,
    contacts => contacts.salesrepresentatives || []
)

export const getSalesAssistantsContacts = createSelector(
    contactsSelector,
    contacts => contacts.salesassistants || []
)

export const getFirstSalesRepresentativeContacts = createSelector(
    getSalesRepresentativeContacts,
    salesrepresentatives => salesrepresentatives[0]
)

export const getFirstSalesAssistantsContacts = createSelector(
    getSalesAssistantsContacts,
    salesassistants => salesassistants[0]
)