import React from 'react';
import Card from "../../../../../../Commun/Card/Card";
import AddFleetContainer from "./AddFleetContainer";

const ContractAddFleet = (props) => {
    const { match: { params: { id } } } = props

    return (
        <div className={'p-4'}>
            <Card>
                <AddFleetContainer id={id} />
            </Card>
        </div>
    );
};

export default ContractAddFleet;
