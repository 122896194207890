import React, { useState, useEffect } from "react";
import { Field } from "redux-form";
import moment from "moment";
import DatePickerFieldRedux from "@/components/Commun/Inputs/Date/DatePickerFieldRedux";
import CheckboxFieldRedux from "@/components/Commun/Inputs/Checkbox/CheckboxFieldRedux";
import SmallSpinner from "@/components/Commun/Spinners/SmallSpinner";
import { Link } from "react-router-dom";
import Card from "@/components/Commun/Card/Card";

const DeleteVehiculesFlotte = ({ data, handleSubmit, changeValue, selected_vehicules, loading, contractId }) => {
    const [minDate, setMinDate] = useState(moment().subtract(3, "months")); // Default minDate

    useEffect(() => {
        // Calculate the most recent entry date from all vehicles in data
        const mostRecentDate = data.data.reduce((latestDate, vehicule) => {
            const vehicleDate = moment(vehicule.dateEntree);
            return vehicleDate.isAfter(latestDate) ? vehicleDate : latestDate;
        }, moment('1970-01-01')); // Initialize with a very early date

        setMinDate(mostRecentDate);
    }, [data.data]);

    const filteredData = data.data.filter(vehicule => !vehicule.inactif);

    const updateVehiculesSelected = (e, vehiculeAdhesion) => {
        const selected = selected_vehicules || [];
        const updatedSelected = e ? [...selected, vehiculeAdhesion] : selected.filter((id) => id !== vehiculeAdhesion);
        changeValue('selected_vehicules', updatedSelected);
    };

    const renderMessage = () => {
        if (selected_vehicules && selected_vehicules.length === filteredData.length) {
            return (
                <div className="alert alert-warning" role="alert">
                    Conseil APRIL Moto : En retirant le dernier véhicule de votre contrat, celui-ci sera automatiquement terminé. Si vous souhaitez remplacer votre 2-roues par un nouveau véhicule, ajoutez d'abord le nouveau 2-roues.
                </div>
            );
        }
    };

    return (
        <div className={'p-4'}>
            <Card>
                <h3 className={'m-0 pb-3 border-bottom border-dark'}>
                    Choisissez les véhicules <span className={'text-color-secondary'}>à sortir de la flotte</span>
                </h3>

                <div className="mt-5">
                    <Link className={'btn btn-outline-secondary max-rounded'} to={`/mon-contrat/${contractId}`}>
                        <i className={'fas fa-chevron-left mr-3'}/> Retour au contrat
                    </Link>
                </div>
                <form className={'mt-5 col-lg-6 col-md-12'} onSubmit={handleSubmit}>

                    <div className="row">
                        {filteredData.map((vehicule) => (
                            <div key={vehicule.adhesion} className="col-md-4">
                                <div className="form-check form-check-inline">
                                    <Field
                                        name={`vehicules[${vehicule.adhesion}]`}
                                        component={CheckboxFieldRedux}
                                        type="checkbox"
                                        id={`vehicule-${vehicule.adhesion}`}
                                        onChange={(e) => updateVehiculesSelected(e.target.checked, vehicule.adhesion)}
                                    />
                                    <label className="form-check-label" htmlFor={`vehicule-${vehicule.adhesion}`}>
                                        <strong className="font-weight-bold">{vehicule.marque} {vehicule.modele}</strong> - {vehicule.immatriculation}
                                    </label>
                                </div>
                            </div>
                        ))}
                    </div>
                    <hr/>
                    <div className="row">
                        <div className="col-4">
                            <Field
                                name="date_sortie"
                                component={DatePickerFieldRedux}
                                label="Date de sortie"
                                minDate={minDate} // Set the dynamic minDate here
                                maxDate={moment()}
                            />
                        </div>
                        <div className="col-12">
                            {renderMessage()}
                        </div>
                        <div className={'alert alert-warning mt-2 mb-2'}>
                            <p>Par véhicule, vous déclarez qu’au moins une des conditions ci-dessous est respectée :<br/>
                                - Le véhicule est vendu et le souscripteur dispose d’un certificat de vente <br/>
                                - Le véhicule est volé et le souscripteur dispose d’une plainte pour le vol du véhicule
                            </p>
                        </div>
                        <button className="btn btn-primary d-block m-auto" type="submit" disabled={loading}>
                            {loading && <SmallSpinner absolute/>}
                            Sortir les véhicules
                        </button>
                    </div>
                </form>
            </Card>
        </div>
    );
}

export default DeleteVehiculesFlotte;
