import {connect} from 'react-redux'
import Electrique from "./Electrique";
import {getSubscribeLinksUser} from "../../../../../../redux/selectors/user/user.selectors";
import {getProduit} from "../../../../../../redux/selectors/produits/produits.selectors";
import {getProductsLink} from "../../../../../../redux/selectors/links/links.selectors";
import {getErrorEntity, getLoadingEntity} from "../../../../../../redux/selectors/ui/ui.selectors";
import {fetchProduits, PRODUITS} from "../../../../../../redux/actions/app/produits/produits.actions";
import {bindActionCreators, compose} from "redux";
import loader from "../../../../../Commun/Spinners/Loader/Loader";

const mapStateToProps = state => {
    return {
        subscribeLinks: getSubscribeLinksUser(state),
        produitMoto: getProduit(state, 'moto'),
        produitCyclo: getProduit(state, '50'),
        query: getProductsLink(state),
        loaded: getLoadingEntity(state, PRODUITS) === false,
        error: getErrorEntity(state, PRODUITS)
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    fetchProduits: ({query}) => fetchProduits({query})
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { query } = stateProps
    const { fetchProduits } = dispatchProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => fetchProduits({query})
    }
}

const ElectriqueContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader()
)(Electrique)

export default ElectriqueContainer
