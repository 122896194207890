import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import ContractsListe from './ContractsListe'
import {getContractsLink} from "../../../../../../redux/selectors/links/links.selectors";
import {getFiltersEntity} from "../../../../../../redux/selectors/ui/ui.selectors";
import {CONTRACT, fetchContract} from "../../../../../../redux/actions/app/contracts/contracts.actions";
import {
    getBuChildrenUser,
    getSubscribeLinksUser,
    getUserIsConcessionnaire, getUserIsCourtier
} from "../../../../../../redux/selectors/user/user.selectors";

const mapStateToProps = state => {
    return {
        filters: getFiltersEntity(state, CONTRACT),
        isConcessionnaire: getUserIsConcessionnaire(state),
        isCourtier: getUserIsCourtier(state),
        subscribeLinks: getSubscribeLinksUser(state),
        apporteurs: getBuChildrenUser(state),
        query: getContractsLink(state)
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    fetchContract: ({query, filters}) => fetchContract({query, filters})
}, dispatch)

const ContractsListeContainer = connect(mapStateToProps, mapDispatchToProps)(ContractsListe)

export default ContractsListeContainer
