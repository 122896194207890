import {createSelector} from 'reselect'

const accidentsSelector = state => state.accidents

/***** DATA ACCIDENTS *****/
export const getDataAccidents = createSelector(
    accidentsSelector,
    accidents => accidents.data
)

/***** META ACCIDENTS *****/
export const getMetaAccidents = createSelector(
    accidentsSelector,
    accidents => accidents.meta || {}
)

/***** LINKS ACCIDENTS *****/
export const getLinksAccidents = createSelector(
    accidentsSelector,
    accidents => accidents.links
)

export const getCategorieCodeAccident = createSelector(
    accidentsSelector,
    data => data.categorieCode
)

export const getGarantiesAccident = createSelector(
    accidentsSelector,
    data => data.garanties
)