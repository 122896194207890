import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types'
import { FieldArray } from 'redux-form'
import {getCompagnys} from "../../../../../../../../../../utils/function";
import Cadre from "../../../../../../../../../Commun/Cadre/Cadre";
import FieldArrayAdversaires from "./FieldArrayAdversaires";

const Adversaires = (props) => {
    const { changeValue, adversaires, nbVehicules } = props
    const [compagnys, setCompagnys] = useState([])

    useEffect(() => {
        (async function fetchCompagnys(){
            let compagnys = await getCompagnys()

            const listcompanys = compagnys.map(company => ({
                value: company.id,
                codegta: company.codegta,
                label: company.name + ' - ' + company.adresse + ' - ' + company.codePostal + ' ' + company.ville
            }))

            setCompagnys(listcompanys)
        })()
    }, [])

    return (
        <div className={'form-content'}>
            <Cadre icon={'fas fa-user'} title={'Adversaire(s)'}>
                <FieldArray
                    name={'adversaires'}
                    nbVehicules={nbVehicules}
                    component={FieldArrayAdversaires}
                    compagnys={compagnys}
                    changeValue={changeValue}
                    adversaires={adversaires}
                />
            </Cadre>
        </div>
    );
};

Adversaires.propTypes = {
    changeValue: PropTypes.func,
    adversaires: PropTypes.array
};

export default Adversaires;
