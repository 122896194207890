export const ANALYTICS = '[Analytics]'

export const SET_ANALYTICS = `${ANALYTICS} Set`

export const setAnalytics = ({entity}) => ({
    type: `${entity} ${SET_ANALYTICS}`,
    payload : {
        entity
    }
})

export const setTrackPage = ({page}) => ({
    type: `${ANALYTICS} ${page}`,
    meta: {
        entity: ANALYTICS,
        analytics: {
            event: 'Page View',
            actionGoogle: page
        },
    }
})
