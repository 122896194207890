import {PAYMENT, POST_PAYMENT} from '../../../actions/app/payment/payment.actions'
import {API_ERROR, API_SUCCESS, apiRequest} from '../../../actions/core/api/api.actions'
import {setError, setLoader} from '../../../actions/app/ui/ui.actions'
import {setNotification} from '../../../actions/core/notifications/notifications.actions'


export const paymentMiddleware = () => next => action => {
    next(action)

    const { payload } = action

    switch (action.type){
        case POST_PAYMENT:
            next([
                apiRequest({body: (payload.body), method: 'POST', url: payload.data, entity: PAYMENT}),
                setLoader({state: true, entity: PAYMENT})
            ])
            break

        case `${PAYMENT} ${API_SUCCESS}`:
            next([
                setLoader({state: false, entity: PAYMENT}),
                setError({state: false, entity: PAYMENT}),
                setNotification({entity: PAYMENT, body: `<p>Le mail de paiement a bien été envoyé au client</p>`, type: 'success', title: 'Le mail envoyé !'})
            ])

            break

        case `${PAYMENT} ${API_ERROR}`:
            next([
                setNotification({
                    entity: PAYMENT,
                    body: 'Une erreur est survenue lors de l\'envoi de l\'email de paiement au client.',
                    type: 'error',
                    title: 'Attention !',
                    timeout: "0"
                }),
                setError({state: true, entity: PAYMENT}),
                setLoader({state: false, entity: PAYMENT}),
            ])
            break

        default:
            break;
    }
    return null
}
