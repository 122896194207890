import React, {useEffect} from 'react';
import PropTypes from 'prop-types'
import {Field} from "redux-form";
import TextFieldRedux from "../../../../../../../../../Commun/Inputs/Text/TextFieldRedux";
import RadioDesignButton from "../../../../../../../../../Commun/Inputs/Radio/RadioDesignButton/RadioDesignButton";
import PostalCodeCity from "../../../../../../../../../Commun/Inputs/PostalCodeCity/PostalCodeCity";
import _ from 'lodash'
import FilteredAutocomplete from "../../../../../../../../../Commun/FilteredAutocomplete/FilteredAutocomplete";
import {codeCompagnys} from "../../../../../../../../../../utils/optionsSelect";

const FieldArrayAdversaires = (props) => {
    const { fields, changeValue, adversaires, compagnys, nbVehicules } = props

    useEffect(() => {
        fields.length === 0 && fields.push({})
    }, [fields])

    useEffect(() => {
        if (Number(nbVehicules) > 1){
            fields.push({})
        }
    }, [nbVehicules])

    return (
        <div>
            {
                fields.map((a, i) => (
                    <div key={i} className={'border border-color-secondary p-3 my-3'}>
                        <p className="h4">
                            Partie adverse n°{i + 1}
                            <i
                                className={'ml-2 fas fa-trash-alt text-color-secondary cursor-pointer hover-dark-color'}
                                onClick={() => fields.remove(i)}
                            />
                        </p>

                        <Field
                            name={`${a}.immat`}
                            component={TextFieldRedux}
                            label='Immatriculation'
                        />

                        <Field
                            name={`${a}.police`}
                            component={TextFieldRedux}
                            label="Numéro de contrat d'assurance"
                        />

                        <FilteredAutocomplete
                            name={`${a}.cie`}
                            label="Compagnie d'assurance"
                            labelOtherValue="Autre compagnie"
                            dataFiltered={_.filter(compagnys, c => _.includes(codeCompagnys, c.value))}
                            data={compagnys}
                            changeValue={changeValue}
                            onChange={(value) => changeValue('codegta', _.find(compagnys, ['value', value])?.codegta)}
                        />

                        <Field
                            name={`${a}.titre`}
                            component={RadioDesignButton}
                            label={'Civilité'}
                            data={[
                                {value: "M", label:"Monsieur"},
                                {value: "Mme", label:"Madame"},
                                {value: "Societe", label:"Société"}
                            ]}
                        />

                        <Field
                            name={`${a}.nom`}
                            component={TextFieldRedux}
                            label="Nom"
                        />

                        {
                            adversaires[i] && adversaires[i].titre !== 'Societe' &&
                            <Field
                                name={`${a}.prenom`}
                                component={TextFieldRedux}
                                label="Prénom"
                            />
                        }

                        <Field
                            name={`${a}.blesse`}
                            component={RadioDesignButton}
                            label={'Y-a-t-il des blessés dans cette partie adverse ?'}
                            data={[
                                {value: "T", label:"Oui"},
                                {value: "F", label:"Non"}
                            ]}
                        />

                        <Field
                            name={`${a}.adresse`}
                            component={RadioDesignButton}
                            label={'Avez-vous son adresse ?'}
                            data={[
                                {value: "T", label:"Oui"},
                                {value: "F", label:"Non"}
                            ]}
                        />

                        {
                            adversaires[i] && adversaires[i].adresse === 'T' && <div>
                                <Field
                                    name={`${a}.adresse1`}
                                    component={TextFieldRedux}
                                    label="Adresse"
                                />

                                <PostalCodeCity
                                    nameCodePostal={`${a}.cp`}
                                    nameVille={`${a}.ville`}
                                    label={'Ville de résidence'}
                                    changeValue={changeValue}
                                />

                            </div>
                        }
                    </div>
                ))
            }
            <div className={'text-right'}>
                <span className={'btn btn-primary'} onClick={() => fields.push({})}>Ajouter une partie adverse</span>
            </div>
        </div>
    );
};

FieldArrayAdversaires.defaultProps = {
    adversaires: [],
    compagnys: []
};

FieldArrayAdversaires.propTypes = {
    changeValue: PropTypes.func,
    adversaires: PropTypes.array,
    compagnys: PropTypes.array
};

export default FieldArrayAdversaires;
