import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import PoppinInformations from "./PoppinInformations";
import loader from "../../../Commun/Spinners/Loader/Loader";
import {getPopinsLink} from "../../../../redux/selectors/links/links.selectors";
import {fetchPopins} from "../../../../redux/actions/app/popins/popins.actions";
import {getPopinUi} from "../../../../redux/selectors/ui/ui.selectors";
import {getBelairIdUser} from "../../../../redux/selectors/user/user.selectors";

const mapStateToProps = state => {
    return {
        popin: getPopinUi(state),
        query: getPopinsLink(state),
        userBelairId: getBelairIdUser(state)
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    fetchPoppin: ({query}) => fetchPopins({query})
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { query } = stateProps
    const { fetchPoppin } = dispatchProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => fetchPoppin({query})
    }
}

const PoppinInformationsContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader()
)(PoppinInformations)

export default PoppinInformationsContainer
