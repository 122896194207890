export const listePays = [
    {
        value: "albanie",
        label: "Albanie"
    },
    {
        value: "allemagne",
        label: "Allemagne"
    },
    {
        value: "andorre",
        label: "Andorre"
    },
    {
        value: "autriche",
        label: "Autriche"
    },
    {
        value: "azerbaidjan",
        label: "Azerbaïdjan"
    },
    {
        value: "belgique",
        label: "Belgique"
    },
    {
        value: "bielorussie",
        label: "Biélorussi"
    },
    {
        value: "bosnie",
        label: "Bosnie-Herzegovine"
    },
    {
        value: "bulgarie",
        label: "Bulgarie"
    },
    {
        value: "chypre",
        label: "Chypre"
    },
    {
        value: "croatie",
        label: "Croatie"
    },
    {
        value: "danemark",
        label: "Danemark"
    },
    {
        value: "espagne",
        label: "Espagne"
    },
    {
        value: "estonie",
        label: "Estonie"
    },
    {
        value: "finlande",
        label: "Finlande"
    },
    {
        value: "gb",
        label: "Grande-Bretagne"
    },
    {
        value: "grece",
        label: "Grèce"
    },
    {
        value: "hongrie",
        label: "Hongrie"
    },
    {
        value: "italie",
        label: "Italie"
    },
    {
        value: "iran",
        label: "Iran"
    },
    {
        value: "irlande",
        label: "Irlande"
    },
    {
        value: "islande",
        label: "Islande"
    },
    {
        value: "israel",
        label: "Israël"
    },
    {
        value: "lettonie",
        label: "Lettonie"
    },
    {
        value: "lituanie",
        label: "Lituanie"
    },
    {
        value: "luxembourg",
        label: "Luxembourg"
    },
    {
        value: "macedoine",
        label: "Macédoine du Nord"
    },
    {
        value: "malte",
        label: "Malte"
    },
    {
        value: "maroc",
        label: "Maroc"
    },
    {
        value: "moldavie",
        label: "Moldavie"
    },
    {
        value: "montenegro",
        label: "Monténégro"
    },
    {
        value: "norvege",
        label: "Norvège"
    },
    {
        value: "pologne",
        label: "Pologne"
    },
    {
        value: "portugal",
        label: "Portugal"
    },
    {
        value: "tcheque",
        label: "République-Tchèque"
    },
    {
        value: "roumanie",
        label: "Roumanie"
    },
    {
        value: "russie",
        label: "Russie"
    },
    {
        value: "serbie",
        label: "Serbie"
    },
    {
        value: "slovaquie",
        label: "Slovaquie"
    },
    {
        value: "slovenie",
        label: "Slovénie"
    },
    {
        value: "suede",
        label: "Suède"
    },
    {
        value: "suisse",
        label: "Suisse"
    },
    {
        value: "tunisie",
        label: "Tunisie"
    },
    {
        value: "turquie",
        label: "Turquie"
    },
    {
        value: "ukraine",
        label: "Ukraine"
    }
]
