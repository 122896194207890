import React from 'react';
import moment from 'moment'
import {useHistory} from "react-router-dom";

const AccidentsContract = (props) => {
    const { accidents, idContrat, isFleet } = props
    let history = useHistory()

    return (
        <div className="table-responsive">
            {
                accidents.length === 0 ?
                    <p>Aucun sinistre.</p>
                    : <table className="table table-hover">
                        <thead>
                        <tr>
                            <th scope="col">Date de survenance</th>
                            { isFleet && <th scope="col">Véhicule</th> }
                            <th scope="col">N° de sinistre</th>
                            <th scope="col">Situation</th>
                            <th scope="col">Responsabilité</th>
                            <th scope="col">Détail</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            accidents.map(a => (
                                <tr key={a.id} onClick={() => history.push(`/mon-contrat/${idContrat}/mon-sinistre/${a.id}`)} className={'cursor-pointer'}>
                                    <td>{moment(a.dateSurv).format('L')}</td>
                                    { isFleet && <td>{a.marqueVehicule} {a.modeleVehicule}<br/>
                                        <b>{a.immatVehicule ? a.immatVehicule : '-'}</b>
                                    </td> }
                                    <td>{a.numCbt}</td>
                                    <td>{a.situation}</td>
                                    <td>{a.tauxResp} %</td>
                                    <td>
                                <span className={'h6'}>
                                    <i className={'fas fa-search text-color-secondary'} />
                                </span>
                                    </td>
                                </tr>
                            ))
                        }
                        </tbody>
                    </table>
            }
        </div>
    );
};

export default AccidentsContract;
