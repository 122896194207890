import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import loader from '../../../../../Commun/Spinners/Loader/Loader'
import Notifications from './Notifications'
import {fetchStats} from "../../../../../../redux/actions/app/stats/stats.actions";
import {getStatsLink} from "../../../../../../redux/selectors/links/links.selectors";
import {
    getContractsWaitingStats, getReceiptsRejet,
    getReceiptsWaiting,
    getTotalStats
} from "../../../../../../redux/selectors/stats/stats.selectors";

const mapStateToProps = state => {
    return {
        query: getStatsLink(state),
        stats: {
            total: getTotalStats(state),
            dossiersAttente: getContractsWaitingStats(state),
            impayes: getReceiptsWaiting(state),
            rejets: getReceiptsRejet(state)
        }
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    fetchStats: ({query}) => fetchStats({query})
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { query } = stateProps
    const { fetchStats } = dispatchProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => fetchStats({query}),
    }
}

const NotificationsContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader()
)(Notifications)

export default NotificationsContainer
