import {API_ERROR, API_SUCCESS, apiRequest} from '../../../actions/core/api/api.actions'
import {setError, setErrorResponse, setLoader} from '../../../actions/app/ui/ui.actions'
import {
    FETCH_QUITTANCES_CONTRACT,
    QUITTANCES_CONTRACT, setQuittancesContract
} from "../../../actions/app/contracts/quittances.contract.actions";

export const quittancesContractsMiddleware = () => next => action => {
    next(action)

    switch (action.type){

        case FETCH_QUITTANCES_CONTRACT:
            next([
                apiRequest({body: null, method: 'GET', url: action.payload.data, entity: QUITTANCES_CONTRACT}),
                setLoader({state: true, entity: QUITTANCES_CONTRACT})
            ])
            break

        case `${QUITTANCES_CONTRACT} ${API_SUCCESS}`:
            next([
                setQuittancesContract({data: action.payload.data.data ? action.payload.data.data : action.payload.data}),
                setError({state: false, entity: QUITTANCES_CONTRACT}),
                setLoader({state: false, entity: QUITTANCES_CONTRACT})
            ])
            break

        case `${QUITTANCES_CONTRACT} ${API_ERROR}`:
            next([
                setError({state: true, entity: QUITTANCES_CONTRACT}),
                setErrorResponse({entity: QUITTANCES_CONTRACT, data: action?.payload?.data?.response?.data}),
                setLoader({state: false, entity: QUITTANCES_CONTRACT})
            ])
            break

        default:
            break
    }
    return null
}
