import {connect} from 'react-redux';
import {compose} from 'redux';
import _ from 'lodash';
import VehiculeDetail from "./VehiculeDetail";
import {getFleetGetLink} from "../../../../../../../../../redux/selectors/links/links.selectors";
import {
    fetchFleetContract,
    FLEET_CONTRACT
} from "../../../../../../../../../redux/actions/app/contracts/fleet.contract.actions";
import loader from "../../../../../../../../Commun/Spinners/Loader/Loader";
import {
    getContract,
    getFleetContract,
    getIsWakamContract
} from "../../../../../../../../../redux/selectors/contracts/contracts.selectors";
import {getErrorEntity, getLoadingEntity} from "../../../../../../../../../redux/selectors/ui/ui.selectors";

const mapStateToProps = (state, ownProps) => {
    const { ids } = ownProps

    return {
        query: getFleetGetLink(state),
        ids,
        vehicule: getFleetContract(state),
        loaded: getLoadingEntity(state, FLEET_CONTRACT) === false,
        error: getErrorEntity(state, FLEET_CONTRACT),
        contract: getContract(state),
        isWakam: getIsWakamContract(state)
    }
}

const mapDispatchToProps = {
    fetchFleetContract
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { query, ids } = stateProps
    const { fetchFleetContract } = dispatchProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => fetchFleetContract({query: _.replace(query, '{id}', ids)}),

    }
}

const VehiculeDetailContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader()
)(VehiculeDetail)

export default VehiculeDetailContainer
