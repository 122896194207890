import React from 'react';
import PropTypes from 'prop-types'
import _ from "lodash";

const DashboardTable = (props) => {
    const { columns, data } = props

    return (
        <table className="table table-hover table-responsive">
            <thead>
            <tr>
                <th>&nbsp;</th>
                {
                    _.map(columns,y => (
                        <th scope="col" key={y}>{y}</th>
                    ))
                }
            </tr>
            </thead>
            <tbody>
            {
                _.map(data, (d, i) => (
                    <tr key={i}>
                        <td className={'font-weight-bold'}>{d.title}</td>
                        {
                            _.map(d.nb,(n, i) => (
                                <td key={i}>{d.format ? d.format(n) : n}</td>
                            ))
                        }
                    </tr>
                ))
            }
            </tbody>
        </table>
    );
};

DashboardTable.defaultProps = {
    columns: [],
    data: []
};

DashboardTable.propTypes = {
    columns: PropTypes.array,
    data: PropTypes.arrayOf(PropTypes.object)
};

export default DashboardTable;
