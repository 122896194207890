import {SET_NOTIFICATION} from '../../../actions/core/notifications/notifications.actions'
import Swal from "sweetalert2";

export const toast = (props) => {
    if (props.timeout) {
        props.timer = props.timeout
    }
    Swal.fire({
        toast: props.toast || true,
        position: props.position || 'top',
        showConfirmButton: props.showConfirmButton || false,
        timer: props.timer !== undefined ? parseInt(props.timer) : 10000,
        timerProgressBar: props.timerProgressBar || true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        },
        titleText: props.title || '',
        html: props.body || '',
        icon: props.type || 'info',
    })
}

export const notificationsMiddleware = () => next => action => {
    next(action)

    if (action.type.includes(SET_NOTIFICATION)) {
        const {type, body, timeout, title} = action.meta
        toast({type, body, timeout, title})
    }
}