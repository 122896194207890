export const DASHBOARD = '[Dashboard]'

export const FETCH_DASHBOARD = `${DASHBOARD} Fetch`
export const SET_DASHBOARD = `${DASHBOARD} Set`

export const fetchDashboard = ({query, filters}) => ({
    type: FETCH_DASHBOARD,
    payload: {
        data: query,
        filters
    }
})

export const setDashboard = ({data}) => ({
    type: SET_DASHBOARD,
    payload: {
        data
    }
})
