export const USERS_PROFIL = '[Profil Users]'

export const FETCH_USERS_PROFIL = `${USERS_PROFIL} Fetch`
export const SET_USERS_PROFIL = `${USERS_PROFIL} Set`
export const POST_USERS_PROFIL = `${USERS_PROFIL} Post`

export const fetchUsersProfil = ({query}) => ({
    type: FETCH_USERS_PROFIL,
    payload: {
        data: query
    }
})

export const setUsersProfil = ({data}) => ({
    type: SET_USERS_PROFIL,
    payload: {
        data
    }
})

export const postUsersProfil = ({form, query, uriGetUsers, idApporteur}) => ({
    type: POST_USERS_PROFIL,
    payload: {
        data: query,
        body: form,
        header: "application/json"
    },
    meta: {
        uriGetUsers,
        idApporteur,
        isBu: false
    }
})
