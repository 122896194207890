import React from 'react';

const RadioDesignButton = (props) => {
    const { input, meta: {touched, error}, label, data, type = 'radio', className } = props

    return (
        <div className={`form-group ${className}`}>
            <label className={'d-block'}>{label}</label>
            {
                data.map(d => (
                    <label className={`mr-3 btn btn-outline-secondary max-rounded ${input.value === d.value ? 'active' : ''}`} key={d.value}> {d.label}
                        <input type={type} name={input.name} value={d.value} onChange={input.onChange} checked={input.value === d.value} hidden/>
                    </label>
                ))
            }
            {touched && error && <small className={'invalid-feedback'}>{error}</small>}
        </div>
    );
};

export default RadioDesignButton;
