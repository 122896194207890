import {SET_STATS} from "../../actions/app/stats/stats.actions";

export const statsReducer = (stats = {}, action = {}) => {
    const {payload} = action

    if (action.type === SET_STATS) {
        return payload.data
    } else {
        return stats
    }
}
