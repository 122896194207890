import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import {formValueSelector, change} from "redux-form";
import {
    getFleetContract,
    getGarantiesContract,
    getImmatriculationContract,
    getIsFleetContract, getIsWakamContract,
    getMarqueVehiculeContract,
    getModeleVehiculeContract,
    getPrelevementContract
} from "../../../../../../../../../../redux/selectors/contracts/contracts.selectors";
import Reparation from "./Reparation";

const mapStateToProps = state => {
    const selector = formValueSelector('declaration-sinistre')

    return {
        reparateur: selector(state, 'reparateur'),
        isWakam: getIsWakamContract(state),
        showVisitExpert: selector(state, 'showVisitExpert'),
        garanties: !getIsFleetContract(state) ? getGarantiesContract(state) : getFleetContract(state).garanties,
        categorie: selector(state, 'catsinorigin'),
        reparateur_autre: selector(state, 'reparateur_autre'),
        remb_garage: selector(state, 'remb_garage'),
        immatriculation: getImmatriculationContract(state),
        marqueModele: `${getMarqueVehiculeContract(state)} ${getModeleVehiculeContract(state)}`,
        prelevement: getPrelevementContract(state)
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    changeValue: (field, value) => change('declaration-sinistre', field, value)
}, dispatch)

const ReparationContainer = connect(mapStateToProps, mapDispatchToProps)(Reparation)

export default ReparationContainer
