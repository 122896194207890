export const ACCIDENTS = '[Accidents]'
export const DOCUMENTS_ACCIDENT = '[Accident Documents]'
export const REJECT_ACCIDENT = '[Accident Reject]'
export const ACCIDENT_REPAIRER = '[Accident reparateur]'

export const FETCH_ACCIDENTS = `${ACCIDENTS} Fetch`
export const SET_ACCIDENTS = `${ACCIDENTS} Set`
export const POST_ACCIDENTS = `${ACCIDENTS} Post`
export const FETCH_DOCUMENTS_ACCIDENT = `${DOCUMENTS_ACCIDENT} Fetch`
export const SET_DOCUMENTS_ACCIDENT = `${DOCUMENTS_ACCIDENT} Set`
export const POST_REJECT_ACCIDENT = `${REJECT_ACCIDENT} Post`
export const POST_ACCIDENT_REPAIRER = `${ACCIDENT_REPAIRER} Post`

export const fetchAccidents = ({query, filters}) => ({
    type: FETCH_ACCIDENTS,
    payload: {
        data: query,
        filters
    }
})

export const setAccidents = ({data}) => ({
    type: SET_ACCIDENTS,
    payload:{
        data
    }
})

export const postAccidents = ({form, query, documents, idVehicule}) => ({
    type: POST_ACCIDENTS,
    payload: {
        data: query,
        body: {form, documents},
        otherData: idVehicule
    },
})

export const fetchDocumentsAccident = ({query}) => ({
    type: FETCH_DOCUMENTS_ACCIDENT,
    payload: {
        data: query
    }
})

export const setDocumentsAccident = ({data}) => ({
    type: SET_DOCUMENTS_ACCIDENT,
    payload: {
        data
    }
})

export const postRejectAccident = ({query, categorie}) => ({
    type: POST_REJECT_ACCIDENT,
    payload:{
        data: query,
        body: {categorie}
    }
})

export const postAccidentRepairer = ({form, query}) => ({
    type: POST_ACCIDENT_REPAIRER,
    payload: {
        data: query,
        body: form
    }
})