import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import loader from '../../../../../Commun/Spinners/Loader/Loader'
import PieceAttente from './PieceAttente'
import {getContractsLink} from "../../../../../../redux/selectors/links/links.selectors";
import {getErrorEntity, getLoadingEntity} from "../../../../../../redux/selectors/ui/ui.selectors";
import {CONTRACT, fetchContract} from "../../../../../../redux/actions/app/contracts/contracts.actions";
import {
    getDataContracts,
    getLinksContracts,
    getMetaContracts
} from "../../../../../../redux/selectors/contracts/contracts.selectors";

const mapStateToProps = state => {
    return {
        contracts: getDataContracts(state),
        filters: {waiting_document: true},
        meta: getMetaContracts(state),
        links: getLinksContracts(state),
        query: getContractsLink(state),
        loaded: getLoadingEntity(state, CONTRACT) === false,
        error: getErrorEntity(state, CONTRACT)
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    fetchContract: ({query, filters}) => fetchContract({query, filters})
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { query, filters } = stateProps
    const { fetchContract } = dispatchProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => fetchContract({query, filters})
    }
}

const PieceAttenteContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader()
)(PieceAttente)

export default PieceAttenteContainer
