import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import Card from '../../../../../../../Commun/Card/Card';
import { Field } from 'redux-form';
import TextFieldRedux from '../../../../../../../Commun/Inputs/Text/TextFieldRedux';
import SmallSpinner from '../../../../../../../Commun/Spinners/SmallSpinner';
import Autocomplete from '../../../../../../../Commun/Inputs/Autocomplete/Autocomplete';
import { getCitiesFromFrance, getCountries } from '../../../../../../../../utils/function';
import _ from 'lodash';
import { listFormeJuridique } from '../../../../../../../../utils/optionsSelect';
import SelectFieldRedux from '../../../../../../../Commun/Inputs/Select/SelectFieldRedux';

const InfosNaissance = (props) => {
    const { handleSubmit, loading, ville, pays, societe } = props;
    const history = useHistory();
    const [countries, setCountries] = useState([]);
    const [listVilles, setListVilles] = useState([]);
    const [query, setQuery] = useState('');
    const [queries, setQueries] = useState([]);

    useEffect(() => {
        (async function fetchCountries() {
            let countries = await getCountries();

            const listcountries = countries.map((c) => ({
                value: c.id,
                label: c.name,
            }));

            setCountries(listcountries);
        })();
    }, []);

    async function fetchCites(value) {
        let cities = await getCitiesFromFrance(value);
        if (!Array.isArray(cities)) {
            return;
        }
        let list = _.orderBy(cities, ['sInsee'], ['asc']);

        list = cities.map((c) => ({
            value: c.sBirthplace,
            label: c.sBirthplace,
        }));

        const newVilles = _.uniqBy([...listVilles, ...list], 'label');
        setListVilles(newVilles);
    }

    useEffect(() => {
        if (query && query.length >= 2 && pays === 'FRA' && !queries.includes(query)) {
            fetchCites(query);
            setQueries([...queries, query]);
        }
    }, [query, pays]);

    async function onHandleSubmit(e) {
        e.preventDefault();
        if (pays === 'FRA' && ville) {
            await getCitiesFromFrance(ville, 'ValidateFormBirth');
        }
        handleSubmit();
    }

    return (
        <div className={'p-lg-4'}>
            <Card>
                <div>
                    <h3 className={'m-0 pb-3 border-bottom border-dark'}>
                        Modifier les <span className='text-color-secondary'>Informations personnelles</span> de votre client
                    </h3>

                    <div className='mt-5'>
                        <span className={'btn btn-outline-secondary'} onClick={() => history.goBack()}>
                            <i className={'fas fa-chevron-left mr-3'} /> Retour
                        </span>
                    </div>

                    <div className='mt-5 col-lg-6 col-md-12 mx-auto'>
                        <form onSubmit={onHandleSubmit} className={'mt-5'}>
                            {societe ? (
                                <>
                                    <Field name='statut_juridique' component={SelectFieldRedux} label={'Statut juridique'}>
                                        <option value={''}>Sélectionnez le statut juridique de l'entreprise</option>
                                        {listFormeJuridique.map((item, index) => {
                                            return (
                                                <option key={index} value={item.id}>
                                                    {item.name}
                                                </option>
                                            );
                                        })}
                                    </Field>
                                    <Field name={'siren_siret'} component={TextFieldRedux} label={'N° SIREN/SIRET'} type={'tel'} required />
                                </>
                            ) : (
                                <>
                                    <Field name={'nom'} label={'Nom de naissance'} component={TextFieldRedux} maxLength={20} required />
                                    <Field name={`pays`} component={Autocomplete} label='Pays de naissance' data={countries} required />
                                    {pays === 'FRA' ? (
                                        <Field name={'ville'} component={Autocomplete} label={'Ville de naissance'} data={listVilles} setQuery={setQuery} required />
                                    ) : (
                                        <Field name={'ville'} component={TextFieldRedux} label={'Ville de naissance'} required />
                                    )}
                                </>
                            )}
                            <div className='text-center'>
                                {loading ? (
                                    <SmallSpinner />
                                ) : (
                                    <button type='submit' className={'btn btn-primary'}>
                                        Je valide les informations personnelles
                                    </button>
                                )}
                            </div>
                        </form>
                    </div>
                </div>
            </Card>
        </div>
    );
};

InfosNaissance.defaultProps = {};

InfosNaissance.propTypes = {
    handleSubmit: PropTypes.func,
    phone: PropTypes.string,
    email: PropTypes.string,
};

export default InfosNaissance;
