import {SET_DOCUMENTS_ACTIVE, SET_DOCUMENTS_INACTIVE} from "../../actions/app/documents/documents.actions";

export const initStateDocuments = {
    active: {},
    inactive: []
}

export const documentsReducer = (documents = initStateDocuments, action = {}) => {
    const {payload} = action

    switch (action.type) {
        case SET_DOCUMENTS_ACTIVE:
            return {...documents, active: payload.data}
        case SET_DOCUMENTS_INACTIVE:
            return {...documents, inactive: payload.data}
        default:
            return documents
    }
}
