import React from 'react'
import moto from "../../../../../../../../../../../assets/images/accidents/moto_topview.png";
import quad from "../../../../../../../../../../../assets/images/accidents/quad_topview.png";
import voiture from "../../../../../../../../../../../assets/images/accidents/voiture_topview.png";
import RadioAccidentRedux from "../../../../../../../../../../Commun/Inputs/RadioAccident/RadioAccidentRedux";
import {Field} from "redux-form";

const CardChoc = (props) => {
    const {produit} = props

    switch (produit) {
        case 'AUTOF3':
        case '7311_AUT1':
            return (
                <div className={'choc header-row text-center position-relative'} style={{margin: 60}}>
                    <Field
                        name={'point_choc'}
                        type={'voiture'}
                        component={RadioAccidentRedux}
                        label={'Selectionnez le point de choc initial :'}
                        data={[
                            {value: 'top_right', label:'top_right'},
                            {value: 'top_left', label:'top_left'},
                            {value: 'bottom_left', label:'bottom_left'},
                            {value: 'bottom_right', label:'bottom_right'},
                            {value: 'left', label:'left'},
                            {value: 'right', label:'right'},
                        ]}
                    />
                    <img src={voiture} alt={'Point de choc voiture'} height={200}/>
                </div>
            )

        case '7311_PVK':
            return (
                <div className={'choc header-row text-center position-relative'} style={{margin: 60}}>
                    <Field
                        name={'point_choc'}
                        type={'quad'}
                        component={RadioAccidentRedux}
                        label={'Selectionnez le point de choc initial :'}
                        data={[
                            {value: 'top_right', label:'top_right'},
                            {value: 'top_left', label:'top_left'},
                            {value: 'bottom_left', label:'bottom_left'},
                            {value: 'bottom_right', label:'bottom_right'},
                            {value: 'left', label:'left'},
                            {value: 'right', label:'right'},
                        ]}
                    />
                    <img src={quad} alt={'Point de choc quad'} height={250}/>
                </div>
            )

        default:
            return (
                <div className={'choc header-row text-center position-relative'} style={{margin: 60}}>
                    <Field
                        name={'point_choc'}
                        type={'moto'}
                        component={RadioAccidentRedux}
                        label={'Selectionnez le point de choc initial :'}
                        data={[
                            {value: 'top', label:'top'},
                            {value: 'top_left', label:'top_left'},
                            {value: 'middle_left', label:'middle_left'},
                            {value: 'bottom_left', label:'bottom_left'},
                            {value: 'bottom', label:'bottom'},
                            {value: 'top_right', label:'top_right'},
                            {value: 'middle_right', label:'middle_right'},
                            {value: 'bottom_right', label:'bottom_right'},
                        ]}
                    />
                    <img src={moto} alt={'Point de choc moto'} height={300}/>
                </div>
            )
    }
}

export default CardChoc
