import React from 'react';
import {Field} from "redux-form";
import SelectFieldRedux from "../../../../../../../../../Commun/Inputs/Select/SelectFieldRedux";
import _ from "lodash";
import Cadre from "../../../../../../../../../Commun/Cadre/Cadre";
import RadioDesignButton from "../../../../../../../../../Commun/Inputs/Radio/RadioDesignButton/RadioDesignButton";

const Police = (props) => {
    const {interventionPolice, fetchDocumentsAccident, catsin, equipementsDommage} = props

    return (
        <div className={'form-content h-100'}>
            <Cadre icon={'fas fa-shield-alt'} title={'Les forces de police sont-elles intervenues ?'} large>
                <Field
                    name='intervention_police'
                    component={RadioDesignButton}
                    label={'Il y a-t-il eu intervention des forces de police ?'}
                    data={[{value: "T", label:"Oui"}, {value: "F", label:"Non"}]}
                    required
                />
                {interventionPolice === 'T' &&
                    <>
                        <Field
                            name='document_police'
                            component={SelectFieldRedux}
                            label={'Sélectionnez le type de document reçu par votre client'}
                            onChange={(e) => fetchDocumentsAccident({
                                    categorie: _.split(catsin, '.')[0],
                                    souscategorie: _.split(catsin, '.')[1],
                                    equipements: equipementsDommage === 'T' ?? false,
                                    doc_police: e.target.value
                                })
                            }
                        >
                            <option value={null}>Choisissez le document</option>
                            <option value={'O'}>Procès verbal</option>
                            <option value={'M'}>Main courante</option>
                            <option value={'V'}>Rapport des autorités</option>
                            <option value={null}>Ne sait pas</option>
                        </Field>
                        <div className="alert alert-info mt-5" role="alert">
                            <p>Si vous avez un doute, fiez vous à l’entête du document transmis par les forces de police :</p>
                            <ul>
                                <li>S’il y a indiqué <b>“Procès verbal”</b> en tête, choisissez le document <b>Procès Verbal</b></li>
                                <li>S’il y a indiqué <b>“Main courante”</b> en entête, choisissez le document <b>Main Courante</b></li>
                                <li>S’il y a indiqué <b>“Rapport”</b> ou <b>“Triplicata d’accident”</b>, choisissez le document <b>“Rapport des autorités”</b></li>
                                <li>Pour tout autre document, indiquez <b>“Ne sait pas”</b>.</li>
                            </ul>
                        </div>
                    </>
                }
            </Cadre>
        </div>
    );
};

export default Police;
