import {connect} from 'react-redux'
import ErrorMessage from './ErrorMessage'
import {getErrorResponse} from "../../../redux/selectors/ui/ui.selectors";

const mapStateToProps = state => {
    return {
        error: getErrorResponse(state)
    }
}

const ErrorMessageContainer = connect(mapStateToProps)(ErrorMessage)

export default ErrorMessageContainer
