import {connect} from 'react-redux'
import {compose} from 'redux'
import FleetTable from "./FleetTable";
import {getFleetLink} from "../../../../../../../../redux/selectors/links/links.selectors";
import {
    fetchFleetContract,
    FLEET_CONTRACT
} from "../../../../../../../../redux/actions/app/contracts/fleet.contract.actions";
import loader from "../../../../../../../Commun/Spinners/Loader/Loader";
import {getFleetContract} from "../../../../../../../../redux/selectors/contracts/contracts.selectors";
import {getErrorEntity, getFiltersEntity, getLoadingEntity} from "../../../../../../../../redux/selectors/ui/ui.selectors";

const mapStateToProps = state => {
    return {
        query: getFleetLink(state),
        filters: getFiltersEntity(state, FLEET_CONTRACT),
        fleet: getFleetContract(state),
        error: getErrorEntity(state, FLEET_CONTRACT),
        loaded: getLoadingEntity(state, FLEET_CONTRACT) === false
    }
}

const mapDispatchToProps = {
    fetchFleetContract
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { query } = stateProps
    const { fetchFleetContract } = dispatchProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => fetchFleetContract({query, filters: { contrat: ownProps.id }})
    }
}

const FleetTableContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader()
)(FleetTable)

export default FleetTableContainer
