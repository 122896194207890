import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import {reduxForm, formValueSelector} from 'redux-form'
import Publicites from './Publicites'
import _ from "lodash";
import {getFlyersLink, getFlyersOrderLink} from "../../../../../redux/selectors/links/links.selectors";
import {
    fetchPublicites,
    PUBLICITES, PUT_PUBLICITES,
    putPublicites
} from "../../../../../redux/actions/app/publicites/publicites.actions";
import loader from '../../../../Commun/Spinners/Loader/Loader'
import {
    getDispositionGeneralesPublicites,
    getPlvPublicites, getPublicites
} from "../../../../../redux/selectors/publicites/publicites.selectors";
import {getErrorEntity, getLoadingEntity} from "../../../../../redux/selectors/ui/ui.selectors";
import validate from './validate'
import {
    getAddressCityUser,
    getAddressPostalCodeUser,
    getAddressStreet1User,
    getBelairIdUser,
    getEmailUser,
    getNameUser
} from "../../../../../redux/selectors/user/user.selectors";

const mapStateToProps = state => {
    const selector = formValueSelector('commande-publicite')

    return {
        commande: selector(state, 'commande'),
        plv: getPlvPublicites(state),
        dispositions: getDispositionGeneralesPublicites(state),
        publicites: getPublicites(state),
        uriFetch: getFlyersLink(state),
        uriPut: getFlyersOrderLink(state),
        loaded: getLoadingEntity(state, PUBLICITES) === false,
        error: getErrorEntity(state, PUBLICITES),
        putLoading: getLoadingEntity(state, PUT_PUBLICITES),
        putError: getErrorEntity(state, PUT_PUBLICITES),
        user_id: getBelairIdUser(state),
        initialValues:{
            name: getNameUser(state),
            email: getEmailUser(state),
            delivery_address: getAddressStreet1User(state),
            zip_code: getAddressPostalCodeUser(state),
            city: getAddressCityUser(state),
            commande: {}
        }
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    fetchPublicites: ({query}) => fetchPublicites({query}),
    putPublicites: ({query, form}) => putPublicites({query, form})
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { fetchPublicites, putPublicites } = dispatchProps
    const { uriFetch, uriPut, user_id } = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => fetchPublicites({query: _.replace(uriFetch, '{id}', user_id)}),
        onSubmit: (form) => putPublicites({query: _.replace(uriPut, '{id}', user_id), form})
    }
}

const PublicitesContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    reduxForm({
        form: 'commande-publicite',
        validate
    }),
    loader()
)(Publicites)

export default PublicitesContainer
