import React, {useState} from 'react';
import _ from 'lodash'
import Card from "../../../../Commun/Card/Card";
import LargeSpinner from "../../../../Commun/Spinners/LargeSpinner/LargeSpinner";

const FormulaireProducts = (props) => {
    const { buChildren, subscribeLinks, product } = props
    const [url, setUrl] = useState(subscribeLinks[product])
    const [nameConcess, setNameConcess] = useState(null)

    if (buChildren.length === 0){
        window.location = subscribeLinks[product]
        return <LargeSpinner />
    }

    const changeBuChildren = id => {
        if (id === '') {
                setUrl(subscribeLinks[product])
        } else {
            let concess = _.find(buChildren, ['codeBelair', id])
                setUrl(concess.subscribe_links[product])
                setNameConcess(concess.name)
            }
        }

    return (
        <div className={'p-lg-4'}>
            <Card>
                <div>
                    <h3 className={'m-0 pb-3 border-bottom border-dark'}>
                        Souhaitez-vous affecter le devis à l'un de <span className={'text-color-secondary'}>vos concessionnaires</span> ?
                    </h3>
                </div>

                <div className="max-width-7 mx-auto mt-5">
                    <div className="row">
                        <div className="col">
                            <label>Choisissez le concessionaire affecté au devis</label>
                            <select className="form-control" onChange={(e) => changeBuChildren(e.target.value)} >
                                <option value={''}>Aucun concessionnaire</option>
                                {
                                    buChildren.map(bu => (
                                        <option value={bu.codeBelair} key={bu.codeBelair}>{bu.name}</option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <label>&nbsp;</label>
                            <a href={url} target={'_blank'} className={'text-center btn btn-primary d-block max-rounded'}>
                                {
                                    nameConcess == null || url === subscribeLinks[product]
                                        ? 'Je fais mon devis sans affecter de concessionnaire'
                                        : `J'affecte "${nameConcess}" sur mon devis`
                                }
                            </a>
                        </div>
                    </div>
                    <p className={'text-center mt-5'}>Pour toute information complémentaire, n'hésitez pas à vous rapprocher de vos conseillers habituels !</p>
                </div>
            </Card>
        </div>
    );
};

export default FormulaireProducts;
