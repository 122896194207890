import {connect} from 'react-redux'
import {compose} from 'redux'
import Fleet from "./Fleet";
import {getFleetLink} from "@/redux/selectors/links/links.selectors";
import {
    fetchFleetContract,
    FLEET_CONTRACT
} from "@/redux/actions/app/contracts/fleet.contract.actions";
import {getFleetContract} from "@/redux/selectors/contracts/contracts.selectors";
import {getFiltersEntity} from "@/redux/selectors/ui/ui.selectors";

const mapStateToProps = state => {
    return {
        query: getFleetLink(state),
        filters: getFiltersEntity(state, FLEET_CONTRACT),
        fleet: getFleetContract(state)
    }
}

const mapDispatchToProps = {
    fetchFleetContract
}

const FleetContainer = compose(
    connect(mapStateToProps, mapDispatchToProps),
)(Fleet)

export default FleetContainer
