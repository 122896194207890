import {API_ERROR, API_SUCCESS, apiRequest} from '../../../actions/core/api/api.actions'
import {setError, setErrorResponse, setLoader} from '../../../actions/app/ui/ui.actions'
import {DASHBOARD, FETCH_DASHBOARD, setDashboard} from "../../../actions/app/dashboard/dashboard.actions";
import {setNotification} from "../../../actions/core/notifications/notifications.actions";

export const dashboardMiddleware = () => next => action => {
    next(action)

    switch (action.type){

        case FETCH_DASHBOARD:
            let url = action.payload.data

            if (action.payload.filters){
                for (let [key, value] of Object.entries(action.payload.filters)) {
                    url = value ? `${url}?${key}=${value}` : url
                }
            }

            next([
                apiRequest({body: null, method: 'GET', url, entity: DASHBOARD}),
                setLoader({state: true, entity: DASHBOARD})
            ])
            break

        case `${DASHBOARD} ${API_SUCCESS}`:
            next([
                setDashboard({data: action.payload.data.data}),
                setError({state: false, entity: DASHBOARD}),
                setLoader({state: false, entity: DASHBOARD})
            ])
            break

        case `${DASHBOARD} ${API_ERROR}`:
            next([
                setNotification({entity: DASHBOARD, body: action.payload.data.response.data.reason, title: 'Attention !', type: 'error'}),
                setError({state: true, entity: DASHBOARD}),
                setErrorResponse({entity: DASHBOARD, data: action?.payload?.data?.response?.data}),
                setLoader({state: false, entity: DASHBOARD})
            ])
            break

        default:
            break
    }
    return null
}
