import React from 'react';
import PropTypes from 'prop-types'
import Cadre from "../../../../../../../../../Commun/Cadre/Cadre";
import {Field} from "redux-form";
import UploadInlineContainer from "../../../../../../../../../Commun/Inputs/Upload/UploadInline/UploadInlineContainer";

const Documents = (props) => {
    const { categorie, listDocuments } = props

    return (
        <div className={'form-content'}>
            <Cadre icon={'fas fa-folder-open'} title={'Les documents attendus'}>
                {
                    listDocuments.map((d, i) => (
                        <div className="col" key={i}>
                            {
                                categorie === '10.0'
                                    ? <div className="row upload-content">
                                        <div className="col-10">
                                            <p>{d.description}</p>
                                        </div>
                                    </div>
                                    : <Field
                                        component={UploadInlineContainer}
                                        name={'upload'}
                                        formName='declaration-sinistre'
                                        label={d.description}
                                        code={`files[${d.code}]`}
                                    />
                            }
                        </div>
                    ))
                }
            </Cadre>
        </div>
    );
};

Documents.defaultProps = {
    categorie: '',
    listDocuments: []
};

Documents.propTypes = {
    categorie: PropTypes.string,
    listDocuments: PropTypes.array
};

export default Documents;
