import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import loader from '../../../../../../../Commun/Spinners/Loader/Loader'
import QuittanceDetail from './QuittanceDetail'
import {
    fetchQuittancesContract,
    QUITTANCES_CONTRACT
} from "../../../../../../../../redux/actions/app/contracts/quittances.contract.actions";
import _ from 'lodash'
import {getErrorEntity, getLoadingEntity} from "../../../../../../../../redux/selectors/ui/ui.selectors";
import {getReceiptsLink} from "../../../../../../../../redux/selectors/links/links.selectors";
import {getQuittancesContract} from "../../../../../../../../redux/selectors/contracts/contracts.selectors";

const mapStateToProps = (state, ownProps) => {
    const { ids } = ownProps.match.params

    return {
        quittance: getQuittancesContract(state),
        query: _.replace(getReceiptsLink(state), '{id}', ids),
        loaded: getLoadingEntity(state, QUITTANCES_CONTRACT) === false,
        error: getErrorEntity(state, QUITTANCES_CONTRACT)
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    fetchQuittancesContract: ({query}) => fetchQuittancesContract({query})
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { fetchQuittancesContract } = dispatchProps
    const { query } = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => fetchQuittancesContract({query})
    }
}

const QuittanceDetailContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader()
)(QuittanceDetail)

export default QuittanceDetailContainer
