const validate = values => {
    const errors = {}

    const requiredFields = [
        'adresseNew'
    ]

    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Ce champ est obligatoire'
        }
    })

    if(values['codePostalNew'] === undefined){
        errors['postalcodecity-address'] = 'Ce champ est obligatoire'
    } else if(values['codePostalNew'].substr(0, 2) === '97' || values['codePostalNew'].substr(0, 2) === '98' ){
        errors['postalcodecity-address'] = 'Attention : nous ne sommes pas en mesure d\'assurer les véhicules résidant à Monaco ou dans les dom/tom.'
    }else if(!values['assuranceCorse'] && values['codePostalNew'].substr(0, 2) === '20'){
        errors['postalcodecity-address'] = "Attention : nous n'assurons pas les véhicules en Corse.";
    }

    if( !values['sansStationnement'] && (values['new_adress_is_new_lieuStationnement'] === undefined || values['new_adress_is_new_lieuStationnement']==="F") ){

        if (values['cp_new_lieuStationnement'] === undefined) {
            errors['postalcodecity-lieuStationnement'] = 'Ce champ est obligatoire'
        } else if (values['cp_new_lieuStationnement'].substr(0, 2) === '97' || values['cp_new_lieuStationnement'].substr(0, 2) === '98' ) {
            errors['postalcodecity-lieuStationnement'] = 'Attention : nous ne sommes pas en mesure d\'assurer les véhicules résidant à Monaco ou dans les dom/tom.'
        }else if (!values['assuranceCorse'] && values['cp_new_lieuStationnement'].substr(0, 2) === '20'){
            errors['postalcodecity-lieuStationnement'] = "Attention : nous n'assurons pas les véhicules en Corse.";
        }

    }

    return errors
}

export default validate
