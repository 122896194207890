import React from 'react';
import moment from "moment";

const FleetTable = (props) => {
    const { data, history, id } = props

    return (
        <table className="amo-table table table-hover table-bordered table-responsive table-striped mt-3">
            <thead>
            <tr>
                <th scope="col" className={'align-text-top text-white'}>Numéro adhesion</th>
                <th scope="col" className={'align-text-top text-white'}>Marque, Modèle, Immatriculation</th>
                <th scope="col" className={'align-text-top text-white'}>Mise en circulation</th>
                <th scope="col" className={'align-text-top text-white'}>Actif / Inactif</th>
                <th scope="col" className={'align-text-top text-white'}>Détails</th>
            </tr>
            </thead>
            <tbody>
            {
                data.map(d => (
                    <tr key={d.adhesion} onClick={() => history.push(`/mon-contrat/${id}/ma-flotte/vehicule/${d.adhesion}`)}>
                        <td className={'align-middle'}>{d.adhesion}</td>
                        <td>{d.marque} {d.modele}<br/>
                            <b>{d.immatriculation ? d.immatriculation : '-'}</b>
                        </td>
                        <td className={'align-middle'}>{d.dateMEC ? moment(d.dateMEC).format('L') : '-'}</td>
                        <td className="align-middle h4"><i className={`fas fa-circle ${d.inactif ? 'text-danger' : 'text-success'}`} /></td>
                        <td className={'h4 align-middle'}><i className={'fas fa-search text-color-secondary'} /></td>
                    </tr>
                ))
            }
            </tbody>
        </table>
    );
};

FleetTable.defaultProps = {
    data: []
};

export default FleetTable;
