import React from 'react';
import PropTypes from 'prop-types'
import Card from "../../../../Commun/Card/Card";
import InformationsUserContainer from "./InformationsUser/InformationsUserContainer";
import CadreCollapseServices from "./CadreCollapseServices/CadreCollapseServices";
import RelanceUserContainer from "./RelanceUser/RelanceUserContainer";

const Profil = (props) => {
    const { isConcessionnaire } = props

    return (
        <div className={'p-lg-4'}>
            <Card>
                <div>
                    <h3 className={'m-0 pb-3 border-bottom border-dark'}>
                        Vos <span className={'text-color-secondary'}>services</span>
                    </h3>
                </div>

                {
                    !isConcessionnaire &&
                    <CadreCollapseServices
                        icon={'custom-icon-document'}
                        title={'Relance Pièces Manquantes'}
                        subtitle={'Ne courrez plus après les documents manquants !'}
                        newBloc={true}>
                        <RelanceUserContainer />
                    </CadreCollapseServices>
                }

                {
                    !isConcessionnaire &&
                    <CadreCollapseServices
                        icon={'fas fa-home'}
                        title={'Votre Fiche Conseil'}>
                        <InformationsUserContainer />
                    </CadreCollapseServices>
                }

            </Card>
        </div>
    );
};

Profil.defaultProps = {
    isConcessionnaire: false
};

Profil.propTypes = {
    isConcessionnaire: PropTypes.bool
};

export default Profil;
