import {LOGO_PROFIL, POST_LOGO_PROFIL} from "../../../actions/app/profil/logo.profil.actions";
import {API_ERROR, API_SUCCESS, apiRequest} from "../../../actions/core/api/api.actions";
import {setError, setLoader} from "../../../actions/app/ui/ui.actions";
import {setNotification} from "../../../actions/core/notifications/notifications.actions";
import {setProfil} from "../../../actions/app/profil/profil.actions";

export const logoProfilMiddleware = () => next => action => {
    next(action)

    const { payload } = action

    switch (action.type) {

        case POST_LOGO_PROFIL:

            let data = new FormData()
            data.append('logo', payload.body.logo)

            next([
                apiRequest({body: data, method: 'POST', url: payload.data, entity: LOGO_PROFIL}),
                setLoader({state: true, entity: LOGO_PROFIL})
            ])
            break

        case `${LOGO_PROFIL} ${API_SUCCESS}`:

            next([
                setNotification({entity: LOGO_PROFIL, body: `<p>Votre logo a bien été mise à jour.</p>`, type: 'success', title: 'Mise à jour du logo réussi'}),
                setProfil({user: {logo: action.payload.data.reason}}),
                setError({state: false, entity: LOGO_PROFIL}),
                setLoader({state: false, entity: LOGO_PROFIL})
            ])
            break

        case `${LOGO_PROFIL} ${API_ERROR}`:

            next([
                setNotification({entity: LOGO_PROFIL, body: payload.data.response.data.message, type: 'error', title: 'Attention ! Modification du logo :', timeout: "0"}),
                setError({state: true, entity: LOGO_PROFIL}),
                setLoader({state: false, entity: LOGO_PROFIL})
            ])
            break

        default:
            break
    }

}
