import React, {Fragment, useState, useEffect} from 'react';
import moment from "moment/moment";
import SmallSpinner from "../../../../../../../Commun/Spinners/SmallSpinner";
import {Redirect} from "react-router-dom";
import DatePickerFieldRedux from "../../../../../../../Commun/Inputs/Date/DatePickerFieldRedux";
import {Field} from "redux-form";
import ClausesHivernage from "./ClausesHivernage/ClausesHivernage";

const FormHivernage = (props) => {
    const {id_contrat, is_eligible_hivernage, can_activate_hivernage, dernier_hivernage, maxDate, loading, loading_fin, query_fin, handleSubmit, submitFinHivernage, hivernagePost, hivernagePostFin, maxDateEcheance, hivernage_reduction, loading_get_reduc, submitGetHivernageReduc, dateFin, formIsValid} = props
    const [tarifSimule, setTarifSimule] = useState(false)

    useEffect(() => {
        if (hivernage_reduction){
            setTarifSimule(true)
        }
    }, [hivernage_reduction])

    if (!is_eligible_hivernage){
        return <Redirect to={`/mon-contrat/${id_contrat}`} />
    }

    return (
        <>
            {dernier_hivernage?.statutCode === '1' &&
                <>
                    <p className={'font-weight-bold my-4'}>L'offre <span className={'text-warning'}>Ma période hivernale</span> de votre client est activée jusqu'au {moment(dernier_hivernage.dateFin, 'YYYY-MM-DD').format('DD/MM/YYYY')}.</p>
                    <div className="d-grid gap-2">
                        <div className={'text-center'}>
                            {
                                hivernagePostFin
                                    ? <div className={'alert-success alert'}>
                                        <p className={'mb-0'}>Ma période hivernale est bien désactivée.</p>
                                    </div>
                                    : loading_fin
                                        ? <SmallSpinner />
                                        : <button className={'btn btn-primary max-rounded null'}
                                                  type={'button'}
                                                  onClick={() => {
                                                      if (window.confirm('En désactivant de manière anticipée Ma période hivernale, il ne sera plus possible de la réactiver')) submitFinHivernage()
                                                  }}>
                                            Je désactive Ma période hivernale de manière anticipée
                                        </button>
                            }
                        </div>
                    </div>
                </>
            }

            {
                (dernier_hivernage?.periodeCourante && dernier_hivernage.statutCode !== '1') &&
                <>
                    { can_activate_hivernage
                        ? <p className={'mt-4 font-weight-bold'}>Votre client a bénéficié de <span className={'text-warning'}>Ma période hivernale</span> du {moment(dernier_hivernage.dateDebut, 'YYYY-MM-DD').format('DD/MM/YYYY')} au {moment(dernier_hivernage.dateFin, 'YYYY-MM-DD').format('DD/MM/YYYY')}.</p>
                        : <Fragment className={'mt-4'}>
                            <p className={'font-weight-bold'}>Votre client a bénéficié de <span className={'text-warning'}>Ma période hivernale</span> entre le {moment(dernier_hivernage.dateDebut, 'YYYY-MM-DD').format('DD/MM/YYYY')} et le {moment(dernier_hivernage.dateFin, 'YYYY-MM-DD').format('DD/MM/YYYY')}.</p>
                            <p className={'font-weight-bold'}>Il n’est plus possible de l’activer.</p>
                        </Fragment>
                    }
                </>
            }

            <div className={`${(dernier_hivernage?.statutCode === '1' || (dernier_hivernage?.periodeCourante && !can_activate_hivernage)) ? 'overlay' : ''}`}>
                <ClausesHivernage />

                {
                    can_activate_hivernage &&
                    <div className="col-lg-6 col-md-12 mx-auto">
                        <h4 className={'text-center font-weight-bold'}>Pour activer Ma période hivernale, <span className={'text-warning'}>choisissez uniquement la date de fin</span></h4>
                        <form onSubmit={handleSubmit} className={'mt-5'}>
                            <div className="row  my-4">
                                <div className="col col-md-6">
                                    <p>La date de début est <span className={'font-weight-bold'}>obligatoirement</span> celle d’aujourd’hui</p>
                                </div>
                                <div className="col col-md-6">
                                    {
                                        maxDateEcheance
                                            ? <p>La date de fin de Ma période hivernale ne peut pas dépasser la date d’échéance <span className={'font-weight-bold'}>{moment(maxDate).format('DD/MM/YYYY')}</span></p>
                                            : <p>La date de fin de Ma période hivernale de votre client ne peut dépasser le <span className={'font-weight-bold'}>{moment(maxDate).format('DD/MM/YYYY')}</span></p>
                                    }
                                </div>

                                <div className="col col-md-6">
                                    <p className={'mb-4'}>Date de début :<br/><span className={'fw-bold'}>{moment().format('DD/MM/YYYY')}</span></p>
                                </div>

                                <div className="col col-md-6 mb-2">
                                    <Field
                                        name={'dateFin'}
                                        component={DatePickerFieldRedux}
                                        label={'Date de fin'}
                                        minDate={moment().add(1, 'day')}
                                        maxDate={maxDate}
                                        onChange={() => setTarifSimule(false)}
                                    />
                                    {
                                        loading_get_reduc ? <SmallSpinner style={{ display: 'block', margin: 'auto' }} /> :
                                            <button
                                                className={'btn btn-primary max-rounded null'}
                                                type={'button'}
                                                disabled={!formIsValid}
                                                onClick={() => submitGetHivernageReduc()}>
                                                Estimer la réduction
                                            </button>
                                    }
                                    {
                                        (tarifSimule && hivernage_reduction) &&
                                        <p className="mb-0 mt-3">
                                            Pour Ma période hivernale du {moment().format('DD/MM/YYYY')} au {moment(dateFin).format('DD/MM/YYYY')}, <span className={'font-weight-bold'}>le&nbsp;montant estimé de votre réduction est de {hivernage_reduction} euros.</span><br/>
                                            Le montant exact de la réduction sera affiché sur la lettre&#8209;avenant transmise après activation.
                                        </p>
                                    }
                                </div>
                            </div>

                            <div className={'text-center'}>
                                {
                                    hivernagePost
                                        ? <div className={'alert-success alert'}>
                                            <p className={'mb-0'}>Ma période hivernale est bien activée.</p>
                                        </div>
                                        : loading
                                            ? <SmallSpinner style={{ display: 'block', margin: 'auto' }} />
                                            : <button
                                                className={'btn btn-primary max-rounded null'}
                                                type={'submit'}
                                                disabled={!tarifSimule}
                                            >
                                                {
                                                    (dernier_hivernage && dernier_hivernage?.periodeCourante)
                                                        ? 'Je réactive Ma période hivernale aux dates choisies'
                                                        : 'J\'active Ma période hivernale aux dates choisies'
                                                }
                                            </button>
                                }
                            </div>
                        </form>
                    </div>
                }
            </div>
        </>
    );
};

export default FormHivernage;