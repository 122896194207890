import React, {Component} from 'react'
import PropTypes from 'prop-types'
import { Route, Redirect } from 'react-router-dom'

class PrivateRoute extends Component{

    UNSAFE_componentWillMount() {
        this.props.resetLoader()
        window.scroll(0,0)
    }

    render(){
        const { login, token, fetchUser, fail, query, component: Component, ...rest } = this.props

        if ((!token && !login) || (fail && token && !login)){
            return <Redirect to={'/bienvenue'} />
        }

        if (fail || !token) {
            window.location.reload()
            return null
        }

        if (!login) {
            return fetchUser({query})
        }

        return (
            <Route
                {...rest}
                render={props => {
                    return <Component {...props} />
                }}
            />
        )
    }
}

PrivateRoute.propTypes = {
    component: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
}

export default PrivateRoute
