import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash'
import ChangeOriasContainer from "./ChangeOrias/ChangeOriasContainer";
import SelectFieldRedux from "../../../../../Commun/Inputs/Select/SelectFieldRedux";
import {getInsurers} from "../../../../../../utils/function";

const InformationsUser = (props) => {
    const { business_unit, immatOrias, formeJuridique, capital, nrc, partenaires, listeTypeSociete, villercs } = props
    const [ editOrias, setEditOrias ] = useState(false)
    const [insurers, setInsurers] = useState([])

    useEffect(() => {
        (async function fetchInsurers(){
            let insurers = await getInsurers()
            setInsurers(insurers)
        })()
    }, [])

    return (
        <div className={'font-size-16'}>
            <div className={'mt-5 mt-lg-0'}>
                <p><b>Fiche d’information et de conseil : APRIL Moto vous accompagne</b></p>

                <p>Afin de vous accompagner dans cette démarche, APRIL Moto met à votre disposition une Fiche Information Conseil (FIC)</p>

                <p>Cette solution, qui vous permet de réaliser simplement le recueil des besoins de votre prospect est constituée de plusieurs parties :</p>

                <p>Votre signature et celle de votre client : la preuve en cas de contrôle ou de litige que votre client est en accord avec le recueil réalisé. Il convient ensuite de conserver ce document dans le dossier de votre client ».</p>

                <ul>
                    <li>Les informations précontractuelles : les données obligatoires que vous devez communiquer à votre prospect selon la réglementation.</li>
                    <li>Le recueil des besoins : les questions nécessaires pour découvrir et répondre aux attentes de votre prospect.</li>
                    <li>L’offre : votre proposition d’un produit adapté selon les besoins exprimés et identifiés.</li>
                </ul>

                <p>A noter, les conseils des garanties proposées concernent uniquement les produits APRIL Moto.</p>
            </div>
            {
                !editOrias
                    ? <div>
                        <p>Numéro Orias : <b className={'d-block float-lg-right'}>{immatOrias}</b></p>
                        <p>Type de société : <b className={'d-block float-lg-right'}>{formeJuridique ? _.find(listeTypeSociete, ['id', formeJuridique]).value : '-'}</b></p>
                        { formeJuridique !== 'EI' && <p>Capital : <b className={'d-block float-lg-right'}>{capital && capital.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}</b></p> }
                        <p>Numéro RCS : <b className={'d-block float-lg-right'}>{nrc}</b></p>
                        <p>Ville RCS : <b className={'d-block float-lg-right'}>{villercs}</b></p>
                        <p>
                            Liste des sociétés partenaires :
                            <span className={'d-block float-lg-right'}>
                        <SelectFieldRedux>
                            {
                                partenaires.map((p, i) => (
                                    <option key={i}>{p}</option>
                                ))
                            }
                        </SelectFieldRedux>
                    </span>
                        </p>
                    </div>
                    : <ChangeOriasContainer insurers={insurers} business_unit={business_unit} setEditOrias={setEditOrias} />
            }
            { !editOrias && <button className={'btn btn-primary d-block max-rounded'} onClick={() => setEditOrias(true)}>Mettre à jour mes informations</button> }
        </div>
    );
};
InformationsUser.defaultProps= {
    business_unit: {}
}

InformationsUser.propTypes = {
    business_unit: PropTypes.object
};

export default InformationsUser;
