import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import loader from '../../../../../../../Commun/Spinners/Loader/Loader'
import AccidentDetail from './AccidentDetail'
import _ from 'lodash';
import {
    getAccidentContractLink,
} from "../../../../../../../../redux/selectors/links/links.selectors";
import {
    ACCIDENTS_CONTRACT,
    fetchAccidentsContract
} from "../../../../../../../../redux/actions/app/contracts/accidents.contract.actions";
import {getErrorEntity, getLoadingEntity} from "../../../../../../../../redux/selectors/ui/ui.selectors";
import {
    getAccidentsContract,
    getIsWakamContract
} from "../../../../../../../../redux/selectors/contracts/contracts.selectors";

const mapStateToProps = (state, ownProps) => {
    const { id, ids } = ownProps.match.params

    return {
        accident: getAccidentsContract(state),
        isWakam: getIsWakamContract(state),
        query: _.replace(_.replace(getAccidentContractLink(state), '{id}', id), '{ids}', ids),
        loaded: getLoadingEntity(state, ACCIDENTS_CONTRACT) === false,
        error: getErrorEntity(state, ACCIDENTS_CONTRACT)
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    fetchAccidentsContract: ({query}) => fetchAccidentsContract({query})
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { fetchAccidentsContract } = dispatchProps
    const { query } = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => fetchAccidentsContract({query})
    }
}

const AccidentDetailContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader()
)(AccidentDetail)

export default AccidentDetailContainer
