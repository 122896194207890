import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import TopArticles from "./TopArticles";
import {getArticlesLink} from "../../../../../../redux/selectors/links/links.selectors";
import {ARTICLES, fetchArticles} from "../../../../../../redux/actions/app/articles/articles.actions";
import loader from "../../../../../Commun/Spinners/Loader/Loader";
import {getAllArticles, getHomeArticles} from "../../../../../../redux/selectors/articles/articles.selectors";
import {getErrorEntity, getLoadingEntity} from "../../../../../../redux/selectors/ui/ui.selectors";

const mapStateToProps = state => {
    return {
        nbArticles: getAllArticles(state).length,
        topArticles: getHomeArticles(state),
        query: getArticlesLink(state),
        loaded: getLoadingEntity(state, ARTICLES) === false,
        error: getErrorEntity(state, ARTICLES)
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    fetchArticles: ({query}) => fetchArticles({query})
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { query } = stateProps
    const { fetchArticles } = dispatchProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => fetchArticles({query})
    }
}

const TopArticlesContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader()
)(TopArticles)

export default TopArticlesContainer
