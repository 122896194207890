import React, {useState} from 'react';
import PropTypes from 'prop-types'
import Card from "../../../Commun/Card/Card";
import _ from "lodash";

const PoppinInformations = (props) => {
    const { popin, userBelairId } = props
    const [close, setClose] = useState(false)
    const userIsExclude = _.includes(_.split(popin.exclude_id, ','), String(userBelairId))

    if (popin.active && !close && !userIsExclude){
        return (
            <div className={'w-100 position-fixed h-100 results-search-content'}>
                <div className={'results-card'}>
                    <Card>
                        <div className="row">
                            <h4 className={'p-3 text-color-secondary'}>{popin.title}</h4>
                        </div>
                        <div className="row">
                            <p className={'p-3'} style={{whiteSpace: 'pre-line'}}>{popin.content}</p>
                        </div>
                        <i className="fas fa-times" onClick={() => setClose(true)}/>
                    </Card>
                </div>
            </div>
        );
    }
    return null
};

PoppinInformations.defaultProps = {
    popin: {}
};

PoppinInformations.propTypes = {
    popin: PropTypes.object
};

export default PoppinInformations;
