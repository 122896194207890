import React, {useState} from 'react';
import {Link} from "react-router-dom";

const Notifications = (props) => {
    const { stats } = props
    const [dropdown, setDropdown] = useState(false)

    return (
        <div className={'float-right h-100'}>
            {
                stats.total > 0 && <div className={'px-5 pt-4 border-left h-100 cursor-pointer'} onMouseEnter={() => setDropdown(true)} onMouseLeave={() => setDropdown(false)}>
                    <div>
                        <i className="far fa-bell hover-animation-shake font-size-24" />
                        <span className="border-0 badge badge-pill badge-warning position-absolute text-white">{stats.total > 100 ? '+100' : stats.total}</span>
                    </div>
                </div>
            }
            {
                dropdown && <div style={{zIndex: 10, right: 0}} className={'border border-color-secondary position-absolute bg-white p-3'} onMouseEnter={() => setDropdown(true)} onMouseLeave={() => setDropdown(false)}>
                    { stats.impayes > 0 && <Link to={'/ma-gestion/mes-impayes'} className={'d-block text-dark my-1'}>
                        <i className="fas fa-folder-open mr-2 text-color-secondary" /><span className={'hover-underline d-inline-block'}>{stats.impayes} dossiers impayés</span>
                    </Link> }
                    { stats.dossiersAttente > 0 && <Link to={'/ma-gestion/mes-dossiers-en-attente'} className={'d-block text-dark my-1'}>
                        <i className="fas fa-folder-open mr-2 text-color-secondary" /><span className={'hover-underline d-inline-block'}>{stats.dossiersAttente} dossiers en attente</span>
                    </Link> }
                    { stats.rejets > 0 && <Link to={'/ma-gestion/mes-rejets-de-prelevement'} className={'d-block text-dark my-1'}>
                        <i className="fas fa-folder-open mr-2 text-color-secondary" /><span className={'hover-underline d-inline-block'}>{stats.rejets} rejets de prélèvement</span>
                    </Link> }
                </div>
            }
        </div>
    );
};

export default Notifications;
