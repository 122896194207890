import React from 'react';
import Cadre from "../../../../../../../../Commun/Cadre/Cadre";
import moment from "moment";
import {Link} from "react-router-dom";
import APILink from "../../../../../../../../Commun/APILink/APILink";
import DocumentsClient from "@/components/Commun/DocumentsClient/DocumentsClient";
import _ from "lodash";
import {getReason} from "@/utils/translate";

const VehiculeDetail = (props) => {
    const { vehicule, id, ids, souscripteur, numeroPolice, contract, isWakam } = props

    return (
        <>
            <h3 className={'m-0 pb-3 border-bottom border-dark'}>
                <b>{souscripteur.nom}</b> - N° <b>{numeroPolice}</b>
                <span className={`float-right h6`}>Situation : véhicule {vehicule.inactif ? 'inactif' : 'actif' } <span className="h4"><i className={`fas fa-circle ${vehicule.inactif ? 'text-danger' : 'text-success'} align-middle`} /></span></span>
            </h3>

            <div className="mt-5 d-flex align-items-start">
                <Link className={'btn btn-outline-secondary max-rounded'} to={`/mon-contrat/${id}`}>
                    <i className={'fas fa-chevron-left mr-3'}/> Retour au contrat
                </Link>

                {
                    !vehicule.inactif && !isWakam && <Link to={`/mon-contrat/${id}/ma-flotte/sortie-vehicule/${ids}`} className={`btn btn-danger max-rounded ml-3 mb-3`}>
                        Sortir le véhicule
                    </Link>
                }

                <Link to={contract.situation.statutCode !== "2" ? `/mon-contrat/${id}/declarer-un-sinistre/${ids}` : null} className={`btn btn-secondary max-rounded ml-3 mb-3 mr-3 ${contract.situation.statutCode === "2" && 'disabled amo-tooltip'}`}>
                    Déclarer un sinistre
                    <small className={'tooltiptext'}>{getReason(['CONTRAT_EN_ETABLISSEMENT'])}</small>
                </Link>



                {!vehicule.inactif && <div className="ml-3 d-inline-block"><DocumentsClient contract={contract} flotte={true} vehicule={vehicule} /></div> }
            </div>

            <div className={'row m-0'}>
                <div className="mt-5 col-lg-6 col-md-12 pl-lg-0 p-md-0 pr-lg-3">
                    <Cadre icon={'fas fa-motorcycle'} title={'Véhicule assuré'}>
                        <p>Adhésion : <b className={'float-right'}>{vehicule.adhesion}</b></p>
                        <p>Véhicule : <b className={'float-right'}>{vehicule.marque} {vehicule.modele}</b></p>
                        <p>Cylindrée : <b className={'float-right'}>{vehicule.cylindree ? <span>{vehicule.cylindree}cm<sup>3</sup></span> : '-'}</b></p>
                        <p>Immatriculation : <b className={'float-right'}>{vehicule.immatriculation}</b></p>
                        <p>Date de mise en circulation : <b
                            className={'float-right'}>{vehicule.dateMEC ? moment(vehicule.dateMEC).format('L') : '-'}</b>
                        </p>
                        <p>Date d'entrée : <b
                            className="float-right">{vehicule.dateEntree ? moment(vehicule.dateEntree).format('L') : '-'}</b>
                        </p>
                        <p>Date de sortie : <b
                            className="float-right">{vehicule.dateSortie ? moment(vehicule.dateSortie).format('L') : '-'}</b>
                        </p>
                        <p>Financement en leasing : <b className={'float-right'}>{vehicule.leasing === 'T' ? 'OUI' : 'NON'}</b></p>
                    </Cadre>
                </div>
                <div className="mt-5 col-lg-6 col-md-12 pl-lg-0 p-md-0 pr-lg-3">
                <Cadre icon={'fas fa-shield-alt'} title={'Garanties'}>
                        <ul>
                            {
                                vehicule.garanties.map((g, i) => {
                                    if (!_.includes(['FRAIS_GESTION', 'FRAIS_FRAC', 'FRAIS_VARIATION'], g.code_famille)){
                                        return <li key={i} className={'mb-3 font-size-14'}>{g.libelle}</li>
                                    }
                                })
                            }
                        </ul>
                    </Cadre>
                </div>
            </div>
        </>
    );
};

VehiculeDetail.defaultProps = {
    vehicule: {}
};

VehiculeDetail.propTypes = {};

export default VehiculeDetail;


