import React, {useRef, useState} from 'react';
import PropTypes from 'prop-types'

const UploadWithPreview = (props) => {
    const {label} = props
    const inputRef = useRef(null)
    const imgRef = useRef(null)
    const [img, setImg] = useState(false)

    const previewImg = img => {
        props.input.onChange(img)

        if (img){
            let reader = new FileReader()
            reader.onload = () => {
                imgRef.current.src = reader.result
            }
            reader.readAsDataURL(img)
            setImg(img)
        } else {
            setImg(false)
        }
    }


    return (
        <div className={'form-group'}>
            <div>
                <img
                    alt='Preview Upload'
                    ref={imgRef}
                    className={`w-25 mt-4 mb-2 mx-auto ${img.type === 'image/jpeg' || img.type === 'image/png' ? 'd-block' : 'd-none'}`}
                />
                <small className={'font-italic'}>{img.name}</small>
            </div>

            <span className={'btn btn-ternary my-4 d-block mx-auto max-rounded'} onClick={() => inputRef.current.click()}>{label}</span>

            <input
                ref={inputRef}
                hidden
                type='file'
                accept='image/*, application/pdf'
                onChange={(e) => previewImg(e.target.files[0])}
            />
            <div>
                <p className={'font-weight-bold mb-1'}>Taille max&nbsp;: 10Mo.</p>
                <small className={'font-italic'}>fichiers acceptés&nbsp;: png, jpg, tif, gif, pdf.</small>
            </div>
        </div>
    );
};

UploadWithPreview.defaultProps = {
    label: 'Choisissez un fichier'
};

UploadWithPreview.propTypes = {
    label: PropTypes.string
};

export default UploadWithPreview;
