import React, {useRef} from 'react';
import './search.scss'
import Card from "../../Card/Card";
import {Redirect, Link} from 'react-router-dom'

const Search = (props) => {
    const { postSearch, placeholder, className, loading, result, urlRedirect } = props
    const inputSearch = useRef()

    const submit = key => {
        if (key === 'Enter'){
            postSearch({string: inputSearch.current.value})
        }
    }

    return (
        <div className={'h-100'}>
            <div className={`input-group ${className}`}>
                <input style={{fontSize: 14}} type="text" className="no-focus form-control rounded-0 h-100 border-color-secondary border-left-0 border-top-0 border-right-0" placeholder={placeholder} ref={inputSearch} onKeyPress={(e) => submit(e.key)}/>
                <div className={'input-group-append p-4 h-100 cursor-pointer btn btn-secondary rounded-0'} onClick={() => postSearch({string: inputSearch.current.value})}>
                    {
                        loading ? <div className='spinner-border' style={{width: 20, height:20}} />
                            : <i className={`fas fa-search font-size-20`} />
                    }
                </div>
            </div>
            { result.data && result.data.length === 0 && <small className={'text-danger d-block font-weight-bold'}>Aucun résultat</small>}
            {
                result.data && result.data.length > 1 && <div className={'w-100 position-fixed h-100 results-search-content'}>
                    <div className={'results-card'}>
                        <Card>
                            <div className="row">
                                {
                                    result.data.map(r => (
                                        <div className="col-3 min-width-2 m-2 p-0" key={r.id}>
                                            <Link to={`${urlRedirect}${r.id}`} onClick={() => props.clearSearch()}>
                                                <div className={'border bg-white text-center p-3 cursor-pointer hover-translateY hover-border-secondary h-100 text-dark'} >
                                                    <small className={'d-block mb-1 font-weight-bold'}>{r.souscripteur ? r.souscripteur.nom : `${r.conducteur.nom} ${r.conducteur.prenom}`}</small>
                                                    <small className={'d-block font-weight-bold'}>{r.numeroPolice ? r.numeroPolice : r.id}</small>
                                                    <small className={'d-block'}>{r.title}</small>
                                                </div>
                                            </Link>
                                        </div>
                                    ))
                                }
                            </div>
                            <i className="fas fa-times" onClick={() => props.clearSearch()}/>
                        </Card>
                    </div>
                </div>
            }
            { result.data && result.data.length === 1 && <Redirect to={`${urlRedirect}${result.data[0].id}`} />}
        </div>
    );
};

Search.defaultProps = {};

Search.propTypes = {};

export default Search;
