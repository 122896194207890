import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import _ from 'lodash'
import AccidentForm from "./AccidentForm";
import {getContractLink} from "../../../../../../../../redux/selectors/links/links.selectors";
import {getErrorEntity, getLoadingEntity} from "../../../../../../../../redux/selectors/ui/ui.selectors";
import {CONTRACT, fetchContract} from "../../../../../../../../redux/actions/app/contracts/contracts.actions";
import loader from "../../../../../../../Commun/Spinners/Loader/Loader";
import {getIsFleetContract} from "../../../../../../../../redux/selectors/contracts/contracts.selectors";

const mapStateToProps = (state, ownProps) => {
    const { id } = ownProps.match.params

    return {
        idContract: id,
        query: getContractLink(state),
        loaded: getLoadingEntity(state, CONTRACT) === false,
        error: getErrorEntity(state, CONTRACT),
        isFleet: getIsFleetContract(state)
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    fetchContract: ({query}) => fetchContract({query})
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { query, idContract } = stateProps
    const { fetchContract } = dispatchProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => fetchContract({query: _.replace(query, '{id}', idContract)})
    }
}

const AccidentFormContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader()
)(AccidentForm)

export default AccidentFormContainer
