import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import Rejets from "./Rejets";
import loader from "../../../../../Commun/Spinners/Loader/Loader";
import {fetchImpayes, IMPAYES, postPayMail} from "../../../../../../redux/actions/app/impayes/impayes.actions";
import {
    getDataImpayes,
    getLinksImpayes,
    getMetaImpayes
} from "../../../../../../redux/selectors/impayes/impayes.selectors";
import {getAllReceiptsLink, getPayMailLink} from "../../../../../../redux/selectors/links/links.selectors";
import {
    getEaseUi,
    getErrorEntity,
    getFiltersEntity,
    getLoadingEntity
} from "../../../../../../redux/selectors/ui/ui.selectors";

const mapStateToProps = state => {
    return {
        rejets: getDataImpayes(state),
        links: getLinksImpayes(state),
        meta: getMetaImpayes(state),
        filters: getFiltersEntity(state, IMPAYES),
        query: getAllReceiptsLink(state),
        loaded: getLoadingEntity(state, IMPAYES) === false,
        error: getErrorEntity(state, IMPAYES),
        querySendPayMail: getPayMailLink(state),
        easeUi: getEaseUi(state),
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    fetchImpayes: ({query, filters}) => fetchImpayes({query, filters}),
    postPayMail: ({query, email, idQuittance, idContract}) => postPayMail({query, email, idQuittance, idContract})
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { query, querySendPayMail } = stateProps
    const { fetchImpayes, postPayMail } = dispatchProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => fetchImpayes({query, filters: {status: 'rejet_prelevement'}}),
        postPayMail: ({email, idQuittance, idContract}) => postPayMail({query: querySendPayMail, email, idQuittance, idContract})
    }
}

const RejetsContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader()
)(Rejets)

export default RejetsContainer
