import { connect } from 'react-redux';
import ReloadContract from "./ReloadContract";
import _ from "lodash";
import { getContractLink, } from "@/redux/selectors/links/links.selectors";
import { compose } from "redux";
import {CONTRACT, fetchContract} from "@/redux/actions/app/contracts/contracts.actions";
import loader from '../../../../../../../Commun/Spinners/Loader/Loader'
import {getErrorEntity, getLoadingEntity} from "../../../../../../../../redux/selectors/ui/ui.selectors"
const mapStateToProps = (state, ownProps) => {
    const { id } = ownProps.match.params

    return {
        query: _.replace(getContractLink(state), '{id}', id),
        loaded: getLoadingEntity(state, CONTRACT) === false,
        error: getErrorEntity(state, CONTRACT),
    };
}

const mapDispatchToProps = {
    fetchContract
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { fetchContract } = dispatchProps;
    const { query } = stateProps;

    return {
        ...ownProps,
        ...stateProps,
        ...dispatchProps,
        load: () => fetchContract({query})
    };
}

const ReloadContractContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader()
)(ReloadContract);

export default ReloadContractContainer;
