import React from 'react';
import PropTypes from 'prop-types'
import {useHistory} from "react-router-dom";
import Card from "../../../../../../../Commun/Card/Card";
import {Field} from "redux-form";
import TextFieldRedux from "../../../../../../../Commun/Inputs/Text/TextFieldRedux";
import {normalizeTel} from "../../../../../../../../utils/normalize";
import Cadre from "../../../../../../../Commun/Cadre/Cadre";
import SmallSpinner from "../../../../../../../Commun/Spinners/SmallSpinner";

const InfosPerso = (props) => {
    const { handleSubmit, phone, email, loading } = props
    const history = useHistory()

    return (
        <div className={'p-lg-4'}>
            <Card>
                <div>
                    <h3 className={'m-0 pb-3 border-bottom border-dark'}>
                        Modifier les <span className="text-color-secondary">Informations personnelles</span> de votre client
                    </h3>

                    <div className="mt-5">
                        <span className={'btn btn-outline-secondary'} onClick={() => history.goBack()}><i className={'fas fa-chevron-left mr-3'}/> Retour</span>
                    </div>

                    <div className="mt-5 col-lg-6 col-md-12 mx-auto">
                        <Cadre icon={'fas fa-id-card'} title={'Informations actuelles'}>
                            <div className="mt-5 mt-lg-0">
                                <p className={'max-width-4'}>Téléphone : <b className={'d-block float-lg-right'}>{phone}</b></p>
                                <p className={'max-width-4'}>Email : <b className={'d-block float-lg-right'}>{email}</b></p>
                            </div>
                        </Cadre>
                        <form onSubmit={handleSubmit} className={'mt-5'}>
                            <Field
                                name={'tel_mobile'}
                                label={'Téléphone mobile'}
                                component={TextFieldRedux}
                                normalize={normalizeTel}
                            />
                            <Field
                                name={'tel_fixe'}
                                label={'Téléphone fixe'}
                                component={TextFieldRedux}
                                normalize={normalizeTel}
                            />
                            <Field
                                name={'email'}
                                label={'Adresse e-mail de contact'}
                                type={'email'}
                                component={TextFieldRedux}
                            />
                            <Field
                                name={'email_confirmation'}
                                label={'Confirmez votre e-mail'}
                                type={'email'}
                                component={TextFieldRedux}
                            />

                            <div className="text-center">
                                {
                                    loading
                                        ? <SmallSpinner />
                                        : <button className={'btn btn-primary'}>Je valide les informations personnelles</button>
                                }
                            </div>
                        </form>
                    </div>
                </div>
            </Card>
        </div>
    );
};

InfosPerso.defaultProps = {};

InfosPerso.propTypes = {
    handleSubmit: PropTypes.func,
    phone: PropTypes.string,
    email: PropTypes.string
};

export default InfosPerso;
