import React, {useEffect} from 'react';
import {Link} from 'react-router-dom'

const Maintenance = () => {

    useEffect(() => {
        const meta = document.createElement('meta');
        meta.name = 'robots';
        meta.content = 'noindex';
        document.head.appendChild(meta);

        return () => document.head.removeChild(meta);
    }, []);

    return (
        <div className={'text-center pt-5 mt-5 full-screen'}>
            <h1 className={'text-secondary'}>Votre espace partenaire est en cours de maintenance.</h1>
            <p>Nos équipes techniques font le maximum pour rétablir votre espace,<br/> nous vous remercions de votre compréhension.</p>

            <div className="container">
                <div className="row">
                    <div className="col mt-5">
                        <a
                            href={'https://www.april-moto.com/'}
                            target={'_blank'}
                            rel="noopener noreferrer"
                            className={'btn btn-secondary m-2 max-rounded'}
                        >
                            Consulter le site APRIL Moto
                        </a>

                        <Link
                            to={'/'}
                            className={'btn btn-secondary m-2 max-rounded'}
                        >
                            Me reconnecter
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Maintenance;
