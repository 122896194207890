import {
    SET_IS_LOGIN,
    SET_LOADER,
    SET_MAINTENANCE,
    SET_ERROR,
    SET_IS_LOGOUT,
    RESET_LOADER,
    SET_FILTER,
    SET_CHANGE_ADDRESS,
    SET_RESPONSE_ERROR,
    SET_PAY_MAIL,
    SET_CLOSE_POPPIN_CHARTE,
    SET_RESPONSE_POST_ACCIDENT,
    SET_NEW_VALUE_POST_USER_REMINDER_PREFERENCES_PMQ,
    SET_HIVERNAGE_SUCCESS,
    SET_HIVERNAGE_REDUCTION,
} from '../../actions/app/ui/ui.actions'
import {QUOTE} from "../../actions/app/quotes/quotes.actions";
import {CONTRACT} from "../../actions/app/contracts/contracts.actions";
import {CLEAR_SEARCH, SET_SEARCH} from "../../actions/app/search/search.actions";
import {IMPAYES} from "../../actions/app/impayes/impayes.actions";
import {SET_DOCUMENTS_ACCIDENT} from "../../actions/app/accidents/accidents.actions";
import {SET_POPINS} from "../../actions/app/popins/popins.actions";
import {FLEET_CONTRACT} from "../../actions/app/contracts/fleet.contract.actions";

export const initStateUi = {
    login: false,
    logout: false,
    maintenance: false,
    filters: {},
    search: {},
    ease: {}
}

export const resetLoader = {
    [QUOTE]: true,
    [CONTRACT]: true,
    [IMPAYES]: true,
    [FLEET_CONTRACT]: true
}

export const uiReducer = (ui = initStateUi, action = {}) => {
    const { payload, meta } = action

    switch (true){
        case action.type.includes(SET_LOADER):
            return {...ui, loading: {...ui.loading, [meta]: payload}}
        case action.type.includes(RESET_LOADER):
            return {...ui, loading: {...ui.loading, ...resetLoader}}
        case action.type.includes(SET_ERROR):
            return {...ui, error: {...ui.error, [meta]: payload}}
        case action.type.includes(SET_RESPONSE_ERROR):
            return {...ui, errorResponse: payload}
        case action.type.includes(SET_IS_LOGIN):
            return {...ui, login: payload}
        case action.type.includes(SET_IS_LOGOUT):
            return {...ui, logout: payload}
        case action.type.includes(SET_MAINTENANCE):
            return {...ui, maintenance : payload}
        case action.type.includes(SET_FILTER):
            return {...ui, filters: {...ui.filters, [meta]: payload}}
        case action.type.includes(SET_SEARCH):
            return {...ui, search: {...ui.search, [meta]: payload.data}}
        case action.type.includes(CLEAR_SEARCH):
            return {...ui, search: {...ui.search, [payload]: {}}}
        case action.type.includes(SET_DOCUMENTS_ACCIDENT):
            return {...ui, ease: {...ui.ease, documentsAccident: payload.data}}
        case action.type.includes(SET_CHANGE_ADDRESS):
            return {...ui, ease: {...ui.ease, changeAddress: payload}}
        case action.type.includes(SET_PAY_MAIL):
            return {...ui, ease: {...ui.ease, paymail: {...ui.ease.paymail, [payload.data]: true}}}
        case action.type.includes(SET_POPINS):
            return {...ui, popin: payload.data}
        case action.type.includes(SET_CLOSE_POPPIN_CHARTE):
            return {...ui, closedPoppinCharte: payload.data}
        case action.type.includes(SET_RESPONSE_POST_ACCIDENT):
            return {...ui, postAccidentResponse: payload.data}
        case action.type.includes(SET_NEW_VALUE_POST_USER_REMINDER_PREFERENCES_PMQ):
            return {...ui, postNewValueUserPMQReminder: payload.data}
        case action.type.includes(SET_HIVERNAGE_SUCCESS):
            return {...ui, hivernagePost: {...ui.hivernagePost, [payload.entity]: payload.state}}
        case action.type.includes(SET_HIVERNAGE_REDUCTION):
            return {...ui, hivernageReduction: {...ui.hivernageReduction, [payload.entity]: payload.state}}

        default:
            return ui
    }
}
