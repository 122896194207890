import React, {useEffect} from 'react';
import PropTypes from 'prop-types'
import {Field} from "redux-form";
import RadioDesignButton from "../../../../../../../../../Commun/Inputs/Radio/RadioDesignButton/RadioDesignButton";
import TextFieldRedux from "../../../../../../../../../Commun/Inputs/Text/TextFieldRedux";
import PostalCodeCity from "../../../../../../../../../Commun/Inputs/PostalCodeCity/PostalCodeCity";

const FieldArrayTemoins = (props) => {
    const { fields, changeValue } = props

    useEffect(() => {
        fields.length === 0 && fields.push({})
    }, [fields])

    return (
        <div>
            {
                fields.map((t, i) => (
                    <div key={i} className={'border border-color-secondary p-3 my-3'}>
                        <p className="h4">
                            Témoin n°{i + 1}
                            <i
                                className={'ml-2 fas fa-trash-alt text-color-secondary cursor-pointer hover-dark-color'}
                                onClick={() => fields.remove(i)}
                            />
                        </p>

                        <Field
                            name={`${t}.titre`}
                            component={RadioDesignButton}
                            label={'Civilité'}
                            data={[
                                {value: "M", label:"Monsieur"},
                                {value: "Mme", label:"Madame"}
                            ]}
                        />

                        <Field
                            name={`${t}.nom`}
                            component={TextFieldRedux}
                            label="Nom"
                        />

                        <Field
                            name={`${t}.prenom`}
                            component={TextFieldRedux}
                            label="Prénom"
                        />

                        <Field
                            name={`${t}.portable`}
                            component={TextFieldRedux}
                            label="Téléphone"
                            type={'tel'}
                        />

                        <Field
                            name={`${t}.adresse1`}
                            component={TextFieldRedux}
                            label="Adresse"
                        />

                        <PostalCodeCity
                            nameCodePostal={`${t}.cp`}
                            nameVille={`${t}.ville`}
                            label={'Ville de résidence'}
                            changeValue={changeValue}
                        />

                    </div>
                ))
            }
            <div className={'text-right'}>
                <span className={'btn btn-primary'} onClick={() => fields.push({})}>Ajouter un témoin</span>
            </div>
        </div>
    );
};

FieldArrayTemoins.propTypes = {
    changeValue: PropTypes.func
};

export default FieldArrayTemoins;
