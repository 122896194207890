import React, {Fragment} from 'react';
import moment from 'moment'
import _ from 'lodash'
import Card from "../../../../../../../Commun/Card/Card";
import Cadre from "../../../../../../../Commun/Cadre/Cadre";
import {Link} from "react-router-dom";
import Timeline from "../../../../../../../Commun/Timeline/Timeline";

const AccidentDetail = (props) => {
    const { isWakam, accident, history } = props
    const { expert, documents, reparateur, reglements, upload_app_link } = accident

    const hasCoordonneeGarage = _.find(documents, ['code', '23'])
    const hasCoordonneeGarageNotReceived = hasCoordonneeGarage?.recu === false

    return (
        <div className={'p-4'}>
            <Card>
                <div>
                    <h4 className={'m-0 pb-3 border-bottom border-dark'}>
                        <b>{accident.conducteur}</b> - N° <b>{accident.numCbt} - {accident.libelle}</b>
                        <span className={`float-right h6`}>Nos équipes sont intervenues sur votre dossier le : <b>{moment(accident.dateDerniereIntervention).format('L')}</b></span>
                    </h4>
                </div>
                <div className="mt-5">
                    <span className={'btn btn-outline-secondary max-rounded'} onClick={() => history.goBack()}>
                        <i className={'fas fa-chevron-left mr-3'}/> Retour
                    </span>
                </div>

                {accident.documents && !isWakam && <Timeline accident={accident}/>}

                { accident.situationCode === "A" &&
                <div className={'alert alert-warning mt-5'}>
                    Votre déclaration de sinistre du {moment(accident.dateOuverture).format('L')} a bien été prise en compte et est en attente d'ouverture par l'un de nos conseillers. Nous reviendrons rapidement vers vous après étude de votre dossier.
                </div>
                }

                { accident.situationCode === "3" &&
                <div className={'alert alert-warning mt-5'}>
                    {
                        _.find(accident.documents, ['code', 'C']) && _.find(accident.documents, ['code', 'C']).recu
                            ? <><br />Votre déclaration du sinistre du {moment(accident.dateOuverture).format('L')} a bien été prise en compte et est en attente d'ouverture par l'un de nos conseillers. Nous reviendrons rapidement vers vous après étude de vote dossier.</>
                            : <><br />La responsabilité n'est pas encore déterminée car le justificatif de la déclaration est en attente.</>
                    }
                </div>
                }

                { (accident.situationCode === "8" && accident.type === "Corporel") &&
                <div className={'alert alert-warning mt-5'}>
                    La partie matérielle de son véhicule est clôturée, nous vous invitons à vous rapprocher d'Allianz pour toutes informations sur la partie corporelle :  corporel@allianz.fr
                </div>
                }

                <div className={'mt-5 row m-0'}>
                    <div className="col-6 pl-0">
                        <Cadre icon={'fas fa-file-alt'} title={'Détail du sinistre'}>
                            <p>Situation : <b className={'float-right'}>{accident.situation}</b></p>
                            <p>Date du sinistre : <b className={'float-right'}>{moment(accident.dateSurv).format('L')}</b></p>
                            <p>Ouvert le : <b className={'float-right'}>{moment(accident.dateOuverture).format('L')}</b></p>
                            <p>Clos le : <b className={'float-right'}>{accident.dateFermeture ? moment(accident.dateFermeture).format('L') : '-'}</b></p>
                            <p>Catégorie : <b className={'float-right'}>{accident.categorie}</b></p>
                            <p>
                                Responsabilité :
                                {
                                    accident.situationCode === "3"
                                        ? <b className={'float-right'}>Non déterminée</b>
                                        : accident.situationCode === "A"
                                        ? <b className={'float-right'}>En attente</b>
                                        : <b className={'float-right'}>{accident.tauxResp}%</b>
                                }
                            </p>
                            <p>Type : <b className={'float-right'}>{accident.type}</b></p>
                        </Cadre>
                    </div>
                    {
                        accident.situationCode !== "A" && <div className="col-6 pr-0">
                            <Cadre icon={'fas fa-user'} title={'Expert'}>
                                {
                                    expert && expert.raisonSociale
                                        ? <div>
                                            <p>Nom : <b className={'float-right'}>{expert.raisonSociale}</b></p>
                                            <p>Adresse : <b className={'float-right'}>{expert.rue1} {expert.rue2}</b></p>
                                            <p>Code postal, Ville : <b className={'float-right'}>{expert.codePostal} {expert.ville}</b></p>
                                            <p>Date de passage : <b className={'float-right'}>{moment(accident.datePassageExpert).format('L')}</b></p>
                                            <p>Téléphone : <b className={'float-right'}>{expert.phone}</b></p>
                                            <p>Fax : <b className={'float-right'}>{expert.fax ? expert.fax : '-'}</b></p>
                                            <p>Email : <b className={'float-right'}>{expert.email}</b></p>
                                        </div>
                                        : <p className={'font-weight-bold'}>- Aucun expert</p>
                                }
                            </Cadre>
                        </div>
                    }
                </div>

                <div className={'mt-5 row m-0'}>
                    <div className="col p-0">
                        <Cadre icon={'fas fa-file-alt'} title={'Liste des documents'}>
                            <div className="table-responsive">
                                <table className="table">
                                    <tbody>
                                    {
                                        _.map(_.orderBy(documents, ['recu', 'traite']), d => (
                                            <tr key={d.code} className={`${!d.recu && 'hover-background-table'}`}>
                                                <td className="font-weight-bold text-left align-middle border-top-0 border-bottom">
                                                    {
                                                        !d.recu ?
                                                            <i className={'fas fa-circle text-danger mr-2'} />
                                                            : d.traite ?
                                                            <i className={'fas fa-circle text-success mr-2'} />
                                                            : <i className={'fas fa-circle text-warning mr-2'} />
                                                    }
                                                    {d.libelle}
                                                </td>
                                                <td className={' border-top-0 border-bottom'}>
                                                    {d.commentaire || '-'}
                                                </td>
                                                <td className={'text-center border-top-0 border-bottom'}>
                                                    {
                                                        !d.recu ?
                                                            <div>
                                                                <p className={'bg-danger d-inline-block p-1 text-white rounded m-0 w-100 max-width-2'}>
                                                                    Non reçu
                                                                </p>
                                                            </div>
                                                            : d.traite ?
                                                            <div>
                                                                <p className={'bg-success d-inline-block p-1 text-white rounded m-0 w-100 max-width-2'}>
                                                                    Validé
                                                                </p>
                                                                <p className="text-success m-0">
                                                                    {d.dateTraitement ? `le ${moment(d.dateTraitement).format('L')}` : '-'}
                                                                </p>
                                                            </div>
                                                            : <div>
                                                                <p className={'bg-warning d-inline-block p-1 text-white rounded m-0 w-100 max-width-2'}>
                                                                    En cours de traitement
                                                                </p>
                                                            </div>
                                                    }
                                                </td>
                                            </tr>
                                        ))
                                    }
                                    </tbody>
                                </table>

                                <div className="d-flex mt-5 justify-content-around p-0">
                                    <div className="col-5">
                                        <a href={upload_app_link} target={'_blank'} rel="noopener noreferrer">
                                            <div className={'border text-dark p-4 hover-translateY hover-border-secondary h-100'}>
                                                <h4 className={'mb-3'}>Envoyez les documents par l'espace sécurisé</h4>
                                                <div className={'text-center'}>
                                                    <span className={'btn btn-outline-secondary max-rounded ml-2'}>
                                                        <i className="fas fa-cloud-upload-alt" /> Envoyer les documents
                                                    </span>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </Cadre>
                    </div>
                </div>

                <div className={'mt-5 row m-0'}>
                    <div className="col-6 pl-0">
                        <Cadre icon={'fas fa-shield-alt'} title={'Réparateur'}>
                            {
                                reparateur && reparateur.raisonSociale
                                    ? <div>
                                        <p>Nom : <b className={'float-right'}>{reparateur.raisonSociale}</b></p>
                                        <p>Adresse : <b className={'float-right'}>{reparateur.rue1} {reparateur.rue2}</b></p>
                                        <p>Code postal, Ville : <b className={'float-right'}>{reparateur.codePostal} {reparateur.ville}</b></p>
                                        <p>Téléphone : <b className={'float-right'}>{reparateur.phone}</b></p>
                                        <p>Fax : <b className={'float-right'}>{reparateur.fax ? reparateur.fax : '-'}</b></p>
                                        <p>Email : <b className={'float-right'}>{reparateur.email}</b></p>
                                    </div>
                                    : <p className={'font-weight-bold'}>- Aucun réparateur</p>
                            }

                            {
                                hasCoordonneeGarage && hasCoordonneeGarageNotReceived && accident.situationCode === "1" && _.isEmpty(accident.reparateur) && accident.categorieCode !== '10' &&
                                <Link to={`/mon-contrat/${accident.id_contrat}/mon-sinistre/${accident.id}/ajouter-reparateur`} className={`btn btn-primary max-rounded`}>
                                    Ajouter un réparateur
                                </Link>
                            }
                        </Cadre>
                    </div>
                    <div className="col-6 pr-0">
                        <Cadre icon={'fas fa-euro-sign'} title={'Règlement'}>
                            <div className="table-responsive pt-4">
                                <table className="table table-hover">
                                    <thead>
                                    <tr>
                                        <th scope="col">Nom</th>
                                        <th scope="col">Date</th>
                                        <th scope="col">Débit</th>
                                        <th scope="col">Informations</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        reglements && reglements.map((r, i) => (
                                            <tr key={i}>
                                                <td>{r.raisonSociale}</td>
                                                <td>{moment(r.date).format('L')}</td>
                                                <td>{r.debit}&nbsp;€</td>
                                                <td className={'amo-tooltip text-center h6'}>
                                                    {
                                                        moment.duration(moment().diff(moment(r.date))).asDays() < 30
                                                            ? <Fragment>
                                                                <i className="fas fa-exclamation-triangle text-color-secondary" />
                                                                {
                                                                    r.titre === 'GAR'
                                                                        ?  <small className={'tooltiptext'}>Nous adressons la lettre chèque au réparateur sous un délai moyen de 10 jours.</small>
                                                                        :  <small className={'tooltiptext'}>Votre assuré recevra une lettre-chèque sous une délai moyen de 10 jours.</small>
                                                                }
                                                            </Fragment>
                                                            : '-'
                                                    }
                                                </td>
                                            </tr>
                                        ))
                                    }
                                    </tbody>
                                </table>
                            </div>
                        </Cadre>
                    </div>
                </div>
            </Card>
        </div>
    );
};

export default AccidentDetail;
