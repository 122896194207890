import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import {formValueSelector, reduxForm} from 'redux-form'
import Webcallback from './Webcallback'
import {getErrorEntity, getLoadingEntity} from "../../../redux/selectors/ui/ui.selectors";
import {WS_FORMULAIRE_URL} from "../../../constants/constants";
import {postWebcallback, WEBCALLBACK} from "../../../redux/actions/app/webcallback/webcallback.actions";
import validate from './validate'
import moment from "moment";
import _ from 'lodash'

const mapStateToProps = (state, ownProps) => {
    const selector = formValueSelector('webcallback')

    return {
        loading: getLoadingEntity(state, WEBCALLBACK),
        erreur: getErrorEntity(state, WEBCALLBACK),
        initialValues: {
            'date': moment(),
        },
        date: selector(state, 'date'),
        isLSA: ownProps.match.params.key === 'gx3Gi-K7D74Yt87J75l33Aohw4ns-U_H3wvHiLqIOYoKvaQ_s2daNeTckWtXeyoG'
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    postWebcallback
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { match: { params: { key } } } = ownProps
    const { postWebcallback } = dispatchProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        onSubmit: (form) => {
            let clone = _.cloneDeep(form)
            clone.date = moment(form.date).format('L')
            return postWebcallback({form: clone, query: WS_FORMULAIRE_URL+`/besoin-d-aide/`+ key +`/recontact`})
        }
    }
}

const WebcallbackContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    reduxForm({
        form: 'webcallback',
        validate
    })
)(Webcallback)

export default WebcallbackContainer
