import {connect} from 'react-redux'
import Collection from './Collection'
import {getSubscribeLinksUser} from "../../../../../../redux/selectors/user/user.selectors";

const mapStateToProps = state => {

    return {
        subscribeLinks: getSubscribeLinksUser(state)
    }
}

const CollectionContainer = connect(mapStateToProps)(Collection)

export default CollectionContainer
