import React from 'react';
import Card from "../../../../../Commun/Card/Card";
import ContractsTableContainer from './ContractsTable/ContractsTableContainer'
import collectionImg from '../../../../../../assets/pictos/produits/campingcar.png'

const ContractsListe = (props) => {
    const { apporteurs, filters, fetchContract, query, subscribeLinks, isConcessionnaire, isCourtier } = props

    return (
        <div className={'p-lg-4'}>
            <Card>
                <div>
                    <h3 className={'m-0 pb-3 border-bottom border-dark'}>
                        Retrouvez <span className={'text-color-secondary'}>les contrats</span> de votre portefeuille
                        {
                            (isConcessionnaire || isCourtier) && <a href={subscribeLinks.collection} target={'_blank'} rel="noopener noreferrer" className={'btn btn-primary float-right max-rounded'}>
                                <img src={collectionImg} alt='collection' className={'mr-lg-2'} />
                                <span className={'d-none d-lg-inline-block'}>Connexion APRIL Collection & loisirs</span>
                            </a>
                        }
                    </h3>

                    {
                        (isConcessionnaire || isCourtier) && <div className="row mt-5">
                                <div className="col-lg-3 col-md-6 mb-2">
                                    <label>Type de produit</label>
                                    <select className="form-control" onChange={(e) => fetchContract({query, filters: {...filters, product: e.target.value}})} value={filters.product}>
                                        <option value={''}>Tous les produits</option>
                                        <option value={'SCOOTER'}>Scooter</option>
                                        <option value={'MOTO'}>Moto</option>
                                        <option value={'MOTO VERTE'}>Moto Verte</option>
                                        <option value={'QUAD'}>Quad</option>
                                        <option value={'CYCLO'}>Cyclo</option>
                                        <option value={'AUTO'}>Auto</option>
                                        <option value={'e-Trottineur'}>Trottinette</option>
                                        <option value={'VELO'}>Vélo</option>
                                        <option value={'DELIVERY'}>Livraison</option>
                                    </select>
                                </div>

                                <div className="col-lg-3 col-md-6 mb-2">
                                    <label>Apporteur d'affaires</label>
                                    <select className={'form-control'} onChange={(e) => fetchContract({query, filters: {...filters, id_apporteur: e.target.value}})} value={filters.id_apporteur}>
                                        <option value={''}>Tous les apporteurs</option>
                                        {
                                            apporteurs.map(a => (
                                                <option key={a.codeBelair} value={a.codeBelair}>{a.name} - {a.ville}, {a.codePostal}</option>
                                            ))
                                        }
                                    </select>
                                </div>

                                <div className="col-lg-3 col-md-6 mb-2">
                                    <label>Statut des contrats</label>
                                    <select className={'form-control'} onChange={(e) => fetchContract({query, filters: {...filters, status: e.target.value}})} value={filters.status}>
                                        <option value={''}>Tous les contrats</option>
                                        <option value={'1'}>Contrats en cours</option>
                                        <option value={'2'}>Contrats en établissement</option>
                                        <option value={'3'}>Contrats à régulariser</option>
                                        <option value={'6'}>Contrats résiliés</option>
                                        <option value={'I'}>Contrats résiliés / impayés</option>
                                        <option value={'A'}>Contrats avenant en attente</option>
                                    </select>
                                </div>
                            </div>
                    }

                    <ContractsTableContainer />

                </div>
            </Card>
        </div>
    );
};

export default ContractsListe;
