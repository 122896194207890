import React from 'react';
import {BrowserRouter, Route, Switch, Redirect} from 'react-router-dom'
import _ from 'lodash'
import PrivateRouteContainer from './PrivateRoute/PrivateRouteContainer'
import WithTrackerComponent from '../Commun/GTM/WithTrackerComponent'
import Maintenance from "./Maintenance/Maintenance";
import LoginAprilMotoContainer from "./LoginAprilMoto/LoginAprilMotoContainer";
import LoginAprilOnContainer from "./LoginAprilOn/LoginAprilOnContainer";
import WhitelabelContainer from "./Whitelabel/WhitelabelContainer";
import ErreurDeConnexion from "./LoginAprilOn/ErreurDeConnexion";
import WebcallbackContainer from "./Webcallback/WebcallbackContainer";
import ChangeMdpContainer from "./ChangeMdp/ChangeMdpContainer";

const App = (props) => {
    const { maintenance } = props

    return (
        <BrowserRouter>
            <Switch>
                {maintenance && !_.startsWith(window.location.pathname, '/maintenance') && <Redirect to={'/maintenance'} />}
                <Route exact path='/connexion' component={WithTrackerComponent(LoginAprilMotoContainer)} />
                <Route exact path='/login/:token/:action?/:id?' component={WithTrackerComponent(LoginAprilOnContainer)} />
                <Route exact path='/bienvenue' component={WithTrackerComponent(ErreurDeConnexion)} />
                <Route exact path='/maintenance' component={WithTrackerComponent(Maintenance)} />
                <Route exact path='/webcallback/:key' component={WithTrackerComponent(WebcallbackContainer)} />
                <Route exact path='/activation-mot-de-passe/:token' component={WithTrackerComponent(ChangeMdpContainer)} />
                <PrivateRouteContainer path='/' component={WhitelabelContainer} />
            </Switch>
        </BrowserRouter>
    )
}

export default App
