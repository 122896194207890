import {connect} from 'react-redux'
import {compose} from 'redux'
import {
    getLastDateCharterSigned,
    getUserIsConcessionnaire,
    getUserIsConcessionnaireGP,
    getUserIsConcessionnairePremium, getUserIsCourtier
} from "../../../redux/selectors/user/user.selectors";
import Layout from "./Layout";

const mapStateToProps = state => {
    return {
        isConcessionnaire: getUserIsConcessionnaire(state),
        isCourtier: getUserIsCourtier(state),
        isConcessionnairePremium: getUserIsConcessionnairePremium(state),
        isConcessionnaireGP: getUserIsConcessionnaireGP(state),
        lastDateCharterSigned: getLastDateCharterSigned(state)
    }
}


const LayoutContainer = compose(
    connect(mapStateToProps)
)(Layout)


export default LayoutContainer
