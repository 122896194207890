import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import loader from '../../Commun/Spinners/Loader/Loader'
import Whitelabel from './Whitelabel'
import {fetchWhitelabel, WHITELABEL} from "../../../redux/actions/app/whitelabel/whitelabel.actions";
import {getWhitelabelLink} from "../../../redux/selectors/links/links.selectors";
import _ from 'lodash'
import {getWhitelabelUser} from "../../../redux/selectors/user/user.selectors";
import {getLoadingEntity} from "../../../redux/selectors/ui/ui.selectors";

const mapStateToProps = state => {
    return {
        query: _.replace(getWhitelabelLink(state), '{id}', getWhitelabelUser(state)),
        loaded: getLoadingEntity(state, WHITELABEL) === false
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    fetchWhitelabel: ({query}) => fetchWhitelabel({query})
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { fetchWhitelabel } = dispatchProps
    const { query } = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => fetchWhitelabel({query}),
    }
}

const WhitelabelContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader()
)(Whitelabel)

export default WhitelabelContainer
